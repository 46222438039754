export default {
  PROFILE_PICTURE: 'profilePicture',
  DIVERSITY: 'diversity',
  COURSEWORK: 'coursework',
  BULLETS_AND_BUZZWORDS: 'bulletsAndBuzzwords',
  WHY_COMPANY: 'whyCompany',
  EXTRAS: 'extras',
  PREVIEW_RESUME: 'previewResume',
  OPPORTUNITY_LOCATIONS: 'opportunityLocations',
  PORTFOLIO: 'portfolio',
  TRANSCRIPT: 'transcript',
};
