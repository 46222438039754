import { FieldArray, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BackButton, ForwardButton } from 'components/v2/buttons';
import { Form, FormRow, FormActionButtonsRow, Radio } from 'components/v2/forms/components';
import { Heading, HeadingLevel } from 'components/v2/heading';
import { levelRoutes, LEVEL_STEPS } from 'constant';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { isStudyAbroadCompletedSelector, isStudyAbroadLoadingSelector } from 'store/v2/level/selectors';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  locationCountriesIsLoadingSelector,
  locationCountriesSelector,
  locationStatesErrorSelector,
  locationStatesIsLoadingSelector,
  locationStatesSelector,
} from 'store/v2/shared/selectors';

import StudyAbroadExperienceForm from './components/StudyAbroadExperienceForm';
import { studyAbroadExperienceInitialValues, validationSchema } from './form-config';
import { StyledAddButton } from './StudyAbroad.styled';

const StudyAbroad = () => {
  const dispatch = useDispatch();

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.studyAbroad);

  const [showForm, setShowForm] = useState(() => initialValues.studiesAbroad?.length > 0);

  const isStudyAbroadCompleted = useSelector(isStudyAbroadCompletedSelector);
  const isStudyAbroadLoading = useSelector(isStudyAbroadLoadingSelector);

  const isStatesOptionsLoading = useSelector(locationStatesIsLoadingSelector);
  const statesOptions = useSelector(locationStatesSelector);
  const statesOptionsLoadFailure = useSelector(locationStatesErrorSelector);

  const isCountriesOptionsLoading = useSelector(locationCountriesIsLoadingSelector);
  const countriesOptions = useSelector(locationCountriesSelector);
  const countriesOptionsLoadFailure = useSelector(locationStatesErrorSelector);

  useEffect(() => {
    if (!isStatesOptionsLoading && statesOptions.length === 0 && !statesOptionsLoadFailure) {
      dispatch(sharedActionsCreator.statesOptionsRequest());
    }

    if (!isCountriesOptionsLoading && countriesOptions.length === 0 && !countriesOptionsLoadFailure) {
      dispatch(sharedActionsCreator.countriesOptionsRequest());
    }
  }, [statesOptions.length, isStatesOptionsLoading, countriesOptions.length, isCountriesOptionsLoading]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={values => {
        dispatch(
          levelActionsCreator.saveLevelFormValuesRequest(
            LEVEL_STEPS.studyAbroad,
            values,
            true,
            levelRoutes.researchAndPrograms
          )
        );
      }}
    >
      {({ values, setFieldValue, handleSubmit, isValid }) => {
        const handleGoBack = () => {
          dispatch(
            levelActionsCreator.saveLevelFormValuesRequest(
              LEVEL_STEPS.studyAbroad,
              values,
              isStudyAbroadCompleted,
              levelRoutes.academics
            )
          );
        };

        const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const hasExperience = e.target.value === 'yes';

          setShowForm(hasExperience);
          if (hasExperience && values.studiesAbroad.length === 0) {
            setFieldValue('studiesAbroad', [studyAbroadExperienceInitialValues], true);
          }
          if (!hasExperience) {
            setFieldValue('studiesAbroad', []);
          }
        };

        return (
          <Form onSubmit={handleSubmit} className="container px-0">
            <FormRow>
              <div className="col-12 d-flex">
                <Heading level={HeadingLevel.h2}>Have you studied abroad while in college?</Heading>
              </div>
            </FormRow>
            <FormRow>
              <div className="col-3">
                <Radio
                  name="studyAbroad"
                  className="mb-3"
                  optionName="Yes"
                  value="yes"
                  onChange={handleRadioChange}
                  checked={showForm}
                />
              </div>
              <div className="col-6">
                <Radio
                  name="studyAbroad"
                  className="mb-3"
                  optionName="No"
                  value="no"
                  onChange={handleRadioChange}
                  checked={!showForm}
                />
              </div>
            </FormRow>
            {values.studiesAbroad.length > 0 && (
              <FieldArray
                name="studiesAbroad"
                render={({ push, remove }) => (
                  <>
                    {values.studiesAbroad.map((entry, index) => (
                      <StudyAbroadExperienceForm
                        formId={`studiesAbroad.${index}`}
                        key={index}
                        index={index}
                        data={entry}
                        handleRemove={remove}
                        canBeRemoved={values.studiesAbroad.length > 1}
                      />
                    ))}
                    <div>
                      <StyledAddButton
                        onClick={() => {
                          push(studyAbroadExperienceInitialValues);
                        }}
                      >
                        Add another
                      </StyledAddButton>
                    </div>
                  </>
                )}
              />
            )}

            <FormRow>
              <FormActionButtonsRow>
                <BackButton className="col-6 col-md-3" disabled={isStudyAbroadLoading} onClick={handleGoBack} />
                <ForwardButton
                  className="col-6 col-md-3"
                  isLoading={isStudyAbroadLoading}
                  type="submit"
                  disabled={!isValid || isStudyAbroadLoading}
                />
              </FormActionButtonsRow>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export default StudyAbroad;
