import { useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { BackButton, ForwardButton } from 'components/v2/buttons';
import {
  Form,
  FormActionButtonsRow,
  FormRow,
  Input,
  Label,
  PhoneInput,
  Radio,
  Select,
} from 'components/v2/forms/components';
import { Heading, HeadingLevel } from 'components/v2/heading';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS, levelConfigs, levelRoutes, listLevels } from 'constant';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { isContactInformationLoadingSelector } from 'store/v2/level/selectors';
import { ContactInformationFormValues } from 'store/v2/level/types';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  locationCitiesIsLoadingSelector,
  locationCitiesSelector,
  locationCountriesIsLoadingSelector,
  locationCountriesSelector,
  locationStatesErrorSelector,
  locationStatesIsLoadingSelector,
  locationStatesSelector,
} from 'store/v2/shared/selectors';
import getOption from 'utils/form-builder/get-option';

import { validationSchema } from './form-config';

const ContactInformation: React.FC = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.contactInformation);

  const isContactInformationLoading = useSelector(isContactInformationLoadingSelector);

  const isStatesOptionsLoading = useSelector(locationStatesIsLoadingSelector);
  const statesOptions = useSelector(locationStatesSelector);
  const statesOptionsLoadFailure = useSelector(locationStatesErrorSelector);

  const isCitiesOptionsLoading = useSelector(locationCitiesIsLoadingSelector);
  const citiesOptions = useSelector(locationCitiesSelector);

  const isCountriesOptionsLoading = useSelector(locationCountriesIsLoadingSelector);
  const countriesOptions = useSelector(locationCountriesSelector);
  const countriesOptionsLoadFailure = useSelector(locationStatesErrorSelector);

  const { values, errors, touched, isValid, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik<ContactInformationFormValues>({
      initialValues,
      validationSchema,
      validateOnMount: true,
      onSubmit: values => {
        dispatch(
          levelActionsCreator.saveLevelFormValuesRequest(
            LEVEL_STEPS.contactInformation,
            values,
            true,
            levelRoutes.highSchool
          )
        );
      },
    });

  const phoneInputProps = useMemo(() => ({ name: 'phone', required: true }), []);

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.contactInformation, values));
  }, 600);

  const handlePhoneInputChange = useCallback(value => {
    setFieldValue('phone', value);
  }, []);

  const handleChangeLocation = useCallback(event => {
    const isInternational = event.target.value === 'International';

    if (isInternational) {
      setFieldValue('stateOfHome', '');
      setFieldValue('cityOfHome', '');
    } else {
      setFieldValue('countryOfHome', '');
      setFieldValue('countryCityOfHome', '');
    }

    setFieldValue('internationalHome', isInternational);
  }, []);

  const handleGeneralOptionChange = useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value?.id, true);
    },
    [setFieldValue]
  );

  const handleLocationStateOptionChange = useCallback(
    (value, metaData) => {
      handleGeneralOptionChange(value, metaData);

      dispatch(sharedActionsCreator.stateCitiesOptionsRequest(value.id));

      setFieldValue('cityOfHome', '');
    },
    [setFieldValue, dispatch]
  );

  const handleGoBack = useCallback(() => {
    push(levelConfigs[listLevels.ONE_A].goBackConfig.url);
  }, []);

  useEffect(() => {
    if (!isStatesOptionsLoading && statesOptions.length === 0 && !statesOptionsLoadFailure) {
      dispatch(sharedActionsCreator.statesOptionsRequest());
    }

    if (!isCountriesOptionsLoading && countriesOptions.length === 0 && !countriesOptionsLoadFailure) {
      dispatch(sharedActionsCreator.countriesOptionsRequest());
    }
  }, [statesOptions.length, isStatesOptionsLoading, countriesOptions.length, isCountriesOptionsLoading]);

  useEffect(() => {
    // Need to fetch city options if a state was selected
    if (values?.stateOfHome) {
      dispatch(sharedActionsCreator.stateCitiesOptionsRequest(values?.stateOfHome));
    }
  }, []);

  useEffect(() => {
    // To be able to open a temporary resume with the fresh data need to update the store constantly
    debouncedSaveLevelFormValues(values);
  }, [values]);

  return (
    <Form className="container px-0" onSubmit={handleSubmit}>
      <FormRow>
        <div className="col-12 d-flex">
          <Heading level={HeadingLevel.h2}>Contact Information</Heading>
        </div>
      </FormRow>
      <Paper>
        <FormRow>
          <div className="col-6">
            <Input
              className="mb-3"
              name="firstName"
              label="First Name"
              placeholder="First Name"
              value={values?.firstName}
              error={touched?.firstName ? errors?.firstName : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>
          <div className="col-6">
            <Input
              className="mb-3"
              name="middleName"
              label="Middle Name"
              placeholder="Middle Name"
              value={values?.middleName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-12">
            <Input
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              value={values?.lastName}
              error={touched?.lastName ? errors?.lastName : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>
          <div className="col-12">
            <Input
              name="linkedInURL"
              label="LinkedIn URL"
              placeholder="Linkedin.com/in/___"
              value={values?.linkedInURL}
              error={touched?.linkedInURL ? errors?.linkedInURL : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-12">
            <PhoneInput
              value={values.phone}
              error={touched.phone && errors.phone ? errors.phone : ''}
              onChange={handlePhoneInputChange}
              onBlur={handleBlur}
              inputProps={phoneInputProps}
            />
          </div>
          <div className="col-12">
            <Label
              htmlFor="internationalHome"
              tooltip="This is the address that will be shown in the header section of your resume."
              required
            >
              Permanent/Home
            </Label>
          </div>
          <div className="col-3">
            <Radio
              name="internationalHome"
              className="mb-3"
              optionName="US"
              value="US"
              onChange={handleChangeLocation}
              checked={!values.internationalHome}
            />
          </div>
          <div className="col-6">
            <Radio
              name="internationalHome"
              className="mb-3"
              optionName="International"
              value="International"
              onChange={handleChangeLocation}
              checked={values.internationalHome}
            />
          </div>
          {!values.internationalHome && (
            <>
              <div className="col-6">
                <Select
                  className="mb-0"
                  name="stateOfHome"
                  placeholder="State"
                  value={getOption(statesOptions, values?.stateOfHome, 'id')}
                  error={touched?.stateOfHome ? errors?.stateOfHome : ''}
                  options={statesOptions}
                  onChange={handleLocationStateOptionChange}
                  isLoading={isStatesOptionsLoading}
                  required
                />
              </div>
              <div className="col-6">
                <Select
                  className="mb-0"
                  name="cityOfHome"
                  placeholder="City"
                  value={getOption(citiesOptions, +values?.cityOfHome, 'id')}
                  error={touched?.cityOfHome ? errors?.cityOfHome : ''}
                  options={citiesOptions}
                  onChange={handleGeneralOptionChange}
                  isLoading={isCitiesOptionsLoading}
                  required
                />
              </div>
            </>
          )}
          {values.internationalHome && (
            <>
              <div className="col-6 ">
                <Select
                  className="mb-0"
                  name="countryOfHome"
                  placeholder="Country"
                  value={getOption(countriesOptions, +values?.countryOfHome, 'id')}
                  error={touched?.countryOfHome ? errors?.countryOfHome : ''}
                  options={countriesOptions}
                  onChange={handleGeneralOptionChange}
                  isLoading={isCountriesOptionsLoading}
                  required
                />
              </div>
              <div className="col-6">
                <Input
                  className="mb-0"
                  name="countryCityOfHome"
                  placeholder="City"
                  value={values?.countryCityOfHome}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.countryCityOfHome ? errors?.countryCityOfHome : ''}
                />
              </div>
            </>
          )}
        </FormRow>
      </Paper>
      <FormActionButtonsRow className="mt-4">
        <BackButton className="col-6" onClick={handleGoBack} disabled={isContactInformationLoading}>
          {levelConfigs[listLevels.ONE_A].goBackConfig.title}
        </BackButton>
        <ForwardButton
          className="col-6 col-md-3"
          type="submit"
          disabled={!isValid || isContactInformationLoading}
          isLoading={isContactInformationLoading}
        />
      </FormActionButtonsRow>
    </Form>
  );
};

export default ContactInformation;
