import * as Yup from 'yup';

import { LocationInformationFormValues } from 'store/v2/register/types';

export const locationInitialValues: LocationInformationFormValues = {
  internationalHome: false,
  stateOfHome: '',
  cityOfHome: '',
  stateOfUniversity: '',
  cityOfUniversity: '',
  countryOfHome: '',
  countryCityOfHome: '',
};

const requiredMessage = 'This field is required';

export const locationInformationFormConfig = {
  locationInitialValues,
  validationSchema: Yup.object().shape({
    internationalHome: Yup.boolean().required(requiredMessage),
    stateOfHome: Yup.string().when('internationalHome', {
      is: false,
      then: Yup.string().required(requiredMessage),
    }),
    cityOfHome: Yup.string().when('internationalHome', {
      is: false,
      then: Yup.string().required(requiredMessage),
    }),
    countryOfHome: Yup.string().when('internationalHome', {
      is: true,
      then: Yup.string().required(requiredMessage),
    }),
    countryCityOfHome: Yup.string().when('internationalHome', {
      is: true,
      then: Yup.string().required(requiredMessage),
    }),
    stateOfUniversity: Yup.string().required(requiredMessage),
    cityOfUniversity: Yup.string().required(requiredMessage),
  }),
};
