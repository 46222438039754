import { Text } from '@hivediversity/common-lib/components/base/styled';
import { opportunityTypes } from '@hivediversity/common-lib/constant';
import locationPropTypes from '@hivediversity/common-lib/utils/prop-types/location';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';
import React from 'react';

const OpportunityLocation = ({ location, opportunityType, $bsStyle }) => {
  const jobOnSite = location?.type === 1;
  const jobOnSiteOrRemote = location?.type === 3;
  const jobRemote = location?.type === 2;
  const state = location?.state ? location.state.name : '';
  const countryName = location ? location.countryName : null;
  const countryId = location ? location?.country?.id : '';
  const country = location ? location?.country?.country : '';
  const city = location?.city ? location.city.city : '';
  const cityName = location?.cityName ? location?.cityName : null;

  return (
    <>
      {jobRemote ? (
        <>
          {opportunityType?.toLowerCase() === opportunityTypes.EVENT ? (
            <Text $bsStyle={$bsStyle}>Virtual</Text>
          ) : (
            <Text $bsStyle={$bsStyle}>Remote</Text>
          )}
        </>
      ) : (
        <>
          {location?.international ? (
            <Text $bsStyle={$bsStyle}>
              {jobOnSite && `${countryId === 214 ? countryName : country},  ${countryId !== 214 ? cityName : ''}`}
              {jobOnSiteOrRemote &&
                `${countryId === 214 ? countryName : country}, ${countryId !== 214 ? cityName : ''}`}
            </Text>
          ) : (
            <>
              <Text $bsStyle={$bsStyle}>
                {jobOnSite && `US, ${city}, ${state}`}
                {jobOnSiteOrRemote && `US, ${city}, ${state}`}
              </Text>
            </>
          )}
        </>
      )}
    </>
  );
};

OpportunityLocation.propTypes = {
  location: locationPropTypes.isRequired,
  opportunityType: PropTypes.oneOf([
    capitalize(opportunityTypes.JOB),
    capitalize(opportunityTypes.EVENT),
    capitalize(opportunityTypes.INTERNSHIPS),
    '',
  ]).isRequired,
  $bsStyle: PropTypes.shape({}),
};

OpportunityLocation.defaultProps = {
  $bsStyle: null,
};

export default OpportunityLocation;
