import { controls } from 'constant';

const getPlaceholder = control => {
  switch (control) {
    case controls.INDUSTRIES:
      return 'Add a new industry above';
    case controls.JOBS_INTEREST:
      return 'Add a type of job above';
    case controls.CAREERS:
      return 'Add a keyword above';
    case controls.LINKS:
      return 'Add a link above';
    default:
      return `Add a ${control} above` || '';
  }
};
export default getPlaceholder;
