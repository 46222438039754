import { MessageError } from '@hivediversity/common-lib/components';
import { FieldArray, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { AddButton, BackButton, ForwardButton } from 'components/v2/buttons';
import { Form, FormRow, FormActionButtonsRow } from 'components/v2/forms/components';
import { Heading, HeadingLevel } from 'components/v2/heading';
import { levelRoutes, LEVEL_STEPS, customStyles } from 'constant';
import LinesTextbox from 'pages/v2/level/components/LineTextbox';
import {
  ExperienceFormHeader as LinesHeader,
  ExperienceFormSubTitle as LinesSubTitle,
  ExperienceFormTitle as LinesTitle,
  ExperienceFormWrapper as LinesWrapper,
} from 'pages/v2/level/components/LineTextbox/LineTextbox.styled';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import {
  isAdditionalDetailsAthleticsCompletedSelector,
  isAdditionalDetailsAthleticsLoadingSelector,
} from 'store/v2/level/selectors';

import { getDefaultExperienceLine, MAX_LINES_NUMBER, validationSchema } from './form-config';

const AdditionalDetailsCollegiateAthletics: React.FC = () => {
  const dispatch = useDispatch();
  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.collegiateAthletics);

  const isCompleted = useSelector(isAdditionalDetailsAthleticsCompletedSelector);
  const isLoading = useSelector(isAdditionalDetailsAthleticsLoadingSelector);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={values => {
          dispatch(
            levelActionsCreator.saveLevelFormValuesRequest(
              LEVEL_STEPS.additionalDetails,
              values,
              true,
              levelRoutes.finalStepLevel1A,
              true
            )
          );
        }}
      >
        {({ values, handleSubmit, isValid }) => {
          const handleGoBack = () => {
            dispatch(
              levelActionsCreator.saveLevelFormValuesRequest(
                LEVEL_STEPS.additionalDetailsCollegiateAthletics,
                values,
                isCompleted,
                levelRoutes.collegiateAthletics
              )
            );
          };
          const isNotValidCountLinesInEveryExperience = values.d1Athletics.some(experience => {
            return experience.lines.length < 2 && !experience.lines[0]?.isMerge;
          });

          return (
            <Form className="container px-0" onSubmit={handleSubmit}>
              <FormRow>
                <div className="col-12 d-flex">
                  <Heading level={HeadingLevel.h2}>Describe your Experience.</Heading>
                </div>
              </FormRow>

              {values.d1Athletics?.map((val, parentIndex) => (
                <FieldArray
                  key={parentIndex}
                  name={`d1Athletics[${parentIndex}].lines`}
                  render={({ push, remove, swap, replace, insert }) => (
                    <>
                      {(val.lines || []).length > 0 ? (
                        <LinesWrapper>
                          <LinesHeader>
                            <LinesTitle>{val.sportName}</LinesTitle>
                            <LinesSubTitle>{val.universityName}</LinesSubTitle>
                          </LinesHeader>
                          {val.lines.map((_line, lineIndex) => (
                            <LinesTextbox
                              key={lineIndex}
                              baseName={`d1Athletics[${parentIndex}].lines`}
                              index={lineIndex}
                              remove={remove}
                              swap={swap}
                              replace={replace}
                              insert={insert}
                              lines={val.lines}
                            />
                          ))}
                          {val.lines.length < MAX_LINES_NUMBER ? (
                            <AddButton onClick={() => push(getDefaultExperienceLine())}>Add Line</AddButton>
                          ) : null}
                        </LinesWrapper>
                      ) : null}
                    </>
                  )}
                />
              ))}

              <FormRow>
                <FormActionButtonsRow>
                  <BackButton className="col-6" onClick={handleGoBack} />
                  <ForwardButton
                    className="col-6"
                    type="submit"
                    isLoading={isLoading}
                    disabled={!isValid || isLoading || isNotValidCountLinesInEveryExperience}
                  >
                    FINISH
                  </ForwardButton>
                </FormActionButtonsRow>
              </FormRow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AdditionalDetailsCollegiateAthletics;
