export const initialState = {
  data: null,
  error: null,
  resumeError: null,
  isLoading: false,
  isLoadingPassword: false,
  isSaving: false,
  isSuccess: false,
  isDeleting: false,
  isLoadingResumeStatus: false,
  isLoadingResume: false,
  progress: { level: null, screen: null },
  resumeCount: { count: null, onepage: null },
  errorResume: null,
  blockResume: null,
  isLoadingApplicant: false,
  isLoadingResumeTemp: false,
  resumePdf: null,
  applicant: null,
  isLoadingAvailableLines: false,
  availableLines: 0,
  errorLines: false,
  verifiedValues: null,
  assignmentErrors: null,
  isVerifyingValues: false,
  alerts: [],
  areAlertsDeleted: false,
};
