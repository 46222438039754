import PropTypes from 'prop-types';

export default PropTypes.shape({
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      level: PropTypes.string,
      status: PropTypes.string,
      screen: PropTypes.number,
      createdAt: PropTypes.string,
      updatedAt: PropTypes.string,
      finishedAt: PropTypes.string,
    })
  ),
  breakpoint: PropTypes.string,
});
