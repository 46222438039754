const getVerbPastToggle = verb => {
  switch (verb) {
    case 'save':
      return 'saved';
    case 'like':
      return 'liked';
    case 'follow':
      return 'followed';
    default:
      return null;
  }
};

export default getVerbPastToggle;
