import { REGISTER_STEPS } from 'constant';
import { additionalInformationInitialValues } from 'pages/v2/register/components/additional-information/form-config';
import { contactInformationInitialValues } from 'pages/v2/register/components/contact-information/form-config';
import { locationInitialValues } from 'pages/v2/register/components/location-information/form-config';
import { passwordInitialValues } from 'pages/v2/register/components/password/form-config';

import { RegisterState } from './types';

export const initialState: RegisterState = {
  [REGISTER_STEPS.basicInformation]: {
    values: {
      firstName: '',
      middleName: '',
      lastName: '',
      pronouns: {
        subject: '',
        object: '',
        possessive: '',
      },
      heardFromUs: null,
      heardFromUsSubOption: null,
      heardFromUsExpanded: '',
      registrationDegreeType: false,
      university: null,
      monthGraduation: null,
      yearGraduation: null,
    },
    isCompleted: false,
  },
  [REGISTER_STEPS.contactInformation]: {
    values: {
      ...contactInformationInitialValues,
    },
    isCompleted: false,
  },
  [REGISTER_STEPS.additionalInformation]: {
    values: {
      ...additionalInformationInitialValues,
    },
    isCompleted: false,
  },
  [REGISTER_STEPS.locationInformation]: {
    values: {
      ...locationInitialValues,
    },
    isCompleted: false,
  },
  [REGISTER_STEPS.password]: {
    values: {
      ...passwordInitialValues,
    },
    isCompleted: false,
  },
};
