import { getIn, useFormikContext } from 'formik';

const getFormikError = name => {
  const formik = useFormikContext();

  const touch = getIn(formik.touched, name);
  const error = getIn(formik.errors, name);

  return !!touch && !!error ? error : '';
};

export default getFormikError;
