import every from 'lodash/every';
import size from 'lodash/size';

const checkLines = experiences =>
  every(
    experiences,
    ({ lines, activeLines }) =>
      !activeLines || every(lines, line => !line.enabled || line.high === 2 || size(line.value) > 0)
  );

export default checkLines;
