const themes = {
  PURPLE: 'purple',
  GREEN: 'green',
  TEAL: 'teal',
  FUCHSIA: 'fuchsia',
  VIOLET: 'violet',
  newGreen: 'newGreen',
  GOLD: 'gold',
  STEEL: 'steel',
  BLACK: 'black',
};

export default themes;
