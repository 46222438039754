import { every, filter } from 'lodash';

import { getLinesSize } from 'utils';

const checkExperiences = (experiences, minLines) =>
  every(experiences, ({ activeLines, lines, endYear, endMonth, startYear, startMonth }) => {
    let year = endYear || startYear;
    let month = endMonth || startMonth;
    let tempMinLine;

    const currentDate = new Date();
    const endTime = new Date(year, month - 1);
    if (endTime.getTime() >= currentDate.getTime()) {
      tempMinLine = 1;
    }

    return activeLines ? getLinesSize(filter(lines, line => line.enabled)) >= (tempMinLine ?? minLines) : true;
  });

export default checkExperiences;
