import styled from 'styled-components';

export const StyledButton = styled.button`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  background: none;
  border: none;
  color: #ffbe33;
  outline: none;
  padding: 0;
  width: auto;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 1px #ffbe33;
  }
`;
