import shortId from 'shortid';

export const defaultLine = {
  id: shortId.generate(),
  maxLength: 109,
  high: 0,
  value: '',
  isMerge: false,
  enabled: true,
  selected: false,
  chosen: false,
  isHighlighted: false,
};

export const getDefaultLine = (enabled = false) => ({
  id: shortId.generate(),
  maxLength: 109,
  high: 0,
  value: '',
  isMerge: false,
  enabled,
  selected: false,
  chosen: false,
  isHighlighted: false,
});

export const defaultLines = [getDefaultLine(), getDefaultLine(), getDefaultLine(), getDefaultLine()];

export const defaultExperienceLines = isStartDateFuture =>
  isStartDateFuture ? [getDefaultLine()] : [getDefaultLine(), getDefaultLine(), getDefaultLine()];
export const defaultLeadershipLines = [getDefaultLine()];

export const defaultExperienceLinesEnabled = isStartDateFuture =>
  isStartDateFuture ? [getDefaultLine(true)] : [getDefaultLine(true), getDefaultLine(true), getDefaultLine(true)];
export const defaultLeadershipLinesEnabled = [getDefaultLine(true)];
