import PropTypes from 'prop-types';
import React from 'react';

import { Container } from './styled';

const Card = ({ zIndex, children, className }) => (
  <Container $bsStyle={{ zIndex }} className={className}>
    {children}
  </Container>
);

Card.propTypes = {
  zIndex: PropTypes.number,
  className: PropTypes.string,
};

Card.defaultProps = {
  className: '',
  zIndex: 0,
};

export default Card;
