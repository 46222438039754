import { createReducer } from 'reduxsauce';

import { sharedTypes } from './actions';
import { initialState } from './initial-state';
import { SharedState } from './types';

const heardFromUsOptionsRequest = state => ({
  ...state,
  heardFromUsOptions: {
    ...state.heardFromUsOptions,
    isLoading: true,
    error: '',
  },
});

const heardFromUsOptionsSuccess = (state, { options }) => ({
  ...state,
  heardFromUsOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const heardFromUsOptionsFailure = (state, { error }) => ({
  ...state,
  heardFromUsOptions: {
    ...state.heardFromUsOptions,
    isLoading: false,
    error,
  },
});

const heardFromUsSubOptionsRequest = state => ({
  ...state,
  heardFromUsSubOptions: {
    ...state.heardFromUsSubOptions,
    isLoading: true,
    error: '',
  },
});

const heardFromUsSubOptionsSuccess = (state, { options }) => ({
  ...state,
  heardFromUsSubOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const heardFromUsSubOptionsFailure = (state, { error }) => ({
  ...state,
  heardFromUsSubOptions: {
    ...state.heardFromUsSubOptions,
    isLoading: false,
    error,
  },
});

const universityOptionsRequest = state => ({
  ...state,
  universityOptions: {
    ...state.universityOptions,
    isLoading: true,
    error: '',
  },
});

const universityOptionsSuccess = (state, { options }) => ({
  ...state,
  universityOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const universityOptionsFailure = (state, { error }) => ({
  ...state,
  universityOptions: {
    ...state.universityOptions,
    isLoading: false,
    error,
  },
});

const yearOptionsRequest = state => ({
  ...state,
  yearOptions: {
    ...state.yearOptions,
    isLoading: true,
    error: '',
  },
});

const yearOptionsSuccess = (state, { options }) => ({
  ...state,
  yearOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const yearOptionsFailure = (state, { error }) => ({
  ...state,
  yearOptions: {
    ...state.yearOptions,
    isLoading: false,
    error,
  },
});

const studyPeriodOptionsRequest = state => ({
  ...state,
  studyPeriodOptions: {
    ...state.studyPeriodOptions,
    isLoading: true,
    error: '',
  },
});

const studyPeriodOptionsSuccess = (state, { options }) => ({
  ...state,
  studyPeriodOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const studyPeriodOptionsFailure = (state, { error }) => ({
  ...state,
  studyPeriodOptions: {
    ...state.studyPeriodOptions,
    isLoading: false,
    error,
  },
});

const diversityOptionsRequest = state => ({
  ...state,
  diversityOptions: {
    ...state.diversityOptions,
    isLoading: true,
    error: '',
  },
});

const diversityOptionsSuccess = (state, { options }) => ({
  ...state,
  diversityOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const diversityOptionsFailure = (state, { error }) => ({
  ...state,
  diversityOptions: {
    ...state.diversityOptions,
    isLoading: false,
    error,
  },
});

const diversitySubOptionsRequest = state => ({
  ...state,
  diversitySubOptions: {
    ...state.diversitySubOptions,
    isLoading: true,
    error: '',
  },
});

const diversitySubOptionsSuccess = (state, { options }) => ({
  ...state,
  diversitySubOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const diversitySubOptionsFailure = (state, { error }) => ({
  ...state,
  diversitySubOptions: {
    ...state.diversitySubOptions,
    isLoading: false,
    error,
  },
});

const statesOptionsRequest = state => ({
  ...state,
  statesOptions: {
    ...state.statesOptions,
    isLoading: true,
    error: '',
  },
});

const statesOptionsSuccess = (state, { options }) => ({
  ...state,
  statesOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const statesOptionsFailure = (state, { error }) => ({
  ...state,
  statesOptions: {
    ...state.statesOptions,
    isLoading: false,
    error,
  },
});

const stateCitiesOptionsRequest = state => ({
  ...state,
  citiesOptions: {
    ...state.citiesOptions,
    isLoading: true,
    error: '',
  },
});

const stateCitiesOptionsSuccess = (state, { options }) => ({
  ...state,
  citiesOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const stateCitiesOptionsFailure = (state, { error }) => ({
  ...state,
  citiesOptions: {
    ...state.citiesOptions,
    isLoading: false,
    error,
  },
});

const schoolStateCitiesOptionsRequest = state => ({
  ...state,
  schoolStateCitiesOptions: {
    ...state.schoolStateCitiesOptions,
    isLoading: true,
    error: '',
  },
});

const schoolStateCitiesOptionsSuccess = (state, { options }) => ({
  ...state,
  schoolStateCitiesOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const schoolStateCitiesOptionsFailure = (state, { error }) => ({
  ...state,
  schoolStateCitiesOptions: {
    ...state.schoolStateCitiesOptions,
    isLoading: false,
    error,
  },
});

const countriesOptionsRequest = state => ({
  ...state,
  countriesOptions: {
    ...state.countriesOptions,
    isLoading: true,
    error: '',
  },
});

const countriesOptionsSuccess = (state, { options }) => ({
  ...state,
  countriesOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const countriesOptionsFailure = (state, { error }) => ({
  ...state,
  countriesOptions: {
    ...state.countriesOptions,
    isLoading: false,
    error,
  },
});
const majorCategoriesOptionsRequest = state => ({
  ...state,
  majorCategoriesOptions: {
    ...state.majorCategoriesOptions,
    isLoading: true,
    error: '',
  },
});

const majorCategoriesOptionsSuccess = (state, { options }) => ({
  ...state,
  majorCategoriesOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const majorCategoriesOptionsFailure = (state, { error }) => ({
  ...state,
  majorCategoriesOptions: {
    ...state.majorCategoriesOptions,
    isLoading: false,
    error,
  },
});

const bachelorDegreeOptionsRequest = state => ({
  ...state,
  bachelorDegreeOptions: {
    ...state.bachelorDegreeOptions,
    isLoading: true,
    error: '',
  },
});

const bachelorDegreeOptionsSuccess = (state, { options }) => ({
  ...state,
  bachelorDegreeOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const bachelorDegreeOptionsFailure = (state, { error }) => ({
  ...state,
  bachelorDegreeOptions: {
    ...state.bachelorDegreeOptions,
    isLoading: false,
    error,
  },
});

const masterDegreeOptionsRequest = state => ({
  ...state,
  masterDegreeOptions: {
    ...state.masterDegreeOptions,
    isLoading: true,
    error: '',
  },
});

const masterDegreeOptionsSuccess = (state, { options }) => ({
  ...state,
  masterDegreeOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const masterDegreeOptionsFailure = (state, { error }) => ({
  ...state,
  masterDegreeOptions: {
    ...state.masterDegreeOptions,
    isLoading: false,
    error,
  },
});

const majorsOptionsRequest = state => ({
  ...state,
  majorsOptions: {
    ...state.majorsOptions,
    isLoading: true,
    error: '',
  },
});

const majorsOptionsSuccess = (state, { options }) => ({
  ...state,
  majorsOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const majorsOptionsFailure = (state, { error }) => ({
  ...state,
  majorsOptions: {
    ...state.majorsOptions,
    isLoading: false,
    error,
  },
});

const minorsOptionsRequest = state => ({
  ...state,
  minorsOptions: {
    ...state.minorsOptions,
    isLoading: true,
    error: '',
  },
});

const minorsOptionsSuccess = (state, { options }) => ({
  ...state,
  minorsOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const minorsOptionsFailure = (state, { error }) => ({
  ...state,
  minorsOptions: {
    ...state.minorsOptions,
    isLoading: false,
    error,
  },
});

const degreeTypeOptionsRequest = state => ({
  ...state,
  degreeTypeOptions: {
    ...state.degreeTypeOptions,
    isLoading: true,
    error: '',
  },
});

const degreeTypeOptionsSuccess = (state, { options }) => ({
  ...state,
  degreeTypeOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const degreeTypeOptionsFailure = (state, { error }) => ({
  ...state,
  degreeTypeOptions: {
    ...state.degreeTypeOptions,
    isLoading: false,
    error,
  },
});

const periodRangesOptionsRequest = state => ({
  ...state,
  periodRangesOptions: {
    ...state.periodRangesOptions,
    isLoading: true,
    error: '',
  },
});

const periodRangesOptionsSuccess = (state, { options }) => ({
  ...state,
  periodRangesOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const periodRangesOptionsFailure = (state, { error }) => ({
  ...state,
  periodRangesOptions: {
    ...state.periodRangesOptions,
    isLoading: false,
    error,
  },
});
const sportsOptionsRequest = state => ({
  ...state,
  sportsOptions: {
    ...state.sportsOptions,
    isLoading: true,
    error: '',
  },
});

const sportsOptionsSuccess = (state, { options }) => ({
  ...state,
  sportsOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const sportsOptionsFailure = (state, { error }) => ({
  ...state,
  sportsOptions: {
    ...state.sportsOptions,
    isLoading: false,
    error,
  },
});

const registerCheckEmailRequest = state => ({
  ...state,
  checkEmail: {
    isLoading: true,
  },
});

const registerCheckEmailSuccess = (state, { exists, message }) => ({
  ...state,
  checkEmail: {
    isLoading: false,
    exists,
    message,
    error: '',
  },
});

const registerCheckEmailError = (state, { error }) => ({
  ...state,
  checkEmail: {
    isLoading: false,
    exists: false,
    message: '',
    error,
  },
});

const periodOfStudiesOptionsRequest = state => ({
  ...state,
  periodOfStudiesOptions: {
    ...state.periodOfStudiesOptions,
    isLoading: true,
    error: '',
  },
});

const periodOfStudiesOptionsSuccess = (state, { options }) => ({
  ...state,
  periodOfStudiesOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const periodOfStudiesOptionsFailure = (state, { error }) => ({
  ...state,
  periodOfStudiesOptions: {
    ...state.periodOfStudiesOptions,
    isLoading: false,
    error,
  },
});

const cacheStateCitiesOptionsRequest = (state, { id }) => ({
  ...state,
  cachedData: {
    ...state.cachedData,
    states: {
      ...state.cachedData.states,
      [id]: {
        ...state.cachedData.states[id],
        isLoading: true,
        error: '',
      },
    },
  },
});

const cacheStateCitiesOptionsSuccess = (state, { options, id }) => ({
  ...state,
  cachedData: {
    ...state.cachedData,
    states: {
      ...state.cachedData.states,
      [id]: {
        options: [...options],
        isLoading: false,
        error: '',
      },
    },
  },
});

const cacheStateCitiesOptionsFailure = (state, { error, id }) => ({
  ...state,
  cachedData: {
    ...state.cachedData,
    states: {
      ...state.cachedData.states,
      [id]: {
        ...state.cachedData.states[id],
        isLoading: false,
        error: error,
      },
    },
  },
});

const proficiencyLevelsOptionsRequest = state => ({
  ...state,
  skillLevelsOptions: {
    ...state.skillLevelsOptions,
    isLoading: true,
    error: '',
  },
});

const proficiencyLevelsOptionsSuccess = (state, { options }) => ({
  ...state,
  skillLevelsOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const proficiencyLevelsOptionsFailure = (state, { error }) => ({
  ...state,
  skillLevelsOptions: {
    ...state.skillLevelsOptions,
    isLoading: false,
    error,
  },
});

const proficiencySkillCategoriesOptionsRequest = state => ({
  ...state,
  skillCategoryOptions: {
    ...state.skillCategoryOptions,
    isLoading: true,
    error: '',
  },
});

const proficiencySkillCategoriesOptionsSuccess = (state, { options }) => ({
  ...state,
  skillCategoryOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const proficiencySkillCategoriesOptionsFailure = (state, { error }) => ({
  ...state,
  skillCategoryOptions: {
    ...state.skillCategoryOptions,
    isLoading: false,
    error,
  },
});

const languageProficiencyOptionsRequest = state => ({
  ...state,
  languageProficiencyOptions: {
    ...state.languageProficiencyOptions,
    isLoading: true,
    error: '',
  },
});

const languageProficiencyOptionsSuccess = (state, { options }) => ({
  ...state,
  languageProficiencyOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const languageProficiencyOptionsFailure = (state, { error }) => ({
  ...state,
  languageProficiencyOptions: {
    ...state.languageProficiencyOptions,
    isLoading: false,
    error,
  },
});

const languageOptionsRequest = state => ({
  ...state,
  languageOptions: {
    ...state.languageOptions,
    isLoading: true,
    error: '',
  },
});

const languageOptionsSuccess = (state, { options }) => ({
  ...state,
  languageOptions: {
    options: [...options],
    isLoading: false,
    error: '',
  },
});

const languageOptionsFailure = (state, { error }) => ({
  ...state,
  languageOptions: {
    ...state.languageOptions,
    isLoading: false,
    error,
  },
});

const reducer = createReducer<SharedState>(initialState, {
  [sharedTypes.HEARD_FROM_US_OPTIONS_REQUEST]: heardFromUsOptionsRequest,
  [sharedTypes.HEARD_FROM_US_OPTIONS_SUCCESS]: heardFromUsOptionsSuccess,
  [sharedTypes.HEARD_FROM_US_OPTIONS_FAILURE]: heardFromUsOptionsFailure,

  [sharedTypes.HEARD_FROM_US_SUB_OPTIONS_REQUEST]: heardFromUsSubOptionsRequest,
  [sharedTypes.HEARD_FROM_US_SUB_OPTIONS_SUCCESS]: heardFromUsSubOptionsSuccess,
  [sharedTypes.HEARD_FROM_US_SUB_OPTIONS_FAILURE]: heardFromUsSubOptionsFailure,

  [sharedTypes.UNIVERSITY_OPTIONS_REQUEST]: universityOptionsRequest,
  [sharedTypes.UNIVERSITY_OPTIONS_SUCCESS]: universityOptionsSuccess,
  [sharedTypes.UNIVERSITY_OPTIONS_FAILURE]: universityOptionsFailure,

  [sharedTypes.DIVERSITY_OPTIONS_REQUEST]: diversityOptionsRequest,
  [sharedTypes.DIVERSITY_OPTIONS_SUCCESS]: diversityOptionsSuccess,
  [sharedTypes.DIVERSITY_OPTIONS_FAILURE]: diversityOptionsFailure,

  [sharedTypes.DIVERSITY_SUB_OPTIONS_REQUEST]: diversitySubOptionsRequest,
  [sharedTypes.DIVERSITY_SUB_OPTIONS_SUCCESS]: diversitySubOptionsSuccess,
  [sharedTypes.DIVERSITY_SUB_OPTIONS_FAILURE]: diversitySubOptionsFailure,

  [sharedTypes.YEAR_OPTIONS_REQUEST]: yearOptionsRequest,
  [sharedTypes.YEAR_OPTIONS_SUCCESS]: yearOptionsSuccess,
  [sharedTypes.YEAR_OPTIONS_FAILURE]: yearOptionsFailure,

  [sharedTypes.STUDY_PERIOD_OPTIONS_REQUEST]: studyPeriodOptionsRequest,
  [sharedTypes.STUDY_PERIOD_OPTIONS_SUCCESS]: studyPeriodOptionsSuccess,
  [sharedTypes.STUDY_PERIOD_OPTIONS_FAILURE]: studyPeriodOptionsFailure,

  [sharedTypes.STATES_OPTIONS_REQUEST]: statesOptionsRequest,
  [sharedTypes.STATES_OPTIONS_SUCCESS]: statesOptionsSuccess,
  [sharedTypes.STATES_OPTIONS_FAILURE]: statesOptionsFailure,

  [sharedTypes.STATE_CITIES_OPTIONS_REQUEST]: stateCitiesOptionsRequest,
  [sharedTypes.STATE_CITIES_OPTIONS_SUCCESS]: stateCitiesOptionsSuccess,
  [sharedTypes.STATE_CITIES_OPTIONS_FAILURE]: stateCitiesOptionsFailure,

  [sharedTypes.SCHOOL_STATE_CITIES_OPTIONS_REQUEST]: schoolStateCitiesOptionsRequest,
  [sharedTypes.SCHOOL_STATE_CITIES_OPTIONS_SUCCESS]: schoolStateCitiesOptionsSuccess,
  [sharedTypes.SCHOOL_STATE_CITIES_OPTIONS_FAILURE]: schoolStateCitiesOptionsFailure,

  [sharedTypes.COUNTRIES_OPTIONS_REQUEST]: countriesOptionsRequest,
  [sharedTypes.COUNTRIES_OPTIONS_SUCCESS]: countriesOptionsSuccess,
  [sharedTypes.COUNTRIES_OPTIONS_FAILURE]: countriesOptionsFailure,

  [sharedTypes.BACHELOR_DEGREE_OPTIONS_REQUEST]: bachelorDegreeOptionsRequest,
  [sharedTypes.BACHELOR_DEGREE_OPTIONS_SUCCESS]: bachelorDegreeOptionsSuccess,
  [sharedTypes.BACHELOR_DEGREE_OPTIONS_FAILURE]: bachelorDegreeOptionsFailure,

  [sharedTypes.MASTER_DEGREE_OPTIONS_REQUEST]: masterDegreeOptionsRequest,
  [sharedTypes.MASTER_DEGREE_OPTIONS_SUCCESS]: masterDegreeOptionsSuccess,
  [sharedTypes.MASTER_DEGREE_OPTIONS_FAILURE]: masterDegreeOptionsFailure,

  [sharedTypes.MAJORS_OPTIONS_REQUEST]: majorsOptionsRequest,
  [sharedTypes.MAJORS_OPTIONS_SUCCESS]: majorsOptionsSuccess,
  [sharedTypes.MAJORS_OPTIONS_FAILURE]: majorsOptionsFailure,

  [sharedTypes.MINORS_OPTIONS_REQUEST]: minorsOptionsRequest,
  [sharedTypes.MINORS_OPTIONS_SUCCESS]: minorsOptionsSuccess,
  [sharedTypes.MINORS_OPTIONS_FAILURE]: minorsOptionsFailure,

  [sharedTypes.REGISTER_CHECK_EMAIL_REQUEST]: registerCheckEmailRequest,
  [sharedTypes.REGISTER_CHECK_EMAIL_SUCCESS]: registerCheckEmailSuccess,
  [sharedTypes.REGISTER_CHECK_EMAIL_ERROR]: registerCheckEmailError,

  [sharedTypes.SPORTS_OPTIONS_REQUEST]: sportsOptionsRequest,
  [sharedTypes.SPORTS_OPTIONS_SUCCESS]: sportsOptionsSuccess,
  [sharedTypes.SPORTS_OPTIONS_FAILURE]: sportsOptionsFailure,

  [sharedTypes.PERIOD_RANGES_OPTIONS_REQUEST]: periodRangesOptionsRequest,
  [sharedTypes.PERIOD_RANGES_OPTIONS_SUCCESS]: periodRangesOptionsSuccess,
  [sharedTypes.PERIOD_RANGES_OPTIONS_FAILURE]: periodRangesOptionsFailure,

  [sharedTypes.PERIOD_OF_STUDIES_OPTIONS_REQUEST]: periodOfStudiesOptionsRequest,
  [sharedTypes.PERIOD_OF_STUDIES_OPTIONS_SUCCESS]: periodOfStudiesOptionsSuccess,
  [sharedTypes.PERIOD_OF_STUDIES_OPTIONS_FAILURE]: periodOfStudiesOptionsFailure,

  [sharedTypes.CACHE_STATE_CITIES_OPTIONS_REQUEST]: cacheStateCitiesOptionsRequest,
  [sharedTypes.CACHE_STATE_CITIES_OPTIONS_SUCCESS]: cacheStateCitiesOptionsSuccess,
  [sharedTypes.CACHE_STATE_CITIES_OPTIONS_FAILURE]: cacheStateCitiesOptionsFailure,

  [sharedTypes.MAJOR_CATEGORIES_OPTIONS_REQUEST]: majorCategoriesOptionsRequest,
  [sharedTypes.MAJOR_CATEGORIES_OPTIONS_SUCCESS]: majorCategoriesOptionsSuccess,
  [sharedTypes.MAJOR_CATEGORIES_OPTIONS_ERROR]: majorCategoriesOptionsFailure,

  [sharedTypes.DEGREE_TYPE_OPTIONS_REQUEST]: degreeTypeOptionsRequest,
  [sharedTypes.DEGREE_TYPE_OPTIONS_SUCCESS]: degreeTypeOptionsSuccess,
  [sharedTypes.DEGREE_TYPE_OPTIONS_ERROR]: degreeTypeOptionsFailure,

  [sharedTypes.PROFICIENCY_LEVELS_OPTIONS_REQUEST]: proficiencyLevelsOptionsRequest,
  [sharedTypes.PROFICIENCY_LEVELS_OPTIONS_SUCCESS]: proficiencyLevelsOptionsSuccess,
  [sharedTypes.PROFICIENCY_LEVELS_OPTIONS_FAILURE]: proficiencyLevelsOptionsFailure,

  [sharedTypes.PROFICIENCY_SKILL_CATEGORIES_OPTIONS_REQUEST]: proficiencySkillCategoriesOptionsRequest,
  [sharedTypes.PROFICIENCY_SKILL_CATEGORIES_OPTIONS_SUCCESS]: proficiencySkillCategoriesOptionsSuccess,
  [sharedTypes.PROFICIENCY_SKILL_CATEGORIES_OPTIONS_FAILURE]: proficiencySkillCategoriesOptionsFailure,

  [sharedTypes.LANGUAGE_PROFICIENCY_OPTIONS_REQUEST]: languageProficiencyOptionsRequest,
  [sharedTypes.LANGUAGE_PROFICIENCY_OPTIONS_SUCCESS]: languageProficiencyOptionsSuccess,
  [sharedTypes.LANGUAGE_PROFICIENCY_OPTIONS_FAILURE]: languageProficiencyOptionsFailure,

  [sharedTypes.LANGUAGE_OPTIONS_REQUEST]: languageOptionsRequest,
  [sharedTypes.LANGUAGE_OPTIONS_SUCCESS]: languageOptionsSuccess,
  [sharedTypes.LANGUAGE_OPTIONS_FAILURE]: languageOptionsFailure,
});

export default reducer;
