import { getOrCached } from './http';

export const heardFromUsOptionsRequest = () => getOrCached('/heardFromUs');

export const heardFromUsSubOptionsRequest = (id: number) => getOrCached(`/heardFromUs/${id}`);

export const universityOptionsRequest = () => getOrCached('/universities');

export const yearOptionsRequest = () => getOrCached('/years');

export const studyPeriodOptionsRequest = () => getOrCached('/periods/extra');

export const diversityOptionsRequest = () => getOrCached('/diversities');

export const diversitySubOptionsRequest = (id: number) => getOrCached(`/diversities/${id}`);

export const statesOptionsRequest = () => getOrCached('/states');

export const stateCitiesOptionsRequest = (stateID: string) => getOrCached(`/cities/state/${stateID}`);

export const schoolStateCitiesOptionsRequest = stateCitiesOptionsRequest;

export const countriesOptionsRequest = () => getOrCached('/countries');

export const periodsOfStudiesOptionsRequest = () => getOrCached('/periods/extra');

export const proficiencyLevelOptionsRequest = () => getOrCached('/proficiencies/tech_skill');

export const proficiencySkillCategoriesOptionsRequest = () => getOrCached('/proficiencies/skill_category');

export const languageProficiencyOptionsRequest = () => getOrCached('/proficiencies/language');

export const languageOptionsRequest = () => getOrCached('/languages');
// TODO update this once handling an API version logic is created
export const majorsOptionsRequest = () =>
  getOrCached('academics/majors', {}, { baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2') });

// TODO update this once handling an API version logic is created
export const minorsOptionsRequest = () =>
  getOrCached('academics/minors', {}, { baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2') });

export const majorCategoriesOptionsRequest = () => getOrCached('/majorCategories');

// TODO update this once handling an API version logic is created
export const degreeOptionsRequest = typeId =>
  getOrCached(
    'academics/degrees',
    { type_id: typeId },
    { baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2') }
  );

// TODO update this once handling an API version logic is created
export const degreeTypeOptionsRequest = () =>
  getOrCached('academics/degrees/types', {}, { baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2') });

export const sportsOptionsRequest = () => getOrCached('/sports');

export const periodRangeOptionsRequest = () => getOrCached('/periods/range');
