import PropTypes from 'prop-types';

import chaptersProps from './chapters';
import documentsProps from './documents';
import imagesProps from './images';
import nextButtonProps from './next-button';
import summariesProps from './summaries';

export default PropTypes.shape({
  id: PropTypes.number,
  number: PropTypes.number,
  title: PropTypes.string,
  chapterName: PropTypes.string,
  sectionTitle: PropTypes.string,
  subTitle: PropTypes.string,
  nextButton: nextButtonProps,
  chapters: chaptersProps,
  summaries: summariesProps,
  description: PropTypes.string,
  documents: documentsProps,
  images: imagesProps,
});
