import { CloseButton } from '@hivediversity/common-lib/buttons';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import { Footer } from 'components';
import { themesObj } from 'constant';

import { Container, Title, Section, WrapperTitle, Content, Body, Div, Wrapper } from './styled';

const LayoutQuestion = ({
  title,
  sectionName,
  className,
  helperTitle,
  helperDescription,
  showHelper,
  theme,
  children,
  onPrevious,
  activeStep,
  form,
  hasFooter,
  onClose,
  returnToggleDialog,
  onChange,
  hidePreviousButton,
  disabledNextButton,
}) => {
  const [dialogContent, setDialogContent] = useState(helperDescription);
  const [showDialog, setShowDialog] = useState(showHelper);
  const isSaving = useSelector(state => state.level.isSaving);

  useEffect(() => {
    setDialogContent(helperDescription);
  }, [helperDescription]);

  useEffect(() => {
    if (helperDescription) {
      setDialogContent(helperDescription);
      setShowDialog(showHelper);
    }
    if (helperDescription && returnToggleDialog) {
      returnToggleDialog(showHelper);
    }
  }, [showHelper]);

  return (
    <ThemeProvider theme={theme || themesObj.black}>
      <Container>
        <Wrapper>
          {onClose && <CloseButton onClick={onClose} />}
          <Div>
            <WrapperTitle>
              <Title>{title}</Title>
              <Section>{sectionName}</Section>
            </WrapperTitle>
            <Content id="question-form" className={`question-wrapper ${className || ''}`}>
              <Body>{children}</Body>
            </Content>
            {hasFooter && (
              <Footer
                type="submit"
                form={form}
                actionPrev={onPrevious}
                hidePrev={hidePreviousButton || activeStep <= 0}
                showDialog={dialogContent ? showDialog : false}
                title={helperTitle}
                dialog={dialogContent}
                returnToggleDialog={setShowDialog}
                isLoadingNextButton={isSaving}
                onChange={onChange}
                disabledNextButton={disabledNextButton}
              />
            )}
          </Div>
        </Wrapper>
      </Container>
    </ThemeProvider>
  );
};

LayoutQuestion.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.shape({}))]),
  onPrevious: PropTypes.func,
  title: PropTypes.string,
  sectionName: PropTypes.string,
  className: PropTypes.string,
  helperTitle: PropTypes.string,
  helperDescription: PropTypes.string,
  showHelper: PropTypes.bool,
  theme: PropTypes.shape({}),
  activeStep: PropTypes.number,
  form: PropTypes.string,
  hasFooter: PropTypes.bool,
  onClose: PropTypes.func,
  returnToggleDialog: PropTypes.func,
  onChange: PropTypes.func,
  hidePreviousButton: PropTypes.bool,
};

LayoutQuestion.defaultProps = {
  onPrevious: null,
  title: '',
  sectionName: '',
  className: '',
  helperTitle: '',
  helperDescription: '',
  showHelper: false,
  theme: null,
  activeStep: 0,
  form: null,
  hasFooter: true,
  onClose: undefined,
  returnToggleDialog: undefined,
  onChange: undefined,
  children: undefined,
  hidePreviousButton: false,
};

export default LayoutQuestion;
