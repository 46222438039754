import { routes } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { takeLatest, call, put } from 'redux-saga/effects';

import { messageBee } from 'constant';
import { clearToken } from 'services/storage';
import {
  getStudent,
  saveImageStudent,
  delAccount,
  clearStudentImage,
  updatePassword,
  getResumeCount,
  getStudentAvailableLines,
  getApplicant,
  getTempResume,
  checkAssignmentValuesRequest,
  requestStudentsAlerts,
  getResumeStatus,
  cleanHighlightRequest,
  removeStudentAlertRequest,
  removeStudentAlertsRequest,
} from 'services/student';
import { requestUser } from 'services/user';
import studentCreator, { studentTypes } from 'store/student/actions';
import { getResponseError, i18n, isEmpty } from 'utils';

export function* fetch() {
  try {
    const response = yield call(getStudent);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(studentCreator.studentFetchSuccess(response.data || {}));
    } else {
      yield put(studentCreator.studentFetchError(error));
    }
  } catch (error) {
    yield put(studentCreator.studentFetchError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* saveImage({ upImg, croppedImg, startX, zoom, startY, width, withRedirect, redirect, closeWindow }) {
  try {
    const response = yield call(saveImageStudent, upImg, croppedImg, startX, zoom, startY, width);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(studentCreator.studentSaveImageSuccess(response.data));
      if (withRedirect) {
        yield put(push(redirect));
      }

      if (!withRedirect && closeWindow) {
        yield call(window.close);
      }
    } else {
      yield put(studentCreator.studentSaveImageError(error));
    }
  } catch (error) {
    yield put(studentCreator.studentSaveImageError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* clearImage({ firstName, lastName, redirect, closeWindow }) {
  try {
    const response = yield call(clearStudentImage, firstName, lastName);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(studentCreator.studentClearImageSuccess(response.data));

      if (!closeWindow && redirect) {
        yield put(push(redirect));
      }

      if (closeWindow) {
        yield call(window.close);
      }
    } else {
      yield put(studentCreator.studentClearImageError(error));
    }
  } catch (error) {
    yield put(studentCreator.studentClearImageError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* deleteAccount() {
  try {
    const response = yield call(delAccount);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(studentCreator.studentDeleteAccountSuccess(response.data));
      yield clearToken();
      yield put(push(routes.ROOT));
    } else {
      yield put(studentCreator.studentDeleteAccountError(error));
    }
  } catch (error) {
    yield put(studentCreator.studentDeleteAccountError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* changePassword({ oldPassword, newPassword }) {
  try {
    const response = yield call(updatePassword, oldPassword, newPassword);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(studentCreator.studentChangePasswordSuccess());
    } else {
      yield put(studentCreator.studentChangePasswordError(error));
    }
  } catch (error) {
    yield put(studentCreator.studentChangePasswordError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* fetchAvailableLines({ studentId }) {
  try {
    const response = yield call(getStudentAvailableLines, studentId);
    const error = yield getResponseError(response);
    if (response?.data?.lines === 0) {
      yield put(studentCreator.studentAvailableLinesError(messageBee.ERROR_AVAILABLE_LINE_MESSAGE));
    }
    if (!error) {
      yield put(studentCreator.studentAvailableLinesSuccess(response.data.lines));
    } else {
      yield put(studentCreator.studentAvailableLinesError(error));
    }
  } catch (error) {
    yield put(studentCreator.studentAvailableLinesError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* fetchResumeCount({ studentId }) {
  try {
    let id = studentId;
    if (!studentId) {
      const { data: user } = yield call(requestUser);
      id = user.id;
    }
    const response = yield call(getResumeCount, id);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(studentCreator.studentSetResumeCount(response.data));
      yield put(studentCreator.studentSetBlockResume(get(response.data, 'onepage') === false));
    }
  } catch (error) {
    yield put(studentCreator.studentSetResumeError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* findApplicant({ opportunityId }) {
  try {
    const response = yield call(getApplicant, opportunityId);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(studentCreator.studentFindApplicantSuccess(response.data.applicant));
    } else {
      yield put(studentCreator.studentFindApplicantError(error));
    }
  } catch (error) {
    yield put(studentCreator.studentFindApplicantError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* findTempResume({ student }) {
  try {
    const response = yield call(getTempResume, student);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(studentCreator.studentFindTempResumeSuccess(response.data));
    } else {
      yield put(studentCreator.studentFindTempResumeError(error));
    }
  } catch (error) {
    yield put(studentCreator.studentFindTempResumeError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* checkAssignmentValues({ values }) {
  try {
    const response = yield call(checkAssignmentValuesRequest, values.otherExperiences);
    const error = yield getResponseError(response);

    if (!error && isEmpty(response.data.errors)) {
      yield put(studentCreator.studentCheckAssignmentValuesSuccess(values));
    } else {
      yield put(studentCreator.studentCheckAssignmentValuesError(response.data.errors || error));
    }
  } catch (error) {
    yield put(studentCreator.studentCheckAssignmentValuesError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* getStudentsAlerts() {
  try {
    const response = yield call(requestStudentsAlerts);
    const error = yield getResponseError(response);

    if (error) {
      yield put(studentCreator.studentsAlertsFetchError(error));
    } else {
      yield put(studentCreator.studentsAlertsFetchSuccess(response.data));
    }
  } catch (error) {
    yield put(studentCreator.studentsAlertsFetchError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* fetchResumeStatus({ studentId }) {
  try {
    const response = yield call(getResumeStatus, studentId);
    const error = yield getResponseError(response);
    if (error) {
      yield put(studentCreator.studentResumeStatusError(error));
    } else {
      yield put(studentCreator.studentResumeStatusSuccess(response.data));
    }
  } catch (error) {
    yield put(studentCreator.studentResumeStatusError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* cleanHighlight({ studentId }) {
  try {
    const response = yield call(cleanHighlightRequest, studentId);
    const error = yield getResponseError(response);
    if (error) {
      yield put(studentCreator.studentCleanHighlightError(error));
    } else {
      yield put(studentCreator.studentCleanHighlightSuccess(response.data));
    }
  } catch (error) {
    yield put(studentCreator.studentCleanHighlightError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* removeStudentAlert({ tag }) {
  try {
    const response = yield call(removeStudentAlertRequest, tag);
    const error = yield getResponseError(response);
    if (error) {
      yield put(studentCreator.removeStudentAlertError(error));
    } else {
      yield put(studentCreator.removeStudentAlertSuccess(response.data));
    }
  } catch (error) {
    yield put(studentCreator.removeStudentAlertError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* removeStudentAlerts() {
  try {
    const response = yield call(removeStudentAlertsRequest);
    const error = yield getResponseError(response);
    if (error) {
      yield put(studentCreator.removeStudentAlertsError(error));
    } else {
      yield put(studentCreator.removeStudentAlertsSuccess());
    }
  } catch (error) {
    yield put(studentCreator.removeStudentAlertsError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* studentSaga() {
  yield takeLatest(studentTypes.STUDENT_FETCH_REQUEST, fetch);
  yield takeLatest(studentTypes.STUDENT_SAVE_IMAGE_REQUEST, saveImage);
  yield takeLatest(studentTypes.STUDENT_DELETE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(studentTypes.STUDENT_CLEAR_IMAGE_REQUEST, clearImage);
  yield takeLatest(studentTypes.STUDENT_CHANGE_PASSWORD_REQUEST, changePassword);
  yield takeLatest(studentTypes.STUDENT_AVAILABLE_LINES_REQUEST, fetchAvailableLines);
  yield takeLatest(studentTypes.STUDENT_FETCH_RESUME_COUNT, fetchResumeCount);
  yield takeLatest(studentTypes.STUDENT_FIND_APPLICANT_REQUEST, findApplicant);
  yield takeLatest(studentTypes.STUDENT_FIND_TEMP_RESUME_REQUEST, findTempResume);
  yield takeLatest(studentTypes.STUDENT_CHECK_ASSIGNMENT_VALUES_REQUEST, checkAssignmentValues);
  yield takeLatest(studentTypes.STUDENTS_ALERTS_FETCH_REQUEST, getStudentsAlerts);
  yield takeLatest(studentTypes.STUDENT_RESUME_STATUS_REQUEST, fetchResumeStatus);
  yield takeLatest(studentTypes.STUDENT_CLEAN_HIGHLIGHT_REQUEST, cleanHighlight);
  yield takeLatest(studentTypes.REMOVE_STUDENT_ALERT_REQUEST, removeStudentAlert);
  yield takeLatest(studentTypes.REMOVE_STUDENT_ALERTS_REQUEST, removeStudentAlerts);
}
