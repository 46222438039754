import * as yup from 'yup';

import createYupSchema from './create-yup-schema';

const getValidations = (section, ownStep) => {
  if (!section) {
    return null;
  }
  const validations = createYupSchema(section, ownStep);
  return yup.object().shape(validations);
};

export default getValidations;
