import * as Yup from 'yup';

const requiredMessage = 'This field is required';

import { TechnicalSkillsFormValues } from 'store/v2/level/types';
export const technicalSKillsFormInitialValues: TechnicalSkillsFormValues = {
  name: '',
  category: null,
  categoryName: '',
  proficiency: null,
  proficiencyName: '',
  otherCategoryName: '',
  technicalSkill: [],
};

export const OTHER_FIELD_ID = 19;
export const MAX_COUNT = 230;

export const validationSchema = Yup.object().shape({
  name: Yup.string().nullable(),
  proficiency: Yup.string().nullable(),
  proficiencyName: Yup.string().nullable(),
  category: Yup.string().nullable(),
  categoryName: Yup.string().nullable(),
  otherCategoryName: Yup.string()
    .nullable()
    .when('proficiencyName', {
      is: proficiency => proficiency === OTHER_FIELD_ID.toString(),
      then: Yup.string().required(requiredMessage),
    }),
});
