import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  companySearchRequest: ['filters'],
  companySearchSuccess: ['companies', 'meta'],
  companySearchError: ['error'],

  companyFindRequest: ['companyId'],
  companyFindSuccess: ['company'],
  companyFindError: ['error'],

  companyTogglesRequest: ['company', 'entity'],
  companyTogglesSuccess: ['data', 'company'],
  companyTogglesError: ['error'],

  companyOpportunitiesRequest: ['companyId', 'params'],
  companyOpportunitiesSuccess: ['opportunities', 'meta'],
  companyOpportunitiesError: ['error'],
});

export const companyTypes = Types;
export default Creators;
