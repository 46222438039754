export default {
  menu: {
    border: 0,
    boxShadow: 'initial',
    background: 'transparent',
    position: 'relative',
    borderRadius: '6px',
  },
  container: { margin: 0, height: '100%', display: 'block', zIndex: 1, maxWidth: '100%' },
  button: {
    borderRadius: '6px',
    border: '1px solid #FFFFFF',
    background: '#009ED0',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '17px',
    height: '44px',
    zIndex: 1,
    padding: '8px 10px 8px 10px',
  },
  option: {
    borderRadius: '6px',
    background: '#009ED0',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
    fontSize: '14px',
    lineHeight: '28px',
    height: '44px',
    '&:hover': {
      backgroundColor: '#009ED0',
      filter: 'brightness(0.9)',
      cursor: 'pointer',
    },
  },
  options: {
    margin: '0',
    fontFamily: 'Poppins',
    textTransform: 'capitalize',
  },
};
