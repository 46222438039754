import { get, isEmpty, compact } from 'lodash';

const parseNullToEmptyString = obj => {
  return Object.keys(obj).reduce((acc, key) => {
    if (Array.isArray(obj)) {
      return acc;
    }
    if (obj[key] === null || obj[key] === undefined) {
      return { ...acc, [key]: '' };
    }
    if (Array.isArray(obj[key]) && !isEmpty(compact(obj[key].map(a => get(a, 'added'))))) {
      return { ...acc, [key]: obj[key].filter(o => o.added) };
    }
    return { ...acc, [key]: obj[key] };
  }, {});
};

export default parseNullToEmptyString;
