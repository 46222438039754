import { all } from 'redux-saga/effects';

import { appSaga } from 'store/app/saga';
import { authorizationSaga } from 'store/authorization/saga';
import { companySaga } from 'store/company/saga';
import { levelSaga } from 'store/level/saga';
import { opportunitySaga } from 'store/opportunity/saga';
import { progressSaga } from 'store/progress/saga';
import { staticDataSaga } from 'store/static-data/saga';
import { studentSaga } from 'store/student/saga';
import { userSaga } from 'store/user/saga';
import { levelSaga as levelSaga_v2 } from 'store/v2/level/saga';
import { sharedSaga } from 'store/v2/shared/saga';

export default function* rootSaga() {
  yield all([
    userSaga(),
    authorizationSaga(),
    levelSaga(),
    studentSaga(),
    progressSaga(),
    appSaga(),
    opportunitySaga(),
    companySaga(),
    staticDataSaga(),
    sharedSaga(),
    levelSaga_v2(),
  ]);
}
