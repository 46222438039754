import alertTags from './alerts-tags';
import tooltipsMessages from './tooltips-messages';

const alertsTagsMessages = {
  [alertTags.BULLETS_BUZZ]: tooltipsMessages.LEADERSHIP,
  [alertTags.GPA]: tooltipsMessages.GPA,
  [alertTags.COURSEWORK]: tooltipsMessages.COURSEWORK,
  [alertTags.ENHANCED_EXP]: tooltipsMessages.RESEARCH_ACADEMIC,
  [alertTags.LINKEDIN_URL]: tooltipsMessages.LINKEDIN,
};

export default alertsTagsMessages;
