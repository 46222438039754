import styled from 'styled-components';

import { IconButton } from 'components/v2/buttons';

export const StyledIconButton = styled(IconButton)`
  border-radius: 8px;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 12px;
`;
