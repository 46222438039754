export default {
  login: {
    screen: {
      TITLE: 'Hello HIVE.',
      SUBTITLE: 'Sign in to your account',
      FORGOT_PASSWORD: 'Forgot your password',
      SIGN_UP_BUTTON: 'Sign Up',
      SIGN_IN_BUTTON: 'Sign In',
      HAS_ACCOUNT: "Don't have an account?",
      EMAIL_PLACEHOLDER: 'Email',
      PASSWORD_PLACEHOLDER: 'Password',
    },
    validations: {
      EMAIL_REQUIRED: 'Email required.',
      EMAIL_INVALID: 'Invalid email.',
      PASSWORD_MINIMUM: 'Minimum 6 digit.',
      PASSWORD_REQUIRED: 'Password required.',
    },
  },
  forgotPassword: {
    screen: {
      ENTER_EMAIL: 'Please enter your',
      EMAIL_BELLOW: 'email below.',
      CHECK_EMAIL: 'Check your email.',
      CANCEL_BUTTON: 'Cancel',
      RESEND_BUTTON: 'Resend link',
    },
    validations: {
      EMAIL_INVALID: 'Invalid email.',
      EMAIL_REQUIRED: 'Email required.',
    },
  },
  resetPassword: {
    screen: {
      ENTER_EMAIL: 'Please enter your',
      NEW_PASSWORD: 'new password.',
      CHECK_EMAIL: 'Check your email.',
      CANCEL_BUTTON: 'Cancel',
      PASSWORD_PLACEHOLDER: 'Password',
      CONFIRM_PLACEHOLDER: 'Confirm Password',
      SEND_BUTTON: 'Send',
      PASSWORD_CHANGED: 'Your password has been changed.',
      PLEASE_LOG_NOW: 'Please login now.',
      LOG_IN: 'Login',
    },
    validations: {
      PASSWORD_MINIMUM: 'Minimum 8 digit.',
      PASSWORD_REQUIRED: 'This field is required.',
      CONFIRM_REQUIRED: 'This field is required.',
      CONFIRM_MINIMUM: 'Minimum 8 digit.',
      EQUAL_PASSWORD: "Passwords don't match.",
    },
  },
  register: {
    screen: {
      COME_ON: 'Sign up and get buzzing.',
      LETS_GO: "LET'S GO",
      HEY: 'Hey you!',
      YOUR_NAME: "What's your name?",
      PLACEHOLDER_FIRST_NAME: 'First Name',
      PLACEHOLDER_LAST_NAME: 'Last Name',
      SCHOOL_EMAIL: "What's your school email?",
      PRIMARY_EMAIL: "What's your primary email?",
      EMAIL: '.edu',
      SCHOOL_EMAIL_INFO: `If you don’t receive a verification email, please reach out at <a href="mailto:support@hellohive.com">support@hellohive.com</a> and we will help you verify your account.`,
      HAS_PASSWORD: 'Password?',
      CREATE_PASSWORD: 'Create Password',
      CONFIRM_PASSWORD: 'Re-enter Password',
      CAN_DIGITS: 'Can we get',
      YOUR_DIGITS: 'your digits?',
      PLACEHOLDER_PHONE: 'Cell ( XXX-XXX-XXXX )',
      PLEASE_INFO: 'Please confirm that the email',
      CHECK_INFO: 'below is correct.',
      SKIP: 'Skip',
    },
    validations: {
      FIRST_NAME_MINIMUM: 'Minimum 2 characters.',
      FIRST_NAME_INVALID: 'Invalid name.',
      FIRST_NAME_REQUIRED: 'This field is required.',
      LAST_NAME_MINIMUM: 'Minimum 2 characters.',
      LAST_NAME_INVALID: 'Invalid name.',
      LAST_NAME_REQUIRED: 'This field is required.',
      UNIVERSITY_REQUIRED: 'Select one option.',
      EMAIL_REQUIRED: 'Email required.',
      EMAIL_INVALID: 'Invalid email.',
      PASSWORD_REQUIRED: 'This field is required.',
      PASSWORD_MINIMUM: 'Minimum 8 digit.',
      CONFIRM_PASSWORD_REQUIRED: 'This field is required.',
      PASSWORD_CHECK_NUMBER: 'Missing 1 number.',
      PASSWORD_CHECK_LOWERCASE_LETTER: 'Missing 1 lowercase letter.',
      PASSWORD_CHECK_UPPERCASE_LETTER: 'Missing 1 uppercase letter.',
      PASSWORD_CHECK_SPECIAL_CHARACTER: 'Missing 1 special character.',
      PASSWORD_CHARACTERS_REQUIRED:
        'Passwords should be between 8 and 30 characters and include at least 1 uppercase letter, 1 lowercase letter, 1 special character (@$!%*?&-), and 1 number.',
      PASSWORD_MAX_LENGTH: 'Password length too long, password must be between 8 and 30 characters',
      PASSWORD_MIN_LENGTH: 'Password length too short, password must be between 8 and 30 characters',
      CONFIRM_PASSWORD_MINIMUM: 'Minimum 8 digit.',
      EQUAL_PASSWORD: "Passwords don't match.",
      PHONE_REQUIRED: 'This field is required.',
      ENTER_VALID_PHONE: 'Please enter a valid phone number.',
      OLD_PASSWORD_MINIMUM: 'Minimum 6 digit.',
    },
  },
  onBoarding: {
    screen: {
      WELL_COME: 'Welcome back ',
      KNOW_YOU: "Let's get to know you a bit more.",
      LETS_GO: "LET'S GO",
      GRADUATING: 'Graduating',
      CLASS_OF: 'Class of',
      SUBJECT: 'Subject',
      PREFERRED_PRONOUNS: 'My preferred pronouns are:',
      PRONOUNS_SUBJECT: 'Ex: She, He, They',
      OBJECT: 'Object',
      PRONOUNS_OBJECT: 'Ex: Her, Him, Them',
      POSSESSIVE: 'Possessive',
      PRONOUNS_POSSESSIVE: 'Ex: Hers, His, Theirs',
      SKIP: 'Skip',
      BE_CREATIVE: 'Be creative!',
      YOURSELF_EMOJI: 'Express yourself in 5 emojis',
      ENTER_EMOJI: 'Ready, Set, Emoji',
      LETS_GO_BABY: "LET'S GO, BABY",
      I_AM: 'I am a',
      CLASS_STANDING_OPTION: 'Class Standing',
      YEAR_OPTION: 'Year',
    },
    validations: {
      CLASS_STANDING_REQUIRED: 'Please, select one option.',
      MONTH_REQUIRED: 'Please, select month.',
      YEAR_REQUIRED: 'Please, select year.',
      PRONOUNS_SUBJECT: 'This field is required.',
      PRONOUNS_OBJECT: 'This field is required.',
      PRONOUNS_POSSESSIVE: 'This field is required.',
    },
  },
  general: {
    REQUIRED: 'This field is required.',
  },
  errors: {
    RESPONSE_ERROR: 'Sorry, we cannot process the request. Try Later.',
    CLIENT_ERROR: 'Sorry, we cannot process the request. Try Later.',
    GENERAL_ERROR: 'Sorry, we cannot process the request. Try Later.',
    SERVER_ERROR: 'Sorry, we cannot process the request. Try Later.',
    TIMEOUT_ERROR: 'Sorry, we cannot process the request. Try Later.',
    CONNECTION_ERROR: 'Sorry, we cannot process the request. Try Later.',
    NETWORK_ERROR: 'Sorry, we cannot process the request. Try Later.',
    CANCEL_ERROR: 'Sorry, we cannot process the request. Try Later.',
    TOKEN_ERROR: 'Error token or token expired',
  },
};
