import omit from 'lodash/omit';

import { defaultValuesLevels, listLevels, listQuizzes } from 'constant';

const getLevel = id => defaultValuesLevels.find(a => a.id === id);

/**
 * Function util that return a object with default level values or first default level values.
 * @param {*} levelId
 * @return {object}
 */

const getDefaultLevel = levelId => {
  if (!levelId) {
    return defaultValuesLevels[0].values;
  }
  const defaultLevel = defaultValuesLevels.find(a => a.id === levelId);
  return { ...(defaultLevel ? defaultLevel.values : defaultValuesLevels[0].values) };
};

/**
 * Similar function util before, but return a object default level and previous levels without quizzes.
 * @return {object}
 */

export const getDefaultLevels = levelId => {
  const requiredValues = {
    email: '',
    typeId: null,
    university: null,
    id: null,
  };
  switch (levelId) {
    case listLevels.ONE_A:
      return { ...omit(getLevel(listLevels.ONE_A).values, listQuizzes), ...requiredValues };
    case listLevels.ONE_B:
      return {
        ...omit({ ...getLevel(listLevels.ONE_A).values, ...getLevel(listLevels.ONE_B).values }, listQuizzes),
        ...requiredValues,
      };
    case listLevels.ONE_C:
      return {
        ...omit(
          {
            ...getLevel(listLevels.ONE_A).values,
            ...getLevel(listLevels.ONE_B).values,
            ...getLevel(listLevels.ONE_C).values,
          },
          listQuizzes
        ),
        ...requiredValues,
      };
    default:
      return { ...omit(getLevel(listLevels.ONE_A).values, listQuizzes), ...requiredValues };
  }
};

export default getDefaultLevel;
