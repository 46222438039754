const alertsTags = {
  BULLETS_BUZZ: 'bullets_buzz',
  GPA: 'gpa',
  COURSEWORK: 'coursework',
  ENHANCED_EXP: 'enhanced_exp',
  LINKEDIN_URL: 'linkedin_url',
  EMPTY: 'empty',
};

export default alertsTags;
