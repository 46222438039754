import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { alertsTagsMessages, origins } from 'constant';
import { StyledLink } from 'styled';

import { Text } from './styled';

const buttonLabel = {
  coursework: 'Relevant Coursework',
  bullets_buzz: 'Bullets Buzz',
  gpa: 'GPA',
  enhanced_exp: 'Enhanced Experiences',
  linkedin_url: 'Linkedin',
};

const Alerts = ({ alerts, origin }) => (
  <>
    {alerts.map(alert => (
      <Fragment key={alert.tag}>
        {alert.tag !== 'empty' && (
          <>
            <Text>{alertsTagsMessages[alert.tag]}</Text>
            <StyledLink
              to={`level/${alert.level}?screen=${alert.tag}&origin=${origin}`}
              $bsStyle={{ marginLeft: 0, fontSize: '18px' }}
            >{`Go to ${buttonLabel[alert.tag]}`}</StyledLink>
          </>
        )}
      </Fragment>
    ))}
  </>
);

Alerts.propTypes = {
  alerts: PropTypes.arrayOf(
    PropTypes.shape({
      tag: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
    })
  ),
  origin: PropTypes.oneOf([origins.HOME, origins.LEVELS]).isRequired,
};

Alerts.defaultProps = {
  alerts: [],
};

export default Alerts;
