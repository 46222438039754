export default {
  NewCards: {
    minWidth: '100%',
    maxWidth: '100%',
  },

  subTitleCard: {
    textAlign: 'left',
    display: 'inline-block',
  },

  WrapperCtaCard: {
    margin: '0px',
    position: 'relative',
    '@media (max-width: 430px)': {
      margin: '0 0 0 0',
    },
  },

  OverlayFilter: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    width: '100vw',
    height: '100vw',
    background: 'rgba(36, 16, 79, 0.73)',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    opacity: '0',
    pointerEvent: 'none',
  },

  FilterDropDown: {
    position: 'absolute',
    width: '281px',
    right: '0px',
    display: 'none',
    borderRadius: '12px',
    zIndex: '99',
    backgroundColor: 'var(--color-primary)',
    padding: '16px 0 0 0',
    '&.show': {
      display: 'block',
    },
    filter: 'drop-shadow(0px 4px 20px rgba(33, 33, 33, 0.66))',
    '.filter-header': {
      color: 'var(--color-white)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 16px',
      boxShadow: '0px 4.66904px 23.3452px rgba(0, 0, 0, 0.26)',
      height: '50px',
      marginTop: '-16px',
    },
    '.filter-list-dropdown': {
      listStyle: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      margin: '0px',
      padding: '12px 0',
      flexDirection: 'column',
      li: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        borderBottom: '1px solid var(--color-primary-dark)',
        '&:last-child': {
          border: 'none',
        },
        label: {
          width: '100%',
          display: 'flex',
          padding: '12px 1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
        },
        '.tab-content': {
          maxHeight: '0',
          padding: '0 1rem',
          transition: 'max-height .5s ease-in-out',
          backgroundColor: 'var(--color-primary-dark)',
          overflow: 'hidden',
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          '.item': {
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            height: '34px',
            alignItems: 'center',
            label: {
              padding: '0',
            },
            input: {
              border: '1px solid var(--color-white)',
              backgroundColor: 'var(--color-primary-dark)',
              appearance: 'none',
              width: '17px',
              height: '17px',
              borderRadius: '50%',
              position: 'relative',
              '&:checked': {
                backgroundColor: 'var(--color-white)',
                '&:after': {
                  content: `'✓'`,
                  fontSize: '15px',
                  color: 'var(--color-primary-dark)',
                  fontWeight: 'bold',
                },
              },
            },
          },
        },
        'input.hidden': {
          position: 'absolute',
          opacity: '0',
          zIndex: '-1',
        },
        'input.hidden:checked ~ .tab-content': {
          maxHeight: '100vh',
          padding: '1em',
        },
        'input.hidden:checked ~ label .triangle': {
          width: '0',
          height: '0',
          borderStyle: 'solid',
          borderWidth: '0 5px 5px 5px',
          borderColor: 'transparent transparent var(--color-white) transparent',
        },
        '.filter-list-dropdown_item_left': {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          span: {
            fontWeight: 'bold',
            fontSize: '16px',
            lineHeight: '18px',
            color: 'var(--color-white)',
            '&.marked': {
              fontWeight: 'bold',
              fontSize: '12px',
              lineHeight: '15px',
              marginTop: '6px',
              color: 'var(--color-secondary)',
            },
          },
        },
        '.filter-list-dropdown_item_right': {
          alignItems: 'center',
          justifyContent: 'flex-end',
          display: 'flex',
          flexDirection: 'column',
          '.triangle': {
            width: '0',
            height: '0',
            borderStyle: 'solid',
            borderWidth: '5px 5px 0 5px',
            borderColor: 'var(--color-white) transparent transparent transparent',
          },
        },
      },
    },
    '.filter-header-left': {
      color: 'var(--color-white)',
      fontSize: '16px',
    },
    '.filter-header-right': {
      color: 'var(--color-white)',
      fontSize: '12px',
      fontWeight: 'bold',
    },
    '&:after': {
      content: `''`,
      width: '0',
      height: '0',
      borderStyle: 'solid',
      borderWidth: '0 11px 15px 11px',
      borderColor: 'transparent transparent var(--color-primary) transparent',
      top: '-11px',
      right: '42px',
      position: 'absolute',
    },
  },
};
