import styled from 'styled-components';

import { ErrorMessage } from 'components/v2/forms/components';

export const EmailShowCase = styled.div`
  width: 100%;
  max-width: 298px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 4px;
  padding: 1.5rem;
  color: white;
  font-size: 16px;
  line-height: 100%;
  margin-inline: auto;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 45px;
  gap: 1rem;
  justify-content: center;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 16px;
`;
