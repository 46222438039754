export const initialState = {
  error: null,
  isLoading: false,
  isLoadingToggles: false,
  isLoadingSearch: false,
  company: null,
  filters: null,
  data: {
    companies: [],
    meta: null,
  },
  opportunities: [],
  isLoadingOpportunities: false,
};
