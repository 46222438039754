import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';

const Head = ({ title }) => {
  return (
    <Helmet>
      <title>{title}</title>
    </Helmet>
  );
};

export default Head;

Head.propTypes = {
  title: PropTypes.string.isRequired,
};

Head.defaultProps = {
  title: 'HelloHive',
};
