import _, { template, mapValues } from 'lodash';

import { getParentValues, isTemplate } from 'utils';

const buildStyles = (styles, values, control) => {
  const parentValues = getParentValues(values, control);
  return mapValues(styles, style =>
    isTemplate(style) ? template(style)({ ...values, _, values, parentValues }) : style
  );
};

export default buildStyles;
