import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { BackButton, Button } from 'components/v2/buttons';
import { Heading, HeadingLevel } from 'components/v2/heading';
import { AuthTemplate } from 'components/v2/templates';
import { registerRoutesV2, routeNames } from 'constant';
import authCreator from 'store/authorization/actions';
import { getError, getIsRegistering } from 'store/authorization/selectors';
import { registerStateSelector, registerStateValuesSelector } from 'store/v2/register/selectors';

import { ButtonContainer, EmailShowCase, StyledErrorMessage } from './VerifyAccount.styled';

const VerifyAccount: React.FC = () => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const registerStore = useSelector(registerStateSelector);
  const registerStoreValues = useSelector(registerStateValuesSelector);
  const isRegistering = useSelector(getIsRegistering);
  const email = registerStoreValues.email;
  const error = useSelector(getError);

  const handlePrev = useCallback(() => {
    push(registerRoutesV2.step5);
  }, [push]);

  const callRetrySendEmail = useCallback(() => {
    dispatch(authCreator.authRetrySendEmailRequest(registerStoreValues.email));
  }, []);

  useEffect(() => {
    dispatch(authCreator.authRegisterRequest(registerStoreValues));
  }, []);

  React.useEffect(() => {
    if (Object.values(registerStore).some(registerStep => !registerStep.isCompleted)) {
      push(registerRoutesV2.step1);
    }
  }, [registerStore]);

  return (
    <AuthTemplate title={routeNames.REGISTER_THANK_YOU}>
      <Heading level={HeadingLevel.h1}>Check your inbox to verify your account.</Heading>
      <EmailShowCase>{email}</EmailShowCase>
      <ButtonContainer>
        <BackButton onClick={handlePrev} />
        <Button isLoading={isRegistering} onClick={callRetrySendEmail}>
          Resend Link
        </Button>
      </ButtonContainer>
      <StyledErrorMessage error={error} />
    </AuthTemplate>
  );
};

export default VerifyAccount;
