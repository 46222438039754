import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  control: PropTypes.string,
  multipleSelection: PropTypes.bool,
  clearValueOnDestroy: PropTypes.bool,
  clearValueOnHide: PropTypes.bool,
  activeFormFields: PropTypes.shape({}),
  conditional: PropTypes.arrayOf(PropTypes.shape({})),
  itemToShow: PropTypes.arrayOf(PropTypes.string),
  optionalControls: PropTypes.arrayOf(PropTypes.string),
  validationSubType: PropTypes.string,
  labelText: PropTypes.string,
  inputControl: PropTypes.string,
  selectControl: PropTypes.string,
  backgroundButton: PropTypes.string,
  style: PropTypes.shape({}),
  childrenStyle: PropTypes.shape({}),
  wrapperStyle: PropTypes.shape({}),
  label: PropTypes.string,
  defaultAmount: PropTypes.number,
  variant: PropTypes.string,
  redirectStep: PropTypes.number,
  showStep: PropTypes.bool,
  notShowCount: PropTypes.bool,
  saveName: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  nameControl: PropTypes.string,
  nameRoute: PropTypes.string,
  valueRoute: PropTypes.string,
  onChangeSetValue: PropTypes.arrayOf(PropTypes.shape({})),
  enableScreens: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      values: PropTypes.arrayOf(PropTypes.number),
      validation: PropTypes.string,
    })
  ),
  disabledScreens: PropTypes.arrayOf(PropTypes.shape({})),
  inputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
    })
  ),
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
  ]),
});
