import { clone, findIndex, isEmpty } from 'lodash';
import { takeLatest, call, put, select } from 'redux-saga/effects';

import { requestCompany, requestCompanyOpportunities, requestToggles, requestSearch } from 'services/company';
import { getToken } from 'services/storage';
import companyCreator, { companyTypes } from 'store/company/actions';
import { getCompanies, getCompaniesMeta } from 'store/company/selectors';
import { getResponseError, getVerbPastToggle, i18n } from 'utils';

export function* search({ filters }) {
  try {
    const response = yield call(requestSearch, filters);
    const error = yield getResponseError(response);
    const { data = [], meta = {} } = response.data;
    if (!error) {
      yield put(companyCreator.companySearchSuccess(data, meta));
    } else {
      yield put(companyCreator.companySearchError(error));
    }
  } catch (error) {
    yield put(companyCreator.companySearchError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* find({ companyId }) {
  try {
    const response = yield call(requestCompany, companyId);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(companyCreator.companyFindSuccess(response.data.company));
    } else {
      yield put(companyCreator.companyFindError(error));
    }
  } catch (error) {
    yield put(companyCreator.companyFindError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* toggles({ company, entity }) {
  try {
    const entityInPast = getVerbPastToggle(entity);
    const response = yield call(requestToggles, company.id, !company[entityInPast], entity);
    const error = yield getResponseError(response);

    if (!error) {
      const companies = clone(yield select(getCompanies));
      const meta = clone(yield select(getCompaniesMeta));
      if (!isEmpty(companies)) {
        const index = findIndex(companies, c => c.id === company.id);
        companies[index] = { ...company, [entityInPast]: !company[entityInPast] };
      }
      yield put(
        companyCreator.companyTogglesSuccess(
          { companies, meta },
          { ...company, [entityInPast]: !company[entityInPast] }
        )
      );
    } else {
      yield put(companyCreator.companyTogglesError(error));
    }
  } catch (error) {
    yield put(companyCreator.companyTogglesError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* companyOpportunities({ companyId, params }) {
  try {
    const response = yield call(requestCompanyOpportunities, companyId, params, !!getToken());
    const error = yield getResponseError(response);
    const { data = [], meta = {} } = response.data;

    if (!error) {
      yield put(companyCreator.companyOpportunitiesSuccess(data, meta));
    } else {
      yield put(companyCreator.companyOpportunitiesError(response.data.message || response.problem));
    }
  } catch (error) {
    yield put(companyCreator.companyOpportunitiesError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* companySaga() {
  yield takeLatest(companyTypes.COMPANY_SEARCH_REQUEST, search);
  yield takeLatest(companyTypes.COMPANY_FIND_REQUEST, find);
  yield takeLatest(companyTypes.COMPANY_OPPORTUNITIES_REQUEST, companyOpportunities);
  yield takeLatest(companyTypes.COMPANY_TOGGLES_REQUEST, toggles);
}
