import { takeLatest, call, put } from 'redux-saga/effects';

import { fetchRequest } from 'services/static-data';
import staticDataCreator, { staticDataTypes } from 'store/static-data/actions';
import { getResponseError, i18n } from 'utils';

export function* fetch({ params }) {
  try {
    const response = yield call(fetchRequest, params);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(staticDataCreator.staticDataFetchSuccess(response.data));
    } else {
      yield put(staticDataCreator.staticDataFetchError(error));
    }
  } catch (error) {
    yield put(staticDataCreator.staticDataFetchError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* staticDataSaga() {
  yield takeLatest(staticDataTypes.STATIC_DATA_FETCH_REQUEST, fetch);
}
