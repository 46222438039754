import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  control: PropTypes.string,
  background: PropTypes.string,
  theme: PropTypes.string,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      correctAnswers: PropTypes.arrayOf(PropTypes.number),
      expandedBee: PropTypes.string,
      responseControl: PropTypes.string,
      form: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
    })
  ),
});
