import filter from 'lodash/filter';
import sumBy from 'lodash/sumBy';

import getLinesSize from './get-lines-size';

const getEnabledLines = collections => {
  return sumBy(collections, ({ lines, activeLines }) =>
    activeLines ? getLinesSize(filter(lines, line => line.enabled)) : 0
  );
};

export default getEnabledLines;
