export const getCompany = state => state.company.company;

export const getCompanies = state => state.company.data?.companies;

export const getIsLoading = state => state.company.isLoading;

export const getError = state => state.company.error;

export const getIsLoadingOpportunities = state => state.company.isLoadingOpportunities;

export const getCompaniesMeta = state => state.company.data?.meta;

export const getIsLoadingSearch = state => state.company.isLoadingSearch;

export const getOpportunities = state => state.company.data?.opportunities;

export const getOpportunitiesMeta = state => state.company.data?.meta;

export const getCompanyFilters = state => state.company?.filters;
