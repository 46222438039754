import { FieldArray, getIn, useFormikContext } from 'formik';
import React, { useCallback } from 'react';

import { AddButton } from 'components/v2/buttons';
import { Checkbox, Input, Label, Radio } from 'components/v2/forms/components';
import { HeadingLevel } from 'components/v2/heading';
import {
  AddSectionButton,
  RemoveButton,
  SectionHeader,
} from 'pages/v2/level/1A/components/academics/components/shared';
import { scholarshipInitialValues } from 'store/v2/level/initial-state';
import { AcademicsFormValues } from 'store/v2/level/types';

const NAME_PREFIX = 'degreeScholarships';

interface ScholarshipsProps {
  activeDegreeFormIndex: number;
}

const Scholarships: React.FC<ScholarshipsProps> = ({ activeDegreeFormIndex }) => {
  const fieldName = `${NAME_PREFIX}[${activeDegreeFormIndex}]`;

  const { values, errors, touched, handleChange, handleBlur, setFieldValue } = useFormikContext<AcademicsFormValues>();

  const getError = useCallback(
    (key: string) => (getIn(touched, key) && getIn(errors, key) ? getIn(errors, key) : ''),
    [touched, errors, getIn]
  );

  const onFullTuitionChange = (event, index) => {
    const isFullTuitionChecked = event.target.value === 'yes';

    if (isFullTuitionChecked) {
      setFieldValue(`${fieldName}[${index}].amount`, '');
    }

    setFieldValue(`${fieldName}[${index}].fullTuition`, isFullTuitionChecked);
  };

  return (
    <FieldArray name={fieldName}>
      {arrayHelpers =>
        values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.length === 0 ? (
          <AddSectionButton label="Scholarship" onClick={() => arrayHelpers.push({ ...scholarshipInitialValues })} />
        ) : (
          <>
            {(values?.[NAME_PREFIX]?.[activeDegreeFormIndex] || []).map((value, index) => (
              <React.Fragment key={`${fieldName}[${index}]`}>
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <SectionHeader className="mb-2" level={HeadingLevel.h2}>
                    Scholarship #{index + 1}
                  </SectionHeader>
                  <RemoveButton onClick={() => arrayHelpers.remove(index)} />
                </div>
                <div className="col-12">
                  <Input
                    className="mb-3"
                    name={`${fieldName}[${index}].name`}
                    label="Scholarship"
                    placeholder="Name of Scholarship"
                    value={value?.name}
                    error={getError(`${fieldName}[${index}].name`)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </div>
                <div className="col-12">
                  <Label>Full Tuition</Label>
                </div>
                <div className="col-3">
                  <Radio
                    className="mb-3"
                    name={`${fieldName}[${index}].fullTuition`}
                    optionName="Yes"
                    value="yes"
                    onChange={event => onFullTuitionChange(event, index)}
                    checked={value?.fullTuition}
                  />
                </div>
                <div className="col-6">
                  <Radio
                    className="mb-3"
                    name={`${fieldName}[${index}].fullTuition`}
                    optionName="No"
                    value="no"
                    onChange={event => onFullTuitionChange(event, index)}
                    checked={!value?.fullTuition}
                  />
                </div>
                {!value?.fullTuition && (
                  <div className="col-12">
                    <Input
                      className="mb-3"
                      name={`${fieldName}[${index}].amount`}
                      label="Annual $ Amount"
                      placeholder="Amount (Ex.: 10,000)"
                      value={value?.amount}
                      error={getError(`${fieldName}[${index}].amount`)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </div>
                )}
                <div className="col-12">
                  <Checkbox
                    name={`${fieldName}[${index}].includeOnResume`}
                    optionName="Include on resume and profile."
                    checked={value?.includeOnResume}
                    onChange={handleChange}
                  />
                </div>
              </React.Fragment>
            ))}
            <div className="col-12">
              <AddButton className="mb-4" onClick={() => arrayHelpers.push({ ...scholarshipInitialValues })}>
                Add Another
              </AddButton>
            </div>
          </>
        )
      }
    </FieldArray>
  );
};

export default Scholarships;
