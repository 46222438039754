import PropTypes from 'prop-types';

export default PropTypes.shape({
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  alternativeEmail: PropTypes.string,
  Pronouns: PropTypes.shape({
    object: PropTypes.string,
    subject: PropTypes.string,
    possessive: PropTypes.string,
  }),
  myselfEmoji: PropTypes.string,
  seekingJob: PropTypes.number,
});
