import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  opportunitySearchRequest: ['filters'],
  opportunitySearchSuccess: ['opportunities', 'meta'],
  opportunitySearchError: ['error'],

  opportunityFetchAmountRequest: [null],
  opportunityFetchAmountSuccess: ['amount'],
  opportunityFetchAmountError: ['error'],

  opportunityFindRequest: ['opportunityId'],
  opportunityFindSuccess: ['opportunity'],
  opportunityFindError: ['error'],

  opportunityApplyRequest: ['opportunity'],
  opportunityApplySuccess: ['applicant'],
  opportunityApplyError: ['errorApply'],

  opportunityTogglesRequest: ['opportunity', 'entity'],
  opportunityTogglesSuccess: ['data', 'opportunity'],
  opportunityTogglesError: ['error'],

  opportunitySetTagRequest: ['opportunityId', 'tag', 'entity'],
  opportunitySetTagSuccess: ['opportunity'],
  opportunitySetTagError: ['error'],

  cleanOpportunity: [null],

  setQueryParams: ['showApplyModal'],

  opportunityViewsRequest: ['opportunityId'],
});

export const opportunityTypes = Types;
export default Creators;
