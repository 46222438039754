const getOpportunityTypeColor = opportunity => {
  if (opportunity.typeDuration === 'Part Time Internship') {
    return '#5CA7BF';
  }
  if (opportunity.typeDuration === 'Full Time Internship') {
    return '#07CCE2';
  }
  if (opportunity.typeDuration === 'Part Time Job') {
    return '#009ED0';
  }
  if (opportunity.typeDuration === 'Full Time Job') {
    return '#00C1B0';
  }
  if (opportunity.typeDuration === 'Event') {
    return '#3BF1BD';
  }
  return '#3BF1BD';
};

export default getOpportunityTypeColor;
