export const getStudent = state => state.student.data;

export const getIsLoading = state => state.student.isLoading;

export const getError = state => state.student.error;

export const getIsSaving = state => state.student.isSaving;

export const getIsDeleting = state => state.student.isDeleting;

export const getApplicant = state => state.student.applicant;

export const getIsLoadingApplicant = state => state.student.isLoadingApplicant;

export const getIsLoadingResumeTemp = state => state.student.isLoadingResumeTemp;

export const getResumeTemp = state => state.student.resumePdf;

export const getAssignmentErrors = state => state.student.assignmentErrors;

export const getVerifiedValues = state => state.student.verifiedValues;

export const getIsVerifyingValues = state => state.student.isVerifyingValues;

export const getErrorUpdate = state => state.student.errorUpdate;

export const getIsSuccess = state => state.student.isSuccess;

export const getAvailableLines = state => state.student.availableLines;

export const getLoadingAvailableLines = state => state.student.isLoadingAvailableLines;

export const getErrorLines = state => state.student.errorLines;

export const getErrorResume = state => state.student.errorResume;

export const getAlerts = state => state.student.alerts;

export const getResumeStatus = state => state.student.percentageOccupied;

export const getResumeCount = state => state.student.resumeCount;

export const getResumeError = state => state.student.resumeError;

export const getIsLoadingResume = state => state.student.isLoadingResume;

export const getIsLoadingResumeStatus = state => state.student.isLoadingResumeStatus;

export const getIsLoadingApply = state => state.student.isLoadingApply;

export const getAreAlertsDeleted = state => state.student.areAlertsDeleted;
