import React from 'react';

import { StyledFormActionButtonsRow } from './FormActionButtonsRow.styled';
interface FormActionButtonsRowProps {
  className?: string;
  children?: JSX.Element | JSX.Element[];
}
const FormActionButtonsRow = (props: FormActionButtonsRowProps) => {
  return <StyledFormActionButtonsRow className={props.className}>{props.children}</StyledFormActionButtonsRow>;
};

export default FormActionButtonsRow;
