import { LEVEL_STEPS } from 'constant';

import {
  AcademicsFormValues,
  AdditionalDetailsCollegiateAthleticsValues,
  AdditionalDetailsFormValues,
  CertificationsAndLicensesFormValues,
  CollegiateAthleticsFormValues,
  Concentration,
  ContactInformationFormValues,
  CourseworkFormValues,
  Degree,
  DiversityFormValues,
  HighSchoolFormValues,
  Honor,
  LanguagesFormValues,
  LevelState,
  Major,
  Minor,
  PersonalInterstsFormValues,
  ResearchAndProgramsFormValues,
  Scholarship,
  StudyAbroadFormValues,
  TechnicalSkillsFormValues,
} from './types';

export const concentrationInitialValues: Concentration = {
  concentration: '',
  category: null,
  otherCategory: '',
  gpa: '',
  includeOnResume: false,
};

export const majorInitialValues: Major = {
  major: null,
  otherMajor: '',
  category: null,
  otherCategory: '',
  gpa: '',
  includeOnResume: false,
};

export const minorInitialValues: Minor = {
  minor: null,
  otherMinor: '',
};

export const scholarshipInitialValues: Scholarship = {
  amount: '',
  fullTuition: false,
  includeOnResume: false,
  name: '',
};

export const honorInitialValues: Honor = {
  includeOnResume: false,
  isThroughoutCollege: false,
  year: [],
  name: '',
};

export const degreeInitialValues: Degree = {
  degree: null,
  university: null,
  state: '',
  city: null,
  college: '',
  graduationMonth: null,
  graduationYear: null,
  cumulativeGPA: '',
  includeCumulativeGPAOnResume: false,
  notPublishCumulativeGPAs: false,
  notHaveCumulativeGPA: false,
  concentrations: [],
  majors: [],
  minors: [],
};

export const bachelorDegreeInitialValues: Degree = {
  ...degreeInitialValues,
  majors: [{ ...majorInitialValues }],
  minors: [{ ...minorInitialValues }],
};

export const masterDegreeInitialValues: Degree = {
  ...degreeInitialValues,
  concentrations: [{ ...concentrationInitialValues }],
};

export const initialState: LevelState = {
  [LEVEL_STEPS.contactInformation]: {
    values: {} as ContactInformationFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.highSchool]: {
    values: {} as HighSchoolFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.academics]: {
    values: {} as AcademicsFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.studyAbroad]: {
    values: {} as StudyAbroadFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.researchAndPrograms]: {
    values: {} as ResearchAndProgramsFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },

  [LEVEL_STEPS.coursework]: {
    values: {} as CourseworkFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.collegiateAthletics]: {
    values: {} as CollegiateAthleticsFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.additionalDetailsCollegiateAthletics]: {
    values: {} as AdditionalDetailsCollegiateAthleticsValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.additionalDetails]: {
    values: {} as AdditionalDetailsFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.technicalSkill]: {
    values: {} as TechnicalSkillsFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.languages]: {
    values: {} as LanguagesFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.certificationsAndLicences]: {
    values: {} as CertificationsAndLicensesFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.personalInterests]: {
    values: {} as PersonalInterstsFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
  [LEVEL_STEPS.diversity]: {
    values: {} as DiversityFormValues,
    isCompleted: false,
    isLoading: false,
    error: '',
  },
};
