import { isEmpty, find } from 'lodash';

import getIsComplete from './get-is-complete';
import getLevelUser from './get-level-user';
import { listLevels, progressStatus } from '../constant';

const getLevelIsDisabled = (levelId, prevLevelId, progressLevels = [], typeId, isEdit = false) => {
  // if is first level is always allowed
  if (levelId === listLevels.ONE_A || levelId === listLevels.ONE) {
    return false;
  }

  let levelUser;
  if (
    prevLevelId === listLevels.ONE_A ||
    prevLevelId === listLevels.ONE_B ||
    prevLevelId === listLevels.ONE_C ||
    prevLevelId === listLevels.PC
  ) {
    levelUser = getLevelUser(prevLevelId, null, progressLevels);
  } else {
    levelUser = getLevelUser(prevLevelId, typeId, progressLevels);
  }

  if (isEdit) {
    const isComplete = getIsComplete(progressLevels);
    return isEmpty(progressLevels) ? true : !isComplete;
  }

  if (levelId === listLevels.PC) {
    if (levelUser && levelUser.status === progressStatus.COMPLETE) {
      return false;
    }

    const level6 = find(progressLevels, l => l.level === listLevels.SIX && typeId === l.typeId);
    if (level6 && level6.status === progressStatus.COMPLETE) {
      return false;
    }

    const level1old = find(progressLevels, l => l.level === listLevels.FIVE && typeId === l.typeId);
    const level4 = find(progressLevels, l => l.level === listLevels.FOUR && typeId === l.typeId);

    return !(
      level1old &&
      level1old.status === progressStatus.COMPLETE &&
      level4 &&
      level4.status === progressStatus.COMPLETE
    );
  }

  return levelUser ? levelUser.status !== progressStatus.COMPLETE : true;
};

export default getLevelIsDisabled;
