/**
 * Function util that return is object empty or array empty.
 * @param {*} params
 * @return {boolean}
 */

const isEmpty = params => {
  if (Array.isArray(params)) {
    return params.length === 0;
  }
  if (typeof params === 'object' && params !== null) {
    return Object.entries(params).length === 0;
  }
  return true;
};

export default isEmpty;
