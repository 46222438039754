import styled from 'styled-components';

import { Button } from 'components/v2/buttons';

export const StyledButton = styled(Button)`
  border-radius: 4px;
  text-transform: capitalize;
`;

export const Paragraph = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 0;
  color: ${props => (props.active ? '#FFBE33' : '#fff')};
`;

export const HeaderDetails = styled(Paragraph)`
  margin-bottom: 32px;
`;

export const SubmittedHeader = styled(Paragraph)`
  margin-top: 48px;
  margin-bottom: 8px;
`;

export const SubmittedDetailsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubmittedDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .details {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin: 0;
  }
`;

export const SubmittedValuesWrapper = styled.div`
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid #cecae2;
  border-radius: 4px;
  padding: 16px;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .length {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 0.5px;

    color: #9896a5;
  }
`;

export const SubmittedValues = styled.div`
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;

  background: ${props =>
    props.active ? '#2A2123' : 'linear-gradient(0deg, #121021, #121021), rgba(255, 255, 255, 0.1)'};
  border: 1px solid ${props => (props.active ? '#FFBE33' : '#cecae2')};
  border-radius: 8px;

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    max-width: 90%;
  }

  .truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .svg-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    .svg-button {
      padding: 0;
      background: transparent;
      display: inline-flex;
      height: 24px;
      width: fit-content;
      border: none;
    }
  }
`;
