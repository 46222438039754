import styled from 'styled-components';

import hexagon from 'assets/svg/avatar_profile.svg';

const Hexagon = styled.div`
  display: flex;
  background-image: url(${hexagon});
  background-color: transparent;
  background-size: 227px 198px;
  background-repeat: no-repeat;
  font-weight: 900;
  font-size: 72px;
  line-height: 115%;
  width: 227px;
  height: 198px;
  align-items: center;
  justify-content: center;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  color: white;
`;

export default Hexagon;
