import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(0deg, #121021, #121021), linear-gradient(180deg, #1f1d30 0%, #000 100%);
  height: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 458px;
  padding: 16px;
  width: 100%;
`;

export const Image = styled.img`
  max-width: 200px;
  max-height: 120px;
  margin-bottom: 15px;
`;
