import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  saveFormValues: ['stepName', 'values', 'isCompleted'],
  clearFormValues: [''],
});

export const registerTypes = Types;

export default Creators;
