import { nonSelectable } from '@hivediversity/common-lib/styled/css-helpers';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  max-width: 1160px;
  padding: 0 24px;
  width: 100%;
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  position: relative;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  max-width: 677px;
  bottom: -27px;
  background-color: var(--color-primary);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.25);
  width: calc(100% - 18px);
  left: 0;
  z-index: 1;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const ButtonTab = styled.button`
  appearance: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  color: var(--color-white);
  padding: 0;
  text-transform: capitalize;
  ${({ $showing }) =>
    $showing
      ? css`
          font-weight: 900;
          opacity: 1;
        `
      : css`
          font-weight: 200;
          opacity: 0.5;
        `};
  ${nonSelectable};
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Item = styled.li`
  list-style: none;
  padding: 0;
  margin: 0;
  position: relative;
  width: 100%;
  &:after {
    content: '';
    height: 22px;
    top: 0;
    right: 0;
    position: absolute;
    border-right: 1px solid var(--color-white);
  }
  &:last-child {
    &:after {
      display: none;
    }
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const List = styled.ul`
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''};
`;
