import * as yup from 'yup';

import { CollegiateAthletics, ExperienceLine } from 'store/v2/level/types';

export const collegiateAthleticsDefaultValue: CollegiateAthletics = {
  startYear: null,
  endYear: null,
  startPeriod: null,
  startPeriodName: '',
  endPeriod: null,
  endPeriodName: '',
  position: '',
  university: null,
  universityName: '',
  leadership: '',
  sport: null,
  sportName: '',
  isDivisionOne: false,
  includeOnResume: false,
  lines: [] as ExperienceLine[],
};

const PRESENT_OPTION_ID = 5;
const REQUIRED_MESSAGE = 'Required.';

export const validationSchema = yup.object().shape({
  d1Athletics: yup.array().of(
    yup.object().shape({
      university: yup.number().nullable().required(REQUIRED_MESSAGE),
      universityName: yup.string().nullable(),
      sport: yup.number().nullable().required(REQUIRED_MESSAGE),
      sportName: yup.string().nullable(),
      isDivisionOne: yup.boolean().nullable().required(REQUIRED_MESSAGE),
      startPeriodName: yup.string().nullable(),
      startYear: yup.number().nullable().required(REQUIRED_MESSAGE),
      endYear: yup
        .number()
        .nullable()
        .when('endPeriod', {
          is: endPeriod => endPeriod !== PRESENT_OPTION_ID,
          then: yup
            .number()
            .nullable()
            .required(REQUIRED_MESSAGE)
            .test('end-year-later-start-year', 'End year must be after start year', function (endYear) {
              const { startYear } = this.parent as CollegiateAthletics;
              if (startYear && endYear && startYear <= endYear) return true;
              return false;
            }),
        }),
      startPeriod: yup.number().nullable().required(REQUIRED_MESSAGE),
      endPeriod: yup.number().nullable().required(REQUIRED_MESSAGE),
      endPeriodName: yup.string().nullable(),
      position: yup.string().nullable().required(REQUIRED_MESSAGE),
      leadership: yup.string().nullable(),
      includeOnResume: yup.boolean().nullable().required(REQUIRED_MESSAGE),
    })
  ),
});
