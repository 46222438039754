import { get, map } from 'lodash';

const getOptions = (list, nameRoute, valueRoute) => {
  return map(list, listItem => ({
    name: get(listItem, nameRoute || 'name'),
    value: get(listItem, valueRoute || 'id'),
  }));
};

export default getOptions;
