import { LoadingPage, Resume } from '@hivediversity/common-lib/components';
import omit from 'lodash/omit';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { CustomModal, SidebarLogged } from 'components';
import { PreviewResumeButton, IconButton } from 'components/v2/buttons';
import { Heading, HeadingLevel } from 'components/v2/heading';
import { NamedStepper } from 'components/v2/named-stepper';
import { themesObj, LevelConfig, LEVEL_NAME, listQuizzes, LEVEL_STEPS } from 'constant';
import { useNotOnePageModal } from 'hooks';
import studentCreators from 'store/student/actions';
import { getError, getIsLoading, getStudent, getResumeTemp, getIsLoadingResumeTemp } from 'store/student/selectors';
import levelCreators from 'store/v2/level/actions';
import { levelAllFormValuesSelector } from 'store/v2/level/selectors';
import { getLevelFormValues } from 'store/v2/level/utils';
import { parseNullToEmptyString } from 'utils';

import { Wrapper, Container, Content, Header, StyledStepper, StyledBackButton } from './LevelTemplate.styled';

interface LevelTemplateProps extends React.ComponentProps<typeof NamedStepper> {
  levelConfig: LevelConfig[LEVEL_NAME];
  className?: string;
}

const LevelTemplate: React.FC<LevelTemplateProps> = ({ children, levelConfig, steps, activeStep, onStepChange }) => {
  const { push } = useHistory();
  const [showOnePageModal, NotOnePageModal]: any = useNotOnePageModal();

  const dispatch = useDispatch();

  const [showResume, setShowResume] = useState(false);

  const levelAllFormValues = useSelector(levelAllFormValuesSelector);

  const student = useSelector(getStudent);
  const isStudentLoading = useSelector(getIsLoading);
  const studentError = useSelector(getError);

  const resumeTemp = useSelector(getResumeTemp);
  const isLoadingResumeTemp = useSelector(getIsLoadingResumeTemp);

  const handleGoBack = useCallback(() => {
    push(levelConfig.goBackConfig.url);
  }, [push]);

  const handlePreview = () => {
    if (!showOnePageModal()) {
      dispatch(
        studentCreators.studentFindTempResumeRequest(
          parseNullToEmptyString(omit({ ...student, ...levelAllFormValues }, listQuizzes))
        )
      );

      setShowResume(true);
    }
  };

  useEffect(() => {
    if (!student && !isStudentLoading && !studentError) {
      dispatch(studentCreators.studentFetchRequest());
    } else if (student && !isStudentLoading) {
      // Need to sync store with a backend response
      const levelFormValues = Object.values(LEVEL_STEPS).reduce(
        (acc, stepName) => ({
          ...acc,
          [LEVEL_STEPS[stepName]]: getLevelFormValues(stepName, student),
        }),
        {}
      );

      dispatch(levelCreators.setLevelFormValues(levelFormValues));
    }
  }, [student, isStudentLoading, studentError]);

  if (isStudentLoading || !student) {
    return <LoadingPage />;
  }

  return (
    <ThemeProvider theme={themesObj.black}>
      <>
        <NotOnePageModal />
        <Wrapper>
          <Container className="container px-3">
            <Header className="row">
              <div className="col-12 col-md-6 d-flex mb-3 mb-md-0 px-0 align-items-baseline">
                <SidebarLogged inlineToggleButton />
                <Heading className="my-0 mx-auto m-md-0 ms-md-4" level={HeadingLevel.h1}>
                  {levelConfig.title}
                </Heading>
              </div>
              <div className="col-12 col-md-6 d-flex justify-content-between justify-content-md-end px-md-0">
                <IconButton icon="leftChevron" onClick={handleGoBack}>
                  <StyledBackButton>{levelConfig.goBackConfig.title}</StyledBackButton>
                </IconButton>
                <PreviewResumeButton onClick={handlePreview} />
              </div>
            </Header>
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <StyledStepper steps={steps} activeStep={activeStep} onStepChange={onStepChange} />
              </div>
              <div className="col-12 col-lg-7 offset-lg-2">
                <Content>{children}</Content>
              </div>
            </div>
          </Container>
        </Wrapper>
        <CustomModal isActive={showResume} fullPage>
          <Resume
            file={resumeTemp}
            isLoading={isLoadingResumeTemp}
            title="Preview Resume"
            onClose={() => setShowResume(false)}
            hideDownload
          />
        </CustomModal>
      </>
    </ThemeProvider>
  );
};

export default LevelTemplate;
