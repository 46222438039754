const { REACT_APP_ENABLE_TRACK, REACT_APP_TAG_MANAGER_KEY_LEVELS, REACT_APP_TAG_MANAGER_KEY_REGISTER } = process.env;

const trackCompleteRegistration = () => {
  if (REACT_APP_ENABLE_TRACK && REACT_APP_TAG_MANAGER_KEY_LEVELS) {
    window.gtag('event', 'conversion', { send_to: REACT_APP_TAG_MANAGER_KEY_LEVELS });
  }
};

const trackSendEmail = () => {
  if (REACT_APP_ENABLE_TRACK && REACT_APP_TAG_MANAGER_KEY_REGISTER) {
    window.gtag('event', 'conversion', { send_to: REACT_APP_TAG_MANAGER_KEY_REGISTER });
  }
};
const trackLead = () => {
  if (REACT_APP_ENABLE_TRACK && REACT_APP_TAG_MANAGER_KEY_REGISTER) {
    window.gtag('event', 'conversion', { send_to: REACT_APP_TAG_MANAGER_KEY_REGISTER });
  }
};

export { trackCompleteRegistration, trackSendEmail, trackLead };
