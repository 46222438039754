import { buildQueryString, getVerbPastToggle, isEmpty } from 'utils';

import http, { getOrCached } from './http';

export const requestAmount = () => getOrCached('students/opportunities?totalAmount=1');

export const requestOpportunity = opportunityId => getOrCached(`/students/opportunities/${opportunityId}`);

export const requestSearch = ({ oppLocations, oppVerticals, type, ...filters }) => {
  const searchParams = filters;
  if (oppLocations[0]?.id) {
    searchParams.oppLocations = JSON.stringify(oppLocations);
  }
  if (oppVerticals[0]?.id) {
    searchParams.oppVerticals = JSON.stringify(oppVerticals);
  }
  if (!isEmpty(type)) {
    searchParams.type = type;
  }
  return getOrCached(`/students/opportunities/search?${buildQueryString(searchParams)}`);
};

export const requestApply = (opportunityId, data) => {
  return http.post(`/students/opportunities/${opportunityId}/apply`, data);
};

export const requestToggles = (opportunityId, status, entity) => {
  return http.post(`/students/opportunities/${opportunityId}/${entity}`, {
    [getVerbPastToggle(entity)]: status,
  });
};

export const requestSetOpportunityTag = (opportunityId, tag) => {
  return http.post(`/students/opportunities/${opportunityId}/tags`, { tag });
};

export const opportunityViewsRequest = opportunityId => http.post('/students/opportunityViews', { opportunityId });
