import * as yup from 'yup';

import { DECIMAL_NUMBER_WITH_TENTHS_AND_HUNDREDTHS, NUMERIC } from 'constant/regex';
import { Concentration, Major, Minor, Degree, AcademicsFormValues, Scholarship, Honor } from 'store/v2/level/types';

const OTHER_MAJOR_MINOR_ID = 385;
const OTHER_MAJOR_CONCENTRATION_CATEGORY_ID = 32;

const REQUIRED_MESSAGE = 'Required.';

const isGPAValid = value => {
  if (value && DECIMAL_NUMBER_WITH_TENTHS_AND_HUNDREDTHS.test(value)) {
    const parsedValue = parseFloat(value);

    return parsedValue > 0 && parsedValue <= 4;
  }

  return false;
};

const concentrationSchema = yup.object<Concentration>().shape({
  concentration: yup.string().nullable(),
  category: yup
    .number()
    .nullable()
    .when(['concentration'], {
      is: concentration => !!concentration,
      then: yup.number().required(REQUIRED_MESSAGE),
    }),
  otherCategory: yup
    .string()
    .nullable()
    .when(['category'], {
      is: category => category === OTHER_MAJOR_CONCENTRATION_CATEGORY_ID,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
});

const majorSchema = yup.object<Major>().shape({
  major: yup.number().nullable().required(REQUIRED_MESSAGE),
  gpa: yup
    .string()
    .nullable()
    .test('gpa', 'Please enter your GPA in this format: X.XX', value => {
      if (value) {
        return isGPAValid(value);
      }

      return true;
    }),
  otherMajor: yup
    .string()
    .nullable()
    .when(['major'], {
      is: major => major === OTHER_MAJOR_MINOR_ID,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
  category: yup
    .number()
    .nullable()
    .when(['major'], {
      is: major => major === OTHER_MAJOR_MINOR_ID,
      then: yup.number().required(REQUIRED_MESSAGE),
    }),
  otherCategory: yup
    .string()
    .nullable()
    .when(['category'], {
      is: category => category === OTHER_MAJOR_CONCENTRATION_CATEGORY_ID,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
});

const minorSchema = yup.object<Minor>().shape({
  minor: yup.number().nullable(),
  otherMinor: yup
    .string()
    .nullable()
    .when(['minor'], {
      is: minor => minor === OTHER_MAJOR_MINOR_ID,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
});

const degreeSchema = yup.object<Degree>().shape({
  degree: yup.number().nullable().required(REQUIRED_MESSAGE),
  university: yup.number().nullable().required(REQUIRED_MESSAGE),
  state: yup.string().nullable().required(REQUIRED_MESSAGE),
  city: yup.number().nullable().required(REQUIRED_MESSAGE),
  graduationMonth: yup.number().nullable().required(REQUIRED_MESSAGE),
  graduationYear: yup.number().nullable().required(REQUIRED_MESSAGE),
  cumulativeGPA: yup.string().when(['notHaveCumulativeGPA', 'notPublishCumulativeGPAs'], {
    is: false,
    then: yup
      .string()
      .nullable()
      .test('cumulativeGPA', 'Please enter your GPA in this format: X.XX', isGPAValid)
      .required(REQUIRED_MESSAGE),
  }),
  notHaveCumulativeGPA: yup.boolean().nullable().optional(),
  concentrations: yup.array().of(concentrationSchema),
  majors: yup.array().of(majorSchema).min(1),
  minors: yup.array().of(minorSchema),
});

export const degreeScholarshipsSchema = yup.object<Scholarship>().shape({
  name: yup.string().nullable().required(REQUIRED_MESSAGE),
  amount: yup
    .string()
    .nullable()
    .test('amount', 'Please enter numbers only', value => (value ? NUMERIC.test(value) : true)),
});

export const degreeHonorsSchema = yup.object<Honor>().shape({
  name: yup.string().nullable().required(REQUIRED_MESSAGE),
  year: yup.array().when(['isThroughoutCollege'], {
    is: false,
    then: yup.array().min(1, REQUIRED_MESSAGE),
  }),
});

export const validationSchema = yup.object<AcademicsFormValues>().shape({
  degrees: yup.array(yup.array(degreeSchema).min(1)),
  degreeScholarships: yup.array(yup.array(degreeScholarshipsSchema)),
  degreeHonors: yup.array(yup.array(degreeHonorsSchema)),
});
