const mergeObjects = (firstObject, secondObject) => {
  if (!firstObject || !secondObject) {
    return {};
  }
  return Object.keys(firstObject).reduce(
    (accumulator, key) => ({ ...accumulator, [key]: secondObject[key] || '' }),
    {}
  );
};

export default mergeObjects;
