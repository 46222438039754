import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.shape({
    createdAt: PropTypes.string,
    finishedAt: PropTypes.string,
    level: PropTypes.string,
    screen: PropTypes.number,
    status: PropTypes.string,
    typeId: PropTypes.string,
    updatedAt: PropTypes.string,
  })
);
