export const initialState = {
  error: null,
  errorApply: null,
  isLoading: false,
  isLoadingAmount: false,
  isLoadingApply: false,
  isLoadingToggles: false,
  isLoadingSearch: false,
  amount: null,
  opportunity: null,
  params: {},
  filters: null,
  data: {
    opportunities: [],
    meta: null,
  },
  values: {
    whyOurCompany: '',
  },
};
