import { CustomDropdown } from '@hivediversity/common-lib/inputs';
import { opportunityProps } from '@hivediversity/common-lib/utils';
import { push } from 'connected-react-router';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import PropTypes from 'prop-types';
import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import favoriteFill from 'assets/svg/favorite-fill.svg';
import favorite from 'assets/svg/favorite.svg';
import locationIcon from 'assets/svg/location-icon.svg';
import { dropdownStyleForCards, opportunityStyles } from 'constant';
import opportunityCreator from 'store/opportunity/actions';
import { getStaticData } from 'store/static-data/selectors';
import { getRoutes, getDayDiff } from 'utils';

import CompanyName from './company-name';
import OpportunityLocation from './location';
import {
  Wrapper,
  WrapperContentCard,
  Title,
  ContentAllDescriptionCard,
  CardHeadingWrapper,
  Deadline,
  Description,
  Photo,
  Logo,
  WrapperTitle,
  Body,
  Content,
  Qualified,
  Container,
  ContentTagsCard,
  TagsContentCards,
  ContentPhotoCard,
  Favoritebutton,
  Tag,
  ReviewLabel,
} from './styled';

const OpportunityCard = ({ opportunity, params }) => {
  const dispatch = useDispatch();
  const staticData = useSelector(getStaticData);
  const isSettingTag = useSelector(state => state.opportunity.isSettingTag);
  const selectedTag = find(staticData?.data?.studentTags, tag => tag.id === opportunity.studentTag);
  const diffInDays = getDayDiff(opportunity);
  const [remoteTag, setRemoteTag] = useState(false);
  const [deadlineDate, setDeadlineDate] = useState('');
  const handleClick = () => dispatch(push(getRoutes.goToOpportunity(opportunity.id, params)));

  const handleSave = e => {
    e.stopPropagation();
    dispatch(opportunityCreator.opportunityTogglesRequest(opportunity, 'save'));
  };

  const handleChangeTag = tag => {
    return dispatch(opportunityCreator.opportunitySetTagRequest(opportunity.id, tag?.id, params.entity));
  };

  useEffect(() => {
    const secondType = findIndex(opportunity.locations, location =>
      Array.isArray(location.type) ? location.type?.some(typeId => typeId === 2) : location.type === 2
    );
    const thirdType = findIndex(opportunity.locations, location =>
      Array.isArray(location.type) ? location.type?.some(typeId => typeId === 3) : location.type === 3
    );

    const types = ['Job', 'Internships'];
    if ((secondType !== -1 && types.includes(opportunity.type)) || thirdType !== -1) {
      setRemoteTag(true);
    }
    if (opportunity.endMonth && opportunity.endDay && opportunity.endYear) {
      setDeadlineDate(`${opportunity.endMonth}/${opportunity.endDay}/${opportunity.endYear}`);
    }
  }, [opportunity]);

  const stylesForDropDown = {
    ...dropdownStyleForCards,
    option: { ...dropdownStyleForCards.option, textTransform: 'none' },
    button: { ...dropdownStyleForCards.option, textTransform: 'none', padding: '0px' },
  };

  return (
    <Container showStudentsTags={selectedTag}>
      <Wrapper isQualified={opportunity.qualified}>
        <Favoritebutton onClick={handleSave}>
          <img src={opportunity.saved ? favoriteFill : favorite} alt="Add to favorite" />
        </Favoritebutton>
        <WrapperContentCard onClick={handleClick}>
          <ContentPhotoCard>
            <Photo className="company-pic">{opportunity.logoUrl && <Logo src={opportunity.logoUrl} />}</Photo>
            <CompanyName opportunity={opportunity} />
          </ContentPhotoCard>
          <ContentAllDescriptionCard>
            <CardHeadingWrapper>
              <Deadline alert={diffInDays < 7}>Deadline: {deadlineDate}</Deadline>
              {opportunity && opportunity.reviewApplications && (
                <ReviewLabel>
                  Reviewing in <span style={{ color: 'var(--color-secondary)' }}>Real Time</span>
                </ReviewLabel>
              )}
              {opportunity.type === 'Event' && (
                <Tag className="nonCapitalized">Date and Time: {opportunity?.eventDate}</Tag>
              )}
            </CardHeadingWrapper>
            <Body>
              <Content>
                <WrapperTitle>
                  <Title>
                    {opportunity.title}
                    &nbsp;
                    {opportunity.qualified && <Qualified>- Qualified!</Qualified>}
                  </Title>
                  <Description className="description">
                    {opportunity.summary ? opportunity.summary : 'No description'}
                  </Description>
                  <CompanyName opportunity={opportunity} isDesktop />
                </WrapperTitle>
              </Content>
            </Body>
          </ContentAllDescriptionCard>
        </WrapperContentCard>
        {opportunity.applied && (
          <div style={{ marginTop: '18px', width: '100%' }}>
            <CustomDropdown
              options={staticData?.data?.studentTags}
              value={selectedTag}
              styles={stylesForDropDown}
              placeholder="Tag This Position"
              onChange={handleChangeTag}
              isLoading={isSettingTag === opportunity.id}
              newArrow
            />
          </div>
        )}
        <ContentTagsCard>
          <TagsContentCards>
            <Tag className="highlighted">
              <span>{opportunity.typeDurationShort}</span>
            </Tag>
            {opportunity.locations.map(location => (
              <Fragment key={location.id}>
                <Tag>
                  <img src={locationIcon} alt="Location icon" />
                  <OpportunityLocation
                    location={location}
                    opportunityType={opportunity.type}
                    $bsStyle={opportunityStyles.Locations}
                  />
                </Tag>
              </Fragment>
            ))}
            {remoteTag &&
              !(
                findIndex(opportunity.locations, { type: 2 }) !== -1 &&
                ['Job', 'Internships'].includes(opportunity.type)
              ) && (
                <Tag $bsStyle={opportunityStyles.Tag}>
                  <img src={locationIcon} alt="Location icon" />
                  Remote
                </Tag>
              )}
          </TagsContentCards>
        </ContentTagsCard>
      </Wrapper>
    </Container>
  );
};

OpportunityCard.propTypes = {
  opportunity: opportunityProps.isRequired,
  params: PropTypes.shape({
    origin: PropTypes.string,
    perPage: PropTypes.number,
    entity: PropTypes.string,
  }),
};

OpportunityCard.defaultProps = {
  params: null,
};

export default OpportunityCard;
