import styled from 'styled-components';

import { Heading } from './Heading';

export const StyledHeading = styled(Heading)`
  font-family: 'Roboto', Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28.8px;
  color: #ffbe33;
  margin-bottom: 24px;
  text-align: center;
`;
