import * as Yup from 'yup';

import {
  PASSWORD_NUMBER,
  PASSWORD_LOWERCASE_LETTER,
  PASSWORD_UPPERCASE_LETTER,
  PASSWORD_SPECIAL_CHARACTER,
} from 'constant/regex';
import { PasswordValues } from 'store/v2/register/types';
import { i18n } from 'utils';

export const passwordInitialValues: PasswordValues = {
  password: '',
  confirmPassword: '',
  terms: false,
};

export const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required(i18n.t('register.validations.PASSWORD_REQUIRED'))
    .min(8, i18n.t('register.validations.PASSWORD_MIN_LENGTH'))
    .max(30, i18n.t('register.validations.PASSWORD_MAX_LENGTH'))
    .matches(PASSWORD_NUMBER, {
      message: i18n.t('register.validations.PASSWORD_CHECK_NUMBER'),
      excludeEmptyString: true,
    })
    .matches(PASSWORD_LOWERCASE_LETTER, {
      message: i18n.t('register.validations.PASSWORD_CHECK_LOWERCASE_LETTER'),
      excludeEmptyString: true,
    })
    .matches(PASSWORD_UPPERCASE_LETTER, {
      message: i18n.t('register.validations.PASSWORD_CHECK_UPPERCASE_LETTER'),
      excludeEmptyString: true,
    })
    .matches(PASSWORD_SPECIAL_CHARACTER, {
      message: i18n.t('register.validations.PASSWORD_CHECK_SPECIAL_CHARACTER'),
      excludeEmptyString: true,
    }),
  confirmPassword: Yup.string()
    .required(i18n.t('register.validations.CONFIRM_PASSWORD_REQUIRED'))
    .min(8, i18n.t('register.validations.CONFIRM_PASSWORD_MINIMUM'))
    .when('password', {
      is: val => !!(val && val.length > 0),
      then: Yup.string().oneOf([Yup.ref('password')], i18n.t('register.validations.EQUAL_PASSWORD')),
    }),
  terms: Yup.bool().oneOf([true], 'You need to accept the terms and conditions'),
});
