import PropTypes from 'prop-types';
import React from 'react';

import { ButtonTab, Item, List, Container, Wrapper } from './styled';

const tabsList = ({ selectedTab, onTabChange, tabs }) => (
  <Container>
    <Wrapper>
      <List>
        {tabs.map(tab => (
          <Item key={tab}>
            <ButtonTab $showing={selectedTab === tab} onClick={() => onTabChange(tab)}>
              {tab}
            </ButtonTab>
          </Item>
        ))}
      </List>
    </Wrapper>
  </Container>
);

tabsList.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedTab: PropTypes.string.isRequired,
  onTabChange: PropTypes.func.isRequired,
};

export default tabsList;
