import { isUndefined } from 'lodash';

import { listLevels, progressStatus } from 'constant';

const getSubLevelStatus = (section, userProgress, typeId) => {
  let isComplete;

  if (
    section.id === listLevels.ONE_D ||
    section.id === listLevels.TWO ||
    section.id === listLevels.THREE ||
    section.id === listLevels.FOUR ||
    section.id === listLevels.FIVE ||
    section.id === listLevels.SIX
  ) {
    isComplete = userProgress.find(l => l.level === section.id && l.typeId === typeId);
  } else {
    isComplete = userProgress.find(l => l.level === section.id);
  }

  if (isComplete && isComplete.status === progressStatus.COMPLETE) {
    return progressStatus.COMPLETE;
  }

  const level1A = userProgress.find(l => l.level === listLevels.ONE_A);
  const level1B = userProgress.find(l => l.level === listLevels.ONE_B);
  const level1C = userProgress.find(l => l.level === listLevels.ONE_C);

  if (level1A && level1A.status === progressStatus.COMPLETE && section.id === listLevels.ONE_B) {
    return progressStatus.UNLOCKED;
  }

  if (level1B && level1B.status === progressStatus.COMPLETE && section.id === listLevels.ONE_C) {
    return progressStatus.UNLOCKED;
  }

  if (level1C && level1C.status === progressStatus.COMPLETE && section.id === listLevels.ONE_D) {
    return progressStatus.UNLOCKED;
  }

  // for new user when progress is empty
  if (section.id === listLevels.ONE_A && isUndefined(level1A)) {
    return 'active';
  }

  return '';
};

export default getSubLevelStatus;
