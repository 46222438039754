import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Wrapper, Button } from './styled';

const ButtonsGroup = ({ buttons, action, initialFilters }) => {
  const [clickedIds, setClickedIds] = useState(initialFilters);

  const handleClick = ({ target: { id } }) => {
    if (clickedIds.indexOf(id) === -1) {
      setClickedIds([id]);
      action({ target: { id, value: 1 } });
    } else {
      setClickedIds(clickedIds.filter(clickedId => clickedId !== id));
      action({ target: { id, value: null } });
    }
  };

  return (
    <Wrapper>
      {buttons.map(button => (
        <Button
          key={button.apiId}
          id={button.apiId}
          name={button.apiId}
          onClick={handleClick}
          isActive={clickedIds.indexOf(button.apiId) !== -1}
        >
          {button.name}
        </Button>
      ))}
    </Wrapper>
  );
};

ButtonsGroup.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      apiId: PropTypes.string.isRequired,
    }).isRequired
  ).isRequired,
  action: PropTypes.func.isRequired,
  initialFilters: PropTypes.arrayOf(PropTypes.string),
};

ButtonsGroup.defaultProps = {
  initialFilters: [],
};

export default ButtonsGroup;
