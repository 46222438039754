export default {
  DETAILS: 'details',
  SUBMITTED: 'submitted',
  QUALIFIED_FOR: 'qualifiedFor',
  FOLLOWED: 'followed',
  COMPANIES: 'companies',
  OPPORTUNITIES: 'opportunities',
  OPPORTUNITY: 'opportunity',
  COMPANY: 'company',
  POSITION: 'position',
  POSITIONS: 'positions',
};
