import { MessageError } from '@hivediversity/common-lib/components';
import { FieldArray, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { AddButton, BackButton, ForwardButton } from 'components/v2/buttons';
import { Form, FormRow, FormActionButtonsRow } from 'components/v2/forms/components';
import { Heading, HeadingLevel } from 'components/v2/heading';
import { levelRoutes, LEVEL_STEPS, customStyles } from 'constant';
import LinesTextbox from 'pages/v2/level/components/LineTextbox';
import {
  ExperienceFormHeader as LinesHeader,
  ExperienceFormSubTitle as LinesSubTitle,
  ExperienceFormTitle as LinesTitle,
  ExperienceFormWrapper as LinesWrapper,
} from 'pages/v2/level/components/LineTextbox/LineTextbox.styled';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { isAdditionalDetailsCompletedSelector, isAdditionalDetailsLoadingSelector } from 'store/v2/level/selectors';

import { getDefaultLine, MAX_LINES_NUMBER, validationSchema } from './form-config';

const AdditionalDetails = () => {
  const dispatch = useDispatch();

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.researchAndPrograms);

  const isAdditionalDetailsCompleted = useSelector(isAdditionalDetailsCompletedSelector);
  const isAdditionalDetailsLoading = useSelector(isAdditionalDetailsLoadingSelector);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnMount
        validationSchema={validationSchema}
        onSubmit={values => {
          dispatch(
            levelActionsCreator.saveLevelFormValuesRequest(
              LEVEL_STEPS.additionalDetails,
              values,
              true,
              levelRoutes.coursework
            )
          );
        }}
      >
        {({ values, handleSubmit, isValid }) => {
          const handleGoBack = () => {
            dispatch(
              levelActionsCreator.saveLevelFormValuesRequest(
                LEVEL_STEPS.additionalDetails,
                values,
                isAdditionalDetailsCompleted,
                levelRoutes.researchAndPrograms
              )
            );
          };
          const isNotValidCountLinesInEveryExperience = values.enhancedAcademicExperiences.some(experience => {
            return experience.lines.length < 2 && !experience.lines[0]?.isMerge;
          });

          const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
            dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.additionalDetails, values));
          }, 600);

          useEffect(() => {
            debouncedSaveLevelFormValues(values);
          }, [values]);

          return (
            <Form className="container px-0" onSubmit={handleSubmit}>
              <FormRow>
                <div className="col-12 d-flex">
                  <Heading level={HeadingLevel.h2}>Describe your Experience.</Heading>
                </div>
              </FormRow>

              {values.enhancedAcademicExperiences?.map((val, parentIndex) => (
                <FieldArray
                  key={parentIndex}
                  name={`enhancedAcademicExperiences[${parentIndex}].lines`}
                  render={({ push, remove, swap, replace, insert }) => (
                    <>
                      {(val.lines || []).length > 0 ? (
                        <LinesWrapper>
                          <LinesHeader>
                            <LinesTitle>Experience #{parentIndex + 1}</LinesTitle>
                            <LinesSubTitle>{val.program}</LinesSubTitle>
                          </LinesHeader>
                          {val.lines.map((_line, lineIndex) => (
                            <LinesTextbox
                              key={lineIndex}
                              index={lineIndex}
                              baseName={`enhancedAcademicExperiences[${parentIndex}].lines`}
                              remove={
                                values.enhancedAcademicExperiences[parentIndex].lines.length > 2
                                  ? remove
                                  : () => undefined
                              }
                              swap={swap}
                              replace={replace}
                              insert={insert}
                              lines={val.lines}
                            />
                          ))}
                          {val.lines.length < MAX_LINES_NUMBER ? (
                            <AddButton onClick={() => push(getDefaultLine())}>Add Line</AddButton>
                          ) : null}
                        </LinesWrapper>
                      ) : null}
                    </>
                  )}
                />
              ))}

              <FormRow>
                <FormActionButtonsRow>
                  <BackButton className="col-6 col-md-3" onClick={handleGoBack} />
                  <ForwardButton
                    className="col-6 col-md-3"
                    type="submit"
                    isLoading={isAdditionalDetailsLoading}
                    disabled={!isValid || isAdditionalDetailsLoading || isNotValidCountLinesInEveryExperience}
                  />
                </FormActionButtonsRow>
              </FormRow>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AdditionalDetails;
