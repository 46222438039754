import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  firstControl: PropTypes.string,
  addPosition: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.shape({}))),
  secondControl: PropTypes.string,
  section: PropTypes.string,
  contentTitle: PropTypes.string,
  class: PropTypes.string,
  theme: PropTypes.string,
  subTitle: PropTypes.func,
});
