import { createReducer } from 'reduxsauce';

import { progressTypes } from 'store/progress/actions';
import { initialState } from 'store/progress/initial-state';

const fetchRequest = state => ({
  ...state,
  isLoading: true,
  isRequested: false,
  error: null,
});

const fetchSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
  isRequested: true,
  error: null,
});

const fetchError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
  isRequested: false,
});

const updateRequest = state => ({
  ...state,
  isUpdating: true,
  error: null,
});

const updateSuccess = (state, { data }) => ({
  ...state,
  data,
  isUpdating: false,
  error: null,
});

const updateError = (state, { error }) => ({
  ...state,
  error,
  isUpdating: false,
});

const setProgress = (state, { progress }) => ({
  ...state,
  progress,
});

const reducer = createReducer(initialState, {
  [progressTypes.PROGRESS_FETCH_REQUEST]: fetchRequest,
  [progressTypes.PROGRESS_FETCH_SUCCESS]: fetchSuccess,
  [progressTypes.PROGRESS_FETCH_ERROR]: fetchError,

  [progressTypes.PROGRESS_UPDATE_REQUEST]: updateRequest,
  [progressTypes.PROGRESS_UPDATE_SUCCESS]: updateSuccess,
  [progressTypes.PROGRESS_UPDATE_ERROR]: updateError,

  [progressTypes.SET_PROGRESS]: setProgress,
});

export default reducer;
