import { isEmpty } from 'utils';

const getOption = (options, value, fieldName = 'value') => {
  if (!value || isEmpty(options)) {
    return null;
  }
  return options.find(option => option?.[fieldName]?.toString() === value?.toString());
};

export default getOption;
