/* eslint-disable no-template-curly-in-string */

const DIVERSITY_TEMPLATE_STATUS =
  "${(!parentValues.category || parentValues.category == 10 || parentValues.category == 33 || parentValues.category == 34 || parentValues.category == 9 || parentValues.category == 5 || parentValues.category == 4 || parentValues.category == 3 || parentValues.category == 6 || parentValues.category == 7) ? 'none' : 'flex'}";
const DIVERSITY_TEMPLATE_OTHER_STATUS =
  "${(parentValues.category == 10 || parentValues.status == 33 || parentValues.status == 27 || parentValues.status == 35) ? 'flex' : 'none'}";
const DIVERSITY_TEMPLATE_OTHER_STATUS_SECOND =
  "${(parentValues.category == 34 || parentValues.category == 9 || parentValues.category == 5 || parentValues.category == 4 || parentValues.category == 3 || parentValues.category == 6 || parentValues.category == 7) ? 'flex' : 'none'}";
const DIVERSITY_TEMPLATE =
  "${(item.otherStatus || item.statusName) ? item.categoryName+' ('+(item.otherStatus || item.statusName)+ ')' : item.categoryName}";
const DIVERSITY_TEMPLATE_CATEGORY = 'diversities/${parentValues.category}';

export {
  DIVERSITY_TEMPLATE,
  DIVERSITY_TEMPLATE_CATEGORY,
  DIVERSITY_TEMPLATE_STATUS,
  DIVERSITY_TEMPLATE_OTHER_STATUS,
  DIVERSITY_TEMPLATE_OTHER_STATUS_SECOND,
};
