import omit from 'lodash/omit';
import some from 'lodash/some';

import isEmpty from './is-empty';

export const initialErrors = {
  buzzwords: {},
};

export const getHasError = errors => some(errors, error => !isEmpty(error));

const getErrorsApplyOpp = (opportunity, values, isSubmitted, countLines) => {
  const errors = initialErrors;
  if (countLines === 0) {
    errors.buzzwords = {
      ...errors.buzzwords,
      required: `Select your buzzwords.`,
    };
  } else {
    errors.buzzwords = { ...omit(errors.buzzwords, 'required') };
  }
  if (countLines < 4) {
    errors.buzzwords = {
      ...errors.buzzwords,
      minimum: `Select at least 4 buzzwords.`,
    };
  } else {
    errors.buzzwords = { ...omit(errors.buzzwords, 'minimum') };
  }
  if (countLines > 8) {
    errors.buzzwords = {
      ...errors.buzzwords,
      maximum: `Select less than 8 buzzwords.`,
    };
  } else {
    errors.buzzwords = { ...omit(errors.buzzwords, 'maximum') };
  }

  return errors;
};

export default getErrorsApplyOpp;
