import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  authLoginRequest: ['email', 'password', 'redirectTo'],
  authLoginSuccess: ['user', 'token'],
  authLoginError: ['error'],

  authRegisterRequest: ['user'],
  authRegisterSuccess: ['message'],
  authRegisterError: ['error'],

  authForgotPasswordRequest: ['email'],
  authForgotPasswordSuccess: [],
  authForgotPasswordError: ['error'],

  authResetPasswordRequest: ['email', 'password', 'passwordConfirmation', 'token'],
  authResetPasswordSuccess: [],
  authResetPasswordError: ['error'],

  authCheckTokenRequest: ['token'],
  authCheckTokenSuccess: ['message'],
  authCheckTokenError: ['error'],

  authCheckEmailRequest: ['email'],
  authCheckEmailSuccess: ['exists', 'message'],
  authCheckEmailError: ['error'],

  authRetrySendEmailRequest: ['email'],
  authRetrySendEmailSuccess: ['message'],
  authRetrySendEmailError: ['error'],

  authImpersonateRequest: ['code'],
  authImpersonateSuccess: ['user', 'token'],
  authImpersonateError: ['error'],

  authConfirmEmailRequest: ['code'],
  authConfirmEmailSuccess: ['user', 'token'],
  authConfirmEmailError: ['error'],

  authGetTokenRequest: [null],
  authGetTokenSuccess: ['token'],
  authGetTokenError: ['error'],

  authCleanState: [null],
});

export const authTypes = Types;

export default Creators;
