import { opportunityLocationTypes } from 'constant';

const checkData = (id, value) => {
  if (!value) {
    return [id];
  }
  if (value.includes(id)) {
    return value.filter(v => v !== id);
  }
  return value.concat(id);
};

const setFiltersInputs = (filters, name, checked, id) => {
  const values = { ...filters, [name]: id, page: 1 };
  if (name === 'locationType') {
    if (checked && filters[name] && filters[name] !== opportunityLocationTypes.MIXED) {
      values[name] = opportunityLocationTypes.MIXED;
    } else if (filters[name] === opportunityLocationTypes.MIXED) {
      values[name] =
        id === opportunityLocationTypes.INTERNATIONAL
          ? opportunityLocationTypes.REMOTE
          : opportunityLocationTypes.INTERNATIONAL;
    } else {
      values[name] = checked ? id : null;
    }
  }
  if (name === 'type') {
    const value = filters[name];
    return { ...filters, page: 1, [name]: checkData(id, value) };
  }
  return values;
};

export default setFiltersInputs;
