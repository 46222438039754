import styled from 'styled-components';

import { Label } from 'components/v2/forms/components';

export const Wrapper = styled.div`
  margin-bottom: 24px;
  text-align: left;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 8px;
`;

export const StyledInput = styled.input`
  border: 1px solid #cecae2;
  border-radius: 4px;
  background-color: transparent;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 24px;
  padding: 15px 16px 15px 16px;
  outline: none;
  width: 100%;

  &:disabled {
    border-color: #4a485b;

    &,
    &::placeholder {
      color: #4a485b;
    }
  }

  &:focus {
    border-color: #ffbe33;
  }

  :-webkit-autofill {
    transition: background-color 10000s ease 0s;
    background: none !important;
    -webkit-text-fill-color: rgb(255, 255, 255) !important;
  }

  &::placeholder {
    color: #888790;
    font-size: 16px;
  }

  &:-webkit-autofill {
    caret-color: #fff;

    &:hover,
    &:focus,
    &:active {
      caret-color: #fff;
    }
  }
`;
