import React from 'react';

export enum HeadingLevel {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
}

export interface HeadingProps {
  level: HeadingLevel;
  className?: string;
  children?: React.ReactNode;
}

export const Heading: React.FC<HeadingProps> = ({ level, className, children }) => {
  const Component = HeadingLevel[level];

  return <Component className={className}>{children}</Component>;
};
