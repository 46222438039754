import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  staticDataFetchRequest: ['params'],
  staticDataFetchSuccess: ['data'],
  staticDataFetchError: ['error'],
});

export const staticDataTypes = Types;

export default Creators;
