import styled from 'styled-components';

import { Button, ButtonVariant } from 'components/v2/buttons';

export const StyledAddSectionButton = styled(Button).attrs(() => ({
  variant: ButtonVariant.secondary,
}))`
  border-radius: 4px;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  .iconWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;
