import styled from 'styled-components';

import { IconButton } from 'components/v2/buttons';
import { Paper } from 'components/v2/paper';

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: calc(50% - 36px) 0 calc(50% + 36px);
`;

export const StyledPaper = styled(Paper)`
  margin-bottom: 24px;
`;

export const StyledIconButton = styled(IconButton)`
  border-radius: 8px;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 12px 8px 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;
