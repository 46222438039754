import isEmpty from './is-empty';

const getFiltersKeys = filters => {
  if (!filters || isEmpty(filters)) {
    return [];
  }

  return Object.entries(filters)
    .filter(f => f[1] !== null)
    .map(e => e[0]);
};

export default getFiltersKeys;
