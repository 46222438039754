import get from 'lodash/get';

const getLineSize = line => {
  const high = get(line, 'high');
  switch (high) {
    case 0:
      return 1;
    case 1:
      return 2;
    default:
      return 0;
  }
};

export default getLineSize;
