const getDayDiff = opportunity => {
  const now = new Date();
  const timeUTC = new Date(now.getTime() - 60 * 4 * 60000).valueOf();
  const date = new Date(opportunity && opportunity.end);
  const diffInSeconds = (date.getTime() - timeUTC) / 1000;
  const day = 86400;
  return diffInSeconds / day;
};

export default getDayDiff;
