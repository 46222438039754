import PropTypes from 'prop-types';
import React, { Children, cloneElement, Fragment } from 'react';

const Screens = ({ id, activeStep, onPrevious, children }) => {
  const elements = Children.toArray(children).filter(e => !e.props.disabled);

  const element = cloneElement(elements[activeStep], { activeStep, onPrevious });

  return <Fragment key={id}>{element}</Fragment>;
};

Screens.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.arrayOf(PropTypes.element),
  activeStep: PropTypes.number,
  onPrevious: PropTypes.func.isRequired,
};

Screens.defaultProps = {
  id: 'screens',
  children: [],
  activeStep: 0,
};

export default Screens;
