import template from 'lodash/template';

import { getParentValues } from 'utils';

const getEndpoint = ({ endpoint, control }, values) => {
  try {
    const parentValues = getParentValues(values, control);
    return template(endpoint)({ ...values, parentValues });
  } catch (e) {
    return '';
  }
};

export default getEndpoint;
