import styled from 'styled-components';

import { ErrorMessage } from 'components/v2/forms/components/error-message';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 16px;
`;
