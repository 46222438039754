import shortId from 'shortid';

import listLevels from './list-levels';

const defaultValuesLevels = [
  /* ------------ LEVEL 1A ------------ */
  {
    id: listLevels.ONE_A,
    values: {
      // SCREEN 1
      receiveUpdates: false,
      // SCREEN 2
      firstName: '',
      middleName: '',
      lastName: '',
      // SCREEN 3
      cityOfUniversity: '',
      stateOfUniversity: '',
      cityOfHome: '',
      stateOfHome: '',
      countryOfHome: '',
      countryCityOfHome: '',
      internationalHome: false,
      // SCREEN 4
      phone: '',
      // SCREEN 6
      specialization: '',
      // SCREEN 7 & 10 & 12
      firstDegree: '',
      firstMajor: '',
      firstMajorName: '',
      firstMajorCategory: '',
      firstCollege: '',
      monthGraduation: '',
      yearGraduation: '',
      // SCREEN 8 & 11
      secondDegree: '',
      secondMajor: '',
      secondMajorName: '',
      secondMajorCategory: '',
      secondCollege: '',
      secondMajorMonth: '',
      secondMajorYear: '',
      // SCREEN 9
      thirdMajor: '',
      thirdMajorName: '',
      thirdMajorCategory: '',
      // SCREEN ID 90
      hasConcentrations: '',
      // SCREEN ID 91
      firstConcentration: '',
      secondConcentration: '',
      firstMajorConcentration: '',
      firstMajorSecondConcentration: '',
      secondMajorConcentration: '',
      secondMajorSecondConcentration: '',
      thirdMajorConcentration: '',
      thirdMajorSecondConcentration: '',
      // SCREEN 13
      hasMinors: '',
      minors: [],
      // SCREEN 14
      gpa: '',
      includeGPAOnResume: false,
      notPublishGPAS: false,
      notHaveGPA: false,
      includeHighSchoolHonorsOnResume: false,
      includeActSatScoreOnResume: false,
      // SCREEN 15 & 16 & 17
      firstMajorGPA: '',
      includeFirstMajorGPAOnResume: false,
      secondMajorGPA: '',
      includeSecondMajorGPAOnResume: false,
      thirdMajorGPA: '',
      includeThirdMajorGPAOnResume: false,
      // SCREEN 18
      hasScholarships: '',
      // SCREEN 19
      scholarships: [],
      // SCREEN 20
      hasHonors: '',
      // SCREEN 21
      honors: [],
      // SCREEN 22
      hasStudiesAbroad: '',
      // SCREEN 23
      studiesAbroad: [],
      // SCREEN 24
      highSchoolName: '',
      highSchoolCity: '',
      highSchoolState: '',
      highSchoolCountry: '',
      highSchoolCountryCity: '',
      internationalHighSchool: false,
      highSchoolMonthGraduation: '',
      highSchoolYearGraduation: '',
      includeHighSchoolOnResume: false,
      // SCREEN 25
      highSchoolHonors: [],
      actScore: '',
      satScore: '',
      // SCREEN 26
      hasEnhancedAcademicExperiences: '',
      // SCREEN 27 & SCREEN 29
      enhancedAcademicExperiences: [],
      // SCREEN 30
      hasDivisionAthletics: '',
      // SCREEN 32 & SCREEN 33
      d1Athletics: [],
      // UPDATE WITH OTHERS
      otherFirstMajor: '',
      otherSecondMajor: '',
      otherThirdMajor: '',
      otherFirstMajorCategory: '',
      otherSecondMajorCategory: '',
      otherThirdMajorCategory: '',
      otherFirstDegree: '',
      otherSecondDegree: '',
      otherThirdDegree: '',
      hasRelevantCourseWorks: '',
      linkedInURL: '',
      firstDegreeConcentration: '',
      firstDegreeSecondConcentration: '',
      coursework: [],
    },
  },
  /* ------------ LEVEL 1B ------------ */
  {
    id: listLevels.ONE_B,
    values: {
      technicalSkill: [],
      languages: [],
      personalInterests: [],
      certificationsAndLicences: [],
    },
  },
  /* ------------ LEVEL 1C ------------ */
  {
    id: listLevels.ONE_C,
    values: {
      organizationalCategories: [],
      // SCREEN 1
      amountProfessionalExperiences: null,
      // SCREEN 2
      amountOtherExperiences: null,
      // SCREEN 3
      professionalExperiences: [],
      // SCREEN 4
      otherExperiences: [],
    },
  },
  /* ------------ LEVEL 1D ------------ */
  {
    id: listLevels.ONE_D,
    values: {
      beYourselfDAnswers: {
        firstQuestion: '',
        subFirstQuestion: '',
        secondQuestion: '',
        subSecondQuestion: '',
        thirdQuestion: '',
        subThirdQuestion: '',
        fourthQuestion: '',
        subFourthQuestion: '',
        fifthQuestion: '',
        subFifthQuestion: '',
      },
    },
  },
  /* ------------ LEVEL 2 ------------ */
  {
    id: listLevels.TWO,
    values: {
      beInformedAnswers: {
        firstQuestion: '',
        subFirstQuestion: '',
        secondQuestion: '',
        subSecondQuestion: '',
        thirdQuestion: '',
        subThirdQuestion: '',
        fourthQuestion: '',
        subFourthQuestion: '',
        fifthQuestion: '',
        subFifthQuestion: '',
        sixthQuestion: '',
        subSixthQuestion: '',
      },
    },
  },
  /* ------------ LEVEL 3 ------------ */
  {
    id: listLevels.THREE,
    values: {
      bePreparedAnswers: {
        firstQuestion: '',
        subFirstQuestion: '',
        secondQuestion: '',
        subSecondQuestion: '',
        thirdQuestion: '',
        subThirdQuestion: '',
        fourthQuestion: '',
        subFourthQuestion: '',
        fifthQuestion: '',
        subFifthQuestion: '',
        sixthQuestion: '',
        subSixthQuestion: '',
        seventhQuestion: '',
        subSeventhQuestion: '',
        eighthQuestion: '',
        subEighthQuestion: '',
        ninthQuestion: '',
        subNinthQuestion: '',
        tenthQuestion: '',
        subTenthQuestion: '',
        eleventhQuestion: '',
        subEleventhQuestion: '',
        twelfthQuestion: '',
        subTwelfthQuestion: '',
      },
    },
  },
  /* ------------ LEVEL 4 ------------ */
  {
    id: listLevels.FOUR,
    values: {
      beProfessionalAnswers: {
        firstQuestion: '',
        subFirstQuestion: '',
        secondQuestion: '',
        subSecondQuestion: '',
        thirdQuestion: '',
        subThirdQuestion: '',
        fourthQuestion: '',
        subFourthQuestion: '',
        fifthQuestion: '',
        subFifthQuestion: '',
        sixthQuestion: '',
        subSixthQuestion: '',
        seventhQuestion: '',
        subSeventhQuestion: '',
        eighthQuestion: '',
        subEighthQuestion: '',
        ninthQuestion: '',
        subNinthQuestion: '',
        tenthQuestion: '',
        subTenthQuestion: '',
      },
    },
  },
  /* ------------ LEVEL 5 ------------ */
  {
    id: listLevels.FIVE,
    values: {
      bePresentAnswers: {
        firstQuestion: '',
        subFirstQuestion: '',
        secondQuestion: '',
        subSecondQuestion: '',
        thirdQuestion: '',
        subThirdQuestion: '',
        fourthQuestion: '',
        subFourthQuestion: '',
        fifthQuestion: '',
        subFifthQuestion: '',
      },
    },
  },
  /* ------------ LEVEL 6 ------------ */
  {
    id: listLevels.SIX,
    values: {
      beInclusiveAnswers: {
        firstQuestion: '',
        subFirstQuestion: '',
        secondQuestion: '',
        subSecondQuestion: '',
        thirdQuestion: '',
        subThirdQuestion: '',
        fourthQuestion: '',
        subFourthQuestion: '',
        fifthQuestion: '',
        subFifthQuestion: '',
        sixthQuestion: '',
        subSixthQuestion: '',
      },
    },
  },
  /* ------------ LEVEL POST CREDENTIAL ------------ */
  {
    id: listLevels.PC,
    values: {
      diversity: [],
      additionalInformation: [],
      alternativeEmail: '',
      links: [],
      relevantCourseWorks: [],
      coursework: [],
      industries: [],
      jobsInterest: [],
      careers: [],
      seeking: 0,
      preferences: [],
      includeDiversityInclusionsResume: false,
      includeAdditionalDiversityResume: false,
      typeSeeking: '',
      hasSeekingFirstJob: '',
      monthGraduation: '',
      yearGraduation: '',
    },
  },
  {
    /* ------------ EXAMPLES ------------ */
    id: listLevels.EXAMPLE,
    values: {
      controlDeRepeat: 2,
      controlDeRepeatArray: ['a', 'b', 'c'],
      radio: '',
      yesNo: '',
      checkboxes: [],
      input: '',
      options: [],
      radios: [],
      select: '',
      customSelect: '',
      customMultiSelect: [],
      selectTwo: '',
      textArea: '',
      switch: '',
      switchTwo: '',
      inputs: [{ select: '', select2: '' }],
      selectConditional: '',
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      includeCheck: false,
      lines: [
        {
          id: shortId.generate(),
          maxLength: 10,
          high: 0,
          value: '',
          isMerge: false,
          enabled: true,
        },
        {
          id: shortId.generate(),
          maxLength: 10,
          high: 0,
          value: '',
          isMerge: false,
          enabled: true,
        },
      ],
      listSelect: [],
      multiSelect: [],
      option: '',
      repeats: [],
      repeatsAmount: 1,
      repeatsValue: [],
      date: '',
    },
  },
];

export default defaultValuesLevels;
