import { compact } from 'lodash';

import { listLevels, progressStatus } from 'constant';

// Is considered all complete and allow access in the edit when is complete 1A, 1B, 1C and PC
const getIsComplete = levels => {
  if (!levels) {
    return [];
  }

  const newLevels = [];

  levels.forEach(item => {
    const index = newLevels.findIndex(x => x.level === item.level);

    if (index <= -1) {
      newLevels.push({
        level: item.level,
        status: item.status,
        screen: item.screen,
        typeId: item.typeId,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        finishedAt: item.finishedAt,
      });
    }
  });

  return (
    compact(
      newLevels.map(
        l =>
          (l.level === listLevels.ONE_A ||
            l.level === listLevels.ONE_B ||
            l.level === listLevels.ONE_C ||
            l.level === listLevels.PC) &&
          l.status === progressStatus.COMPLETE
      )
    ).length === 4
  );
};

export default getIsComplete;
