import { SharedState } from './types';

export const initialState: SharedState = {
  heardFromUsOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  heardFromUsSubOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  universityOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  yearOptions: {
    options: [],
    isLoading: false,
    error: '',
  },

  studyPeriodOptions: {
    options: [],
    isLoading: false,
    error: '',
  },

  diversityOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  diversitySubOptions: {
    options: [],
    isLoading: false,
    error: '',
  },

  statesOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  citiesOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  schoolStateCitiesOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  countriesOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  bachelorDegreeOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  masterDegreeOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  majorsOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  minorsOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  majorCategoriesOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  degreeTypeOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  periodRangesOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  sportsOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  checkEmail: {
    isLoading: false,
    exists: false,
    message: '',
    error: '',
  },
  periodOfStudiesOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  cachedData: {
    states: {},
  },
  skillLevelsOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  skillCategoryOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  languageProficiencyOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
  languageOptions: {
    options: [],
    isLoading: false,
    error: '',
  },
};
