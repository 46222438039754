const isObjectsEqual = (firstObject, secondObject) => {
  if (!firstObject || !secondObject) {
    return false;
  }
  return typeof firstObject === 'object' && Object.keys(firstObject).length > 0
    ? Object.keys(firstObject).length === Object.keys(secondObject).length &&
        Object.keys(firstObject).every(p => isObjectsEqual(firstObject[p], secondObject[p]))
    : firstObject === secondObject;
};

export default isObjectsEqual;
