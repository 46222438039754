import React from 'react';

import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import { Button } from 'components/v2/buttons';

import { StyledAddSectionButton } from './AddSectionButton.styled';

type AddSectionButtonProps = Pick<React.ComponentProps<typeof Button>, 'onClick'> & { label: string };

const AddSectionButton: React.FC<AddSectionButtonProps> = ({ label, onClick }) => (
  <StyledAddSectionButton onClick={onClick}>
    {label}
    <div className="iconWrapper">
      Add <PlusSvg />
    </div>
  </StyledAddSectionButton>
);

export default AddSectionButton;
