import { useFormik } from 'formik';
import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as CancelSvg } from 'assets/svg/cancel.svg';
import { ReactComponent as CheckboxSvg } from 'assets/svg/checkbox.svg';
import { ReactComponent as PlusSvg } from 'assets/svg/plus.svg';
import { ReactComponent as CancelYellowSvg } from 'assets/svg/v2/levels/cancel-yellow.svg';
import { BackButton, ButtonVariant, ForwardButton } from 'components/v2/buttons';
import { Form, FormActionButtonsRow, FormRow, Input } from 'components/v2/forms/components';
import { StyledCheckbox } from 'components/v2/forms/components/checkbox/Checkbox.styled';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS, listLevels } from 'constant';
import { levelBRoutes, levelConfigs } from 'constant/level-configs';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { PersonalInterstsFormValues } from 'store/v2/level/types';

import { MAX_COUNT, personalInterestsFormInitialValues, validationSchema } from './form-config';
import {
  Paragraph,
  StyledButton,
  StyledButtonText,
  SubmittedDetails,
  SubmittedDetailsWrapper,
  SubmittedHeader,
  SubmittedValues,
  SubmittedValuesWrapper,
  Heading,
  StyledFormNotification,
  ErrorFormNotification,
  CharactersCounter,
} from '../../../Level.styled';

const PersonalInterests: React.FC = () => {
  const dispatch = useDispatch();

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.personalInterests);

  const { values, errors, touched, isValid, handleSubmit, setFieldValue, handleChange, handleBlur } =
    useFormik<PersonalInterstsFormValues>({
      initialValues,
      validationSchema,
      validateOnMount: true,
      onSubmit: values => {
        dispatch(
          levelActionsCreator.saveLevelFormValuesRequest(
            LEVEL_STEPS.personalInterests,
            values,
            true,
            levelBRoutes.diversities
          )
        );
      },
    });

  const clearInputs = () => {
    setFieldValue('personalInterest', personalInterestsFormInitialValues.personalInterest, false);
  };

  const handleAdd = useCallback(() => {
    const { personalInterests, ...valuesToAdd } = values;

    setFieldValue('personalInterests', [
      ...personalInterests,
      { name: valuesToAdd.personalInterest, added: true, includeOnResume: true },
    ]);

    clearInputs();
  }, [values]);

  useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  const remove = useCallback(
    (index: number) => {
      const updatedValues = values.personalInterests.filter((_val, idx) => idx !== index);
      setFieldValue('personalInterests', updatedValues);
    },
    [values.personalInterests]
  );

  const handleGoBack = useCallback(() => {
    dispatch(
      levelActionsCreator.saveLevelFormValuesRequest(
        LEVEL_STEPS.personalInterests,
        values,
        true,
        levelBRoutes.certificationsAndLicenses
      )
    );
  }, [values]);

  const toggleIncludeOnResume = useCallback(
    (index: number) => {
      const updatedValues = values.personalInterests.map((val, idx) => {
        if (idx === index) {
          const { includeOnResume, ...rest } = val;
          return {
            ...rest,
            includeOnResume: !includeOnResume,
          };
        }
        return val;
      });
      setFieldValue('personalInterests', updatedValues);
    },
    [values.personalInterests]
  );

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.personalInterests, values));
  }, 600);

  const getEnteredCharsCount = useCallback(
    inputValues => {
      return inputValues.personalInterests.reduce((prev, cur) => {
        return cur.includeOnResume ? prev + cur.name.length : prev;
      }, inputValues.personalInterest?.length || 0);
    },
    [values]
  );

  const currentCount = useMemo(() => getEnteredCharsCount(values), [values]);

  return (
    <Form data-qa="personal-interests" className="container px-0" onSubmit={handleSubmit}>
      <FormRow>
        <div className="col-12 d-flex">
          <Heading>Personal Interests</Heading>
        </div>
      </FormRow>
      <Paper>
        <FormRow>
          <div className="col-12">
            <Input
              name="personalInterest"
              label="Personal Interest"
              placeholder="Enter a Personal Interest (Write In)"
              value={values?.personalInterest}
              error={touched?.personalInterest ? errors?.personalInterest : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-12">
            <StyledButton
              variant={ButtonVariant.secondary}
              type="button"
              disabled={!values?.personalInterest || !isValid || currentCount > MAX_COUNT}
              onClick={handleAdd}
            >
              <StyledButtonText>Add Personal Interest</StyledButtonText>
              <PlusSvg />
            </StyledButton>
          </div>
          {values?.personalInterests?.length >= 1 ? (
            <>
              <SubmittedHeader>Submitted Personal Interests</SubmittedHeader>
              <SubmittedDetailsWrapper>
                <SubmittedDetails>
                  <CheckboxSvg />
                  <p className="details">
                    Click to include/exclude on resume and profile. When included, the interest will be noted with a
                    gold outline.
                  </p>
                </SubmittedDetails>
                <SubmittedDetails>
                  <CancelSvg />
                  <p className="details">Click to remove from profile.</p>
                </SubmittedDetails>
              </SubmittedDetailsWrapper>
              <SubmittedValuesWrapper>
                <CharactersCounter error={currentCount > MAX_COUNT}>
                  {currentCount}/{MAX_COUNT}
                </CharactersCounter>
                {values?.personalInterests?.map((value, index) => (
                  <SubmittedValues active={value.includeOnResume} key={`${value.name}${index}`}>
                    <div className="checkbox-wrapper">
                      <StyledCheckbox
                        type="checkbox"
                        checked={value.includeOnResume}
                        onChange={() => toggleIncludeOnResume(index)}
                      />
                      <Paragraph active={value.includeOnResume} className="truncate">
                        <span>{value.name}</span>
                      </Paragraph>
                    </div>
                    <div className="svg-wrapper">
                      <button type="button" className="svg-button" title="remove" onClick={() => remove(index)}>
                        {value.includeOnResume ? (
                          <CancelYellowSvg aria-hidden="true" />
                        ) : (
                          <CancelSvg aria-hidden="true" />
                        )}
                      </button>
                    </div>
                  </SubmittedValues>
                ))}
              </SubmittedValuesWrapper>
            </>
          ) : null}
        </FormRow>
      </Paper>
      {!!values?.personalInterest && MAX_COUNT >= currentCount && (
        <StyledFormNotification>
          You entered a Personal Interest but need to select &quot;Add Personal Interest&quot; to move forward or clear
          it.
        </StyledFormNotification>
      )}
      {MAX_COUNT < currentCount && (
        <ErrorFormNotification>Sorry, you do not have enough space left.</ErrorFormNotification>
      )}
      <FormActionButtonsRow className="mt-4">
        <BackButton
          disabled={!!values?.personalInterest || MAX_COUNT < currentCount}
          className="col-3"
          onClick={handleGoBack}
        >
          {levelConfigs[listLevels.ONE_B].goBackConfig.innerTitle}
        </BackButton>
        <ForwardButton
          disabled={!!values?.personalInterest || MAX_COUNT < currentCount}
          className="col-6 col-md-3"
          type="submit"
        />
      </FormActionButtonsRow>
    </Form>
  );
};

export default PersonalInterests;
