import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.background || '#000'};
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

export const Wrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  max-width: 1024px;
`;

export const Title = styled.h1`
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 25px;
  padding-right: 38px;
  line-height: 100%;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin: 0 0 4px;
  padding-bottom: 0;
  padding-left: 30px;
`;

export const Section = styled.h2`
  font-family: 'Gentleman', Arial, sans-serif;
  font-weight: 800;
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  color: white;
  margin: 0;
  padding-left: 30px;
`;

export const WrapperTitle = styled.div`
  margin: 17px 0 15px 0;
  color: white;
  width: 100%;
  min-height: 70px;
`;

export const Content = styled.div`
  background: rgba(0, 0, 0, 0.6);
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 25px 0 25px;
  border-radius: 33px;
  color: white;
  margin-bottom: 88px;
  box-sizing: border-box;
  @media screen and (max-height: 500px) {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.question-wrapper {
    .text-wrapper {
      @media screen and (max-height: 760px) {
        margin-bottom: 1vh;
      }
    }
  }
  .text-wrapper {
    h3 {
      max-width: 400px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-height: 760px) {
    .input-wrapper {
      margin: 5px 0 0 0;
    }
    .row-wrapper {
      margin-bottom: 6vh;
      &:last-of-type {
        margin-bottom: 0;
      }
      .input-wrapper,
      .select-wrapper {
        margin: 0;
      }
    }
    &.fix-row-spaces {
      .row-wrapper {
        margin-bottom: 1vh;
      }
      .text-wrapper {
        margin-bottom: 0.5vh;
        h3 {
          font-size: 4vh;
        }
        label {
          font-size: 2vh;
        }
      }
    }
  }
`;

export const Body = styled.div`
  margin: auto 0;
  padding-bottom: 20px;
  padding-top: 20px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
`;

export const WrapperError = styled.div`
  display: flex;
  margin-bottom: 30px;
  justify-content: center;
`;

export const ErrorMessage = styled.p`
  color: white;
`;
