import styled from 'styled-components';

import { Button, ButtonVariant } from '../buttons';

export const Steps = styled.div`
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

export const Step = styled(Button).attrs(() => ({
  variant: ButtonVariant.secondary,
}))`
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 8px;
  cursor: default;
  padding: 10px 16px;
  text-transform: capitalize;
  width: auto;

  && {
    border: 2px solid rgba(255, 255, 255, 0.3);
    color: rgba(255, 255, 255, 0.3);
  }

  &.active {
    background-color: rgba(255, 190, 51, 0.3);
    border-color: #ffbe33;
    color: #ffbe33;
  }

  &.completed {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }
`;
