import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
import Loader from 'react-loader-spinner';

import helpInfo from 'assets/png/help_info.png';
import preview from 'assets/png/resume_icon.png';
import bigArrow from 'assets/svg/arrow_back-24px.svg';
import denied from 'assets/svg/denied-white.svg';
import helpBee from 'assets/svg/help_bee@2x.svg';
import home from 'assets/svg/home.svg';
import arrow from 'assets/svg/prevArrow.svg';
import { messageBee } from 'constant';

import {
  Container,
  HiveLogo,
  HiveInfo,
  Button,
  DialogTitle,
  WrapperBeeInactive,
  HivePreview,
  Image,
  ButtonSpan,
} from './styled';

import './styles.css';

const Footer = ({
  dialog,
  showDialog,
  hidePrev,
  hideNext,
  actionPrev,
  actionBeeIcon,
  actionNext,
  title,
  customActionBeeIcon,
  type,
  isTrivia,
  blackFooter,
  isLoadingNextButton,
  disabledNextButton,
  disabledPrevButton,
  timeoutExpanded,
  onChange,
  returnToggleDialog,
  disabledBee,
  form,
  showHelpInfo,
  onPreview,
  nextPrev,
  disabledResume,
}) => {
  const [show, setShow] = useState(showDialog);
  const [isDragging, setIsDragging] = useState(false);
  const [initialMouseY, setInitialMouseY] = useState(0);
  const [endMouseY, setEndMouseY] = useState(0);
  const [initialDialog, setInitialDialog] = useState(dialog || null);
  const [initialTitle, setInitialTitle] = useState(title || null);

  useEffect(() => {
    setInitialDialog(initialDialog);
    // eslint-disable-next-line
  }, [dialog]);

  useEffect(() => {
    setInitialTitle(title);
  }, [title]);

  const dialogRef = useRef(null);

  const getMouseYPosFromEvent = event => {
    if (event.type.indexOf('mouse') !== -1) {
      return event.clientY;
    }
    return event.touches[0].clientY;
  };

  const onStartSwipe = e => {
    setIsDragging(true);
    setInitialMouseY(getMouseYPosFromEvent(e));
  };

  const continueSwipe = e => {
    if (isDragging) {
      const currentPosY = getMouseYPosFromEvent(e);
      const diffY = currentPosY - initialMouseY;
      if (diffY < 0) {
        return;
      }

      setEndMouseY(currentPosY);
    }
  };

  const handleToggleDialog = () => {
    if (disabledBee) {
      return;
    }
    if (!dialog && !show) {
      return;
    }
    setInitialDialog(dialog);
    setInitialTitle(title);
    setShow(!show);

    returnToggleDialog(!show);
    onChange({ show: !show });
  };

  const handleCloseDialog = () => {
    if (!dialog && !show) {
      return;
    }
    setShow(!show);
    returnToggleDialog(!show);

    onChange({ show: !show });
  };

  const onEndSwipe = () => {
    setIsDragging(false);

    if (endMouseY === 0) {
      return;
    }

    const diffY = Math.abs(endMouseY - initialMouseY);
    const currentHeightDialog = dialogRef.current.clientHeight;

    // diff Y
    if (diffY > currentHeightDialog / 2 - 50) {
      setTimeout(() => {
        setEndMouseY(0);
        setInitialMouseY(0);
        handleToggleDialog();
      }, 300);
    } else {
      setTimeout(() => {
        setEndMouseY(0);
        setInitialMouseY(0);
      }, 300);
    }
  };

  useEffect(() => {
    const timer =
      showDialog &&
      setTimeout(() => {
        setInitialDialog(dialog);
        setInitialTitle(title);
        setShow(true);
      }, timeoutExpanded);
    return () => clearTimeout(timer);
  }, [showDialog, timeoutExpanded, dialog]);

  const getClass = () => {
    if (!show) {
      return 'footer-form-builder';
    }
    return 'd-none';
  };

  const getClassDialog = () => {
    if (!show && !isTrivia) {
      return 'd-none';
    }
    if (!show && !isTrivia) {
      return 'dialog dialogOut';
    }

    if (show) {
      return 'dialog';
    }
    return 'dialog dialogOut';
  };

  const handleToggleInfo = () => {
    if (!show) {
      setInitialTitle(messageBee.DEFAULT_TITLE);
      setInitialDialog(messageBee.HELPER_INFO);
    }
    setShow(!show);
  };

  const getImageClass = () => {
    if (blackFooter) {
      return 'home-btn';
    }
    if (hidePrev) {
      return 'hidden prev-btn';
    }
    return 'prev-btn';
  };

  return (
    <Container className={blackFooter ? 'content-steps-footer--black' : ''} blackFooter={blackFooter}>
      <div className={getClass()} style={{ display: 'flex', flexDirection: 'row' }}>
        <div className={getImageClass()} id={nextPrev ? 'prevNext' : ''}>
          {nextPrev ? (
            <Button
              type="button"
              style={{
                opacity: disabledPrevButton ? 0.5 : 1,
                cursor: disabledPrevButton ? 'not-allowed' : 'pointer',
              }}
              onClick={disabledPrevButton ? noop : actionPrev}
              disabled={disabledPrevButton}
            >
              <Image src={arrow} alt="arrow" />
              <ButtonSpan>Prev. Page</ButtonSpan>
            </Button>
          ) : (
            <Button
              type="button"
              style={{
                opacity: disabledPrevButton ? 0.5 : 1,
                cursor: disabledPrevButton ? 'not-allowed' : 'pointer',
              }}
              onClick={disabledPrevButton ? noop : actionPrev}
              disabled={disabledPrevButton}
            >
              {blackFooter ? (
                <Image src={home} alt="home" />
              ) : (
                <>
                  <Image src={bigArrow} alt="arrow" />
                </>
              )}
            </Button>
          )}
        </div>
        {onPreview && (
          <HivePreview className="hive-preview" onClick={!isLoadingNextButton && onPreview}>
            <Image src={preview} alt="preview" style={{ opacity: disabledResume || isLoadingNextButton ? 0.5 : 1 }} />
          </HivePreview>
        )}
        <HiveLogo className="hive-logo" onClick={() => handleToggleDialog()}>
          {dialog === null ? (
            <WrapperBeeInactive>
              <Image src={helpBee} alt="helpbee" />
            </WrapperBeeInactive>
          ) : (
            <Image src={helpBee} alt="helpbee" />
          )}
        </HiveLogo>
        {showHelpInfo && (
          <HiveInfo className="hive-info" onClick={() => handleToggleInfo()}>
            <Image src={helpInfo} alt="info" />
          </HiveInfo>
        )}
        {type === 'submit' && (
          <div className={hideNext ? 'hidden next-btn' : 'next-btn'}>
            <Button
              type="submit"
              form={form}
              disabled={isLoadingNextButton || disabledNextButton}
              style={{ cursor: disabledNextButton ? 'not-allowed' : 'pointer' }}
            >
              {isLoadingNextButton ? (
                <Loader type="TailSpin" color="white" height={25} width={25} style={{ display: 'flex' }} />
              ) : (
                <>
                  <Image src={disabledNextButton ? denied : bigArrow} alt="submit" />
                </>
              )}
            </Button>
          </div>
        )}
        {actionNext && (
          <>
            <div className={hideNext ? 'hidden next-btn' : 'next-btn'} id={nextPrev ? 'nextPrev' : ''}>
              <Button
                type="button"
                onClick={() => (disabledNextButton ? noop : actionNext(true))}
                style={{
                  opacity: disabledNextButton ? 0.5 : 1,
                  cursor: disabledNextButton ? 'not-allowed' : 'pointer',
                }}
              >
                {nextPrev && <ButtonSpan>Next Page</ButtonSpan>}
                <Image src={arrow} alt="next" />
              </Button>
            </div>
          </>
        )}
      </div>
      <div
        id="dialog"
        onTouchStart={onStartSwipe}
        onTouchMove={continueSwipe}
        onTouchEnd={onEndSwipe}
        ref={dialogRef}
        className={getClassDialog()}
      >
        {customActionBeeIcon && (
          <HiveLogo onClick={() => actionBeeIcon(true)}>
            <Image src={helpBee} alt="helpbee" />
          </HiveLogo>
        )}
        {!customActionBeeIcon && (
          <HiveLogo className="open" onClick={() => handleCloseDialog()}>
            <Image src={helpBee} alt="helpbee" />
          </HiveLogo>
        )}
        {isTrivia && !initialTitle && (
          <DialogTitle>
            <h6>Explanation</h6>
          </DialogTitle>
        )}
        {initialTitle && (
          <DialogTitle>
            <h6>{initialTitle}</h6>
          </DialogTitle>
        )}
        <div style={{ width: '100vw', display: 'flex', justifyContent: 'center' }}>
          {initialDialog && /<\/?[a-z][\s\S]*>/i.test(initialDialog) && (
            <div
              className="dialog-description"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: initialDialog }}
            />
          )}
          {initialDialog && !/<\/?[a-z][\s\S]*>/i.test(initialDialog) && (
            <div className="dialog-description">{initialDialog || ''}</div>
          )}
        </div>
      </div>
    </Container>
  );
};

Footer.propTypes = {
  isTrivia: PropTypes.bool,
  hidePrev: PropTypes.bool,
  hideNext: PropTypes.bool,
  actionPrev: PropTypes.func,
  actionNext: PropTypes.func,
  actionBeeIcon: PropTypes.func,
  customActionBeeIcon: PropTypes.bool,
  dialog: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  showDialog: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  blackFooter: PropTypes.bool,
  type: PropTypes.string,
  title: PropTypes.string,
  isLoadingNextButton: PropTypes.bool,
  disabledNextButton: PropTypes.bool,
  disabledPrevButton: PropTypes.bool,
  disabledBee: PropTypes.bool,
  timeoutExpanded: PropTypes.number,
  onChange: PropTypes.func,
  returnToggleDialog: PropTypes.func,
  onPreview: PropTypes.func,
  form: PropTypes.string,
  showHelpInfo: PropTypes.bool,
  nextPrev: PropTypes.bool,
  disabledResume: PropTypes.bool,
};

Footer.defaultProps = {
  isTrivia: false,
  hidePrev: false,
  actionPrev: null,
  form: null,
  customActionBeeIcon: false,
  hideNext: null,
  actionNext: null,
  dialog: null,
  actionBeeIcon: null,
  blackFooter: false,
  showDialog: null,
  disabledBee: false,
  type: 'button',
  title: null,
  isLoadingNextButton: false,
  disabledNextButton: false,
  disabledPrevButton: false,
  showHelpInfo: true,
  timeoutExpanded: 0,
  onPreview: null,
  onChange: noop,
  returnToggleDialog: noop,
  nextPrev: false,
  disabledResume: false,
};

export default Footer;
