import { createReducer } from 'reduxsauce';

import { REGISTER_STEPS } from 'constant/register-routes';

import { registerTypes } from './actions';
import { initialState } from './initial-state';
import { RegisterState } from './types';

const saveFormValues = (state, { stepName, values, isCompleted }) => ({
  ...state,
  [REGISTER_STEPS[stepName]]: {
    ...state[REGISTER_STEPS[stepName]],
    values: {
      ...state[REGISTER_STEPS[stepName]].values,
      ...values,
    },
    isCompleted,
  },
});

const clearFormValues = state => ({
  ...state,
  ...initialState,
});

const reducer = createReducer<RegisterState>(initialState, {
  [registerTypes.SAVE_FORM_VALUES]: saveFormValues,
  [registerTypes.CLEAR_FORM_VALUES]: clearFormValues,
});

export default reducer;
