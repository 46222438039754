import React from 'react';

import { StyledForm, StyledErrorMessage } from './Form.styled';

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {
  error?: string;
}

const Form: React.FC<FormProps> = ({ children, error = '', ...props }) => (
  <StyledForm {...props}>
    {children}
    {error && <StyledErrorMessage error={error} />}
  </StyledForm>
);

export default Form;
