import styled from 'styled-components';

import checkboxIcon from 'assets/svg/check-icon.svg';
import { Label } from 'components/v2/forms/components';

export const Wrapper = styled.div`
  margin-bottom: 24px;
  text-align: left;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 0;
`;

export const OptionName = styled.span`
  margin-left: 12px;
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;

  &[aria-disabled='true'] {
    color: #4a485b;
  }
`;

export const StyledCheckbox = styled.input`
  appearance: none;
  width: 18px;
  height: 18px;
  border: 2px solid #88849a;
  border-radius: 4px;
  display: grid;
  place-content: center;

  &:disabled {
    border-color: #4a485b;
    opacity: 0.3;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &::before {
    content: '';
    width: 18px;
    height: 18px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;

    background-color: #3d3b4a;
    background-image: url(${checkboxIcon});
    background-repeat: no-repeat;
  }

  &:checked {
    &::before {
      transform: scale(1);
    }
  }
`;
