import styled from 'styled-components';

import { Button } from 'components/v2/buttons';

export const StyledButton = styled(Button)`
  display: flex;
  font-size: 14px;
  line-height: 14px;
  padding: 8px 24px;
  width: auto;
  text-transform: capitalize;

  @media screen and (min-width: 768px) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;

  &.left {
    margin-right: 8px;
  }

  &.right {
    margin-left: 8px;
    order: 2;
  }
`;
