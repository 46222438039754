import { isEqual } from 'lodash';
import { useEffect } from 'react';

import { listLevels } from 'constant';

let userStore = {};

const useUserStore = store => {
  useEffect(() => {
    userStore = store;
    // eslint-disable-next-line
  }, []);

  const setEditingUserStore = (values, level) => {
    if (isEqual(values, userStore)) {
      return;
    }
    if (level === listLevels.EXAMPLE) {
      console.info(values);
    }
    userStore = { ...store, ...values };
  };

  return { setEditingUserStore };
};

export { userStore };
export default useUserStore;
