import { routes } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import BeeConfirm from 'components/bee-confirm';
import { messageBee } from 'constant';
import studentCreator from 'store/student/actions';

const useNotOnePageModal = () => {
  const dispatch = useDispatch();
  const { blockResume } = useSelector(state => state.student);
  const [show, setShow] = useState(false);

  const goTo2C = () => {
    setShow(false);
    if (blockResume) {
      dispatch(push(routes.LEVEL_BULLETS_BUZZ_WITHOUT_ORIGIN));
    }
  };

  const closeBeeModal = () => {
    setShow(false);
    dispatch(studentCreator.studentSetResumeCount(undefined));
  };

  useEffect(() => {
    dispatch(studentCreator.studentFetchResumeCount());
  }, []);

  const Modal = () => (
    <BeeConfirm
      showModal={show}
      onClose={closeBeeModal}
      onSubmit={goTo2C}
      title={messageBee.NO_ONE_PAGE_TITLE}
      text={messageBee.NOT_ONE_PAGE}
    />
  );

  const showModal = () => {
    if (blockResume) {
      setShow(true);
      return true;
    }
    return false;
  };

  return [showModal, Modal];
};

export default useNotOnePageModal;
