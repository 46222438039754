import { useFormik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { ReactComponent as CheckboxSvg } from 'assets/svg/checkbox.svg';
import { ReactComponent as TrashSvg } from 'assets/svg/trash.svg';
import { BackButton, ButtonVariant, ForwardButton } from 'components/v2/buttons';
import { Form, FormRow, FormActionButtonsRow, Input, Select } from 'components/v2/forms/components';
import { StyledCheckbox } from 'components/v2/forms/components/checkbox/Checkbox.styled';
import { Heading, HeadingLevel } from 'components/v2/heading';
import { Paper } from 'components/v2/paper';
import { levelRoutes, LEVEL_STEPS } from 'constant';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import { getStudent } from 'store/student/selectors';
import levelActionsCreator from 'store/v2/level/actions';
import { isCourseworkCompletedSelector, isCourseworkLoadingSelector } from 'store/v2/level/selectors';
import { CourseworkFormValues } from 'store/v2/level/types';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  majorCategoriesOptionsErrorSelector,
  majorCategoriesOptionsIsLoadingSelector,
  majorCategoriesOptionsSelector,
} from 'store/v2/shared/selectors';
import { getOption } from 'utils';

import {
  Paragraph,
  HeaderDetails,
  StyledButton,
  SubmittedDetails,
  SubmittedDetailsWrapper,
  SubmittedHeader,
  SubmittedValuesWrapper,
  SubmittedValues,
} from './Coursework.styled';
import { courseWorkFormInitialValues, OTHER_FIELD_ID, validationSchema } from './form-config';

const Coursework = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [showOtherField, setShowOtherField] = React.useState(false);

  const student = useSelector(getStudent);

  // ** academic options
  const isAcademicAreaOptionsLoading = useSelector(majorCategoriesOptionsIsLoadingSelector);
  const academicAreaOptions = useSelector(majorCategoriesOptionsSelector);
  const academicAreaOptionsLoadingFailure = useSelector(majorCategoriesOptionsErrorSelector);

  // ** coursework status
  const isCourseworkLoading = useSelector(isCourseworkLoadingSelector);
  const isCourseworkCompleted = useSelector(isCourseworkCompletedSelector);

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.coursework);

  const { isValid, handleSubmit, values, touched, handleChange, handleBlur, errors, setFieldValue } =
    useFormik<CourseworkFormValues>({
      initialValues: initialValues,
      validateOnMount: true,
      validationSchema: validationSchema,
      onSubmit: values => {
        dispatch(levelActionsCreator.saveCourseworkFormValuesRequest(values, true, levelRoutes.collegiateAthletics));
      },
    });

  // sync
  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.coursework, values));
  }, 600);

  const handleAcademicAreaOptionChange = React.useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value?.id.toString(), true);
      setFieldValue('majorCategoryName', value.name, true);
      setShowOtherField(value.id === OTHER_FIELD_ID);
    },
    [setFieldValue, setShowOtherField, true]
  );

  const clearInputs = () => {
    setFieldValue('course', courseWorkFormInitialValues.course, false);
    setFieldValue('majorCategory', courseWorkFormInitialValues.majorCategory, false);
    setFieldValue('majorCategoryName', courseWorkFormInitialValues.majorCategoryName, false);
    setFieldValue('otherCategory', courseWorkFormInitialValues.otherCategory, false);
    setFieldValue('added', courseWorkFormInitialValues.added, false);
    setFieldValue('includeOnResume', courseWorkFormInitialValues.includeOnResume, false);
  };

  const handleGoBack = React.useCallback(() => {
    dispatch(
      levelActionsCreator.saveCourseworkFormValuesRequest(
        values,
        isCourseworkCompleted,
        student?.enhancedAcademicExperiences?.length > 0
          ? levelRoutes.additionalDetails
          : levelRoutes.researchAndPrograms
      )
    );
  }, [push, values, student?.enhancedAcademicExperiences?.length, isCourseworkCompleted]);

  const toggleIncludeOnResume = React.useCallback(
    (index: number) => {
      const updatedValues = values.coursework.map((val, idx) => {
        if (idx === index) {
          const { includeOnResume, ...rest } = val;
          return {
            ...rest,
            includeOnResume: !includeOnResume,
          };
        }
        return val;
      });
      setFieldValue('coursework', updatedValues);
    },
    [values.coursework]
  );
  const remove = React.useCallback(
    (index: number) => {
      const updatedValues = values.coursework.filter((_val, idx) => idx !== index);
      setFieldValue('coursework', updatedValues);
    },
    [values.coursework]
  );

  const handleAdd = React.useCallback(() => {
    const { coursework, ...valuesToAdd } = values;

    setFieldValue('coursework', [...coursework, { ...valuesToAdd, added: true, includeOnResume: true }]);
    clearInputs();
    setShowOtherField(false);
  }, [values]);

  React.useEffect(() => {
    if (!isAcademicAreaOptionsLoading && academicAreaOptions.length === 0 && !academicAreaOptionsLoadingFailure) {
      dispatch(sharedActionsCreator.majorCategoriesOptionsRequest());
    }
  }, [academicAreaOptions.length, isAcademicAreaOptionsLoading, academicAreaOptionsLoadingFailure]);

  React.useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  return (
    <Form className="container px-0" onSubmit={handleSubmit}>
      <FormRow>
        <div className="col-12 d-flex">
          <Heading level={HeadingLevel.h2}>Coursework</Heading>
        </div>
      </FormRow>
      <FormRow>
        <div className="col-12">
          <HeaderDetails>
            Enter any and all current or completed coursework here, and then select which classes you&apos;d like to
            have listed on your resume using the feature below.
          </HeaderDetails>
        </div>
      </FormRow>
      <Paper>
        <FormRow>
          <div className="col-12">
            <Input
              name="course"
              label="Coursework"
              placeholder="Name of Course (Write In)"
              value={values?.course}
              error={touched?.course ? errors?.course : ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-12">
            <Select
              name="majorCategory"
              label="Academic Area"
              placeholder="Select Area"
              value={getOption(academicAreaOptions, values?.majorCategory, 'id')}
              error={touched?.majorCategory ? errors?.majorCategory : ''}
              options={academicAreaOptions}
              onChange={handleAcademicAreaOptionChange}
              isLoading={isAcademicAreaOptionsLoading}
              isDisabled={!values?.course}
              required
            />
          </div>
          {showOtherField ? (
            <div className="col-12">
              <Input
                name="otherCategory"
                placeholder="Enter other academic area"
                value={values?.otherCategory}
                error={touched?.otherCategory ? errors?.otherCategory : ''}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </div>
          ) : null}
        </FormRow>
        <div className="col-12">
          <StyledButton
            variant={ButtonVariant.secondary}
            type="button"
            disabled={
              !values?.course ||
              !values.majorCategory ||
              (values.majorCategory === OTHER_FIELD_ID.toString() && !values.otherCategory) ||
              !isValid
            }
            onClick={handleAdd}
          >
            Add
          </StyledButton>
        </div>
        {values?.coursework.length >= 1 ? (
          <>
            <SubmittedHeader>Submitted Coursework</SubmittedHeader>
            <SubmittedDetailsWrapper>
              <SubmittedDetails>
                <CheckboxSvg />
                <p className="details">
                  Click to include/exclude on resume and profile. When included, the class will be noted with a gold
                  outline.
                </p>
              </SubmittedDetails>
              <SubmittedDetails>
                <TrashSvg />
                <p className="details">Click to remove coursework from “Submitted Coursework.”</p>
              </SubmittedDetails>
            </SubmittedDetailsWrapper>
            <SubmittedValuesWrapper>
              {values?.coursework?.map((value, index) => (
                <SubmittedValues active={value.includeOnResume} key={`${value.course}${value.majorCategory}`}>
                  <div className="checkbox-wrapper">
                    <StyledCheckbox
                      type="checkbox"
                      checked={value.includeOnResume}
                      onChange={() => toggleIncludeOnResume(index)}
                    />

                    {value.majorCategory === OTHER_FIELD_ID.toString() ? (
                      <Paragraph active={value.includeOnResume} className="truncate">
                        <span>{value.course}</span> ({value.otherCategory})
                      </Paragraph>
                    ) : (
                      <Paragraph active={value.includeOnResume} className="truncate">
                        <span>{value.course}</span> ({value.majorCategoryName})
                      </Paragraph>
                    )}
                  </div>
                  <div className="svg-wrapper">
                    <button type="button" className="svg-button" title="remove" onClick={() => remove(index)}>
                      <TrashSvg aria-hidden="true" />
                    </button>
                  </div>
                </SubmittedValues>
              ))}
            </SubmittedValuesWrapper>
          </>
        ) : null}
      </Paper>
      <FormActionButtonsRow>
        <BackButton className="col-6 col-md-3" onClick={handleGoBack} disabled={isCourseworkLoading} />
        <ForwardButton
          type="submit"
          className="col-6 col-md-3"
          disabled={isCourseworkLoading || !!values?.course || !isValid}
          isLoading={isCourseworkLoading}
        />
      </FormActionButtonsRow>
    </Form>
  );
};

export default Coursework;
