export default {
  TabQualification: {
    padding: '40px 16px 0 16px',
  },

  ApplyNow: {
    color: 'var(--color-white)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '125%',
    borderRadius: '8px',
    minWidth: '242px',
    height: '50px',
    textTransform: 'none',
    flexGrow: '1',
    '&:disabled': {
      backgroundColor: 'var(--color-secondary)',
      opacity: '.9',
    },
  },

  Locations: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.005em',
    margin: '0',
    padding: '0',
    '@media (max-width: 430px)': {
      fontSize: '13px',
      lineHeight: '15px',
    },
  },

  Tag: {
    fontFamily: 'Poppins',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '-0.005em',
    '@media (max-width: 430px)': {
      fontSize: '13px',
      lineHeight: '15px',
    },
  },

  FollowButton: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    maxWidth: '140px',
    padding: '12px 28px 10px 33px',
    background: '#009ED0',
    border: '2px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },

  FollowedButton: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    maxWidth: '140px',
    padding: '12px 20px 10px 26px',
    background: 'transparent',
    border: '2px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '8px',
  },

  UnfollowButton: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    maxWidth: '100%',
    width: '100%',
    padding: '15px 20px 12px 26px',
    background: '#278A96',
    border: '2px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '8px',
    marginBottom: '24px',
    zIndex: '9999999999999',
    position: 'relative',
  },
  FilterMessageButton: {
    fontFamily: 'Poppins',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    letterSpacing: '-0.02em',
    color: '#FFFFFF',
    textTransform: 'capitalize',
    maxWidth: '200px',
    padding: '12px 20px 10px 20px',
    background: '#009ED0',
    border: '2px solid #FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '8px',
    margin: '0 8px 16px 8px',
  },
};
