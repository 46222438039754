import hexagon from 'assets/svg/hexagon2.svg';
import hexagonComplete from 'assets/svg/hexagon_complete.svg';
import logoBorders from 'assets/svg/hive-logo-borders.svg';
import honeycombs from 'assets/svg/honeycombs.svg';
import honeycombsOrange from 'assets/svg/honeycombs_orange.svg';
import greenComplete from 'assets/svg/levels/green-complete.svg';
import greenUnlocked from 'assets/svg/levels/green-unlocked.svg';
import lightBlueComplete from 'assets/svg/levels/light-blue-complete.svg';
import lightBlueUnlocked from 'assets/svg/levels/light-blue-unlocked.svg';
import pinkComplete from 'assets/svg/levels/pink-complete.svg';
import pinkUnlocked from 'assets/svg/levels/pink-unlocked.svg';
import violetComplete from 'assets/svg/levels/violet-complete.svg';
import violetUnlocked from 'assets/svg/levels/violet-unlocked.svg';
import yellowComplete from 'assets/svg/levels/yellow-complete.svg';
import yellowUnlocked from 'assets/svg/levels/yellow-unlocked.svg';
import { listLevels, progressStatus } from 'constant';

const getLevelIcon = (levelId, status, isActive) => {
  if (status === progressStatus.COMPLETE) {
    switch (levelId) {
      case listLevels.ONE:
        return lightBlueComplete;
      case listLevels.ONE_A:
        return lightBlueComplete;
      case listLevels.ONE_B:
        return lightBlueComplete;
      case listLevels.ONE_C:
        return lightBlueComplete;
      case listLevels.ONE_D:
        return lightBlueComplete;
      case listLevels.TWO:
        return greenComplete;
      case listLevels.THREE:
        return pinkComplete;
      case listLevels.FOUR:
        return yellowComplete;
      case listLevels.FIVE:
        return violetComplete;
      case listLevels.SIX:
        return violetComplete;
      case listLevels.PC:
        return honeycombs;
      default:
        return hexagonComplete;
    }
  }
  if (status === progressStatus.UNLOCKED) {
    switch (levelId) {
      case listLevels.ONE:
        return lightBlueUnlocked;
      case listLevels.ONE_A:
        return lightBlueUnlocked;
      case listLevels.ONE_B:
        return lightBlueUnlocked;
      case listLevels.ONE_C:
        return lightBlueUnlocked;
      case listLevels.ONE_D:
        return lightBlueUnlocked;
      case listLevels.TWO:
        return greenUnlocked;
      case listLevels.THREE:
        return pinkUnlocked;
      case listLevels.FOUR:
        return yellowUnlocked;
      case listLevels.FIVE:
        return violetUnlocked;
      case listLevels.SIX:
        return violetUnlocked;
      case listLevels.PC:
        return honeycombsOrange;
      default:
        return lightBlueUnlocked;
    }
  }
  if (levelId === listLevels.PC) {
    return logoBorders;
  }
  if (isActive) {
    return lightBlueUnlocked;
  }
  return hexagon;
};

export default getLevelIcon;
