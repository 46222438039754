import { createReducer } from 'reduxsauce';

import { authTypes } from 'store/authorization/actions';
import { initialState } from 'store/authorization/initial-state';

const loginRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
  token: null,
});

const loginSuccess = (state, { user, token }) => ({
  ...state,
  data: user,
  token,
  isLoading: false,
  error: null,
});

const loginError = (state, { error }) => ({
  ...state,
  error,
  token: null,
  isLoading: false,
  data: null,
});

const registerRequest = state => ({
  ...state,
  isRegistering: true,
  error: null,
  message: null,
});

const registerSuccess = (state, { message }) => ({
  ...state,
  isRegistering: false,
  error: null,
  message,
});

const registerError = (state, { error }) => ({
  ...state,
  error,
  isRegistering: false,
  message: null,
});

const resetPasswordRequest = state => ({
  ...state,
  isSuccessResetPassword: false,
  isResetting: true,
  errorResetPassword: null,
});

const resetPasswordSuccess = state => ({
  ...state,
  isSuccessResetPassword: true,
  isResetting: false,
  errorResetPassword: null,
});

const resetPasswordError = (state, { error }) => ({
  ...state,
  isSuccessResetPassword: false,
  errorResetPassword: error,
  isResetting: false,
});

const forgotPasswordRequest = state => ({
  ...state,
  isLoadingForgotPassword: true,
  error: null,
});

const forgotPasswordSuccess = state => ({
  ...state,
  isLoadingForgotPassword: false,
  error: null,
  isSuccess: true,
});

const forgotPasswordError = (state, { error }) => ({
  ...state,
  error,
  isLoadingForgotPassword: false,
  isSuccess: false,
});

const checkTokenRequest = state => ({
  ...state,
  isLoadingCheckToken: true,
  tokenStatus: null,
  error: null,
});

const checkTokenSuccess = (state, { message }) => ({
  ...state,
  isLoadingCheckToken: false,
  tokenStatus: message,
  error: null,
});

const checkTokenError = (state, { error }) => ({
  ...state,
  isLoadingCheckToken: false,
  tokenStatus: null,
  error,
});

const checkEmailRequest = state => ({
  ...state,
  isLoadingCheckEmail: true,
  error: null,
  exists: null,
  message: null,
});

const checkEmailSuccess = (state, { exists, message }) => ({
  ...state,
  isLoadingCheckEmail: false,
  exists,
  message,
  error: null,
});

const checkEmailError = (state, { error }) => ({
  ...state,
  isLoadingCheckEmail: false,
  exists: null,
  message: null,
  error,
});

const retrySendEmailRequest = state => ({
  ...state,
  isRegistering: true,
  error: null,
  message: null,
});

const retrySendEmailSuccess = (state, { message }) => ({
  ...state,
  isRegistering: false,
  message,
  error: null,
});

const retrySendEmailError = (state, { error }) => ({
  ...state,
  isRegistering: false,
  message: null,
  error,
});

const impersonateRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
  token: null,
});

const impersonateSuccess = (state, { user, token }) => ({
  ...state,
  data: user,
  token,
  isLoading: false,
  error: null,
});

const impersonateError = (state, { error }) => ({
  ...state,
  error,
  token: null,
  isLoading: false,
  data: null,
});

const confirmEmailRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
  token: null,
});

const confirmEmailSuccess = (state, { user, token }) => ({
  ...state,
  data: user,
  token,
  isLoading: false,
  error: null,
});

const confirmEmailError = (state, { error }) => ({
  ...state,
  error,
  token: null,
  isLoading: false,
  data: null,
});

const getTokenRequest = state => ({
  ...state,
  isLoadingToken: true,
  isRequested: false,
  error: null,
  token: null,
});

const getTokenSuccess = (state, { token }) => ({
  ...state,
  token,
  isLoadingToken: false,
  isRequested: true,
  error: null,
});

const getTokenError = (state, { error }) => ({
  ...state,
  error,
  token: null,
  isLoadingToken: false,
  isRequested: true,
});

const cleanState = state => ({
  ...state,
  error: initialState.error,
  isLoading: initialState.isLoading,
});

const reducer = createReducer(initialState, {
  [authTypes.AUTH_LOGIN_REQUEST]: loginRequest,
  [authTypes.AUTH_LOGIN_SUCCESS]: loginSuccess,
  [authTypes.AUTH_LOGIN_ERROR]: loginError,

  [authTypes.AUTH_REGISTER_REQUEST]: registerRequest,
  [authTypes.AUTH_REGISTER_SUCCESS]: registerSuccess,
  [authTypes.AUTH_REGISTER_ERROR]: registerError,

  [authTypes.AUTH_FORGOT_PASSWORD_REQUEST]: forgotPasswordRequest,
  [authTypes.AUTH_FORGOT_PASSWORD_SUCCESS]: forgotPasswordSuccess,
  [authTypes.AUTH_FORGOT_PASSWORD_ERROR]: forgotPasswordError,

  [authTypes.AUTH_RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [authTypes.AUTH_RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,
  [authTypes.AUTH_RESET_PASSWORD_ERROR]: resetPasswordError,

  [authTypes.AUTH_CHECK_TOKEN_REQUEST]: checkTokenRequest,
  [authTypes.AUTH_CHECK_TOKEN_SUCCESS]: checkTokenSuccess,
  [authTypes.AUTH_CHECK_TOKEN_ERROR]: checkTokenError,

  [authTypes.AUTH_CHECK_EMAIL_REQUEST]: checkEmailRequest,
  [authTypes.AUTH_CHECK_EMAIL_SUCCESS]: checkEmailSuccess,
  [authTypes.AUTH_CHECK_EMAIL_ERROR]: checkEmailError,

  [authTypes.AUTH_RETRY_SEND_EMAIL_REQUEST]: retrySendEmailRequest,
  [authTypes.AUTH_RETRY_SEND_EMAIL_SUCCESS]: retrySendEmailSuccess,
  [authTypes.AUTH_RETRY_SEND_EMAIL_ERROR]: retrySendEmailError,

  [authTypes.AUTH_IMPERSONATE_REQUEST]: impersonateRequest,
  [authTypes.AUTH_IMPERSONATE_SUCCESS]: impersonateSuccess,
  [authTypes.AUTH_IMPERSONATE_ERROR]: impersonateError,

  [authTypes.AUTH_CONFIRM_EMAIL_REQUEST]: confirmEmailRequest,
  [authTypes.AUTH_CONFIRM_EMAIL_SUCCESS]: confirmEmailSuccess,
  [authTypes.AUTH_CONFIRM_EMAIL_ERROR]: confirmEmailError,

  [authTypes.AUTH_GET_TOKEN_REQUEST]: getTokenRequest,
  [authTypes.AUTH_GET_TOKEN_SUCCESS]: getTokenSuccess,
  [authTypes.AUTH_GET_TOKEN_ERROR]: getTokenError,

  [authTypes.AUTH_CLEAN_STATE]: cleanState,
});

export default reducer;
