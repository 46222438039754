import React from 'react';
import Loader from 'react-loader-spinner';

import { LoaderWrapper, StyledButton } from './Button.styled';

export const enum ButtonVariant {
  primary = 'primary',
  secondary = 'secondary',
  basic = 'basic',
  text = 'text',
}

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: ButtonVariant;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  className = '',
  type = 'button',
  variant = ButtonVariant.primary,
  isLoading = false,
  ...props
}) => (
  <StyledButton className={`${variant} ${className}`} type={type} {...props}>
    {isLoading ? (
      <LoaderWrapper>
        <Loader
          type="TailSpin"
          color={variant === ButtonVariant.primary ? '#09080d' : variant === ButtonVariant.basic ? '#fff' : '#febf32'}
          width={24}
          height={24}
        />
      </LoaderWrapper>
    ) : (
      children
    )}
  </StyledButton>
);

export default Button;
