import { noBoxShadow } from '@hivediversity/common-lib/styled/css-helpers';
import styled from 'styled-components';

export const WrapperButton = styled.div`
  justify-content: center;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  flex-direction: row;
  flex: 1;
  opacity: ${({ disabledResume }) => (disabledResume ? '0.5' : '1')};
`;

export const ButtonPreview = styled.button.attrs(({ type }) => ({ type: type || 'button' }))`
  cursor: pointer;
  width: 100%;
  opacity: 1;
  padding-left: 11px;
  padding-right: 11px;
  color: #fff;
  text-decoration: none;
  background: rgb(144, 89, 206);
  background: linear-gradient(
    165deg,
    rgba(144, 89, 206, 1) 0%,
    rgba(159, 164, 216, 1) 62%,
    rgba(129, 184, 209, 1) 100%
  );
  border-radius: 20px;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  max-width: 160px;
  min-width: 160px;
  height: 32px;
  border: none;
  position: relative;
  &:hover {
    text-decoration: none;
  }
  ${noBoxShadow};
`;
