import { routes } from '@hivediversity/common-lib/constant';

export const error404Links = [
  { label: '#FlyHire Portal', path: routes.DASHBOARD },
  { label: 'Resume & Profile', path: routes.PROFILE },
  { label: 'Contact', path: 'mailto:support@hellohive.com' },
  { label: 'My Account', path: routes.ACCOUNT },
  { label: 'Privacy Policy', path: 'https://hellohive.com/userprivacy', target: '_blank' },
  { label: 'Terms & Conditions', path: 'https://hellohive.com/userterms', target: '_blank' },
];
