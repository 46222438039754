import castArray from 'lodash/castArray';
import filter from 'lodash/filter';
import find from 'lodash/find';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReactSelect from 'react-select';

import DropdownIndicator from 'components/dropdown-indicator';

import customStyle from './custom-style';
import { Container } from './styled';

const Dropdown = ({
  onChange,
  placeholder,
  options,
  getOptionLabel,
  getOptionValue,
  value,
  disabled,
  isClearable,
  isMulti,
}) => {
  const inputRef = useRef(null);

  const handleChange = selected => {
    if (isMulti) {
      return onChange(castArray(selected).map(select => getOptionValue(select)));
    }
    return onChange(getOptionValue(selected));
  };

  return (
    <Container>
      <ReactSelect
        menuPlacement="auto"
        onChange={handleChange}
        defaultValue={null}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        options={options}
        styles={customStyle(inputRef)}
        placeholder={placeholder}
        defaultMenuIsOpen={false}
        closeMenuOnSelect
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        value={
          isMulti
            ? filter(options, option => value?.includes(getOptionValue(option)))
            : find(options, option => getOptionValue(option) === value)
        }
        disabled={disabled}
        menuPortalTarget={document.body}
        menuShouldBlockScroll
        inputFocusOnClick={false}
        isFocused
        isClearable={isClearable}
        isMulti={isMulti}
      />
    </Container>
  );
};

Dropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func,
  getOptionValue: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
      label: PropTypes.string,
    })
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool, PropTypes.arrayOf(PropTypes.number)]),
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
};

Dropdown.defaultProps = {
  getOptionLabel: option => option.label,
  getOptionValue: option => option.id,
  value: undefined,
  disabled: false,
  isClearable: false,
  isMulti: false,
};

export default Dropdown;
