import { last } from 'lodash';
import { takeEvery, call, put, takeLatest } from 'redux-saga/effects';

import { requestNotifications, requestNotificationRead } from 'services/app';
import { getOrCached } from 'services/http';
import appActionsCreator, { appTypes } from 'store/app/actions';
import { getResponseError, i18n } from 'utils';

export function* fetch({ key, endpoint }) {
  try {
    yield put(appActionsCreator.setLoadingEndpoint(key, true));
    if (last(endpoint) === '/') {
      yield put(appActionsCreator.setEndpointFetchError(null));
      return;
    }
    const response = yield call(getOrCached, endpoint);
    const error = yield getResponseError(response);
    if (!error) {
      yield put(appActionsCreator.setData(key, response.data));
    } else {
      yield put(appActionsCreator.setEndpointFetchError(key, error));
    }
  } catch (error) {
    yield put(appActionsCreator.setEndpointFetchError(key, i18n.t('errors.GENERAL_ERROR')));
  } finally {
    yield put(appActionsCreator.setLoadingEndpoint(key, false));
  }
}

export function* notifications() {
  try {
    const response = yield call(requestNotifications);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(appActionsCreator.notificationsSuccess(response.data));
    } else {
      yield put(appActionsCreator.notificationsError(error));
    }
  } catch (error) {
    yield put(appActionsCreator.notificationsError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* notificationRead({ notificationId }) {
  try {
    const response = yield call(requestNotificationRead, notificationId);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(appActionsCreator.notificationReadSuccess(response.data));
    } else {
      yield put(appActionsCreator.notificationReadError(error));
    }
  } catch (error) {
    yield put(appActionsCreator.notificationReadError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* appSaga() {
  yield takeEvery(appTypes.FETCH_ARBITRARY_ENDPOINT, fetch);
  yield takeLatest(appTypes.NOTIFICATIONS_REQUEST, notifications);
  yield takeLatest(appTypes.NOTIFICATION_READ_REQUEST, notificationRead);
}
