import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';

import { requestCheckExistEmail } from 'services/authorization';
import {
  diversityOptionsRequest,
  diversitySubOptionsRequest,
  heardFromUsOptionsRequest,
  heardFromUsSubOptionsRequest,
  universityOptionsRequest,
  yearOptionsRequest,
  studyPeriodOptionsRequest,
  statesOptionsRequest,
  stateCitiesOptionsRequest,
  schoolStateCitiesOptionsRequest,
  countriesOptionsRequest,
  periodsOfStudiesOptionsRequest,
  degreeOptionsRequest,
  majorCategoriesOptionsRequest,
  majorsOptionsRequest,
  degreeTypeOptionsRequest,
  periodRangeOptionsRequest,
  sportsOptionsRequest,
  minorsOptionsRequest,
  proficiencyLevelOptionsRequest,
  proficiencySkillCategoriesOptionsRequest,
  languageProficiencyOptionsRequest,
  languageOptionsRequest,
} from 'services/shared';
import { getResponseError, i18n } from 'utils';

import sharedCreators, { sharedTypes } from './actions';

const OTHER_DEGREE_ID = 173;

export function* heardFromUsOptionsRequestSaga() {
  try {
    const response = yield call(heardFromUsOptionsRequest);

    yield put(sharedCreators.heardFromUsOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.heardFromUsOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* heardFromUsSubOptionsRequestSaga(action) {
  try {
    const response = yield call(heardFromUsSubOptionsRequest, action.id);

    yield put(sharedCreators.heardFromUsSubOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.heardFromUsSubOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* universityOptionsRequestSaga() {
  try {
    const response = yield call(universityOptionsRequest);

    yield put(sharedCreators.universityOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.universityOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* yearOptionsRequestSaga() {
  try {
    const response = yield call(yearOptionsRequest);

    yield put(sharedCreators.yearOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.yearOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* studyPeriodOptionsRequestSaga() {
  try {
    const response = yield call(studyPeriodOptionsRequest);

    yield put(sharedCreators.studyPeriodOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.studyPeriodOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* diversityOptionsRequestSaga() {
  try {
    const response = yield call(diversityOptionsRequest);

    yield put(sharedCreators.diversityOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.diversityOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* diversitySubOptionsRequestSaga(action) {
  try {
    const response = yield call(diversitySubOptionsRequest, action.id);

    yield put(sharedCreators.diversitySubOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.diversitySubOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* statesOptionsRequestSaga() {
  try {
    const response = yield call(statesOptionsRequest);

    yield put(sharedCreators.statesOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.statesOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* stateCitiesOptionsRequestSaga(action) {
  try {
    const response = yield call(stateCitiesOptionsRequest, action.id);

    yield put(sharedCreators.stateCitiesOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.stateCitiesOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* schoolStateCitiesOptionsRequestSaga(action) {
  try {
    const response = yield call(schoolStateCitiesOptionsRequest, action.id);

    yield put(sharedCreators.schoolStateCitiesOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.schoolStateCitiesOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* countriesOptionsRequestSaga() {
  try {
    const response = yield call(countriesOptionsRequest);

    yield put(sharedCreators.countriesOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.countriesOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}
export function* majorCategoriesOptionsRequestSaga() {
  try {
    const response = yield call(majorCategoriesOptionsRequest);

    yield put(sharedCreators.majorCategoriesOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.majorCategoriesOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* bachelorDegreeOptionsRequestSaga(action) {
  try {
    const response = yield call(degreeOptionsRequest, action.degreeType.typeId);

    const updatedValues = response.data.filter(({ id }) => id !== OTHER_DEGREE_ID);

    yield put(sharedCreators.bachelorDegreeOptionsSuccess(updatedValues));
  } catch (error) {
    yield put(sharedCreators.bachelorDegreeOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* masterDegreeOptionsRequestSaga(action) {
  try {
    const response = yield call(degreeOptionsRequest, action.degreeType.typeId);

    const updatedValues = response.data.filter(({ id }) => id !== OTHER_DEGREE_ID);

    yield put(sharedCreators.masterDegreeOptionsSuccess(updatedValues));
  } catch (error) {
    yield put(sharedCreators.masterDegreeOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* majorsOptionsRequestSaga() {
  try {
    const response = yield call(majorsOptionsRequest);

    yield put(sharedCreators.majorsOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.majorsOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* minorsOptionsRequestSaga() {
  try {
    const response = yield call(minorsOptionsRequest);

    yield put(sharedCreators.minorsOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.minorsOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* degreeTypeOptionsRequestSaga() {
  try {
    const response = yield call(degreeTypeOptionsRequest);

    yield put(sharedCreators.degreeTypeOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.degreeTypeOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* periodRangesRequestSaga() {
  try {
    const response = yield call(periodRangeOptionsRequest);

    yield put(sharedCreators.periodRangesOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.periodRangesOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* sportsRequestSaga() {
  try {
    const response = yield call(sportsOptionsRequest);

    yield put(sharedCreators.sportsOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.sportsOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* registerCheckEmailSaga(action) {
  try {
    const response = yield call(requestCheckExistEmail, action.email);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(sharedCreators.registerCheckEmailSuccess(response.data.exists, response.data.message));
    } else {
      yield put(sharedCreators.registerCheckEmailError(error));
    }
  } catch (error) {
    yield put(sharedCreators.registerCheckEmailError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* periodOfStudiesOptionsRequestSaga() {
  try {
    const response = yield call(periodsOfStudiesOptionsRequest);

    yield put(sharedCreators.periodOfStudiesOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.periodOfStudiesOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* cacheStateCitiesOptionsRequestSaga(action) {
  try {
    const response = yield call(stateCitiesOptionsRequest, action.id);

    yield put(sharedCreators.cacheStateCitiesOptionsSuccess(response.data, action.id));
  } catch (error) {
    yield put(sharedCreators.cacheStateCitiesOptionsFailure(i18n.t('errors.GENERAL_ERROR'), action.id));
  }
}

export function* proficiencyLevelsOptionsRequestSaga() {
  try {
    const response = yield call(proficiencyLevelOptionsRequest);

    yield put(sharedCreators.proficiencyLevelsOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.proficiencyLevelsOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* proficiencySkillCategoriesOptionsRequestSaga() {
  try {
    const response = yield call(proficiencySkillCategoriesOptionsRequest);

    yield put(sharedCreators.proficiencySkillCategoriesOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.proficiencySkillCategoriesOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* languageProficiencyOptionsRequestSaga() {
  try {
    const response = yield call(languageProficiencyOptionsRequest);

    yield put(sharedCreators.languageProficiencyOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.languageProficiencyOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* languageOptionsRequestSaga() {
  try {
    const response = yield call(languageOptionsRequest);

    yield put(sharedCreators.languageOptionsSuccess(response.data));
  } catch (error) {
    yield put(sharedCreators.languageOptionsFailure(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* sharedSaga() {
  yield takeLatest(sharedTypes.HEARD_FROM_US_OPTIONS_REQUEST, heardFromUsOptionsRequestSaga);
  yield takeLatest(sharedTypes.HEARD_FROM_US_SUB_OPTIONS_REQUEST, heardFromUsSubOptionsRequestSaga);
  yield takeLatest(sharedTypes.UNIVERSITY_OPTIONS_REQUEST, universityOptionsRequestSaga);
  yield takeLatest(sharedTypes.YEAR_OPTIONS_REQUEST, yearOptionsRequestSaga);
  yield takeLatest(sharedTypes.STUDY_PERIOD_OPTIONS_REQUEST, studyPeriodOptionsRequestSaga);
  yield takeLatest(sharedTypes.DIVERSITY_OPTIONS_REQUEST, diversityOptionsRequestSaga);
  yield takeLatest(sharedTypes.DIVERSITY_SUB_OPTIONS_REQUEST, diversitySubOptionsRequestSaga);
  yield takeLatest(sharedTypes.STATES_OPTIONS_REQUEST, statesOptionsRequestSaga);
  yield takeLatest(sharedTypes.STATE_CITIES_OPTIONS_REQUEST, stateCitiesOptionsRequestSaga);
  yield takeLatest(sharedTypes.SCHOOL_STATE_CITIES_OPTIONS_REQUEST, schoolStateCitiesOptionsRequestSaga);
  yield takeLatest(sharedTypes.COUNTRIES_OPTIONS_REQUEST, countriesOptionsRequestSaga);
  yield takeLatest(sharedTypes.BACHELOR_DEGREE_OPTIONS_REQUEST, bachelorDegreeOptionsRequestSaga);
  yield takeLatest(sharedTypes.MASTER_DEGREE_OPTIONS_REQUEST, masterDegreeOptionsRequestSaga);
  yield takeLatest(sharedTypes.MAJORS_OPTIONS_REQUEST, majorsOptionsRequestSaga);
  yield takeLatest(sharedTypes.MINORS_OPTIONS_REQUEST, minorsOptionsRequestSaga);
  yield takeLatest(sharedTypes.REGISTER_CHECK_EMAIL_REQUEST, registerCheckEmailSaga);
  yield takeLatest(sharedTypes.MAJOR_CATEGORIES_OPTIONS_REQUEST, majorCategoriesOptionsRequestSaga);
  yield takeLatest(sharedTypes.DEGREE_TYPE_OPTIONS_REQUEST, degreeTypeOptionsRequestSaga);
  yield takeLatest(sharedTypes.PERIOD_OF_STUDIES_OPTIONS_REQUEST, periodOfStudiesOptionsRequestSaga);
  yield takeEvery(sharedTypes.CACHE_STATE_CITIES_OPTIONS_REQUEST, cacheStateCitiesOptionsRequestSaga);
  yield takeLatest(sharedTypes.PERIOD_RANGES_OPTIONS_REQUEST, periodRangesRequestSaga);
  yield takeLatest(sharedTypes.SPORTS_OPTIONS_REQUEST, sportsRequestSaga);
  yield takeLatest(sharedTypes.PROFICIENCY_LEVELS_OPTIONS_REQUEST, proficiencyLevelsOptionsRequestSaga);
  yield takeLatest(
    sharedTypes.PROFICIENCY_SKILL_CATEGORIES_OPTIONS_REQUEST,
    proficiencySkillCategoriesOptionsRequestSaga
  );
  yield takeLatest(sharedTypes.LANGUAGE_PROFICIENCY_OPTIONS_REQUEST, languageProficiencyOptionsRequestSaga);
  yield takeLatest(sharedTypes.LANGUAGE_OPTIONS_REQUEST, languageOptionsRequestSaga);
}
