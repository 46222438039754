import { routes } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { listLevels, progressStatus } from 'constant';
import { useNotOnePageModal } from 'hooks';
import { getProgress } from 'store/progress/selectors';

import { ButtonPreview, WrapperButton } from './styled';

const ResumePreviewButton = () => {
  const dispatch = useDispatch();
  const progress = useSelector(getProgress);
  const disabledResume = useSelector(state => state.student.blockResume);
  const [showOnePageModal, NotOnePageModal] = useNotOnePageModal();

  const levelOneA = progress && !isEmpty(progress.levels) && find(progress.levels, p => p.level === listLevels.ONE_A);

  const handleClick = () => {
    if (!showOnePageModal()) {
      dispatch(push(routes.RESUME_WITH_REDIRECT_TO_LEVELS));
    }
  };

  return (
    <>
      <NotOnePageModal />
      <WrapperButton
        show={levelOneA ? levelOneA.status === progressStatus.COMPLETE : false}
        disabledResume={disabledResume}
      >
        <ButtonPreview role="button" onClick={handleClick}>
          Resume preview
        </ButtonPreview>
      </WrapperButton>
    </>
  );
};

export default ResumePreviewButton;
