import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LEVEL_STEPS } from 'constant';
import { getStudent } from 'store/student/selectors';
import levelCreators from 'store/v2/level/actions';
import { LevelFormValues } from 'store/v2/level/types';
import { getLevelFormValues } from 'store/v2/level/utils';

export const useMappedLevelFormValues = <T extends LEVEL_STEPS>(levelStep: T): LevelFormValues<T> => {
  const dispatch = useDispatch();

  const student = useSelector(getStudent);
  const formValues = getLevelFormValues(levelStep, student);

  useEffect(() => {
    dispatch(levelCreators.setLevelStepFormValues(levelStep, formValues));
  }, []);

  return formValues;
};
