import React from 'react';

import { ButtonVariant, Button } from 'components/v2/buttons';

import { StyledButton } from './PreviewResumeButton.styled';

type PreviewResumeButtonProps = React.ComponentProps<typeof Button>;

const PreviewResumeButton: React.FC<PreviewResumeButtonProps> = ({ children = 'View/Save Resume', ...props }) => (
  <StyledButton variant={ButtonVariant.secondary} {...props}>
    {children}
  </StyledButton>
);

export default PreviewResumeButton;
