import { filterErrorMessages } from 'constant';

const getFilterErrorMessage = filters => {
  switch (true) {
    case filters.industries === 1:
      return filterErrorMessages.WANT_INDUSTRIES;
    case filters.jobFunctions === 1:
      return filterErrorMessages.WANT_JOB_FUNCTIONS;
    case filters.culture === 1:
      return filterErrorMessages.WANT_CULTURE;
    case filters.qualified === 1:
      return filterErrorMessages.WANT_QUALIFIED;
    case filters.following === 1:
      return filterErrorMessages.WANT_FOLLOWING;
    case filters.applied === 1:
      return filterErrorMessages.WANT_APPLIED;
    case filters.saved === 1:
      return filterErrorMessages.WANT_SAVED;
    default:
      return '';
  }
};

export default getFilterErrorMessage;
