import { listLevels } from 'constant';
import exampleSection from 'constant/example.json';

import http, { getOrCached } from './http';

export const getLevel = levelId => {
  if (levelId === listLevels.EXAMPLE) {
    return { status: 200, data: exampleSection, ok: true };
  }

  return getOrCached(`/sections/information/${levelId}`);
};

export const levelSave = (levelId, store) => {
  if (
    [
      listLevels.ALL,
      listLevels.ONE_A,
      listLevels.ONE_B,
      listLevels.ONE_C,
      listLevels.ONE_D,
      listLevels.TWO,
      listLevels.THREE,
      listLevels.FOUR,
      listLevels.FIVE,
      listLevels.SIX,
      listLevels.PC,
    ].includes(levelId)
  ) {
    return http.put(`/students/level/${levelId}`, store);
  }
  return new Error('NETWORK ERROR');
};
