import { noBoxShadow } from '@hivediversity/common-lib/styled/css-helpers';
import styled from 'styled-components';

import { NamedStepper } from 'components/v2/named-stepper';

export const Wrapper = styled.div`
  background-image: linear-gradient(0deg, #121021, #121021), linear-gradient(180deg, #1f1d30 0%, #000 100%);
  flex-grow: 1;
  padding: 52px 0;
`;

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: 992px) {
    max-width: 506px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #333140;
  margin-bottom: 24px;
  padding-bottom: 16px;
`;

export const StyledStepper = styled(NamedStepper)`
  margin-bottom: 36px;
`;

export const StyledBackButton = styled.span`
  text-transform: uppercase;
`;

export const StyledIconButton = styled.button.attrs(({ type }) => ({ type: type || 'button' }))`
  padding: 0 5px 0 0;
  display: flex;
  align-items: center;
  margin-right: 18px;
  border: none;
  background: transparent;
  width: 14px;
  &:hover {
    filter: ${({ noOpacity, greyDisabled }) => (noOpacity || greyDisabled ? 'initial' : 'brightness(0.7)')};
    opacity: ${({ noOpacity, greyDisabled }) => (noOpacity || greyDisabled ? 1 : 0.9)};
  }
  ${({ $bsStyle }) => $bsStyle || ''};
  ${noBoxShadow};
`;
