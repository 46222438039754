import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  userFetchRequest: [null],
  userFetchSuccess: ['data'],
  userFetchError: ['error'],

  userUpdateRequest: ['user', 'progress'],
  userUpdateSuccess: ['data'],
  userUpdateError: ['error'],

  userConfirmEmailRequest: ['token'],
  userConfirmEmailSuccess: ['data'],
  userConfirmEmailError: ['error'],

  setUser: ['data'],
});

export const userTypes = Types;
export default Creators;
