import isEmpty from 'lodash/isEmpty';

import { getParentValues } from 'utils';

const setValueByJson = (element, values, setFieldValue, setValues, newValue) => {
  if (isEmpty(element.onChangeSetValue) || !setFieldValue || !setValues) {
    return null;
  }

  const splittedControl = element.control.split('.');
  const deepValue = !!splittedControl[1];
  const control = deepValue ? `${splittedControl[0]}.${splittedControl[1]}` : splittedControl[0];
  const parentValues = getParentValues(values, element.control);

  const valuesChanged = element.onChangeSetValue.reduce((acc, key) => ({ ...acc, [key.control]: key.newValue }), {
    ...parentValues,
  });

  const newValues = { ...valuesChanged, [splittedControl[2] || splittedControl[0]]: newValue };

  if (!deepValue) {
    return setValues(newValues);
  }

  return setFieldValue(control, newValues);
};

export default setValueByJson;
