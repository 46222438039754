import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  studentFetchRequest: [],
  studentFetchSuccess: ['data'],
  studentFetchError: ['error'],

  studentSaveImageRequest: [
    'upImg',
    'croppedImg',
    'startX',
    'zoom',
    'startY',
    'width',
    'withRedirect',
    'redirect',
    'closeWindow',
  ],
  studentSaveImageSuccess: ['data'],
  studentSaveImageError: ['error'],

  studentClearImageRequest: ['firstName', 'lastName', 'redirect', 'closeWindow'],
  studentClearImageSuccess: ['data'],
  studentClearImageError: ['error'],

  studentDeleteAccountRequest: [],
  studentDeleteAccountSuccess: ['data'],
  studentDeleteAccountError: ['error'],

  studentChangePasswordRequest: ['oldPassword', 'newPassword'],
  studentChangePasswordSuccess: [],
  studentChangePasswordError: ['error'],

  studentAvailableLinesRequest: ['studentId'],
  studentAvailableLinesSuccess: ['availableLines'],
  studentAvailableLinesError: ['error'],

  studentFetchResumeCount: ['studentId'],
  studentSetResumeCount: ['resumeCount'],
  studentSetBlockResume: ['blockResume'],
  studentSetResumeError: ['error'],

  studentFindApplicantRequest: ['opportunityId'],
  studentFindApplicantSuccess: ['applicant'],
  studentFindApplicantError: ['error'],

  studentFindTempResumeRequest: ['student'],
  studentFindTempResumeSuccess: ['resumePdf'],
  studentFindTempResumeError: ['error'],

  studentCheckAssignmentValuesRequest: ['values'],
  studentCheckAssignmentValuesSuccess: ['verifiedValues'],
  studentCheckAssignmentValuesError: ['assignmentErrors'],

  studentsAlertsFetchRequest: [],
  studentsAlertsFetchSuccess: ['alerts'],
  studentsAlertsFetchError: ['error'],

  studentResumeStatusRequest: ['studentId'],
  studentResumeStatusSuccess: ['percentageOccupied'],
  studentResumeStatusError: ['resumeError'],

  studentCleanHighlightRequest: ['studentId'],
  studentCleanHighlightSuccess: ['student'],
  studentCleanHighlightError: ['error'],

  studentSet: ['data'],
  studentCleanErrors: [],
  studentCleanState: [],

  removeStudentAlertRequest: ['tag'],
  removeStudentAlertSuccess: ['alerts'],
  removeStudentAlertError: ['error'],

  removeStudentAlertsRequest: [],
  removeStudentAlertsSuccess: [],
  removeStudentAlertsError: ['error'],
});

export const studentTypes = Types;
export default Creators;
