import React, { useCallback, useMemo, CSSProperties } from 'react';
import ReactSelect, { ClearIndicatorProps, Props } from 'react-select';

import { ReactComponent as CancelIcon } from 'assets/svg/cancel.svg';
import { ErrorMessage } from 'components/v2/forms/components/error-message';
import { Option } from 'types/base';

import { Wrapper, StyledLabel } from './Select.styled';

interface SelectProps<T = Option> extends Props<T> {
  label?: string;
  tooltip?: string;
  error?: string;
  required?: boolean;
  filterOptionsBy?: keyof T;
}

const Select: React.FC<SelectProps> = ({
  className = '',
  name = '',
  label = '',
  error = '',
  placeholder = 'Select Option',
  required = false,
  isClearable = false,
  isMulti = false,
  tooltip = '',
  isDisabled,
  filterOptionsBy,
  value,
  options,
  ...props
}) => {
  let filteredOptions = options;

  if (filterOptionsBy) {
    if (isMulti && Array.isArray(value)) {
      const optionsToBeChecked = new Set(value?.map(option => option?.[filterOptionsBy]));

      filteredOptions = options?.filter(option => !optionsToBeChecked.has(option?.[filterOptionsBy]));
    } else {
      filteredOptions = options?.filter(option => option?.[filterOptionsBy] !== value?.[filterOptionsBy]);
    }
  }

  const customStyles = {
    valueContainer: provided => ({
      ...provided,
      padding: 0,
    }),
    singleValue: provided => ({
      ...provided,
      color: isDisabled ? '#4A485B' : '#fff',
    }),
    multiValue: provided => ({
      ...provided,
      backgroundColor: '#26243B',
      border: '1px solid #3B375B',
      marginRight: '8px',
    }),
    multiValueLabel: provided => ({
      ...provided,
      color: '#fff',
      fontSize: '16px',
      paddingLeft: '12px',
      paddingRight: '12px',
    }),
    multiValueRemove: provided => ({
      ...provided,
      color: '#FA4465',
      paddingLeft: '0',
      paddingRight: '12px',
      ':hover': {
        backgroundColor: 'transparent',
      },
    }),
    control: (provided, { isFocused, isDisabled }) => ({
      ...provided,
      borderColor: isFocused ? '#ffbe33' : isDisabled ? '#4A485B' : '#cecae2',
      borderRadius: '4px',
      backgroundColor: 'transparent',
      color: '#fff',
      fontFamily: 'Roboto, sans-serif',
      fontSize: '16px',
      lineHeight: '24px',
      padding: '9px 0 9px 16px',
      outline: 'none',
      width: '100%',
      boxShadow: 'none',
      ':hover': {
        borderColor: isFocused ? '#ffbe33' : '#cecae2',
        cursor: 'pointer',
      },
    }),
    input: provided => ({
      ...provided,
      margin: 0,
      padding: 0,
      color: '#fff',
      caretColor: '#fff',
    }),
    indicatorSeparator: provided => ({
      ...provided,
      backgroundColor: isDisabled ? '#4A485B' : '#cecae2',
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      color: `${state.selectProps.menuIsOpen ? '#ffbe33' : isDisabled ? '#4A485B' : '#cecae2'}`,
      paddingLeft: '16px',
      paddingRight: '16px',
      transition: 'transform 0.3s',
      transform: `rotate(${state.selectProps.menuIsOpen ? '180deg' : 0})`,
      ':hover': { borderColor: '#cecae2' },
    }),
    placeholder: provided => ({
      ...provided,
      margin: 0,
      color: isDisabled ? '#4A485B' : '#9896A5',
    }),
  };

  const customComponents = useMemo(
    () => ({
      ClearIndicator: (props: ClearIndicatorProps<Option>) => {
        const {
          getStyles,
          innerProps: { ref, ...restInnerProps },
        } = props;

        return (
          <div {...restInnerProps} ref={ref} style={getStyles('clearIndicator', props) as CSSProperties}>
            <CancelIcon />
          </div>
        );
      },
    }),
    []
  );

  const handleGetOptionLabel = useCallback(option => option?.name, []);
  const handleGetOptionValue = useCallback(option => option?.id, []);

  return (
    <Wrapper className={className}>
      {label && (
        <StyledLabel isDisabled={isDisabled} htmlFor={name} tooltip={tooltip} required={required} disabled={isDisabled}>
          {label}
        </StyledLabel>
      )}
      <ReactSelect
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        options={filteredOptions}
        getOptionLabel={handleGetOptionLabel}
        getOptionValue={handleGetOptionValue}
        styles={customStyles}
        components={customComponents}
        menuPlacement="auto"
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        {...props}
      />
      {error && <ErrorMessage error={error} />}
    </Wrapper>
  );
};

export default Select;
