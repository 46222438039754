/* eslint no-useless-escape: 0 */

export const NUMERIC = /^[0-9]*$/;

export const ALPHABETIC_WITH_DASH = /^[a-zA-ZÀ-ÖØ-öø-ÿ\-\u00f1\u00d1\u0027\s]*$/;

export const ALPHABETIC_WITH_DASH_AND_FULLSTOP = /^[a-zA-ZÀ-ÖØ-öø-ÿ\-\.\u00f1\u00d1\u0027\s]*$/;

export const ALPHABETIC_WITH_ACCENTS_AND_SPECIAL_CHARACTERS = /^[a-zA-ZÀ-ÖØ-öø-ÿ\u00f1\u00d1\u0027\s]*$/;

export const ALPHA_NUMERIC_WITH_ACCENTS_AND_SPECIAL_CHARACTERS = /^[0-9a-zA-ZÀ-ÖØ-öø-ÿ\-\u00f1\u00d1\u0027\s]*$/;

export const URL =
  /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;

export const URL_WITH_PROTOCOL =
  /^(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|http?:\/\/[a-zA-Z0-9]+\.[^\s]{2,})$/i;

export const LINKEDIN = /[a-zA-Z-0-9]*(\/)?$/g;

export const DATA =
  /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;

export const PASSWORD_REQUIREMENTS = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&-*]).{8,30}$/;

export const PASSWORD_NUMBER = /^(?=.*[0-9])/;

export const PASSWORD_LOWERCASE_LETTER = /^(?=.*[a-z])/;

export const PASSWORD_UPPERCASE_LETTER = /^(?=.*[A-Z])/;

export const PASSWORD_SPECIAL_CHARACTER = /^(?=.*[!@#\$%\^&\-\*])/;

export const DECIMAL_NUMBER_WITH_TENTHS_AND_HUNDREDTHS = /^\d+(.\d{1,2})?$/;

export const STRING_WITH_EMPTY_SPACE = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s]*$/;
