import hexagonFuchsia from 'assets/svg/hexagon-fuchsia.svg';
import hexagonGreen from 'assets/svg/hexagon-green.svg';
import hexagonGold from 'assets/svg/hexagon_gold.png';
import hexagonPurple from 'assets/svg/hexagon_purple.png';
import hexagonTeal from 'assets/svg/hexagon_teal_small.png';
import lockFuchsia from 'assets/svg/lock-fuchsia.svg';
import lockGreen from 'assets/svg/lock-green.svg';
import lockGold from 'assets/svg/lock_gold.png';
import lockPurple from 'assets/svg/lock_purple.svg';
import lockTeal from 'assets/svg/lock_teal.png';

const theme = {
  purple: {
    color: '#6a289a',
    accent: '#8b33cb',
    opposite: '#280f3a',
    background: 'linear-gradient(155.64deg, rgba(139, 53, 201, 0.85) -0.81%, rgba(0, 0, 0, 0.85) 101.15%), #000000',
    lightBackground: 'linear-gradient(177.87deg,#8b35c9 -0.81%,#8b35c9 16.33%,rgba(139, 53, 201, 0) 118.11%)',
    alternativeBackground:
      'radial-gradient(58.19% 63.13% at 5.94% -8.13%, #8B35C9 3%, #8B35C9 0.41%, rgba(29, 37, 38, 0.5) 130%)',
    iconHexagon: hexagonPurple,
    iconLock: lockPurple,
  },
  black: {
    color: '#000',
    accent: '#595959',
    opposite: '#252525',
    background: 'linear-gradient(155.18deg, #1d2526 -0.81%, #1d2526 16.33%, #000000 118.11%)',
    lightBackground: '#1D2526',
    alternativeBackground: '#000',
  },
  teal: {
    color: '#368D97',
    accent: '#49c0ce',
    opposite: '#1D2A2A',
    background: 'linear-gradient(155.64deg, rgba(92, 239, 255, 0.74) -0.81%, rgba(0, 0, 0, 0.74) 97.67%), #000000',
    lightBackground: 'linear-gradient(177.87deg, #53B7C2 -0.81%, #53B7C2 16.33%, rgba(83, 183, 194, 0) 118.11%)',
    alternativeBackground: 'radial-gradient(97.19% 89.13% at 25.94% 10.87%, #53B7C2 0%, rgba(29, 37, 38, 0.5) 67.19%)',
    iconHexagon: hexagonTeal,
    iconLock: lockTeal,
  },
  gold: {
    color: '#a98236',
    accent: '#d29e3b',
    opposite: '#1D2A2A',
    background:
      'linear-gradient(155.64deg, rgba(255, 180, 35, 0.85) -0.81%, rgba(255, 180, 35, 0.85) 16.33%, rgba(0, 0, 0, 0.85) 100.15%), #000000',
    lightBackground:
      'linear-gradient(177.87deg, rgba(255, 180, 35, 0.85) -0.81%, rgba(255, 180, 35, 0.85) 21.49%, rgba(255, 180, 35, 0) 100.15%)',
    alternativeBackground: 'radial-gradient(97.19% 89.13% at 25.94% 10.87%, #FFB423 0%, rgba(29, 37, 38, 0.5) 67.19%)',
    iconHexagon: hexagonGold,
    iconLock: lockGold,
  },
  green: {
    color: '#759664',
    accent: '#a4d38c',
    opposite: '#2d3328',
    background: 'linear-gradient(168.01deg, rgba(216, 255, 194, 0.93) -23.09%, rgba(0, 0, 0, 0.93) 102.37%), #000000',
    lightBackground: 'linear-gradient(168.01deg, #D8FFC2 -23.09%, #1D2526 102.37%)',
    alternativeBackground: 'radial-gradient(97.19% 89.13% at 25.94% 10.87%, #D8FFC2 0%, rgba(29, 37, 38, 0) 100%)',
    iconHexagon: hexagonGreen,
    iconLock: lockGreen,
  },
  fuchsia: {
    color: '#b224ab',
    accent: '#ec30e3',
    opposite: '#3d113b',
    background: 'linear-gradient(167.94deg, rgba(253, 76, 244, 0.82) -0.81%, rgba(0, 0, 0, 0.82) 100.61%), #000000',
    lightBackground: 'linear-gradient(167.94deg, #E82EDF -0.81%, #E82EDF 16.33%, #1D2526 118.11%)',
    alternativeBackground: 'radial-gradient(97.19% 89.13% at 25.94% 10.87%, #E82EDF 0%, rgba(29, 37, 38, 0) 100%)',
    iconHexagon: hexagonFuchsia,
    iconLock: lockFuchsia,
  },
  violet: {
    backgroundColor: 'var(--color-primary)',
    color: 'var(--color-primary)',
    linearGradient: 'var(--color-primary)',
    darkGradient: '',
    radialGradient: '',
    iconHexagon: hexagonFuchsia,
    iconLock: lockFuchsia,
  },
  newGreen: {
    accent: '#49c0ce',
    opposite: '#1D2A2A',
    color: 'var(--color-primary)',
    linearGradient:
      'linear-gradient(167.94deg, var(--color-primary) -0.81%, var(--color-primary) 16.33%, #000000 118.11%)',
    darkGradient: 'linear-gradient(167.94deg, rgba(253, 76, 244, 0.82) -0.81%, rgba(0, 0, 0, 0.82) 100.61%), #000000',
    radialGradient: 'radial-gradient(97.19% 89.13% at 25.94% 10.87%, #E82EDF 0%, rgba(29, 37, 38, 0) 100%)',
    iconHexagon: hexagonFuchsia,
    iconLock: lockFuchsia,
  },
  steel: {
    color: '#94a6a5',
    accent: '#c9e0de',
    opposite: '#2a2f2f',
    background:
      'linear-gradient(167.94deg, rgba(182, 204, 203, 0.85) -0.81%, rgba(182, 204, 203, 0.85) 16.33%, rgba(0, 0, 0, 0.85) 100.15%), #000000',
    lightBackground:
      'linear-gradient(167.94deg, rgba(182, 204, 203, 0.85) -0.81%, rgba(182, 204, 203, 0.85) 16.33%, rgba(0, 0, 0, 0.85) 100.15%)',
    alternativeBackground: 'radial-gradient(97.19% 89.13% at 25.94% 10.87%, #B6CCCB 0%, rgba(29, 37, 38, 0) 100%)',
    iconHexagon: hexagonGreen,
    iconLock: lockGreen,
  },
  dark: {
    accent: '#1f1d30',
    background: 'linear-gradient(180deg, #1f1d30 0%, #000000 100%)',
    lightBackground: '#2d2a39',
    color: '#636171',
  },
};

export default theme;
