import qualified from '@hivediversity/common-lib/assets/svg/qualified.svg';
import { nonSelectable } from '@hivediversity/common-lib/styled/css-helpers';
import styled, { css } from 'styled-components';

import placeholderLogo from 'assets/svg/placeholder-logo.svg';

export const Container = styled.div`
  justify-self: center;
  width: 100%;
  max-width: 700px;
  min-width: 100%;
  min-height: 200px;
  height: auto;
  margin-bottom: 16px;
  @media (max-width: 430px) {
    margin: 0 auto 16px auto;
    width: 100%;
  }
  ${nonSelectable}
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ isQualified }) => (isQualified ? '3px solid #FFBA1F' : 'transparent')};
  flex: 1;
  width: 100%;
  text-align: center;
  justify-content: space-between;
  @media screen and (max-width: 430px) {
    height: 520px;
  }
  border-radius: 8px;
  margin: 0;
  background-color: ${({ theme }) => theme.lightBackground};
  overflow: hidden;
  align-items: flex-start;
  padding: 12px;
  border: 3px solid transparent;
  transition: all 0.25s;
  min-height: 200px;
  height: auto;
  position: relative;
  @media (max-width: 430px) {
    height: auto;
    min-height: 200px;
  }
  ${({ isQualified }) =>
    isQualified &&
    css`
    border-color: var(--color-secondary);
    .company-pic {
    border-color: var(--color-secondary);
    },
    h3, .parentCompany {
    color: var(--color-white);
  },
  .duration, .description {
    color: var(--color-white);
  },
  `}
  ${({ state2 }) =>
    state2 &&
    css`
      border-color: var(--color-white);
      h3,
      .parentCompany {
        color: var(--color-white);
      }
      .duration,
      .description {
        color: var(--color-white);
      }
    `}
    ${({ state3 }) =>
    state3 &&
    css`
      border-color: var(--color-secondary);
    `}
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const WrapperContentCard = styled.div.attrs({ role: 'button' })`
  display: flex;
  cursor: pointer;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Title = styled.h3`
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 800;
  font-size: 17px;
  text-transform: capitalize;
  color: var(--color-white);
  margin-top: 8px;
  margin-bottom: 12px;
  text-align: left;
  display: block;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const DurationLeft = styled.label`
  font-family: 'Poppins', Arial, sans-serif;
  margin: 0 0 0 15px;
  font-weight: normal;
  font-size: 12px;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Deadline = styled.div`
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 14px;
  color: var(--color-white);
  line-height: 14px;
  margin: 0 8px 8px 0;
  font-weight: ${({ alert }) => (alert ? '700' : '500')};
  font-style: normal;
  background-color: #43404f;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 5px 10px;
  ${({ alert }) =>
    alert &&
    css`
      background-color: #febf32;
      border-color: #febf32;
      color: #2d2a39;
    `}
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const ContentAllDescriptionCard = styled.div`
  display: block;
  position: relative;
  margin-left: 10px;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const CardHeadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  color: var(--color-primary);
  text-transform: capitalize;
  font-weight: normal;
  height: auto;
  min-height: auto;
  justify-content: flex-start;
  > label {
    font-size: 14px;
    color: var(--color-white);
    margin-right: 10px;
    margin-left: 0;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const WrapperDescription = styled.div`
  display: -webkit-box;
  max-width: 750px;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-direction: column;
  flex: 1;
  height: 168px;
  color: #838485;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''};
`;
export const SeeMore = styled.span`
  color: #fdbf33;
  font-size: 12px;
  font-weight: 700;
  margin-bottom: ${({ marginBottom }) => marginBottom || '15px;'};
  margin-top: 10px;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Tag = styled.div`
  color: #fff;
  background-color: #43404f;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  margin: 0 5px 8px 0;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  letter-spacing: -0.015em;
  align-items: center;

  &.highlighted {
    border-color: #febf32;
    color: #febf32;
  }

  &.nonCapitalized {
    text-transform: none;
  }

  img {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Description = styled.p`
  word-break: break-word;
  position: relative;
  display: block;
  line-height: 1.3em;
  text-justify: distribute-center-last;
  margin: 0;
  padding: 0;
  font-size: 14px;
  color: #fff;
  @media screen and (max-width: 430px) {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.45;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const SubTitle = styled.p`
  word-break: break-word;
  text-align: center;
  margin: 0;
  font-size: 14px;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const WrapperTitle = styled.div`
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  color: white;
  @media screen and (max-width: 430px) {
    height: auto;
  }
  margin: 0;
  text-align: left;
  justify-content: flex-start;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Photo = styled.div`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 430px) {
    height: 120px;
  }
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  background-image: none;
  background-color: var(--primary-color-darker);
  height: 132px;
  width: 132px;
  position: relative;
  @media (max-width: 430px) {
    width: 75px;
    height: 75px;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Logo = styled.img.attrs(({ src }) => ({ alt: 'logo', src: src || placeholderLogo }))`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;
  bottom: -12px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Qualified = styled.span`
  font-size: 17px;
  font-weight: bold;
  color: var(--color-secondary);
  text-transform: capitalize;
  &::after {
    display: none;
  }
  @media (max-width: 430px) {
    width: auto;
    height: auto;
    font-size: 17px;
    &::after {
      content: url(${qualified});
      margin-top: 5px;
    }
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  height: inherit;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  cursor: pointer;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const TagsContentCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const ContentTagsCard = styled.div`
  display: flex;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const ContentPhotoCard = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 430px) {
    flex-direction: column;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Favoritebutton = styled.button.attrs({ type: 'button' })`
  display: flex;
  position: absolute;
  z-index: 1;
  right: 20px;
  border: none;
  background: transparent;
  img {
    image-rendering: pixelated;
  }
  @media (max-width: 430px) {
    right: 12px;
    top: 12px;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const ReviewLabel = styled.div`
  padding: 5px 10px;
  background-color: #43404f;
  border: 1px solid transparent;
  border-radius: 4px;
  font-family: Poppins, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  margin-right: 40px;
  margin-bottom: 8px;
  ${({ $bsStyle }) => $bsStyle || ''};
`;
