import { takeLatest, call, put, throttle } from 'redux-saga/effects';

import { emptyProgress } from 'constant';
import { updateProgress, fetchProgress } from 'services/progress';
import actionCreator, { progressTypes } from 'store/progress/actions';
import { getResponseError, i18n, isEmpty } from 'utils';

export function* fetch() {
  try {
    const response = yield call(fetchProgress);
    const error = yield getResponseError(response);

    if (!error) {
      const { progress } = response.data;
      yield put(actionCreator.progressFetchSuccess(!isEmpty(progress) ? progress : emptyProgress));
    } else {
      yield put(actionCreator.progressFetchError(error));
    }
  } catch (error) {
    yield put(actionCreator.progressFetchError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* update({ progress }) {
  try {
    const response = yield call(updateProgress, progress);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(actionCreator.progressUpdateSuccess(response.data.progress));
    } else {
      yield put(actionCreator.progressUpdateError(error));
    }
  } catch (error) {
    yield put(actionCreator.progressUpdateError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* progressSaga() {
  yield takeLatest(progressTypes.PROGRESS_FETCH_REQUEST, fetch);
  yield throttle(1000, progressTypes.PROGRESS_UPDATE_REQUEST, update);
}
