import { allLevelSteps } from 'pages/v2/level/levelSteps';

import http from './http';

export const saveLevelRequest = (stepName, values) => {
  const levelStep = allLevelSteps.find(({ name }) => name === stepName);
  let baseURL = `${process.env.REACT_APP_URL_BASE}`;

  // TODO update this once handling an API version logic is created
  if (levelStep?.levelName === '1A' || levelStep?.levelName === '1B') {
    baseURL = baseURL.replace('v1', 'api/v2');
  }

  if (levelStep) {
    return http.put(`/students/level/${levelStep.levelName}`, values, {
      baseURL,
    });
  }
};
