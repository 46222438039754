import listLevels from 'constant/list-levels';

const levels = [
  {
    id: listLevels.ONE,
    title: 'Be Yourself',
    description: 'Resume & Online Profiles',
    sections: [
      {
        id: listLevels.ONE_A,
        group: 1,
        subLevelType: 'On Paper',
        title: 'Resume: Header/Education',
        levelName: 'A',
      },
      {
        id: listLevels.ONE_B,
        group: 1,
        title: 'Resume: Skills and Interests',
        levelName: 'B',
      },
      {
        id: listLevels.ONE_C,
        group: 1,
        title: 'Resume: Experience',
        levelName: 'C',
      },
      {
        id: listLevels.ONE_D,
        group: 2,
        subLevelType: 'Online',
        title: 'Online Profile Completion',
        levelName: 'D',
      },
    ],
  },
  {
    id: listLevels.TWO,
    title: 'Be Informed',
    description: 'Discovering & Digging Deep Into Industries, Positions & Opportunities',
    levelName: '2',
  },
  {
    id: listLevels.THREE,
    title: 'Be Prepared',
    description: 'Organizing Content Internally Before Communicating Externally',
    levelName: '3',
  },
  {
    id: listLevels.FOUR,
    title: 'Be Professional',
    description: 'Delivering Content Effectively Using Communication Strategies & Interview Tips',
    levelName: '4',
  },
  {
    id: listLevels.SIX,
    title: 'Be Inclusive',
    description: 'Preparing for Diversity, Equity & Inclusion in the Workplace',
    levelName: '5',
  },
];

export default levels;
