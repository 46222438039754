import { createReducer } from 'reduxsauce';

import { staticDataTypes } from 'store/static-data/actions';
import { initialState } from 'store/static-data/initial-state';

const fetchRequest = state => ({
  ...state,
  staticData: initialState.data,
  isLoading: true,
  error: null,
});

const fetchSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
});

const fetchFailure = (state, { error }) => ({
  ...state,
  error,
  isLoading: initialState.data,
  staticData: initialState.data,
});

const reducer = createReducer(initialState, {
  [staticDataTypes.STATIC_DATA_FETCH_REQUEST]: fetchRequest,
  [staticDataTypes.STATIC_DATA_FETCH_SUCCESS]: fetchSuccess,
  [staticDataTypes.STATIC_DATA_FETCH_ERROR]: fetchFailure,
});

export default reducer;
