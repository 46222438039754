import _, { isEmpty, template } from 'lodash';

const hasEmptyValue = (values, optionals = [], validationToAdd) => {
  let invalid = false;

  if (!isEmpty(validationToAdd)) {
    for (const validation of validationToAdd) {
      const valid = template(validation.condition)({ _, item: values }) === 'true';
      if (!valid) {
        invalid = true;
        break;
      }
      optionals.push(validation.control);
    }
  }

  const valuesFiltered = Object.entries(values).filter(value =>
    optionals ? !optionals.includes(value[0]) && !value[1] : !value[1]
  );

  return invalid || !isEmpty(valuesFiltered);
};

export default hasEmptyValue;
