import { nanoid } from 'nanoid';
import * as yup from 'yup';

import { ExperienceLine } from 'store/v2/level/types';

export const MAX_CHARS = 109;
export const MAX_LINES_NUMBER = 4;
export const getDefaultLine: () => ExperienceLine = () => ({
  id: nanoid(6),
  value: '',
  enabled: true,
  isMerge: false,

  // the rest is for compatibility with old code
  chosen: false,
  high: false,
  isHighlighted: false,
  maxLength: MAX_CHARS,
  selected: false,
});

export const validationSchema = yup.object().shape({
  enhancedAcademicExperiences: yup.array().of(
    yup.object().shape({
      lines: yup
        .array()
        .of(
          yup.object().shape({
            value: yup.string(),
            enabled: yup.boolean(),
            isMerge: yup.boolean(),
          })
        )
        .test({
          name: 'value-required',
          message: 'Required',
          test: val => {
            if (Array.isArray(val)) {
              return val.every(({ value }, index) => {
                if (index === 0 || index === 1) {
                  return value !== undefined && value?.length >= 2;
                }

                if (!value) {
                  return false;
                }
                return true;
              });
            }

            if (Array.isArray(val)) {
              if (val.length > 2) {
                return false;
              }
            }
            return true;
          },
        }),
    })
  ),
});
