import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import size from 'lodash/size';

const countLinesHighlighted = student => {
  const professionalExperiences = get(student, 'professionalExperiences');
  const otherExperiences = get(student, 'otherExperiences');
  const professionalLines = professionalExperiences?.map(experience => {
    if (experience.activeLines) {
      return experience.lines?.filter(line => line.isHighlighted && line.enabled);
    }
    return null;
  });
  const otherLines = otherExperiences?.map(experience => {
    if (experience.activeLines) {
      return experience.lines?.filter(line => line.isHighlighted && line.enabled);
    }
    return null;
  });
  return size(compact(flatten(professionalLines))) + size(compact(flatten(otherLines)));
};

export default countLinesHighlighted;
