import { operatingSystem } from 'constant';

import getMobileOperatingSystem from './get-mobile-operating-system';

const isMobile = () => {
  const system = getMobileOperatingSystem();
  return (
    system === operatingSystem.ANDROID || system === operatingSystem.IOS || system === operatingSystem.WINDOWS_PHONE
  );
};

export default isMobile;
