import React from 'react';
import { ThemeProvider } from 'styled-components';

import logo from 'assets/png/hello-hive-logo.png';
import Head from 'components/head';
import { themesObj } from 'constant';

import { Wrapper, Container, Image } from './AuthTemplate.styled';

interface AuthTemplateProps {
  title: string;
}

const AuthTemplate: React.FC<AuthTemplateProps> = ({ title, children }) => (
  <ThemeProvider theme={themesObj.black}>
    <Head title={title} />
    <Wrapper>
      <Container>
        <Image src={logo} alt="Hive 5" />
        {children}
      </Container>
    </Wrapper>
  </ThemeProvider>
);

export default AuthTemplate;
