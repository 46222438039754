import React from 'react';

import { IconButton, IconPosition } from 'components/v2/buttons';

import { StyledIconButton } from './Remove.styled';

type RemoveButtonProps = Omit<React.ComponentProps<typeof IconButton>, 'icon' | 'position'>;

const RemoveButton: React.FC<RemoveButtonProps> = ({ children = 'Remove', ...rest }) => (
  <StyledIconButton icon="cross" position={IconPosition.right} {...rest}>
    {children}
  </StyledIconButton>
);

export default RemoveButton;
