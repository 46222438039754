import * as yup from 'yup';

import { STRING_WITH_EMPTY_SPACE } from 'constant/regex';
import { EnhancedAcademicExperience, ExperienceLine, ResearchAndProgramsFormValues } from 'store/v2/level/types';

export const baseInitialValues: EnhancedAcademicExperience = {
  program: '',
  international: false,
  remote: false,
  state: '',
  stateName: '',
  city: '',
  cityName: '',
  country: '',
  countryCity: '',
  // NaN is stringified to null
  year: NaN,
  periodOfStudy: '',
  isThroughoutCollege: false,
  includeOnResume: false,
  lines: [] as ExperienceLine[],
};
export const researchAndProgramsInitialValues: ResearchAndProgramsFormValues = {
  enhancedAcademicExperiences: [baseInitialValues],
};

export const validationSchema = yup.object().shape({
  enhancedAcademicExperiences: yup.array().of(
    yup.object().shape({
      program: yup
        .string()
        .nullable()
        .required('Required.')
        .min(2, 'Must be at least 2 characters')
        .max(42, 'Can be maximum 42 characters'),
      international: yup.boolean().nullable().required('Required.'),
      remote: yup.boolean().nullable().required('Required.'),
      state: yup
        .string()
        .nullable()
        .when(['international', 'remote'], {
          is: (international, remote) => !international && !remote,
          then: yup.string().nullable().required('Required'),
        }),
      city: yup
        .string()
        .nullable()
        .when(['international', 'remote'], {
          is: (international, remote) => !international && !remote,
          then: yup.string().nullable().required('Required'),
          otherwise: yup.string().nullable(),
        }),
      country: yup
        .string()
        .nullable()
        .when(['international', 'remote'], {
          is: (international, remote) => international && !remote,
          then: yup.string().nullable().required('Required'),
          otherwise: yup.string().nullable(),
        }),
      countryCity: yup
        .string()
        .nullable()
        .when(['international', 'remote'], {
          is: (international, remote) => international && !remote,
          then: yup
            .string()
            .nullable()
            .matches(STRING_WITH_EMPTY_SPACE, 'This field can only contain alphabetic characters')
            .required('Required'),
          otherwise: yup.string().nullable(),
        }),
      isThroughoutCollege: yup.boolean().nullable().required('Required.'),
      year: yup
        .string()
        .nullable()
        .when('isThroughoutCollege', {
          is: isThroughoutCollege => !isThroughoutCollege,
          then: yup.string().nullable().required('Required'),
          otherwise: yup.string().nullable(),
        }),
      periodOfStudy: yup
        .string()
        .nullable()
        .when('isThroughoutCollege', {
          is: isThroughoutCollege => !isThroughoutCollege,
          then: yup.string().nullable().required('Required'),
          otherwise: yup.string().nullable(),
        }),
      includeOnResume: yup.boolean().nullable().required('Required.'),
    })
  ),
});
