import isBoolean from 'lodash/isBoolean';
import mapValues from 'lodash/mapValues';

const buildQueryString = searchParams => {
  const params = new URLSearchParams();
  mapValues(searchParams, (value, key) => {
    if (value || isBoolean(value)) {
      params.set(key, value);
    }
  });
  return params.toString();
};

export default buildQueryString;
