import PropTypes from 'prop-types';

export default PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    time: PropTypes.string,
  })
);
