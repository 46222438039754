export default {
  WANT_INDUSTRIES: 'Want to see more personalized positions? Update your selected Industries of Interest below.',
  WANT_JOB_FUNCTIONS: 'Want to see more personalized positions? Update your selected Job Functions of Interest below.',
  WANT_CULTURE: 'Want to see more personalized positions? Update your selected Career Keywords below.',
  WANT_QUALIFIED:
    "Want to see more personalized positions? Update your work eligibility below, and make sure you've completed the HIVE5 Process.",
  WANT_FOLLOWING:
    'Want to see positions at companies you follow? You can follow companies you\'re interested in by clicking the "Follow" button on the company profile.',
  WANT_APPLIED:
    "Want to see positions you've already applied to?. Once you've applied to an position, it will show up in this dashboard.",
  WANT_SAVED:
    'Want to see your saved positions? You can save an position by clicking the bookmark icon on the position.',
};
