import checked from 'assets/svg/checked.svg';
import unChecked from 'assets/svg/unchecked.svg';
import { progressStatus } from 'constant';

const getLevelCheckIcon = (isActive, status) => {
  if (status === progressStatus.COMPLETE) {
    return checked;
  }
  if (status === progressStatus.UNLOCKED) {
    return unChecked;
  }
  return isActive ? unChecked : 'none';
};

export default getLevelCheckIcon;
