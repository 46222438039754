import styled from 'styled-components';

import { Stepper } from 'components/v2/stepper';

export const Wrapper = styled.div`
  background-image: linear-gradient(0deg, #121021, #121021), linear-gradient(180deg, #1f1d30 0%, #000 100%);
  flex-grow: 1;
  padding: 52px 0;
`;

export const Container = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;

  @media screen and (min-width: 992px) {
    max-width: 500px;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Image = styled.img`
  max-width: 80px;
  max-height: 78px;
`;

export const StyledStepper = styled(Stepper)`
  @media screen and (max-width: 991px) {
    margin: 32px 0;
  }
`;
