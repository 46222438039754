import * as Yup from 'yup';

import { LanguagesFormValues } from 'store/v2/level/types';

export const MAX_COUNT = 75;

export const languagesFormInitialValues: LanguagesFormValues = {
  language: null,
  languageName: '',
  proficiency: null,
  proficiencyName: '',
  languages: [],
};

export const validationSchema = Yup.object().shape({
  language: Yup.string().nullable(),
  languageName: Yup.string().nullable(),
  proficiency: Yup.string().nullable(),
  proficiencyName: Yup.string().nullable(),
});
