import * as Yup from 'yup';

import { PersonalInterstsFormValues } from 'store/v2/level/types';

export const personalInterestsFormInitialValues: PersonalInterstsFormValues = {
  personalInterest: '',
  personalInterests: [],
};

export const MAX_COUNT = 75;

export const validationSchema = Yup.object().shape({
  personalInterest: Yup.string().nullable(),
});
