import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Label, Select } from 'components/v2/forms/components';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  yearOptionsIsLoadingSelector,
  studyPeriodOptionsIsLoadingSelector,
  yearOptionsLoadingErrorSelector,
  studyPeriodOptionsLoadingErrorSelector,
  yearOptionsSelector,
  studyPeriodOptionsSelector,
} from 'store/v2/shared/selectors';
import { getOption } from 'utils';

interface TimeOfStudyInputProps {
  formId: string;
  data: { year: string; periodOfStudy: string };
  handleYearChange: (e: any) => void;
  handlePeriodChange: (e: any) => void;
  handleBlur: (e: any) => void;
  timeError: any; // TODO
}
const TimeOfStudyInput = (props: TimeOfStudyInputProps) => {
  const dispatch = useDispatch();

  const { data, formId, handleYearChange, handlePeriodChange, handleBlur, timeError } = props;

  const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
  const isStudyPeriodOptionsLoading = useSelector(studyPeriodOptionsIsLoadingSelector);

  const isYearOptionsLoadingError = useSelector(yearOptionsLoadingErrorSelector);
  const isStudyPeriodOptionsLoadingError = useSelector(studyPeriodOptionsLoadingErrorSelector);

  const yearOptions = useSelector(yearOptionsSelector);
  const studyPeriodOptions = useSelector(studyPeriodOptionsSelector);

  useEffect(() => {
    if (!isYearOptionsLoading && yearOptions.length === 0 && !isYearOptionsLoadingError) {
      dispatch(sharedActionsCreator.yearOptionsRequest());
    }
    if (!isStudyPeriodOptionsLoading && studyPeriodOptions.length === 0 && !isStudyPeriodOptionsLoadingError) {
      dispatch(sharedActionsCreator.studyPeriodOptionsRequest());
    }
  }, [yearOptions.length, isYearOptionsLoading, studyPeriodOptions.length, isStudyPeriodOptionsLoading]);

  const handleYearOptionChange = e => {
    handleYearChange(e);
  };

  const handlePeriodOptionChange = e => {
    handlePeriodChange(e);
  };
  return (
    <>
      <Label required>Time of Study</Label>
      <div className="col-6">
        <Select
          className="mb-0"
          name={`${formId}.year`}
          placeholder="Academic Year"
          required
          value={getOption(yearOptions, data.year, 'name')}
          error={timeError ? timeError.year?.name : ''} // TODO - check touched
          options={yearOptions}
          onChange={handleYearOptionChange}
          isLoading={isYearOptionsLoading}
          onBlur={handleBlur}
        />
      </div>
      <div className="col-6">
        <Select
          className="mb-0"
          name={`${formId}.periodOfStudy`}
          placeholder="Period of Study"
          required
          value={getOption(studyPeriodOptions, data.periodOfStudy, 'id')}
          error={timeError ? timeError.periodOfStudy?.name : ''}
          options={studyPeriodOptions}
          onChange={handlePeriodOptionChange}
          onBlur={handleBlur}
          isLoading={isStudyPeriodOptionsLoading}
        />
      </div>
    </>
  );
};

export default TimeOfStudyInput;
