import styled from 'styled-components';

import { Button } from 'components/v2/buttons';

export const Heading = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #ffbe33;
  margin-bottom: 8px;
`;

export const StyledButton = styled(Button)`
  border-radius: 8px;
  text-transform: capitalize;
  margin-top: 12px;
  margin-bottom: 0;
  width: auto;
  padding: 8px 15px 8px 12px;
  font-size: 16px;
  line-height: 23px;
`;

export const StyledButtonText = styled.span`
  margin-right: 11px;
`;

export const Paragraph = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  margin: 0;
  color: ${props => (props.active ? '#FFBE33' : '#fff')};
`;

export const HeaderDetails = styled(Paragraph)`
  margin-bottom: 32px;
`;

export const SubmittedHeader = styled(Paragraph)`
  margin-top: 48px !important;
  margin-bottom: 8px;
`;

export const SubmittedDetailsWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SubmittedDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .details {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #ffffff;
    margin: 0;
  }
`;

export const SubmittedValuesWrapper = styled.div`
  background: #121021;
  border: 1px solid #8d8ca5;
  border-radius: 4px;
  padding: 8px 16px 16px 26px;
  margin-top: 16px !important;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .length {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;

    display: flex;
    align-items: center;
    justify-content: flex-end;
    letter-spacing: 0.5px;

    color: #9896a5;
  }
`;

export const SubmittedValues = styled.div`
  padding: 12px 16px;
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
  border: ${props => (props.active ? '1px solid #FFBE33' : '1px solid transparent')};
  background: ${props => (props.active ? '#2A2123' : '#292739')};
  border-radius: 8px;

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    max-width: 90%;
  }

  .truncate {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }

  .svg-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    .svg-button {
      padding: 0;
      background: transparent;
      display: inline-flex;
      height: 24px;
      width: fit-content;
      border: none;
    }
  }
`;

export const SubmitedValueTextSecondary = styled.span`
  opacity: 0.5;
  padding-left: 8px;
`;

export const StyledFormNotification = styled.p`
  color: #fff;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin: 24px 0 0 0;
  padding-right: 58px;
`;

export const ErrorFormNotification = styled.p`
  color: #ef3f5f;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-size: 14 px;
  line-height: 24px;
  margin: 24px 0 0 0;
  padding-right: 58px;
`;

export const StepDescription = styled.p`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
`;

export const CharactersCounter = styled.div`
  text-align: right;
  color: ${props => (props.error ? '#ef3f5f' : '#9896a5')};
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
`;
