import React from 'react';

import { ButtonVariant, IconButton, IconPosition } from 'components/v2/buttons';

import { StyledIconButton } from './ForwardButton.styled';

type ForwardButtonProps = Omit<React.ComponentProps<typeof IconButton>, 'icon' | 'variant' | 'position'>;

const ForwardButton: React.FC<ForwardButtonProps> = ({ children = 'NEXT', ...props }) => (
  <StyledIconButton icon="rightArrow" variant={ButtonVariant.primary} position={IconPosition.right} {...props}>
    {children}
  </StyledIconButton>
);

export default ForwardButton;
