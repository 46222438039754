import styled from 'styled-components';
export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const FieldArrayHeader = styled.div`
  .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */

    color: #ffbe33;
  }
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .research-remove {
    padding-inline: 0.5rem;
  }
`;
