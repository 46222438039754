import { sectionsTags } from '@hivediversity/common-lib/constant';

import buildQueryString from './build-query-string';

const getRoutes = {
  applyFormDiversity: (opportunityId, params = {}) =>
    `/level/1B/diversities?redirect=opportunity/${opportunityId}&${buildQueryString(params)}`,
  applyFormCoursework: (opportunityId, params = {}) =>
    `/level/1A?screen=${sectionsTags.COURSEWORK}&origin=opportunity/${opportunityId}&${buildQueryString(params)}`,
  applyFormExperiences: (opportunityId, params = {}) =>
    `/level/1C?screen=${sectionsTags.BULLETS_BUZZ}&origin=opportunity/${opportunityId}&${buildQueryString(params)}`,
  applyFormUploadPhoto: (opportunityId, params = {}) => `/uploadPhoto?${buildQueryString(params)}`,
  goToLevel: levelId => `/level/${levelId}`,
  goToOpportunity: (opportunityId, params = {}) => `/opportunity/${opportunityId}?${buildQueryString(params)}`,
  goToOpportunities: params => `/opportunities?${buildQueryString(params)}`,
  goToCompany: (companyId, params) => `/company/${companyId}?${buildQueryString(params)}`,
  goToCompanies: params => `/companies?${buildQueryString(params)}`,
  goToWhyOurCompany: opportunityId => `/why-our-company/${opportunityId}`,
  goToMyResume: params => `/my-resume?${buildQueryString(params)}`,
  goToResumeSent: (opportunityId, params) => `/resume-sent/${opportunityId}?${buildQueryString(params)}`,
  goToProfileSent: (opportunityId, params) => `/profile-sent/${opportunityId}?${buildQueryString(params)}`,
  goToIndustries: params => `/level/PC?screen=industries&${buildQueryString(params)}&origin=dashboard`,
  goToFunctions: params => `/level/PC?screen=job_functions&${buildQueryString(params)}&origin=dashboard`,
  goToCulture: params => `/level/PC?screen=career_keywords&${buildQueryString(params)}&origin=dashboard`,
  goToDiversity: params => `/level/1B/diversities?redirect=dashboard&${buildQueryString(params)}`,
};

export default getRoutes;
