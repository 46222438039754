const opportunityTabs = ['description', 'qualifications', 'company'];
const companyTabs = ['details', 'positions'];

const opportunity = {
  DESCRIPTION: 'description',
  QUALIFICATIONS: 'qualifications',
  COMPANY: 'company',
};

const company = {
  DETAILS: 'details',
  POSITIONS: 'positions',
};

export default { opportunityTabs, companyTabs, opportunity, company };
