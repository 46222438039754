import { isBoolean, parseInt, isNumber } from 'lodash';

const parseToNumber = value => {
  if (!isBoolean(value) && !value) {
    return 0;
  }
  if (isBoolean(value)) {
    if (value) {
      return 1;
    }
    return 2;
  }
  if (isNumber(parseInt(value, 10))) {
    return parseInt(value, 10);
  }
  return 0;
};

export default parseToNumber;
