import { createReducer } from 'reduxsauce';

import { entities } from 'constant';
import { opportunityTypes } from 'store/opportunity/actions';
import { initialState } from 'store/opportunity/initial-state';

const searchRequest = (state, { filters }) => ({
  ...state,
  filters: {
    ...state.filters,
    ...filters,
  },
  isLoadingSearch: true,
  error: null,
});

const searchSuccess = (state, { opportunities, meta }) => ({
  ...state,
  data: {
    ...state.data,
    meta,
    opportunities: meta.current_page > 1 ? [...state.data?.opportunities, ...opportunities] : opportunities,
  },
  isLoadingSearch: initialState.isLoadingSearch,
  error: null,
});

const searchError = (state, { error }) => ({
  ...state,
  error,
  isLoadingSearch: initialState.isLoadingSearch,
});

const amountRequest = state => ({
  ...state,
  isLoadingAmount: true,
  error: initialState.error,
  amount: initialState.amount,
});

const amountSuccess = (state, { amount }) => ({
  ...state,
  amount,
  isLoadingAmount: initialState.isLoadingAmount,
  error: initialState.error,
});

const amountError = (state, { error }) => ({
  ...state,
  error,
  isLoadingAmount: initialState.isLoadingAmount,
  amount: initialState.amount,
});

const opportunityRequest = (state, { reload = true }) => ({
  ...state,
  isLoading: reload,
  error: initialState.error,
  opportunity: initialState.opportunity,
});

const opportunitySuccess = (state, { opportunity }) => ({
  ...state,
  opportunity,
  isLoading: initialState.isLoading,
  error: initialState.error,
});

const opportunityError = (state, { error }) => ({
  ...state,
  error,
  isLoading: initialState.isLoading,
  opportunity: initialState.opportunity,
});

const applyRequest = state => ({
  ...state,
  isLoadingApply: true,
  isLoadingApply2: true,
  errorApply: initialState.errorApply,
  data: null,
});

const applySuccess = (state, { applicant }) => {
  const { opportunity } = state;
  return {
    ...state,
    applicant,
    opportunity: { ...opportunity, applied: true },
    isLoadingApply: initialState.isLoadingApply,
    errorApply: initialState.errorApply,
  };
};

const applyError = (state, { errorApply }) => ({
  ...state,
  errorApply,
  isLoadingApply: initialState.isLoadingApply,
  data: null,
});

const togglesRequest = state => ({
  ...state,
  isLoadingToggles: true,
  error: initialState.error,
});

const togglesSuccess = (state, { data, opportunity }) => ({
  ...state,
  data,
  opportunity,
  isLoadingToggles: initialState.isLoadingToggles,
  error: initialState.error,
});

const togglesError = (state, { error }) => ({
  ...state,
  error,
  isLoadingToggles: initialState.isLoadingToggles,
});

const opportunitySetTagRequest = (state, { entity, opportunityId }) => ({
  ...state,
  isSettingTag: opportunityId,
  studentTagEntity: entity,
  error: null,
});

const opportunitySetTagSuccess = (state, { opportunity }) => {
  const entity = state.studentTagEntity;
  const data = state[entity]?.data;
  if (!data) {
    return { ...state, opportunity, error: null, isSettingTag: false };
  }
  const cloneData = state[entity].data.slice();
  const opportunityIndex = cloneData.findIndex(datum => datum.id === opportunity.id);
  if (entity === entities.SUBMITTED && !opportunity.studentTag) {
    delete cloneData[opportunityIndex];
  } else {
    cloneData[opportunityIndex] = opportunity;
  }
  return {
    ...state,
    isSettingTag: false,
    [entity]: { ...state[entity], data: cloneData },
    error: null,
    studentTagEntity: null,
  };
};

const opportunitySetTagError = (state, { error }) => ({
  ...state,
  isSettingTag: false,
  error,
  studentTagEntity: null,
});

const cleanOpportunity = state => ({
  ...state,
  error: initialState.error,
  opportunity: initialState.opportunity,
});

const setQueryParams = (state, { showApplyModal }) => ({
  ...state,
  params: { showApplyModal },
});

const reducer = createReducer(initialState, {
  [opportunityTypes.OPPORTUNITY_SEARCH_REQUEST]: searchRequest,
  [opportunityTypes.OPPORTUNITY_SEARCH_SUCCESS]: searchSuccess,
  [opportunityTypes.OPPORTUNITY_SEARCH_ERROR]: searchError,

  [opportunityTypes.OPPORTUNITY_FETCH_AMOUNT_REQUEST]: amountRequest,
  [opportunityTypes.OPPORTUNITY_FETCH_AMOUNT_SUCCESS]: amountSuccess,
  [opportunityTypes.OPPORTUNITY_FETCH_AMOUNT_ERROR]: amountError,

  [opportunityTypes.OPPORTUNITY_FIND_REQUEST]: opportunityRequest,
  [opportunityTypes.OPPORTUNITY_FIND_SUCCESS]: opportunitySuccess,
  [opportunityTypes.OPPORTUNITY_FIND_ERROR]: opportunityError,

  [opportunityTypes.OPPORTUNITY_APPLY_REQUEST]: applyRequest,
  [opportunityTypes.OPPORTUNITY_APPLY_SUCCESS]: applySuccess,
  [opportunityTypes.OPPORTUNITY_APPLY_ERROR]: applyError,

  [opportunityTypes.OPPORTUNITY_TOGGLES_REQUEST]: togglesRequest,
  [opportunityTypes.OPPORTUNITY_TOGGLES_SUCCESS]: togglesSuccess,
  [opportunityTypes.OPPORTUNITY_TOGGLES_ERROR]: togglesError,

  [opportunityTypes.OPPORTUNITY_SET_TAG_REQUEST]: opportunitySetTagRequest,
  [opportunityTypes.OPPORTUNITY_SET_TAG_SUCCESS]: opportunitySetTagSuccess,
  [opportunityTypes.OPPORTUNITY_SET_TAG_ERROR]: opportunitySetTagError,

  [opportunityTypes.CLEAN_OPPORTUNITY]: cleanOpportunity,

  [opportunityTypes.SET_QUERY_PARAMS]: setQueryParams,
});

export default reducer;
