const getFormattedObject = (defaultObject, fillObject) => {
  return Object.keys(defaultObject).reduce(
    (accumulator, key) => ({
      ...accumulator,
      [key]: fillObject[key] || defaultObject[key],
    }),
    {}
  );
};

export default getFormattedObject;
