import React from 'react';

import { Step, Steps } from './NamedStepper.styled';

interface Step {
  name: string;
  label: string;
  isCompleted: boolean;
  isDisabled?: boolean;
}

interface NamedStepperProps {
  activeStep: string;
  steps: readonly Step[];
  onStepChange?: (step: Step) => void;
  className?: string;
}

const NamedStepper: React.FC<NamedStepperProps> = ({ steps, activeStep, onStepChange, className = '' }) => (
  <Steps className={className}>
    {steps.map(step => (
      <Step
        key={step.name}
        className={activeStep === step.name ? 'active' : step.isCompleted && !step?.isDisabled ? 'completed' : ''}
        onClick={() => {
          if (onStepChange && step.isCompleted && !step?.isDisabled) {
            onStepChange(step);
          }
        }}
      >
        {step.label}
      </Step>
    ))}
  </Steps>
);

export default NamedStepper;
