import { PhoneNumberUtil } from 'google-libphonenumber';
import * as Yup from 'yup';

import { regionCode } from 'constant';
import { ContactInformationFormValues } from 'store/v2/register/types';
import { i18n } from 'utils';

const phoneUtil = PhoneNumberUtil.getInstance();

export const contactInformationInitialValues: ContactInformationFormValues = {
  email: '',
  alternativeEmail: '',
  phone: '',
  receiveUpdates: false,
  linkedInURL: '',
};

export const schema = Yup.object().shape({
  email: Yup.string()
    .email(i18n.t('register.validations.EMAIL_INVALID'))
    .required(i18n.t('register.validations.EMAIL_REQUIRED')),
  alternativeEmail: Yup.string()
    .email(i18n.t('register.validations.EMAIL_INVALID'))
    .required(i18n.t('register.validations.EMAIL_REQUIRED')),
  phone: Yup.string().test('USPhoneValidation', i18n.t('register.validations.ENTER_VALID_PHONE'), value => {
    const lengthPhone = value ? value.length : 0;
    if (lengthPhone < 10) {
      return false;
    }
    try {
      let isValid = false;
      for (const code of regionCode) {
        const result = phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, code), code);
        if (result) {
          isValid = true;
          break;
        }
      }
      return isValid;
    } catch (e) {
      return false;
    }
  }),
  receiveUpdates: Yup.boolean().required('Required.'),
});
