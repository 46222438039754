import React from 'react';

import { StyledRadio, StyledLabel, Wrapper, Container, OptionName } from './Radio.styled';

interface RadioProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string;
  optionName?: string;
}

const Radio: React.FC<RadioProps> = ({
  className = '',
  name = '',
  label = '',
  optionName = '',
  required = false,
  disabled = false,
  ...props
}) => (
  <Wrapper className={className}>
    <StyledLabel required={required} disabled={disabled}>
      {label}
      <Container>
        <StyledRadio type="radio" name={name} required={required} disabled={disabled} {...props} />
        {optionName && <OptionName aria-disabled={disabled}>{optionName}</OptionName>}
      </Container>
    </StyledLabel>
  </Wrapper>
);

export default Radio;
