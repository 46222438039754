const exampleAssignments = [
  [
    { company: 'Work 1', industry: 'IT', position: 'dev' },
    { company: 'Work 2', industry: 'IT', position: 'dev' },
  ],
  [
    { group: 'Leadership', position: 'ship', location: 'ship', startDate: '2019', endDate: '2020' },
    { group: 'Activity', position: 'ship', location: 'ship', startDate: '2019', endDate: '2020' },
  ],
];

export default exampleAssignments;
