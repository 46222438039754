import React from 'react';

import { StyledButton } from './LinkButton.styled';

interface LinkButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

const LinkButton: React.FC<LinkButtonProps> = ({ children, type = 'button', ...props }) => (
  <StyledButton type={type} {...props}>
    {children}
  </StyledButton>
);

export default LinkButton;
