import { isEmpty, isNaN, each, clone, filter } from 'lodash';

// Function that checks and enables the correct flow, used on first render and later to calculate the amount steps
// TODO make immutable, it was not done due to screen refresh problems.
const getEnableFlow = ({ sections, store }) => {
  const enableSections = clone(sections);
  const newSections = each(enableSections, section => {
    if (section.form && !isEmpty(section.form)) {
      each(section.form, element => {
        if (element.enableScreens && !isEmpty(element.enableScreens) && store[element.control]) {
          each(element.enableScreens, subElement => {
            const parseStored = parseInt(store[element.control], 10);
            if (subElement.values.includes(!isNaN(parseStored) ? parseStored : 1)) {
              const sectionToEnable = enableSections[subElement.id - 1];
              if (sectionToEnable) {
                sectionToEnable.disabled = false;
              }
            }
          });
        }
      });
    }
  });

  return filter(newSections, section => !section.disabled);
};

export default getEnableFlow;
