import defaultTheme from './default-theme';

const getTheme = (sectionTheme, themes, section) => {
  if (!themes) {
    return defaultTheme;
  }
  if (sectionTheme) {
    return themes[sectionTheme] || defaultTheme;
  }
  return themes[section.theme] || defaultTheme;
};

export default getTheme;
