import { buildQueryString, getVerbPastToggle } from 'utils';

import http, { getOrCached } from './http';

export const requestSearch = filters => {
  return getOrCached(`/students/companies/search?${buildQueryString(filters)}`);
};

export const requestCompany = companyId => {
  return getOrCached(`/students/companies/${companyId}`);
};

export const requestCompanyOpportunities = (companyId, searchParams, isLogged) => {
  return getOrCached(
    isLogged
      ? `/students/companies/${companyId}/opportunities?${buildQueryString(searchParams)}`
      : `/companies/${companyId}/opportunities?${buildQueryString(searchParams)}`
  );
};

export const requestToggles = (companyId, status, entity) => {
  return http.post(`/students/companies/${companyId}/${entity}`, {
    [getVerbPastToggle(entity)]: status,
  });
};
