import { companyProps, getBandName, groupIndustriesByVertical } from '@hivediversity/common-lib/utils';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { customStyles, operatingSystem, flatStyles, opportunityStyles } from 'constant';
import companyCreator from 'store/company/actions';
import { getRoutes, getMobileOperatingSystem } from 'utils';

import {
  Container,
  Name,
  Description,
  SubTitle,
  WrapperDescription,
  Photo,
  BodyHeader,
  Wrapper,
  Body,
  Content,
  TagsContentCards,
  Tag,
  ContentTagsCard,
  ContentPhotoCard,
  WrapperContentCard,
  FollowButton,
} from './styled';

const CompanyCard = ({ company, params }) => {
  const dispatch = useDispatch();
  const industries = groupIndustriesByVertical(company.industries);
  const system = getMobileOperatingSystem();
  const isIOS = system === operatingSystem.IOS;
  const handleClick = () => dispatch(push(getRoutes.goToCompany(company.id, params)));

  const handleFollow = () => {
    return dispatch(companyCreator.companyTogglesRequest(company, 'follow'));
  };

  return (
    <Container key={`${company.id}-container`} $bsStyle={flatStyles.NewCards}>
      <Wrapper>
        <WrapperContentCard>
          <ContentPhotoCard>
            <Photo src={company.logoUrl} />
            <FollowButton className={company?.followed ? 'followed' : ''} onClick={handleFollow}>
              {company?.followed ? 'Following' : 'Follow'}
            </FollowButton>
          </ContentPhotoCard>
          <Body>
            <Content role="button" onClick={handleClick}>
              <BodyHeader $isIOS={isIOS}>
                <Name>{company.name}</Name>
                {company.headquarterLocation && (
                  <>
                    {company.headquarterLocation?.international ? (
                      <SubTitle>
                        {`${company.headquarterLocation?.country?.country}, ${company.headquarterLocation?.cityName} (HQ)`}
                      </SubTitle>
                    ) : (
                      <SubTitle>
                        {`${company.headquarterLocation?.city?.city}, ${company.headquarterLocation?.state?.state_code} (HQ)`}
                      </SubTitle>
                    )}
                  </>
                )}
                {industries.length > 0 &&
                  industries.map(industry => (
                    <div key={industry.name} style={{ display: 'inline' }}>
                      <SubTitle key={industry.name} $bsStyle={{ marginRight: '3px' }}>
                        {`${industry.name}:`}
                      </SubTitle>
                      {industry.results.map((result, index) => (
                        <SubTitle
                          key={`${result.verticalId}${result.vertical}${result.industry}`}
                          $bsStyle={customStyles.displayInline}
                        >
                          {`${
                            index > 0
                              ? `${result.industry}${industry?.results.length - 1 > index ? ',' : ''}`
                              : `${result.industry}${industry?.results.length - 1 > index ? ',' : ''} `
                          }`}
                        </SubTitle>
                      ))}
                    </div>
                  ))}
              </BodyHeader>
              <WrapperDescription>
                <Description>
                  {company.overallShortStatement ? company.overallShortStatement : 'No description'}
                </Description>
              </WrapperDescription>
            </Content>
          </Body>
        </WrapperContentCard>
        <ContentTagsCard>
          <TagsContentCards>
            {company.totalQualifiedFor ? (
              <Tag className="highlighted" $bsStyle={opportunityStyles.Tag}>
                {getBandName(company.totalQualifiedFor, 'Qualified')}
              </Tag>
            ) : null}
            {company.totalOpportunities ? (
              <Tag $bsStyle={opportunityStyles.Tag}>
                {getBandName(company.totalOpportunities, company.totalOpportunities > 1 ? 'Positions' : 'Position')}
              </Tag>
            ) : null}
          </TagsContentCards>
        </ContentTagsCard>
      </Wrapper>
    </Container>
  );
};

CompanyCard.propTypes = {
  company: companyProps.isRequired,
  params: PropTypes.shape({
    origin: PropTypes.string,
    perPage: PropTypes.number,
    entity: PropTypes.string,
  }),
};

CompanyCard.defaultProps = {
  params: null,
};

export default CompanyCard;
