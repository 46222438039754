import { PhoneNumberUtil } from 'google-libphonenumber';

const getFormatPhone = phone => {
  if (!phone) {
    return '';
  }
  const phoneUtil = PhoneNumberUtil.getInstance();
  const phoneNumber = phoneUtil.parseAndKeepRawInput(phone, 'US');
  return phoneUtil.formatInOriginalFormat(phoneNumber, 'US');
};

export default getFormatPhone;
