import { isEmpty, max, find, concat, filter, isEqual } from 'lodash';

import { progressStatus, listLevels } from 'constant';
import { newDateSQLFormat } from 'utils/date-format';

const getFinished = (finishedAt, status) => {
  if (finishedAt) {
    return finishedAt;
  }
  return status === progressStatus.COMPLETE ? newDateSQLFormat() : null;
};

const appendToProgress = ({
  previousProgress,
  breakpoint,
  typeId,
  levelId,
  screen = 0,
  status,
  createdAt = '',
  updatedAt = '',
  finishedAt = '',
}) => {
  // If it is not a level
  if (!levelId) {
    return { ...previousProgress, breakpoint, createdAt, updatedAt, finishedAt };
  }

  // The levels is empty array when it is the first level
  if (levelId && isEmpty(previousProgress.levels)) {
    return {
      levels: [
        {
          typeId,
          level: levelId,
          screen,
          status,
          createdAt: newDateSQLFormat(),
          updatedAt: newDateSQLFormat(),
          finishedAt: null,
        },
      ],
      breakpoint,
    };
  }

  // On next it does not have an empty levels array
  // First filter the array by the levelId and typeId,
  // if it does not find a value it creates it (or update it).
  const levelsCopy = previousProgress.levels
    .slice()
    .filter((v, i, a) => a.findIndex(t => t.level === v.level && t.typeId === v.typeId) === i);

  let progressToUpdate;
  if (
    levelId === listLevels.ONE_A ||
    levelId === listLevels.ONE_B ||
    levelId === listLevels.ONE_C ||
    levelId === listLevels.PC
  ) {
    progressToUpdate = find(levelsCopy, l => l.level === levelId);
  } else {
    progressToUpdate = find(levelsCopy, l => l.level === levelId && l.typeId === typeId);
  }

  const filteredLevels = filter(levelsCopy, l => !isEqual(l, progressToUpdate));
  // If is empty, created this level on the progress levels
  if (!progressToUpdate) {
    return {
      ...previousProgress,
      levels: concat(levelsCopy, {
        typeId,
        level: levelId,
        screen,
        status,
        createdAt: newDateSQLFormat(),
        updatedAt: newDateSQLFormat(),
        finishedAt: status === progressStatus.COMPLETE ? newDateSQLFormat() : null,
      }),
    };
  }

  // If it already exists remove level progress and create a new
  const newLevelProgress = {
    typeId: progressToUpdate.typeId,
    level: levelId,
    screen: max([progressToUpdate.screen, screen]),
    status:
      status === progressStatus.COMPLETE
        ? progressStatus.COMPLETE
        : progressToUpdate.status || progressStatus.INCOMPLETE,
    createdAt: progressToUpdate.createdAt || newDateSQLFormat(),
    updatedAt: newDateSQLFormat(),
    finishedAt: getFinished(progressToUpdate.finishedAt, status),
  };
  let levelsWithoutNewLevel;
  if (
    newLevelProgress.level === listLevels.ONE_A ||
    newLevelProgress.level === listLevels.ONE_B ||
    newLevelProgress.level === listLevels.ONE_C ||
    newLevelProgress.level === listLevels.PC
  ) {
    levelsWithoutNewLevel = filteredLevels.filter(f => f.level !== newLevelProgress.level);
  } else {
    levelsWithoutNewLevel = filteredLevels;
  }

  return { ...previousProgress, levels: concat(levelsWithoutNewLevel, newLevelProgress) };
};

export default appendToProgress;
