import React from 'react';
import ReactTooltip, { TooltipProps } from 'react-tooltip';

import { ReactComponent as TooltipIcon } from 'assets/svg/tooltip-icon.svg';

import { TooltipWrapper } from './Tooltip.styled';

interface Props extends TooltipProps {
  text: string;
  className?: string;
  tooltipClassName?: string;
  html?: boolean;
}

const Tooltip: React.FC<Props> = ({
  text,
  id,
  className = '',
  tooltipClassName = '',
  html = false,
  effect = 'solid',
  ...rest
}) => {
  return (
    <TooltipWrapper className={className} data-for={id} data-tip={text} data-html={html}>
      <TooltipIcon />
      <ReactTooltip
        id={id}
        className={`tooltip ${tooltipClassName}`}
        html={html}
        delayHide={html ? 500 : 200}
        clickable={html}
        effect={effect}
        {...rest}
      />
    </TooltipWrapper>
  );
};

export default Tooltip;
