import { origins } from 'constant';

const getAlertsByLevel = (alerts, level, origin) => {
  if (origin === origins.HOME) {
    return alerts;
  }
  if (origin === origins.LEVELS) {
    return alerts.filter(alert => alert.level.substr(0, 1) === level || alert.level === level);
  }
  return [];
};

export default getAlertsByLevel;
