import { CloseButton, CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import { noop } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import helpBee from 'assets/svg/help_bee@2x.svg';
import { CustomModal } from 'components';
import { messageBee, customStyles } from 'constant';

import { Container, Wrapper, Logo, WrapperButtons, Message, Title } from './styled';

const BeeConfirm = ({
  showModal,
  onClose,
  onSubmit,
  text,
  title,
  textSubmitButton,
  textCancelButton,
  showCancel,
  showConfirm,
  showTitle,
  showText,
  children,
}) => (
  <CustomModal isActive={showModal}>
    <Container>
      <Wrapper>
        <Logo role="button">
          <img src={helpBee} alt="helpbee" />
        </Logo>
        <CloseButton onClick={onClose} />
        {showTitle && <Title>{title}</Title>}
        {showText && /<\/?[a-z][\s\S]*>/i.test(text) && <Message dangerouslySetInnerHTML={{ __html: text }} />}
        {showText && !/<\/?[a-z][\s\S]*>/i.test(text) && <Message>{text}</Message>}
        {children}
        {(showCancel || showConfirm) && (
          <WrapperButtons>
            {showCancel && (
              <CustomButton color={colors.RED} onClick={onClose} $bsStyle={customStyles.maxWidth160}>
                {textCancelButton}
              </CustomButton>
            )}
            {showConfirm && (
              <CustomButton color={colors.VIOLET} onClick={onSubmit} $bsStyle={customStyles.maxWidth160}>
                {textSubmitButton}
              </CustomButton>
            )}
          </WrapperButtons>
        )}
      </Wrapper>
    </Container>
  </CustomModal>
);

BeeConfirm.propTypes = {
  showModal: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
  textSubmitButton: PropTypes.string,
  textCancelButton: PropTypes.string,
  showCancel: PropTypes.bool,
  showConfirm: PropTypes.bool,
  showTitle: PropTypes.bool,
  showText: PropTypes.bool,
};

BeeConfirm.defaultProps = {
  showModal: null,
  showTitle: true,
  showConfirm: true,
  showText: true,
  onSubmit: noop,
  onClose: noop,
  text: messageBee.EXIT_MESSAGE,
  title: messageBee.DEFAULT_TITLE,
  textSubmitButton: "Let's Go",
  textCancelButton: 'Cancel',
  showCancel: false,
};

export default BeeConfirm;
