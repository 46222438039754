import { opportunityForEnums } from '@hivediversity/common-lib/constant';
import { opportunityProps } from '@hivediversity/common-lib/utils';
import PropTypes from 'prop-types';
import React from 'react';

import { ParentCompany } from './styled';

const CompanyName = ({ opportunity, isDesktop }) => (
  <ParentCompany isDesktop={isDesktop}>
    {opportunity?.opportunityFor === opportunityForEnums.PARENT_COMPANY && opportunity?.company?.name}
    {opportunity?.opportunityFor === opportunityForEnums.SUBSIDIARY ||
      (opportunity?.opportunityFor === opportunityForEnums.INTERNAL_DIVISIONS && (
        <>
          <span>{opportunity?.opportunityForName}, </span>
          <i>{opportunity?.company?.name}</i>
        </>
      ))}
    {!opportunity?.opportunityFor && opportunity?.company?.name}
  </ParentCompany>
);

CompanyName.propTypes = {
  opportunity: opportunityProps.isRequired,
  isDesktop: PropTypes.bool,
};

CompanyName.defaultProps = {
  isDesktop: false,
};

export default CompanyName;
