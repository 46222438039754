import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  progressFetchRequest: [null],
  progressFetchSuccess: ['data'],
  progressFetchError: ['error'],

  progressUpdateRequest: ['progress'],
  progressUpdateSuccess: ['data'],
  progressUpdateError: ['error'],

  setProgress: ['progress'],
});

export const progressTypes = Types;
export default Creators;
