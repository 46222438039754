import styled from 'styled-components';

import { Paper } from 'components/v2/paper';

const titleColor = '#FFBE33';
const textColor = '#fff';

export const ExperienceFormWrapper = styled(Paper)`
  margin-bottom: 24px;
`;

export const ExperienceFormHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const ExperienceFormTitle = styled.div`
  color: ${titleColor};
`;
export const ExperienceFormSubTitle = styled.div`
  color: ${textColor};
`;

export const TextboxWrapper = styled.div`
  margin-bottom: 24px;
  color: ${textColor};
`;

export const TextboxHeader = styled.div`
  margin: 8px 0;

  .line-actions-box {
    text-align: right;
  }
`;

export const HeaderAction = styled.button`
  background: transparent;
  border: none;
  margin-left: 16px;
  cursor: pointer;
  display: inline-block;
`;

export const CharactersCounter = styled.div`
  text-align: right;
  color: #9896a5;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
`;

export const Textbox = styled.div`
  border: 1px solid #cecae2;
  border-radius: 4px;
  padding: 8px;
  min-height: ${props => (props.isMerge ? '180px' : '100px')};
  margin-bottom: 16px;
  padding-bottom: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Textarea = styled.textarea`
  background: transparent;
  border: none;
  width: 100%;
  color: #fff;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  padding: 4px 24px;
  resize: none;
  min-height: 100px;
  height: 100%;
  flex-grow: 4;

  &:focus {
    outline: none;
    border: none;
  }
`;

export const CombineButton = styled.button`
  align-items: center;
  color: ${textColor};
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #1c1a2b;
  border-radius: 4px;
  border: 1px solid ${textColor};
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  min-height: 40px;
  padding: 8px 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
`;

export const TextCheckbox = styled.div`
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 16px;
  left: -12px;
  background: #1c1a2b;

  & div {
    margin: 0;
  }
`;

export const RemoveButton = styled.button`
  position: absolute;
  cursor: pointer;
  left: -13px;
  top: 64px;
  background: #1c1a2b;
  border: none;
  text-align: center;
  padding: 3px 4px;
`;
