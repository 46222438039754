import { useEffect, useLayoutEffect, useState } from 'react';

import { entities } from 'constant';
import { isEmpty } from 'utils';

const usePageScroll = (metadata, entityArray, filters, setFilters, showFilters) => {
  const [scrollYPosition, setScrollYPosition] = useState(0);

  const handleScroll = () => {
    if (showFilters) {
      return;
    }
    const container = document.getElementById('sidebar-container');
    if (container) {
      setScrollYPosition(container.scrollTop);
      const bottom =
        container.scrollTop > 0 && Math.ceil(container.scrollTop + container.clientHeight) >= container.scrollHeight;
      if (metadata?.last_page > metadata?.current_page && bottom) {
        setFilters(prevState => ({ ...prevState, page: metadata.current_page + 1 }));
      }
    }
  };

  useEffect(() => {
    if (metadata && !isEmpty(entityArray)) {
      window.addEventListener('scroll', handleScroll, true);
    }
    if (filters.page === 1) {
      setScrollYPosition(0);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, [metadata, entityArray]);

  useLayoutEffect(() => {
    const cards = document.getElementById(entities.OPPORTUNITIES) || document.getElementById(entities.COMPANIES);
    if (scrollYPosition > 0) {
      cards.scrollTo(0, scrollYPosition);
    }
  }, [scrollYPosition]);
};

export default usePageScroll;
