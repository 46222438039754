import * as Yup from 'yup';

import { DiversityFormValues } from 'store/v2/level/types';

export const diversityFormInitialValues: DiversityFormValues = {
  category: null,
  categoryName: '',
  otherStatus: '',
  status: null,
  statusName: '',
  diversity: [],
};

export const MAX_COUNT = 170;

export const validationSchema = Yup.object().shape({
  category: Yup.string().nullable(),
  categoryName: Yup.string().nullable(),
  status: Yup.number().nullable(),
  statusName: Yup.string().nullable(),
  added: Yup.number().nullable(),
  otherStatus: Yup.string().nullable(),
});
