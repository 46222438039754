import get from 'lodash/get';

import { isEmpty } from 'utils';

const getParentValues = (values, control) => {
  const splittedControl = control?.split('.');
  if (isEmpty(splittedControl)) {
    return values;
  }
  return get(values, `${splittedControl[0]}[${splittedControl[1]}]`) || values;
};

export default getParentValues;
