import { getIn, useFormikContext } from 'formik';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { IconButton, IconPosition } from 'components/v2/buttons';
import { Checkbox, FormRow, Input, Label, Select } from 'components/v2/forms/components';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS } from 'constant';
import levelActionsCreator from 'store/v2/level/actions';
import { CollegiateAthleticsFormValues } from 'store/v2/level/types';
import {
  periodRangesOptionsIsLoadingSelector,
  periodRangesOptionsSelector,
  sportsOptionsIsLoadingSelector,
  sportsOptionsSelector,
  universityOptionsIsLoadingSelector,
  universityOptionsSelector,
  yearOptionsIsLoadingSelector,
  yearOptionsSelector,
} from 'store/v2/shared/selectors';
import { getOption } from 'utils';

import { FieldArrayHeader } from './CollegiateAthletics.styled';

type Props = {
  index: number;
  remove: (index: number) => void;
};

const PRESENT_OPTION_ID = 5;

const CollegiateAthleticsForm: React.FC<Props> = ({ index, remove }) => {
  const { values, touched, errors, handleBlur, handleChange, setFieldValue, setFieldTouched } =
    useFormikContext<CollegiateAthleticsFormValues>();

  const dispatch = useDispatch();
  const baseName = `d1Athletics.${index}`;
  const indexValue = values.d1Athletics[index];

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.collegiateAthletics, values));
  }, 600);

  // ** university options
  const isUniversityOptionsLoading = useSelector(universityOptionsIsLoadingSelector);
  const universityOptions = useSelector(universityOptionsSelector);

  // ** sport options
  const isSportsOptionsLoading = useSelector(sportsOptionsIsLoadingSelector);
  const sportsOptions = useSelector(sportsOptionsSelector);

  // ** period range options
  const isPeriodRangesOptionsLoading = useSelector(periodRangesOptionsIsLoadingSelector);
  const periodRangesOptions = useSelector(periodRangesOptionsSelector);

  const startPeriodRangesOptions = useMemo(
    () => periodRangesOptions.filter(({ id }) => id !== PRESENT_OPTION_ID),
    [periodRangesOptions]
  );

  // * year options
  const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
  const yearOptions = useSelector(yearOptionsSelector);

  const getError = React.useCallback(
    (key: string) => {
      return getIn(touched, key) && getIn(errors, key) ? getIn(errors, key) : '';
    },
    [touched, errors, getIn]
  );

  const handleGeneralOptionChange = React.useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value?.id, true);
      setFieldTouched(metaData?.name, true, true);
    },
    [setFieldValue]
  );

  const handleUniversityOptionChange = React.useCallback(
    (value, metaData) => {
      handleGeneralOptionChange(value, metaData);
      setFieldValue(`${baseName}.universityName`, value?.name, true);
    },
    [setFieldValue, baseName, handleGeneralOptionChange]
  );

  const handleSportOptionChange = React.useCallback(
    (value, metaData) => {
      handleGeneralOptionChange(value, metaData);
      setFieldValue(`${baseName}.sportName`, value?.name, true);
    },
    [setFieldValue, baseName, handleGeneralOptionChange]
  );

  const handleStartPeriodOptionChange = React.useCallback(
    (value, metaData) => {
      handleGeneralOptionChange(value, metaData);
      setFieldValue(`${baseName}.startPeriodName`, value?.name, true);
    },
    [setFieldValue, baseName, handleGeneralOptionChange]
  );

  const handleEndPeriodOptionChange = React.useCallback(
    (value, metaData) => {
      setFieldValue(`${baseName}.endPeriodName`, value?.name, true);

      if (value.id === PRESENT_OPTION_ID) {
        setFieldValue(`${baseName}.endYear`, null, true);
      }

      handleGeneralOptionChange(value, metaData);
    },
    [setFieldValue, baseName, handleGeneralOptionChange]
  );

  React.useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  return (
    <div>
      <FieldArrayHeader>
        <span className="text">Sport #{index + 1}</span>
        {values.d1Athletics.length > 1 ? (
          <IconButton className="remove" position={IconPosition.right} icon="cross" onClick={() => remove(index)}>
            Remove
          </IconButton>
        ) : null}
      </FieldArrayHeader>
      <Paper>
        <FormRow>
          <Select
            name={`${baseName}.university`}
            label="University/School"
            placeholder="Name of University/School (Write In)"
            value={getOption(universityOptions, indexValue?.university, 'id')}
            onChange={handleUniversityOptionChange}
            error={getError(`${baseName}.university`)}
            options={universityOptions}
            isLoading={isUniversityOptionsLoading}
            required
          />
        </FormRow>
        <FormRow>
          <Select
            name={`${baseName}.sport`}
            label="Sport"
            placeholder="Name of Sport"
            value={getOption(sportsOptions, indexValue?.sport, 'id')}
            onChange={handleSportOptionChange}
            error={getError(`${baseName}.sport`)}
            options={sportsOptions}
            isLoading={isSportsOptionsLoading}
            required
          />
          <div className="col-12">
            <Checkbox
              name={`${baseName}.isDivisionOne`}
              optionName="Division 1"
              onBlur={handleBlur}
              onChange={handleChange}
              checked={indexValue.isDivisionOne}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="col-12">
            <Label required>Duration</Label>
          </div>
          <div className="col-6">
            <Select
              name={`${baseName}.startPeriod`}
              placeholder="Start Period"
              value={getOption(startPeriodRangesOptions, indexValue?.startPeriod, 'id')}
              onChange={handleStartPeriodOptionChange}
              error={getError(`${baseName}.startPeriod`)}
              options={startPeriodRangesOptions}
              isLoading={isPeriodRangesOptionsLoading}
              required
            />
          </div>
          <div className="col-6">
            <Select
              name={`${baseName}.startYear`}
              placeholder="Start Year"
              value={getOption(yearOptions, indexValue?.startYear, 'id')}
              onChange={handleGeneralOptionChange}
              error={getError(`${baseName}.startYear`)}
              options={yearOptions}
              isLoading={isYearOptionsLoading}
              required
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="col-6">
            <Select
              name={`${baseName}.endPeriod`}
              placeholder="End Period"
              value={getOption(periodRangesOptions, indexValue?.endPeriod, 'id')}
              onChange={handleEndPeriodOptionChange}
              error={getError(`${baseName}.endPeriod`)}
              options={periodRangesOptions}
              isLoading={isPeriodRangesOptionsLoading}
              required
            />
          </div>
          {indexValue?.endPeriod !== PRESENT_OPTION_ID && (
            <div className="col-6">
              <Select
                name={`${baseName}.endYear`}
                placeholder="End Year"
                value={getOption(yearOptions, indexValue?.endYear, 'id')}
                onChange={handleGeneralOptionChange}
                error={getError(`${baseName}.endYear`)}
                options={yearOptions}
                isLoading={isYearOptionsLoading}
                required
              />
            </div>
          )}
        </FormRow>
        <FormRow>
          <Input
            name={`${baseName}.position`}
            label="Position"
            placeholder="Position"
            value={indexValue?.position}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getError(`${baseName}.position`)}
            required
          />
        </FormRow>
        <FormRow>
          <Input
            name={`${baseName}.leadership`}
            label="Leadership Title"
            placeholder="Leadership Title (Optional)"
            value={indexValue?.leadership}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getError(`${baseName}.leadership`)}
          />
        </FormRow>
        <FormRow>
          <Checkbox
            name={`${baseName}.includeOnResume`}
            optionName="Include on resume and profile."
            onBlur={handleBlur}
            onChange={handleChange}
            checked={indexValue.includeOnResume}
          />
        </FormRow>
      </Paper>
    </div>
  );
};

export default CollegiateAthleticsForm;
