import { filter, findIndex, last } from 'lodash';

const getLastStep = (sections, screen) => {
  const sectionsFlow = sections.filter(s => !s.disabled);
  const lastScreenEdit = screen ? last(filter(sections, s => s.tag === screen)) : sections[sections.length - 2];
  const indexLastScreenEdit = findIndex(sections, s => s.id === lastScreenEdit.id);

  return findIndex(sectionsFlow, s => s.id === sections[indexLastScreenEdit + 1].id);
};

export default getLastStep;
