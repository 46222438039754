export const routeNames = {
  LOGIN: 'Sign-in',
  REGISTER_NAME: 'Sign Up | Name',
  REGISTER_EMAIL: 'Sign Up | Email',
  REGISTER_ALTERNATE_EMAIL: 'Sign Up | Alternate Email',
  REGISTER_HEAR_ABOUT_US: 'Sign Up | Hear About Us',
  REGISTER_UNIVERSITY: 'Sign Up | University',
  REGISTER_PASSWORD: 'Sign Up | Password',
  REGISTER_PHONE: 'Sign Up | Phone',
  REGISTER_CONFIRM_EMAIL: 'Sign Up | Confirm Email',
  REGISTER_THANK_YOU: 'Sign Up | Thank You',
  REGISTER_TOKEN: 'Sign-up | validation',
  IMPERSONATE: 'Impersonate',
  RECOVERY: 'Forgot-password',
  RECOVERY_TOKEN: 'Forgot-password | token',
  VERIFY_EMAIL_CODE: 'Verify Email',
  OPPORTUNITY_OPPORTUNITY_ID: 'Opportunity',
  DASHBOARD: 'Home',
  LEVELS: 'Getting Started',
  LEVEL_ID: id => `Getting Started | Step ${id}`,
  LEVELS_CONGRATS: 'Getting Started | Complete',
  LEVELS_ID_ALL_SET: id => `Getting Started | Step ${id} | Complete`,
  PROFILE: 'Profile',
  MY_ACCOUNT: 'My Account',
  UPLOAD_PHOTO: 'Upload Photo',
  MY_RESUME: 'My Resume',
  DIVERSITY: 'My Background',
  COMPANY_COMPANY_ID: 'Company',
  RESUME_SENT_OPPORTUNITY_ID: 'Resume Sent',
  PROFILE_SENT_OPPORTUNITY_ID: 'Profile Sent',
  WHY_OUR_COMPANY_OPPORTUNITY_NAME: 'Why us? | Company',
  ONBOARDING: 'HelloHive | Onboarding',
};
