import React from 'react';

import { Step, Steps } from './Stepper.styled';

interface Step {
  name: string;
  isCompleted: boolean;
}

interface StepperProps {
  activeStep: string;
  steps: readonly Step[];
  onStepChange?: (step: Step) => void;
  className?: string;
}

const Stepper: React.FC<StepperProps> = ({ steps, activeStep, onStepChange, className = '' }) => (
  <Steps className={className}>
    {steps.map(step => (
      <Step
        key={step.name}
        className={activeStep === step.name ? 'active' : step.isCompleted ? 'completed' : ''}
        onClick={() => {
          if (onStepChange && step.isCompleted) {
            onStepChange(step);
          }
        }}
      />
    ))}
  </Steps>
);

export default Stepper;
