import { noBoxShadow, nonSelectable } from '@hivediversity/common-lib/styled/css-helpers';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  z-index: 9999;
  left: 0;
  right: 0;
  height: 54px;
  bottom: 10px;

  .hidden {
    display: none;
  }

  @media screen and (min-width: 1024px) {
    bottom: 10px;
  }
  .d-none {
    display: none !important;
  }
  .footer-form-builder {
    height: 100%;
    justify-content: center;
    .hive-preview {
      display: ${({ blackFooter }) => (blackFooter ? 'none' : 'flex')};
    }
    .home-btn {
      left: 33%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      margin: 0 10px;
      padding-bottom: 3px;
      button {
        background: transparent;
        border: none;
        outline: none;
        &:active {
          outline: none;
        }
      }
    }
    .prev-btn {
      padding-right: 25px;
    }
    .next-btn {
      padding-left: 10px;
    }
  }
  .next-btn {
    button {
      width: 46px;
      height: 46px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: linear-gradient(125.71deg, #8b35c9 -7.85%, rgba(255, 255, 255, 0) 142.18%), #53b7c2;
      border: none transparent;
      img {
        width: 24px;
        height: 24px;
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: 'FlipH';
      }
    }
  }
  #nextPrev {
    button {
      width: 88px;
      height: 46px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      background: transparent;
      border: 1px solid #fdbf33;
      img {
        width: 9px;
        height: 9px;
        margin-left: 3px;
        margin-bottom: 0.5px;
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: 'FlipH';
      }
    }
  }
  .prev-btn {
    button {
      width: 46px;
      height: 46px;
      display: flex;
      flex-direction: column;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: linear-gradient(125.71deg, #8b35c9 -7.85%, rgba(255, 255, 255, 0) 142.18%), #53b7c2;
      border: none transparent;
      img {
        width: 24px;
        height: 24px;
        margin-right: 3px;
        margin-bottom: 0.5px;
      }
    }
  }
  #prevNext {
    button {
      width: 88px;
      height: 46px;
      display: flex;
      flex-direction: row;
      border: none;
      align-items: center;
      justify-content: center;
      border-radius: 24px;
      background: transparent;
      border: 1px solid #fdbf33;
      img {
        width: 9px;
        height: 9px;
        margin-right: 3px;
        margin-bottom: 0.5px;
      }
    }
  }
  .dialog-description {
    max-width: 600px;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'Gentleman', Arial, sans-serif;
    font-weight: 500;
    text-align: center;
  }
  &.content-steps-footer--black {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0 14px;
    z-index: 9999999;
    display: block;
    width: 100%;
    height: 78px;
    align-items: center;
    background: linear-gradient(177.46deg, #1d2526 -0.81%, #1d2526 16.33%, #000000 118.11%);
    .footer-form-builder {
      display: flex;
      margin: 0 auto;
      max-width: 372px;
      width: 100%;
      align-items: center;
      .prev-btn {
        margin-right: auto;
        button {
          background: transparent;
          border: none;
          outline: none;
          &:active,
          :focus {
            outline: 0;
            -webkit-box-shadow: none;
            box-shadow: none;
          }
        }
      }
      .home-btn {
        width: 54px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:active,
        :focus {
          outline: 0;
          -webkit-box-shadow: none;
          box-shadow: none;
        }
      }
      .hive-logo {
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        img {
          width: 54px;
          height: 47px;
          object-fit: contain;
        }
      }
      .hive-info {
        left: 67%;
        top: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        margin-left: 8px;
        img {
          width: 30px;
          height: 30px;
          object-fit: contain;
        }
      }
    }
  }
`;
export const ButtonSpan = styled.span`
  color: #fdbf33;
  font-weight: bold;
  font-size: 11px;
  white-space: nowrap;
`;
export const HiveLogo = styled.div.attrs({ role: 'button' })`
  width: 54px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer;
  ${noBoxShadow};
  &.open {
    position: absolute;
    top: -25px;
  }
  img {
    width: 54px;
    height: 47px;
  }
`;

export const HiveInfo = styled.div.attrs({ role: 'button' })`
  width: 62px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  cursor: pointer;
  ${noBoxShadow};
  img {
    width: 62px;
    height: 55px;
  }
`;

export const HivePreview = styled.div.attrs({ role: 'button' })`
  display: none;
  width: 47px;
  height: 47px;
  align-items: center;
  margin: 0 10px 0 0;
  color: #f4c152;
  min-width: 54px;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  ${noBoxShadow};
  img {
    width: 37px;
    height: 37px;
  }
`;

export const Button = styled.button.attrs(({ type }) => ({ type: type || 'button' }))`
  &:active {
    filter: brightness(0.7);
    opacity: 0.9;
  }
  ${noBoxShadow};
`;

export const DialogTitle = styled.div`
  display: block;
  margin-bottom: 14px;
  padding-bottom: 15px;
  max-width: 600px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  h6 {
    color: #ffd633;
    margin-bottom: 0;
    text-align: center;
  }
  &:before {
    content: '';
    display: block;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: 20px;
    right: 20px;
    background: #222;
  }
`;

export const WrapperBeeInactive = styled.div`
  opacity: 0.2;
`;

export const Image = styled.img.attrs({ draggable: 'false' })`
  ${nonSelectable};
`;
