import PropTypes from 'prop-types';

export default PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  isUpdate: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  error: PropTypes.string,
  actionType: PropTypes.string,
  progress: PropTypes.shape({ screen: PropTypes.number, status: PropTypes.string }),
});
