import styled from 'styled-components';

export const StyledLabel = styled.label`
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;
  margin-bottom: 8px;

  &[aria-disabled='true'] {
    color: #4a485b;
  }

  &[aria-disabled='false'] {
    cursor: pointer;
  }

  & .tooltip-wrapper {
    margin-left: 8px;
  }

  @media screen and (max-width: 767px) {
    & .tooltip {
      max-width: 250px;
    }
  }
`;

export const Required = styled.span`
  color: #ffbe33;
  font-size: 16px;
  margin-left: 4px;

  &[aria-disabled='true'] {
    color: #4a485b;
  }
`;
