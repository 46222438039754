import styled from 'styled-components';

export const StyledButton = styled.button`
  border: 1px solid transparent;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  padding: 15px 24px;
  position: relative;
  text-transform: uppercase;
  width: 100%;

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &:focus {
    box-shadow: 0 0 10px 1px #ffbe33;
  }

  &.primary {
    background-color: #febf32;
    color: #09080d;
  }

  &.secondary,
  &.text {
    background-color: transparent;
    color: #febf32;
  }

  &.secondary {
    border-color: #febf32;
  }

  &.basic {
    background-color: #2b2837;
    color: #fff;
  }
`;

export const LoaderWrapper = styled.div`
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0;
  left: 0;
`;
