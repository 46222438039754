import PropTypes from 'prop-types';
import React from 'react';

import { CheckField, Label, Container, WrapperCheck, CheckboxLabel } from './styled';

const Check = ({ name, centered, label, backgroundCheck, value, onChange, $bsStyle, labelStyle, alignTopCheck }) => {
  const handleChange = e => {
    e.preventDefault();
    onChange(name, !value);
  };

  return (
    <Container centered={centered} $bsStyle={$bsStyle}>
      <WrapperCheck key={name}>
        <Label htmlFor={name} name={name} alignTopcheck={alignTopCheck} onClick={handleChange}>
          <CheckField
            type="checkbox"
            checked={value}
            {...{ name, backgroundCheck }}
            onChange={() => {
              // eslint-disable-line no-empty-function
            }}
          />
          <span />
          <CheckboxLabel labelStyle={labelStyle}>{label}</CheckboxLabel>
        </Label>
      </WrapperCheck>
    </Container>
  );
};

Check.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  centered: PropTypes.bool,
  alignTopCheck: PropTypes.bool,
  backgroundCheck: PropTypes.string,
  $bsStyle: PropTypes.shape({}),
  labelStyle: PropTypes.shape({}),
};

Check.defaultProps = {
  centered: false,
  alignTopCheck: false,
  backgroundCheck: null,
  $bsStyle: undefined,
  labelStyle: {},
};

export default Check;
