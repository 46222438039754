import React from 'react';

import { ReactComponent as checkbox } from 'assets/svg/checkbox.svg';
import { ReactComponent as cross } from 'assets/svg/cross.svg';
import { ReactComponent as leftArrow } from 'assets/svg/left-arrow.svg';
import { ReactComponent as leftChevron } from 'assets/svg/left-chevron.svg';
import { ReactComponent as plus } from 'assets/svg/plus.svg';
import { ReactComponent as resumePreview } from 'assets/svg/resume-preview.svg';
import { ReactComponent as rightArrow } from 'assets/svg/right-arrow.svg';
import { ReactComponent as trash } from 'assets/svg/trash.svg';
import { Button, ButtonVariant } from 'components/v2/buttons';

import { StyledButton, StyledIconWrapper } from './IconButton.styled';

export const enum IconPosition {
  left = 'left',
  right = 'right',
}

const icons = {
  leftChevron,
  leftArrow,
  rightArrow,
  resumePreview,
  plus,
  cross,
  checkbox,
  trash,
};

interface IconButtonProps extends React.ComponentProps<typeof Button> {
  icon: keyof typeof icons;
  hideIcon?: boolean;
  onIconClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void;
  position?: IconPosition;
}

const IconButton: React.FC<IconButtonProps> = ({
  onClick,
  onIconClick,
  icon,
  hideIcon = false,
  variant = ButtonVariant.text,
  position = IconPosition.left,
  className = '',
  children = '',
  ...props
}) => {
  const Icon = icons[icon];

  return (
    <StyledButton className={className} variant={variant} onClick={onClick} {...props}>
      {!hideIcon && (
        <StyledIconWrapper className={position} onClick={onIconClick}>
          <Icon />
        </StyledIconWrapper>
      )}
      {children}
    </StyledButton>
  );
};

export default IconButton;
