import { createSelector } from 'reselect';

import { SharedState } from './types';

export const sharedStateSelector: (state) => SharedState = state => state.v2.shared;

export const heardFromUsOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.heardFromUsOptions.isLoading || false
);

export const heardFromUsOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.heardFromUsOptions.options || []
);

export const heardFromUsSubOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.heardFromUsSubOptions.isLoading || false
);

export const heardFromUsOptionsLoadErrorSelector = createSelector(
  sharedStateSelector,
  state => state.heardFromUsOptions.error || ''
);

export const heardFromUsSubOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.heardFromUsSubOptions.options || []
);

export const universityOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.universityOptions.isLoading || false
);

export const universityOptionsLoadingErrorSelector = createSelector(
  sharedStateSelector,
  state => state.universityOptions.error || ''
);

export const universityOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.universityOptions.options || []
);
export const diversityOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.diversityOptions.isLoading || false
);

export const diversityOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.diversityOptions.options || []
);
export const diversitySubOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.diversitySubOptions.isLoading || false
);

export const diversitySubOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.diversitySubOptions.options || []
);

export const yearOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.yearOptions.isLoading || false
);

export const yearOptionsLoadingErrorSelector = createSelector(
  sharedStateSelector,
  state => state.yearOptions.error || ''
);

export const yearOptionsSelector = createSelector(sharedStateSelector, state => state.yearOptions.options || []);

export const studyPeriodOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.studyPeriodOptions.options || []
);

export const studyPeriodOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.studyPeriodOptions.isLoading || false
);

export const studyPeriodOptionsLoadingErrorSelector = createSelector(
  sharedStateSelector,
  state => state.studyPeriodOptions.error || ''
);

export const locationStatesSelector = createSelector(sharedStateSelector, state => state.statesOptions.options || []);

export const locationStatesIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.statesOptions.isLoading || false
);

export const locationStatesErrorSelector = createSelector(
  sharedStateSelector,
  state => state.statesOptions.error || ''
);

export const locationCitiesSelector = createSelector(sharedStateSelector, state => state.citiesOptions.options || []);

export const locationCitiesIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.citiesOptions.isLoading || false
);

export const locationSchoolCitiesSelector = createSelector(
  sharedStateSelector,
  state => state.schoolStateCitiesOptions.options || []
);

export const locationSchoolCitiesIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.schoolStateCitiesOptions.isLoading || false
);

export const locationCountriesSelector = createSelector(
  sharedStateSelector,
  state => state.countriesOptions.options || []
);

export const locationCountriesIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.countriesOptions.isLoading || false
);
export const majorCategoriesOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.majorCategoriesOptions.options || []
);

export const majorCategoriesOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.majorCategoriesOptions.isLoading || false
);
export const majorCategoriesOptionsErrorSelector = createSelector(
  sharedStateSelector,
  state => state.majorCategoriesOptions.error || ''
);

export const bachelorDegreeOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.bachelorDegreeOptions.isLoading || false
);

export const bachelorDegreeOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.bachelorDegreeOptions.options || []
);

export const bachelorDegreeOptionsErrorSelector = createSelector(
  sharedStateSelector,
  state => state.bachelorDegreeOptions.error || ''
);

export const masterDegreeOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.masterDegreeOptions.isLoading || false
);

export const masterDegreeOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.masterDegreeOptions.options || []
);

export const masterDegreeOptionsErrorSelector = createSelector(
  sharedStateSelector,
  state => state.masterDegreeOptions.error || ''
);

export const majorsOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.majorsOptions.isLoading || false
);

export const majorsOptionsSelector = createSelector(sharedStateSelector, state => state.majorsOptions.options || []);

export const majorsOptionsErrorSelector = createSelector(sharedStateSelector, state => state.majorsOptions.error || '');

export const minorsOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.minorsOptions.isLoading || false
);

export const minorsOptionsSelector = createSelector(sharedStateSelector, state => state.minorsOptions.options || []);

export const minorsOptionsErrorSelector = createSelector(sharedStateSelector, state => state.minorsOptions.error || '');

export const periodRangesOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.periodRangesOptions.isLoading || false
);

export const periodRangesOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.periodRangesOptions.options || []
);

export const periodRangesOptionsErrorSelector = createSelector(
  sharedStateSelector,
  state => state.periodRangesOptions.error || ''
);

export const sportsOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.sportsOptions.isLoading || false
);

export const sportsOptionsSelector = createSelector(sharedStateSelector, state => state.sportsOptions.options || []);

export const sportsOptionsErrorSelector = createSelector(sharedStateSelector, state => state.sportsOptions.error || '');

export const registerCheckEmailExistSelector = createSelector(sharedStateSelector, state => state.checkEmail.exists);

export const registerCheckEmailExistMessageSelector = createSelector(
  sharedStateSelector,
  state => state.checkEmail.message
);

export const registerCheckEmailIsLoading = createSelector(sharedStateSelector, state => state.checkEmail.isLoading);

export const degreeTypeOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.degreeTypeOptions.isLoading || false
);

export const degreeTypeOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.degreeTypeOptions.options || []
);

export const degreeTypeOptionsErrorSelector = createSelector(
  sharedStateSelector,
  state => state.degreeTypeOptions.error || ''
);

export const periodOfStudiesOptionsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.periodOfStudiesOptions.isLoading || false
);

export const periodOfStudiesOptionsLoadingErrorSelector = createSelector(
  sharedStateSelector,
  state => state.periodOfStudiesOptions.error || ''
);

export const periodOfStudiesOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.periodOfStudiesOptions.options || []
);

export const technicalSkillLevelsOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.skillLevelsOptions.options || []
);

export const technicalSkillLevelsIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.skillLevelsOptions.isLoading || false
);

export const technicalSkillCategoriesOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.skillCategoryOptions.options || []
);

export const technicalSkillCategoriesIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.skillCategoryOptions.isLoading || false
);

export const languageProficiencyOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.languageProficiencyOptions.options || []
);

export const languageProficiencyIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.languageProficiencyOptions.isLoading || false
);

export const languageOptionsSelector = createSelector(
  sharedStateSelector,
  state => state.languageOptions.options || []
);

export const languageIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.languageOptions.isLoading || false
);

export const diversityIsLoadingSelector = createSelector(
  sharedStateSelector,
  state => state.diversityOptions.isLoading || false
);

export const cacheStateCitiesSelector = (id: string) =>
  createSelector(sharedStateSelector, state => state.cachedData.states[id]?.options || []);
export const cacheStateCitiesIsLoadingSelector = (id: string) =>
  createSelector(sharedStateSelector, state => state.cachedData.states[id]?.isLoading || false);
export const cacheStateCitiesFailureSelector = (id: string) =>
  createSelector(sharedStateSelector, state => state.cachedData.states[id]?.error || '');
