import hexagon from 'assets/svg/hexagon2.svg';
import lock from 'assets/svg/lock.svg';

const defaultTheme = {
  color: 'grey',
  background: 'linear-gradient(167.94deg, #1D2526 -0.81%, #1D2526 16.33%, #000000 118.11%)',
  lightBackground: '#1D2526',
  alternativeBackground: 'linear-gradient(167.94deg, #1D2526 -0.81%, #1D2526 16.33%, #000000 118.11%)',
  iconHexagon: hexagon,
  iconLock: lock,
  accentColor: '#757575',
};

export default defaultTheme;
