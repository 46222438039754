import { PhoneNumberUtil } from 'google-libphonenumber';
import * as Yup from 'yup';

import { regionCode } from 'constant';
import { ALPHABETIC_WITH_DASH, ALPHABETIC_WITH_DASH_AND_FULLSTOP, STRING_WITH_EMPTY_SPACE } from 'constant/regex';
import { i18n } from 'utils';

const phoneUtil = PhoneNumberUtil.getInstance();

const requiredMessage = 'This field is required';

export const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .nullable()
    .min(2, i18n.t('register.validations.FIRST_NAME_MINIMUM'))
    .matches(ALPHABETIC_WITH_DASH, {
      message: i18n.t('register.validations.FIRST_NAME_INVALID'),
      excludeEmptyString: true,
    })
    .required(i18n.t('register.validations.FIRST_NAME_REQUIRED')),
  lastName: Yup.string()
    .nullable()
    .min(2, i18n.t('register.validations.LAST_NAME_MINIMUM'))
    .matches(ALPHABETIC_WITH_DASH, {
      message: i18n.t('register.validations.LAST_NAME_INVALID'),
      excludeEmptyString: true,
    })
    .required(i18n.t('register.validations.LAST_NAME_REQUIRED')),
  middleName: Yup.string()
    .nullable()
    .min(2, i18n.t('register.validations.FIRST_NAME_MINIMUM'))
    .matches(ALPHABETIC_WITH_DASH_AND_FULLSTOP, {
      message: i18n.t('register.validations.FIRST_NAME_INVALID'),
      excludeEmptyString: true,
    }),
  linkedInURL: Yup.string().nullable(),
  phone: Yup.string()
    .nullable()
    .test('USPhoneValidation', i18n.t('register.validations.ENTER_VALID_PHONE'), value => {
      const lengthPhone = value ? value.length : 0;

      if (lengthPhone < 10) {
        return false;
      }

      try {
        let isValid = false;

        for (const code of regionCode) {
          const result = phoneUtil.isValidNumberForRegion(phoneUtil.parse(value, code), code);

          if (result) {
            isValid = true;
            break;
          }
        }

        return isValid;
      } catch (e) {
        return false;
      }
    }),
  internationalHome: Yup.boolean().nullable().required(requiredMessage),
  stateOfHome: Yup.string()
    .nullable()
    .when('internationalHome', {
      is: false,
      then: Yup.string().nullable().required(requiredMessage),
    }),
  cityOfHome: Yup.string()
    .nullable()
    .when('internationalHome', {
      is: false,
      then: Yup.string().required(requiredMessage),
    }),
  countryOfHome: Yup.string()
    .nullable()
    .when('internationalHome', {
      is: true,
      then: Yup.string().nullable().required(requiredMessage),
    }),
  countryCityOfHome: Yup.string()
    .nullable()
    .when('internationalHome', {
      is: true,
      then: Yup.string()
        .nullable()
        .matches(STRING_WITH_EMPTY_SPACE, 'This field can only contain alphabetic characters')
        .required(requiredMessage),
    }),
});
