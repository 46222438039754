const buildOptions = (placeholder, fetchedOptions) => {
  const options = [...fetchedOptions];

  if (placeholder) {
    const placeholderValue = { value: undefined, name: placeholder };
    options.splice(0, 0, placeholderValue);
  }

  return options;
};

export default buildOptions;
