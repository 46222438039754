import omit from 'lodash/omit';

import { assignment } from 'constant';

const formattedPosition = values => {
  const valuesWithLocationFormatted = values.international
    ? omit(values, assignment.OMIT_FORMATTED_POSITION_INTERNATIONAL)
    : omit(values, assignment.OMIT_FORMATTED_POSITION);

  return parseInt(valuesWithLocationFormatted.endMonth, 10) === 13
    ? omit(valuesWithLocationFormatted, ['endYear', 'endYearName'])
    : valuesWithLocationFormatted;
};

export default formattedPosition;
