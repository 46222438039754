import { FieldArray, getIn, useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { AddButton } from 'components/v2/buttons';
import { Checkbox, Input, Select } from 'components/v2/forms/components';
import { HeadingLevel } from 'components/v2/heading';
import { LEVEL_STEPS } from 'constant';
import {
  AddSectionButton,
  RemoveButton,
  SectionHeader,
} from 'pages/v2/level/1A/components/academics/components/shared';
import levelActionsCreator from 'store/v2/level/actions';
import { honorInitialValues } from 'store/v2/level/initial-state';
import { HighSchoolFormValues } from 'store/v2/level/types';
import { yearOptionsIsLoadingSelector, yearOptionsSelector } from 'store/v2/shared/selectors';
import getOption from 'utils/form-builder/get-option';

const HighSchoolHonors: React.FC = () => {
  const namePrefixProp = 'highSchoolHonors';
  const dispatch = useDispatch();

  const fieldName = `${namePrefixProp}`;

  const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
  const yearOptions = useSelector(yearOptionsSelector);

  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } =
    useFormikContext<HighSchoolFormValues>();

  const getError = useCallback(
    (key: string) => (getIn(touched, key) && getIn(errors, key) ? getIn(errors, key) : ''),
    [touched, errors, getIn]
  );

  const handleSetSelectFieldTouched = useCallback(
    (fieldName: string) => {
      setFieldTouched(fieldName, true, true);
    },
    [setFieldTouched]
  );

  const handleHonorsChange = useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value && value[0]?.name, true);
    },
    [setFieldValue]
  );

  const handleAdd = arrayHelpers => {
    arrayHelpers.push({ ...honorInitialValues });
  };

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.highSchool, values));
  }, 600);

  useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  return (
    <FieldArray name={fieldName}>
      {arrayHelpers =>
        values?.[namePrefixProp]?.length === 0 ? (
          <AddSectionButton label="Honor" onClick={() => arrayHelpers.push({ ...honorInitialValues })} />
        ) : (
          <>
            {(values?.[namePrefixProp] || []).map((value, index) => (
              <React.Fragment key={`${fieldName}[${index}]`}>
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <SectionHeader className="mb-2" level={HeadingLevel.h2}>
                    Honor #{index + 1}
                  </SectionHeader>
                  <RemoveButton onClick={() => arrayHelpers.remove(index)} />
                </div>
                <div className="col-12">
                  <Input
                    className="mb-3"
                    name={`${fieldName}[${index}].name`}
                    label="Honor"
                    placeholder="Name of Honor"
                    value={value?.name}
                    error={getError(`${fieldName}[${index}].name`)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                </div>
                <div className="col-12">
                  <Select
                    className="mb-3"
                    name={`${fieldName}[${index}].year`}
                    label="Academic Year"
                    placeholder="Academic Year"
                    value={getOption(yearOptions, value?.year, 'id')}
                    error={getError(`${fieldName}[${index}].year`)}
                    options={yearOptions}
                    onChange={handleHonorsChange}
                    onMenuClose={() => handleSetSelectFieldTouched(`${fieldName}[${index}].year`)}
                    isLoading={isYearOptionsLoading}
                    filterOptionsBy="name"
                    isMulti
                    required
                  />
                </div>
                <div className="col-12">
                  <Checkbox
                    name={`${fieldName}[${index}].includeOnResume`}
                    optionName="Include on resume and profile."
                    checked={value?.includeOnResume}
                    onChange={handleChange}
                  />
                </div>
              </React.Fragment>
            ))}
            <div className="col-12">
              <AddButton onClick={() => handleAdd(arrayHelpers)}>ADD HONOR</AddButton>
            </div>
          </>
        )
      }
    </FieldArray>
  );
};

export default HighSchoolHonors;
