import styled from 'styled-components';

import caretDown from 'assets/png/caret-down.png';

export const Text = styled.p`
  font-size: 17px;
  color: #fff;
  font-weight: 400;
  font-family: 'Poppins', sans-serif;
`;

export const Container = styled.div`
  padding: 15px;
  overflow-y: auto;
  max-height: 380px;
  min-height: 200px;
`;

export const ModalAnimation = styled.div`
  margin-top: 10px;
  animation-name: rotate;
  animation-duration: 0.7s;
  animation-iteration-count: 2;
  display: flex;
  justify-content: flex-end;
  @keyframes rotate {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(10px);
    }
`;

export const ArrowDown = styled.img.attrs({ src: caretDown, alt: 'arrow-down' })`
  height: 15px;
  transform: rotateX(180deg);
`;
