import { isDate } from 'lodash';

export const newDateSQLFormat = () => new Date().toISOString().slice(0, 19).replace('T', ' ');

export const convertToDate = input => {
  if (!input) {
    return null;
  }
  if (!isDate(new Date(input))) {
    return null;
  }
  return new Date(input);
};

export const dateSQLFormat = input => {
  if (!input) {
    return null;
  }
  const newDate = convertToDate(input);
  if (!isDate(newDate)) {
    return null;
  }

  return newDate.toISOString().slice(0, 19).replace('T', ' ');
};
