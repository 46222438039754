const getBreadcrumbColor = (isCompleted, isDisabled, accentColor) => {
  if (isCompleted) {
    return accentColor || '#FFD633';
  }
  if (isDisabled) {
    return 'rgba(0, 0, 0, 0.25)';
  }
  return 'rgba(0, 0, 0, 0.10)';
};

export default getBreadcrumbColor;
