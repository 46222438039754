import { DataURIToBlob, isDataURL } from 'utils';

import http, { getOrCached, httpFile, uploader } from './http';
import { getToken } from './storage';

export const getStudent = () => {
  return http.get(
    '/students/information',
    {},
    { baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2') }
  );
};

export const saveImageStudent = async (upImg, croppedImg, startX, zoom, startY, width) => {
  let formData = {};
  if (isDataURL(upImg) && isDataURL(croppedImg)) {
    const upImgFile = DataURIToBlob(upImg);
    const croppedImgFile = DataURIToBlob(croppedImg);
    formData = new window.FormData();
    formData.append('image', upImgFile);
    formData.append('profile', croppedImgFile);
    formData.append('startX', startX);
    formData.append('zoom', zoom);
    formData.append('startY', startY);
    formData.append('width', width);
  }
  return uploader.post('/students/updatePicture', formData);
};

export const clearStudentImage = async (firstName, lastName) => {
  return http.put('/students/level/All', {
    photoUrl: '',
    thumb_url: '',
    firstName,
    lastName,
  });
};

export const updatePassword = (oldPassword, newPassword) => {
  return http.put('/students/updatePassword', { oldPassword, newPassword });
};

export const delAccount = () => {
  return http.delete('/students/myself');
};

// TODO update this once handling an API version logic is created
export const getResumeCount = () => {
  return http.get(
    `/students/resume/count-pages`,
    {},
    { baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2') }
  );
};

// TODO update this once handling an API version logic is created
export const getStudentAvailableLines = () => {
  return http.get(
    `students/resume/count-lines`,
    {},
    { baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2') }
  );
};

export const getApplicant = opportunityId => {
  return getOrCached(`students/opportunities/${opportunityId}/applicant`);
};

// TODO update this once handling an API version logic is created
export const getTempResume = student => {
  return httpFile.post('students/resume-temp?download=true', student, {
    ...(getToken() && { headers: { Authorization: `Bearer ${getToken()}` } }),
    baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2'),
  });
};

export const checkAssignmentValuesRequest = otherExperiences => {
  return http.post('students/resume/leadership', { otherExperiences });
};

export const requestStudentsAlerts = () => http.get('students/alerts');

export const getResumeStatus = studentId => {
  return http.get(
    `students/resume/status`,
    {},
    { baseURL: `${process.env.REACT_APP_URL_BASE}`.replace('v1', 'api/v2') }
  );
};

export const cleanHighlightRequest = studentId => {
  return http.put(`students/${studentId}/higlighted/clear`);
};

export const removeStudentAlertRequest = tag => http.delete(`/students/alerts/${tag}`);

export const removeStudentAlertsRequest = () => http.delete('/students/alerts');
