import { createReducer } from 'reduxsauce';

import { levelsTypes } from 'store/level/actions';
import { initialState } from 'store/level/initial-state';

const fetchRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
  isSuccess: false,
  isSaving: false,
  isUpdate: false,
});

const fetchSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
  error: null,
});

const fetchError = (state, { error }) => ({
  ...state,
  isLoading: false,
  error,
});

const saveRequest = state => ({
  ...state,
  isSuccess: false,
  isSaving: true,
  error: null,
});

const saveSuccess = state => ({
  ...state,
  isSuccess: true,
  isSaving: false,
  error: null,
});

const saveError = (state, { error }) => ({
  ...state,
  error,
  isSaving: false,
  isSuccess: false,
});

const updateRequest = state => ({
  ...state,
  isUpdate: true,
  errorUpdate: null,
});

const updateSuccess = state => ({
  ...state,
  isUpdate: false,
  errorUpdate: null,
});

const updateError = (state, { error }) => ({
  ...state,
  errorUpdate: error,
  isUpdate: false,
});

const cleanState = state => ({
  ...state,
  error: null,
  isLoading: false,
  isSaving: false,
  isUpdate: false,
  isSuccess: false,
});

const reducer = createReducer(initialState, {
  [levelsTypes.LEVEL_FETCH_REQUEST]: fetchRequest,
  [levelsTypes.LEVEL_FETCH_SUCCESS]: fetchSuccess,
  [levelsTypes.LEVEL_FETCH_ERROR]: fetchError,

  [levelsTypes.LEVEL_SAVE_REQUEST]: saveRequest,
  [levelsTypes.LEVEL_SAVE_SUCCESS]: saveSuccess,
  [levelsTypes.LEVEL_SAVE_ERROR]: saveError,

  [levelsTypes.LEVEL_UPDATE_REQUEST]: updateRequest,
  [levelsTypes.LEVEL_UPDATE_SUCCESS]: updateSuccess,
  [levelsTypes.LEVEL_UPDATE_ERROR]: updateError,

  [levelsTypes.LEVEL_CLEAN_STATE]: cleanState,
});

export default reducer;
