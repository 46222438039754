const filtersButtonsMobile = [
  { name: 'Industries', apiId: 'industries' },
  { name: 'Functions', apiId: 'jobFunctions' },
  { name: 'Culture', apiId: 'culture' },
  { name: 'Qualified', apiId: 'qualified' },
  { name: 'Following', apiId: 'following' },
  { name: 'Applied', apiId: 'applied' },
  { name: 'Saved', apiId: 'saved' },
];

export default filtersButtonsMobile;
