import { createReducer } from 'reduxsauce';

import { searchType as Types } from './actions';
import { initialState } from './initial-state';

const dashboardStoreSearchQuery = (state, { search }) => ({
  ...state,
  search,
});

const dashboardRemoveSearchQuery = state => ({
  ...state,
  search: initialState.search,
});

const reducer = createReducer(initialState, {
  [Types.DASHBOARD_STORE_SEARCH_REQUEST]: dashboardStoreSearchQuery,
  [Types.DASHBOARD_CLEAR_SEARCH_REQUEST]: dashboardRemoveSearchQuery,
});

export default reducer;
