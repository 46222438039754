/* eslint-disable */
import * as yup from 'yup';
import _, {
  template,
  identity,
  isArray,
  isObject,
  keyBy,
  map,
  mapValues,
  pickBy,
  reduce,
  size,
} from 'lodash';
import md5 from 'md5';
import { userStore } from 'hooks/use-user-store';
import isEmpty  from "lodash/isEmpty";
import isBoolean  from "lodash/isBoolean";
import isNumber  from "lodash/isNumber";
import isString  from "lodash/isString";

const types = {
  TEST: "test",
  REPEATABLE: "repeatable",
  ARRAY: "array",
  OBJECT: "object",
  TRUE: "true"
}

const isBlank = value => _.isNil(value) || _.size(value + '') === 0;

const flatValidations = validations =>
  reduce(
    validations,
    (acc, currentValue, key) => {
      if (isObject(currentValue) && !yup.isSchema(currentValue)) {
        const flattenValidations = flatValidations(currentValue);
        return { ...acc, ...flattenValidations };
      }
      return { ...acc, [key]: currentValue };
    },
    {}
  );

const createCustomTestSchema = (actualSchema, params, ownStep) => {
  const [jsValidation, message] = params;
  const validationName = md5(jsValidation);
  return actualSchema.test(validationName, message, function (value) {
    const templateParams = { _, ...userStore, ownStep, parent: this.parent, value, isBlank };
    return template(jsValidation)(templateParams) === types.TRUE;
  });
};

const createFieldElementSchema = ({ validationType, validations = [] }, ownStep) => {
  try {
    const validationSchemaBase = yup[validationType]();
    return reduce(
      validations,
      (actualSchema, currentValidation) => {
        const { type, params } = currentValidation;
        if (type === types.TEST) {
          return createCustomTestSchema(actualSchema, params, ownStep);
        }
        if(!params || isEmpty(params)) {
          return actualSchema[type]();
        }
        if(isBoolean(params) || isNumber(params) || isString(params)) {
          return actualSchema[type](params);
        }
        return actualSchema[type](...params);
      },
      validationSchemaBase
    );
  } catch (e) {
    return false;
  }
};

const createYupSchema = (element = {}, ownStep) => {
  const { validationType, validations = [], form } = element;
  if (isArray(form) || isArray(element)) {
    const mappedForm = keyBy(
      form || element,
      currentElement => currentElement.control || md5(JSON.stringify(currentElement))
    );
    const formSchema = mapValues(mappedForm, e => createYupSchema(e, ownStep));
    const clearFormSchema = pickBy(formSchema, identity);
    const flattedValidations = flatValidations(clearFormSchema);

    if (element.type === types.REPEATABLE) {
      const repeatableScheme = element.defaultRepetable
        ? yup
            .array()
            .transform((_, originalValue) =>
              map(originalValue, (_, index) =>
                index === ownStep ? originalValue[ownStep] : element.defaultRepetable
              )
            )
        : yup.array().transform((_, originalValue) => [originalValue[ownStep]]);
      return repeatableScheme.of(yup.object().shape(flattedValidations));
    }
    if (validationType === types.ARRAY) {
      return yup.array().of(yup.object().shape(flattedValidations));
    }
    if (validationType === types.OBJECT) {
      return yup.object().shape(flattedValidations);
    }
    return flattedValidations;
  }
  if (size(validations) > 0) {
    return createFieldElementSchema(element, ownStep);
  }
  return undefined;
};

export default createYupSchema;
