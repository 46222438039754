export const registerRoutes = {
  step1: '/register/name',
  step2: '/register/school-email',
  step3: '/register/alt-email',
  step4: '/register/hear-about-us',
  step5: '/register/university',
  step6: '/register/password',
  step7: '/register/digits',
  step8: '/register/confirm-email',
  step9: '/register/thank-you',
};

export const registerRoutesV2 = {
  step1: '/register/basic-information',
  step2: '/register/contact-information',
  step3: '/register/location',
  step4: '/register/additional-information',
  step5: '/register/password',
};

export enum REGISTER_STEPS {
  'basicInformation' = 'basicInformation',
  'contactInformation' = 'contactInformation',
  'additionalInformation' = 'additionalInformation',
  'locationInformation' = 'locationInformation',
  'password' = 'password',
}
