import { createSelector } from 'reselect';

import { REGISTER_STEPS } from 'constant';

import { RegisterState } from './types';

export const registerStateSelector: (state) => RegisterState = state => state.v2.register;

export const registerStateValuesSelector = createSelector(registerStateSelector, state => {
  return Object.values(state).reduce((acc, current) => {
    return {
      ...acc,
      ...current.values,
    };
  }, {});
});

export const basicInformationSelector = createSelector(
  registerStateSelector,
  state => state[REGISTER_STEPS.basicInformation]
);

export const additionalInformationSelector = createSelector(
  registerStateSelector,
  state => state[REGISTER_STEPS.additionalInformation]
);

export const passwordSelector = createSelector(registerStateSelector, state => state[REGISTER_STEPS.password]);

export const additionalInformationCompletedSelector = createSelector(
  additionalInformationSelector,
  state => state.isCompleted || false
);

export const basicInformationIsCompletedSelector = createSelector(
  basicInformationSelector,
  state => state?.isCompleted || false
);
export const passwordIsCompletedSelector = createSelector(passwordSelector, state => state?.isCompleted || false);

export const contactInformationSelector = createSelector(
  registerStateSelector,
  state => state[REGISTER_STEPS.contactInformation]
);

export const contactInformationCompletedSelector = createSelector(
  contactInformationSelector,
  state => state?.isCompleted || false
);

export const locationInformationSelector = createSelector(
  registerStateSelector,
  state => state[REGISTER_STEPS.locationInformation]
);

export const locationInformationIsCompletedSelector = createSelector(
  locationInformationSelector,
  state => state?.isCompleted || false
);
