export const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  phone: '',
  alternativeEmail: '',
  receiveUpdates: false,
  university: '',
  heardFromUs: null,
  heardFromUsExpanded: '',
  heardFromUsSubOption: null,
  hasAlternateMail: false,
  hasSchoolMail: false,
  terms: false,
};
