/* eslint react/prop-types: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import Loader from 'react-loader-spinner';

import { customStyles } from 'constant';

import { Button } from './styled';

const LoadingButton = ({ label, loading, spinColor, bsStyle, className, ...props }) => (
  <Button $bsStyle={bsStyle} disabled={loading} {...props} className={className}>
    {!loading ? label : <Loader style={customStyles.loader} type="TailSpin" color={spinColor} height={24} width={24} />}
  </Button>
);

LoadingButton.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  spinColor: PropTypes.string,
  bsStyle: PropTypes.shape({}),
};

LoadingButton.defaultProps = {
  spinColor: 'white',
  loading: false,
  bsStyle: null,
};

export default LoadingButton;
