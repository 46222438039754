import styled from 'styled-components';

import { Input } from '../input';

export const StyledEndTag = styled.span`
  position: absolute;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.5px;
  color: #9896a5;
  right: 1rem;
  top: ${props => (props.error ? '40%' : '53%')};
  right: 36px;
  pointer-events: none;
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const StyledInput = styled(Input)`
  .input-counter {
    padding-right: 88px;
  }
`;
