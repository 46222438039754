import { createReducer } from 'reduxsauce';

import { userTypes } from 'store/user/actions';
import { initialState } from 'store/user/initial-state';

const confirmEmailRequest = state => ({
  ...state,
  isConfirming: true,
  error: null,
});

const confirmEmailSuccess = (state, { data }) => ({
  ...state,
  data,
  isConfirming: false,
  error: null,
});

const confirmEmailError = (state, { error }) => ({
  ...state,
  error,
  isConfirming: false,
});

const updateRequest = state => ({
  ...state,
  isUpdating: true,
  error: null,
});

const updateSuccess = (state, { data }) => ({
  ...state,
  data,
  isUpdating: false,
  error: null,
});

const updateError = (state, { error }) => ({
  ...state,
  error,
  isUpdating: false,
});

const userRequest = state => ({
  ...state,
  isLoading: true,
  error: null,
  data: null,
});

const userSuccess = (state, { data }) => ({
  ...state,
  data,
  isLoading: false,
  error: null,
});

const userError = (state, { error }) => ({
  ...state,
  error,
  isLoading: false,
  data: null,
});

const setUser = (state, { data }) => ({
  ...state,
  data,
});

const reducer = createReducer(initialState, {
  [userTypes.USER_FETCH_REQUEST]: userRequest,
  [userTypes.USER_FETCH_SUCCESS]: userSuccess,
  [userTypes.USER_FETCH_ERROR]: userError,

  [userTypes.USER_UPDATE_REQUEST]: updateRequest,
  [userTypes.USER_UPDATE_SUCCESS]: updateSuccess,
  [userTypes.USER_UPDATE_ERROR]: updateError,

  [userTypes.USER_CONFIRM_EMAIL_REQUEST]: confirmEmailRequest,
  [userTypes.USER_CONFIRM_EMAIL_SUCCESS]: confirmEmailSuccess,
  [userTypes.USER_CONFIRM_EMAIL_ERROR]: confirmEmailError,

  [userTypes.SET_USER]: setUser,
});

export default reducer;
