import { createSelector } from 'reselect';

import { LEVEL_STEPS } from 'constant';

import { LevelState } from './types';

export const levelStateSelector: (state) => LevelState = state => state.v2.level;

export const levelAllFormValuesSelector = createSelector(levelStateSelector, state =>
  Object.values(state).reduce((acc, levelStep) => ({ ...acc, ...levelStep.values }), {})
);

export const contactInformationSelector = createSelector(
  levelStateSelector,
  state => state[LEVEL_STEPS.contactInformation]
);

export const isContactInformationLoadingSelector = createSelector(
  contactInformationSelector,
  ({ isLoading }) => isLoading || false
);

export const technicalSkillsSelector = createSelector(levelStateSelector, state => state[LEVEL_STEPS.technicalSkill]);

export const isTechnicalSkillsLoadingSelector = createSelector(
  technicalSkillsSelector,
  ({ isLoading }) => isLoading || false
);

export const academicsSelector = createSelector(levelStateSelector, state => state[LEVEL_STEPS.academics]);

export const isAcademicsLoadingSelector = createSelector(academicsSelector, ({ isLoading }) => isLoading || false);

export const isAcademicsCompletedSelector = createSelector(
  academicsSelector,
  ({ isCompleted }) => isCompleted || false
);

export const courseworkSelector = createSelector(levelStateSelector, state => state[LEVEL_STEPS.coursework]);
export const isCourseworkLoadingSelector = createSelector(courseworkSelector, ({ isLoading }) => isLoading);

export const isCourseworkCompletedSelector = createSelector(courseworkSelector, ({ isCompleted }) => isCompleted);

export const collegiateAthleticsSelector = createSelector(
  levelStateSelector,
  state => state[LEVEL_STEPS.collegiateAthletics]
);

export const isCollegiateAthleticsLoadingSelector = createSelector(
  collegiateAthleticsSelector,
  ({ isLoading }) => isLoading || false
);

export const isCollegiateAthleticsCompletedSelector = createSelector(
  collegiateAthleticsSelector,
  ({ isCompleted }) => isCompleted || false
);

export const researchAndProgramsSelector = createSelector(
  levelStateSelector,
  state => state[LEVEL_STEPS.researchAndPrograms]
);
export const isResearchAndProgramsLoadingSelector = createSelector(
  researchAndProgramsSelector,
  ({ isLoading }) => isLoading || false
);
export const isResearchAndProgramsCompletedSelector = createSelector(
  researchAndProgramsSelector,
  ({ isCompleted }) => isCompleted || false
);

export const studyAbroadSelector = createSelector(levelStateSelector, state => state[LEVEL_STEPS.studyAbroad]);
export const isStudyAbroadLoadingSelector = createSelector(studyAbroadSelector, ({ isLoading }) => isLoading || false);
export const isStudyAbroadCompletedSelector = createSelector(
  studyAbroadSelector,
  ({ isCompleted }) => isCompleted || false
);

export const additionalDetailsSelector = createSelector(
  levelStateSelector,
  state => state[LEVEL_STEPS.additionalDetails]
);
export const isAdditionalDetailsLoadingSelector = createSelector(
  additionalDetailsSelector,
  ({ isLoading }) => isLoading || false
);
export const isAdditionalDetailsCompletedSelector = createSelector(
  additionalDetailsSelector,
  ({ isCompleted }) => isCompleted || false
);

export const additionalDetailsAthleticsSelector = createSelector(
  levelStateSelector,
  state => state[LEVEL_STEPS.additionalDetailsCollegiateAthletics]
);
export const isAdditionalDetailsAthleticsLoadingSelector = createSelector(
  additionalDetailsAthleticsSelector,
  ({ isLoading }) => isLoading || false
);
export const isAdditionalDetailsAthleticsCompletedSelector = createSelector(
  additionalDetailsAthleticsSelector,
  ({ isCompleted }) => isCompleted || false
);
