import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  fetchArbitraryEndpoint: ['key', 'endpoint'],
  setLoadingEndpoint: ['key', 'loading'],
  setEndpointFetchError: ['key', 'error'],
  setData: ['key', 'data'],

  notificationsRequest: [null],
  notificationsSuccess: ['notifications'],
  notificationsError: ['error'],

  notificationReadRequest: ['notificationId'],
  notificationReadSuccess: ['notifications'],
  notificationReadError: ['error'],
});

export const appTypes = Types;
export default Creators;
