import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import store from 'store';

import Routes from './routes';
import { GlobalStyles } from './styled';
import './fonts.css';
import 'emoji-mart/css/emoji-mart.css';
import './bootstrap-grid.css';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  enabled: process.env.REACT_APP_ENV !== 'develop',
  integrations: [
    new BrowserTracing({
      tracingOrigins: [process.env.REACT_APP_SENTRY_ORIGIN!],
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: process.env.REACT_APP_ENV === 'prod' ? 0.5 : 1.0,
});

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyles />
    <Routes />
  </Provider>,
  document.getElementById('root')
);
