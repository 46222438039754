import listLevels, { LEVEL_NAMES, LEVEL_NAME } from './list-levels';

export type LevelConfig = {
  [key in LEVEL_NAME]: {
    levelName: LEVEL_NAMES;
    title: string;
    goBackConfig: LevelGoBackConfig;
  };
};

export interface LevelGoBackConfig {
  url: string;
  title: string;
  innerTitle?: string;
}

const rootPath = '/level';

export const levelRoutes = {
  contactInformation: `${rootPath}/${listLevels.ONE_A}/contact-information`,
  highSchool: `${rootPath}/${listLevels.ONE_A}/high-school`,
  academics: `${rootPath}/${listLevels.ONE_A}/academics`,
  studyAbroad: `${rootPath}/${listLevels.ONE_A}/study-abroad`,
  researchAndPrograms: `${rootPath}/${listLevels.ONE_A}/research-and-programs`,
  additionalDetails: `${rootPath}/${listLevels.ONE_A}/additional-details`,
  coursework: `${rootPath}/${listLevels.ONE_A}/coursework`,
  collegiateAthletics: `${rootPath}/${listLevels.ONE_A}/collegiate-athletics`,
  additionalDetailsCollegiateAthletics: `${rootPath}/${listLevels.ONE_A}/additional-details-collegiate-athletics`,
  finalStepLevel1A: `/levels/${listLevels.ONE_A}/all-set`,
};

export const levelBRoutes = {
  technicalSkills: `${rootPath}/${listLevels.ONE_B}/computer-technical`,
  languages: `${rootPath}/${listLevels.ONE_B}/languages`,
  certificationsAndLicenses: `${rootPath}/${listLevels.ONE_B}/certificates-licenses`,
  personalInterests: `${rootPath}/${listLevels.ONE_B}/personal-interests`,
  diversities: `${rootPath}/${listLevels.ONE_B}/diversities`,
  finalStepLevel1B: `/levels/${listLevels.ONE_B}/all-set`,
};

export type LevelRoute = (typeof levelRoutes)[keyof typeof levelRoutes];

export const levelConfigs: LevelConfig = {
  [LEVEL_NAMES.ONE_A]: {
    levelName: LEVEL_NAMES.ONE_A,
    title: 'Level 1A: Header & Education',
    goBackConfig: {
      url: '/levels',
      title: 'Back to Level 1 Menu',
    },
  },
  [LEVEL_NAMES.ONE_B]: {
    levelName: LEVEL_NAMES.ONE_B,
    title: 'Level 1B: Skills & Interests',
    goBackConfig: {
      url: '/levels',
      title: 'Back to Level 1 Menu',
      innerTitle: 'BACK',
    },
  },
};
