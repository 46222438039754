import isNil from 'lodash/isNil';
import shortId from 'shortid';

import { assignment, defaultLines, defaultLine } from 'constant';
import { isEmpty } from 'utils';

const getActiveLine = experience => {
  const activeLines = isNil(experience.activeLines) ? true : experience.activeLines;
  const lines = !isEmpty(experience.lines)
    ? experience.lines.map(line => ({ ...defaultLine, ...line, id: shortId.generate() }))
    : defaultLines;
  return { activeLines, lines };
};

const getAssignmentValues = store =>
  [assignment.PROFESSIONAL_EXPERIENCES, assignment.OTHER_EXPERIENCES]
    .map(s => ({
      [s]: store[s].map((e, idx) => ({
        ...store[s][idx],
        ...getActiveLine(store[s][idx], s, idx),
      })),
    }))
    .reduce((obj, item) => ({ ...obj, ...item }));

export default getAssignmentValues;
