import { month as monthOptions } from '@hivediversity/common-lib/constant';
import { FieldArray, getIn, useFormikContext } from 'formik';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { AddButton } from 'components/v2/buttons';
import { FormRow, Label, Select, Input, Checkbox, Radio } from 'components/v2/forms/components';
import { HeadingLevel } from 'components/v2/heading';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS, tooltipsMessages } from 'constant';
import { RemoveButton, SectionHeader } from 'pages/v2/level/1A/components/academics/components/shared';
import levelActionsCreator from 'store/v2/level/actions';
import { concentrationInitialValues, majorInitialValues, minorInitialValues } from 'store/v2/level/initial-state';
import { AcademicsFormValues } from 'store/v2/level/types';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  masterDegreeOptionsIsLoadingSelector,
  masterDegreeOptionsSelector,
  locationSchoolCitiesIsLoadingSelector,
  locationSchoolCitiesSelector,
  locationStatesIsLoadingSelector,
  locationStatesSelector,
  majorCategoriesOptionsIsLoadingSelector,
  majorCategoriesOptionsSelector,
  majorsOptionsIsLoadingSelector,
  majorsOptionsSelector,
  universityOptionsIsLoadingSelector,
  universityOptionsSelector,
  yearOptionsIsLoadingSelector,
  yearOptionsSelector,
  bachelorDegreeOptionsIsLoadingSelector,
  bachelorDegreeOptionsSelector,
  bachelorDegreeOptionsErrorSelector,
  masterDegreeOptionsErrorSelector,
  minorsOptionsIsLoadingSelector,
  minorsOptionsSelector,
} from 'store/v2/shared/selectors';
import { DegreeTypeOption } from 'store/v2/shared/types';
import getOption from 'utils/form-builder/get-option';

interface DegreeFormProps {
  activeDegreeFormIndex: number;
  degreeIndex: number;
  degreeType: DegreeTypeOption;
}

const NAME_PREFIX = 'degrees';

const OTHER_MAJOR_MINOR_ID = 385;
const OTHER_MAJOR_CONCENTRATION_CATEGORY_ID = 32;
const MAJOR_UNDECIDED_ID = 408;

const FIELDS_TO_BE_SYNCED = [
  'university',
  'state',
  'city',
  'graduationMonth',
  'graduationYear',
  'cumulativeGPA',
  'includeCumulativeGPAOnResume',
  'notPublishCumulativeGPAs',
  'notHaveCumulativeGPA',
];

const CUMULATIVE_GPA_FIELDS = new Set([
  'includeCumulativeGPAOnResume',
  'notPublishCumulativeGPAs',
  'notHaveCumulativeGPA',
]);

const FIELDS_TO_REMOVE_CUMULATIVE_GPA = new Set(['notPublishCumulativeGPAs', 'notHaveCumulativeGPA']);

const DegreeForm: React.FC<DegreeFormProps> = ({ activeDegreeFormIndex, degreeIndex, degreeType }) => {
  const dispatch = useDispatch();

  const activeDegreeForm = `${NAME_PREFIX}[${activeDegreeFormIndex}]`;
  const fieldName = `${activeDegreeForm}[${degreeIndex}]`;

  const isMasterDegree = degreeType?.isMaster;
  const firstDegree = degreeIndex === 0;

  const isBachelorDegreeOptionsLoading = useSelector(bachelorDegreeOptionsIsLoadingSelector);
  const bachelorDegreeOptions = useSelector(bachelorDegreeOptionsSelector);
  const bachelorDegreeOptionsError = useSelector(bachelorDegreeOptionsErrorSelector);

  const isMasterDegreeOptionsLoading = useSelector(masterDegreeOptionsIsLoadingSelector);
  const masterDegreeOptions = useSelector(masterDegreeOptionsSelector);
  const masterDegreeOptionsError = useSelector(masterDegreeOptionsErrorSelector);

  const isDegreeOptionsLoading = isMasterDegree ? isMasterDegreeOptionsLoading : isBachelorDegreeOptionsLoading;

  const degreeOptions = useMemo(
    () => (isMasterDegree ? masterDegreeOptions : bachelorDegreeOptions),
    [isMasterDegree, masterDegreeOptions, bachelorDegreeOptions]
  );

  const isMajorsOptionsLoading = useSelector(majorsOptionsIsLoadingSelector);
  const majorsOptions = useSelector(majorsOptionsSelector);

  const isMinorsOptionsLoading = useSelector(minorsOptionsIsLoadingSelector);
  const minorsOptions = useSelector(minorsOptionsSelector);

  const isMajorCategoriesOptionsLoading = useSelector(majorCategoriesOptionsIsLoadingSelector);
  const majorCategoriesOptions = useSelector(majorCategoriesOptionsSelector);

  const isUniversityOptionsLoading = useSelector(universityOptionsIsLoadingSelector);
  const universityOptions = useSelector(universityOptionsSelector);

  const isStatesOptionsLoading = useSelector(locationStatesIsLoadingSelector);
  const statesOptions = useSelector(locationStatesSelector);

  const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
  const yearOptions = useSelector(yearOptionsSelector);

  const isSchoolStateCitiesOptionsLoading = useSelector(locationSchoolCitiesIsLoadingSelector);
  const schoolStateCitiesOptions = useSelector(locationSchoolCitiesSelector);

  const { values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched } =
    useFormikContext<AcademicsFormValues>();

  const selectedDegree = getOption(
    degreeOptions,
    values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex].degree,
    'id'
  );

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.academics, values));
  }, 600);

  const getError = useCallback(
    (key: string) => (getIn(touched, key) && getIn(errors, key) ? getIn(errors, key) : ''),
    [touched, errors, getIn]
  );

  const getFieldToBeSynced = useCallback((name: string) => {
    const fieldToBeSynced = FIELDS_TO_BE_SYNCED.find(fieldName => name.includes(fieldName));

    return fieldToBeSynced || '';
  }, []);

  const handleSyncChange = useCallback(
    event => {
      const fieldToBeSynced = getFieldToBeSynced(event.target.name);

      if (fieldToBeSynced) {
        values?.degrees?.[activeDegreeFormIndex].forEach((_, index) => {
          setFieldValue(`${activeDegreeForm}[${index}].${fieldToBeSynced}`, event?.target?.value, true);
        });
      }
    },
    [setFieldValue, activeDegreeForm, values?.degrees?.[activeDegreeFormIndex].length]
  );

  const handleCheckboxSyncChange = useCallback(
    event => {
      const fieldToBeSynced = getFieldToBeSynced(event.target.name);

      if (fieldToBeSynced) {
        values?.degrees?.[activeDegreeFormIndex].forEach((_, index) => {
          setFieldValue(`${activeDegreeForm}[${index}].${fieldToBeSynced}`, event?.target?.checked, true);
        });
      }
    },
    [setFieldValue, activeDegreeForm, values?.degrees?.[activeDegreeFormIndex].length]
  );

  const handleSelectChange = useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value?.id, true);
    },
    [setFieldValue]
  );

  const handleSelectSyncChange = useCallback(
    (value, metaData) => {
      const fieldToBeSynced = getFieldToBeSynced(metaData.name);

      if (fieldToBeSynced) {
        values?.degrees?.[activeDegreeFormIndex].forEach((_, index) => {
          setFieldValue(`${activeDegreeForm}[${index}].${fieldToBeSynced}`, value?.id, true);
        });
      }
    },
    [setFieldValue, activeDegreeForm, values?.degrees?.[activeDegreeFormIndex].length]
  );

  const handleSetSelectFieldTouched = useCallback(
    (fieldName: string) => {
      setFieldTouched(fieldName, true, true);
    },
    [setFieldTouched]
  );

  const handleStateOfUniversityChange = useCallback(
    (value, metaData) => {
      handleSelectSyncChange(value, metaData);

      dispatch(sharedActionsCreator.schoolStateCitiesOptionsRequest(value.id));

      setFieldValue(`${fieldName}.city`, '');
    },
    [handleSelectSyncChange, setFieldValue, dispatch, fieldName]
  );

  const handleCumulativeGPAOnChange = event => {
    if (!event.target?.value && !isMasterDegree) {
      values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.majors?.forEach((_, majorIndex) => {
        setFieldValue(`${activeDegreeForm}[${degreeIndex}].majors[${majorIndex}].gpa`, '');
        setFieldValue(`${activeDegreeForm}[${degreeIndex}].majors[${majorIndex}].includeOnResume`, false);
      });
    }

    handleSyncChange(event);
  };

  const handleToggleCumulativeGPAChange = event => {
    values?.degrees?.[activeDegreeFormIndex].forEach((_, degreeIndex) => {
      CUMULATIVE_GPA_FIELDS.forEach(gpaFieldName => {
        const isChecked = event.target.name.includes(gpaFieldName);

        setFieldValue(`${activeDegreeForm}[${degreeIndex}].${gpaFieldName}`, isChecked);

        if (isChecked && FIELDS_TO_REMOVE_CUMULATIVE_GPA.has(gpaFieldName)) {
          setFieldValue(`${activeDegreeForm}[${degreeIndex}].cumulativeGPA`, '');
        } else if (gpaFieldName === 'includeCumulativeGPAOnResume' && !isChecked) {
          if (isMasterDegree) {
            values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.concentrations?.forEach(
              (_, concentrationIndex) => {
                setFieldValue(
                  `${activeDegreeForm}[${degreeIndex}].concentrations[${concentrationIndex}].includeOnResume`,
                  false
                );
              }
            );
          } else {
            values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.majors?.forEach((_, majorIndex) => {
              setFieldValue(`${activeDegreeForm}[${degreeIndex}].majors[${majorIndex}].gpa`, '');
              setFieldValue(`${activeDegreeForm}[${degreeIndex}].majors[${majorIndex}].includeOnResume`, false);
            });
          }
        }
      });
    });
  };

  const onMajorChange = (value, metaData, index) => {
    setFieldValue(`${fieldName}.majors[${index}].otherMajor`, '');
    setFieldValue(`${fieldName}.majors[${index}].category`, null);

    if (value?.id === MAJOR_UNDECIDED_ID) {
      setFieldValue(`${fieldName}.majors[${index}].gpa`, '');
      setFieldValue(`${fieldName}.majors[${index}].includeOnResume`, false);
    }

    handleSelectChange(value, metaData);
  };

  const onMajorCategoryChange = (value, metaData, index) => {
    setFieldValue(`${fieldName}.majors[${index}].otherCategory`, '');

    handleSelectChange(value, metaData);
  };

  const onMinorChange = (value, metaData, index) => {
    setFieldValue(`${fieldName}.minors[${index}].otherMinor`, '');

    handleSelectChange(value, metaData);
  };

  const onConcentrationChange = (event, index) => {
    const value = event.target.value;

    if (!value) {
      setFieldValue(`${fieldName}.concentrations[${index}].category`, null);
      setFieldValue(`${fieldName}.concentrations[${index}].gpa`, '');
      setFieldValue(`${fieldName}.concentrations[${index}].includeOnResume`, false);
    }

    handleChange(event);
  };

  const onConcentrationCategoryChange = (value, metaData, index) => {
    setFieldValue(`${fieldName}.concentrations[${index}].otherCategory`, '');

    handleSelectChange(value, metaData);
  };

  useEffect(() => {
    if (firstDegree) {
      if (
        !isMasterDegree &&
        !isBachelorDegreeOptionsLoading &&
        bachelorDegreeOptions.length === 0 &&
        !bachelorDegreeOptionsError
      ) {
        dispatch(sharedActionsCreator.bachelorDegreeOptionsRequest(degreeType));
      }

      if (
        isMasterDegree &&
        !isMasterDegreeOptionsLoading &&
        masterDegreeOptions.length === 0 &&
        !masterDegreeOptionsError
      ) {
        dispatch(sharedActionsCreator.masterDegreeOptionsRequest(degreeType));
      }
    }
  }, [
    firstDegree,
    degreeType,
    isMasterDegree,
    bachelorDegreeOptions.length,
    masterDegreeOptions.length,
    isBachelorDegreeOptionsLoading,
    isMasterDegreeOptionsLoading,
    bachelorDegreeOptionsError,
    masterDegreeOptionsError,
  ]);

  useEffect(() => {
    // Need to fetch city options if a state was selected
    if (values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[0]?.state && firstDegree) {
      dispatch(
        sharedActionsCreator.schoolStateCitiesOptionsRequest(values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[0]?.state)
      );
    }
  }, [activeDegreeFormIndex, firstDegree]);

  useEffect(() => {
    // To be able to open a temporary resume with the fresh data need to update the store constantly
    debouncedSaveLevelFormValues(values);
  }, [values]);

  return (
    <div className="mb-4">
      <FormRow>
        <div className="col-12 d-flex align-items-center justify-content-between">
          <SectionHeader className="mb-2" level={HeadingLevel.h2}>
            Degree #{degreeIndex + 1} Information
          </SectionHeader>
        </div>
      </FormRow>
      <Paper>
        <FormRow>
          <div className="col-12">
            <Select
              className="mb-3"
              name={`${fieldName}.degree`}
              value={selectedDegree}
              error={getError(`${fieldName}.degree`)}
              options={degreeOptions}
              label="Degree"
              placeholder="Select or Type Your Degree"
              onChange={handleSelectChange}
              onMenuClose={() => handleSetSelectFieldTouched(`${fieldName}.degree`)}
              isLoading={isDegreeOptionsLoading}
              required
            />
          </div>
          <div className="col-12">
            <Select
              className="mb-3"
              name={`${fieldName}.university`}
              value={getOption(
                universityOptions,
                values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.university,
                'id'
              )}
              error={firstDegree ? getError(`${fieldName}.university`) : ''}
              options={universityOptions}
              label="University/School"
              placeholder="Name of University/School"
              onChange={handleSelectSyncChange}
              onMenuClose={() => handleSetSelectFieldTouched(`${fieldName}.university`)}
              isLoading={isUniversityOptionsLoading}
              isDisabled={degreeIndex > 0}
              required
            />
          </div>
          <div className="col-12">
            <Label disabled={degreeIndex > 0} required>
              University/School Location
            </Label>
          </div>
          <div className="col-6">
            <Select
              className="mb-3"
              name={`${fieldName}.state`}
              placeholder="Select State"
              value={getOption(
                statesOptions,
                values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.state,
                'id'
              )}
              error={firstDegree ? getError(`${fieldName}.state`) : ''}
              options={statesOptions}
              onChange={handleStateOfUniversityChange}
              onMenuClose={() => handleSetSelectFieldTouched(`${fieldName}.state`)}
              isLoading={isStatesOptionsLoading}
              isDisabled={degreeIndex > 0}
              required
            />
          </div>
          <div className="col-6">
            <Select
              className="mb-3"
              name={`${fieldName}.city`}
              placeholder="Select City"
              options={schoolStateCitiesOptions}
              value={getOption(
                schoolStateCitiesOptions,
                values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.city,
                'id'
              )}
              error={firstDegree ? getError(`${fieldName}.city`) : ''}
              isLoading={isSchoolStateCitiesOptionsLoading}
              isDisabled={degreeIndex > 0}
              onChange={handleSelectSyncChange}
              onMenuClose={() => handleSetSelectFieldTouched(`${fieldName}.city`)}
              required
            />
          </div>
          <div className="col-12">
            <Input
              className="mb-3"
              name={`${fieldName}.college`}
              label="Secondary College"
              placeholder="(Write In) Ex.: School of Liberal Arts"
              tooltip={tooltipsMessages.SECONDARY_COLLEGE}
              value={values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.college || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
          <div className="col-12">
            <Label disabled={degreeIndex > 0} required>
              Graduation Date
            </Label>
          </div>
          <div className="col-6">
            <Select
              className="mb-3"
              name={`${fieldName}.graduationMonth`}
              placeholder="Month"
              value={getOption(
                monthOptions,
                values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.graduationMonth,
                'id'
              )}
              error={firstDegree ? getError(`${fieldName}.graduationMonth`) : ''}
              options={monthOptions}
              onChange={handleSelectSyncChange}
              onMenuClose={() => handleSetSelectFieldTouched(`${fieldName}.graduationMonth`)}
              isDisabled={degreeIndex > 0}
              required
            />
          </div>
          <div className="col-6">
            <Select
              className="mb-3"
              name={`${fieldName}.graduationYear`}
              placeholder="Year"
              value={getOption(
                yearOptions,
                values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.graduationYear,
                'id'
              )}
              error={firstDegree ? getError(`${fieldName}.graduationYear`) : ''}
              options={yearOptions}
              onChange={handleSelectSyncChange}
              onMenuClose={() => handleSetSelectFieldTouched(`${fieldName}.graduationYear`)}
              isLoading={isYearOptionsLoading}
              isDisabled={degreeIndex > 0}
              required
            />
          </div>
          <div className="col-12">
            <Input
              className="mb-2"
              name={`${fieldName}.cumulativeGPA`}
              label="Cumulative GPA"
              placeholder="Ex.: 3.98 or 3.0"
              value={getIn(values, `${fieldName}.cumulativeGPA`)}
              error={firstDegree ? getError(`${fieldName}.cumulativeGPA`) : ''}
              onChange={handleCumulativeGPAOnChange}
              onBlur={handleBlur}
              disabled={
                getIn(values, `${fieldName}.notHaveCumulativeGPA`) ||
                getIn(values, `${fieldName}.notPublishCumulativeGPAs`) ||
                degreeIndex > 0
              }
              required
            />
          </div>
          <div className="col-12">
            <Radio
              className="mb-2"
              name={`${fieldName}.includeCumulativeGPAOnResume`}
              optionName="Include on resume and profile."
              checked={getIn(values, `${fieldName}.includeCumulativeGPAOnResume`)}
              disabled={degreeIndex > 0}
              onChange={handleToggleCumulativeGPAChange}
            />
          </div>
          <div className="col-12">
            <Radio
              className="mb-2"
              name={`${fieldName}.notPublishCumulativeGPAs`}
              optionName="My school doesn't publish GPAs."
              checked={getIn(values, `${fieldName}.notPublishCumulativeGPAs`)}
              disabled={degreeIndex > 0}
              onChange={handleToggleCumulativeGPAChange}
            />
          </div>
          <div className="col-12">
            <Radio
              className="mb-3"
              name={`${fieldName}.notHaveCumulativeGPA`}
              optionName="I do not have a GPA yet."
              checked={getIn(values, `${fieldName}.notHaveCumulativeGPA`)}
              disabled={degreeIndex > 0}
              onChange={handleToggleCumulativeGPAChange}
            />
          </div>
          {!isMasterDegree && (
            <div className="col-12">
              <FieldArray name={`${fieldName}.majors`}>
                {arrayHelpers => (
                  <>
                    {(values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.majors || []).map(
                      (value, index, array) => (
                        <React.Fragment key={`${fieldName}.majors[${index}]`}>
                          <div className="col-12 d-flex align-items-center justify-content-between">
                            <Label required>Major</Label>
                            {array.length > 1 && <RemoveButton onClick={() => arrayHelpers.remove(index)} />}
                          </div>
                          <div className="col-12">
                            <Select
                              className="mb-2"
                              name={`${fieldName}.majors[${index}].major`}
                              placeholder="Major"
                              value={getOption(majorsOptions, value?.major, 'id')}
                              error={getError(`${fieldName}.majors[${index}].major`)}
                              options={majorsOptions}
                              onChange={(value, metaData) => onMajorChange(value, metaData, index)}
                              onMenuClose={() => handleSetSelectFieldTouched(`${fieldName}.majors[${index}].major`)}
                              isLoading={isMajorsOptionsLoading}
                              required
                            />
                          </div>
                          {value?.major === OTHER_MAJOR_MINOR_ID && (
                            <>
                              <div className="col-12">
                                <Input
                                  className="mb-2"
                                  name={`${fieldName}.majors[${index}].otherMajor`}
                                  placeholder="Write-In"
                                  value={value?.otherMajor}
                                  error={getError(`${fieldName}.majors[${index}].otherMajor`)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                              <Select
                                className="mb-2"
                                name={`${fieldName}.majors[${index}].category`}
                                placeholder="Major Category"
                                value={getOption(majorCategoriesOptions, value?.category, 'id')}
                                error={getError(`${fieldName}.majors[${index}].category`)}
                                options={majorCategoriesOptions}
                                onChange={(value, metaData) => onMajorCategoryChange(value, metaData, index)}
                                onMenuClose={() =>
                                  handleSetSelectFieldTouched(`${fieldName}.majors[${index}].category`)
                                }
                                isLoading={isMajorsOptionsLoading}
                                required
                              />
                            </>
                          )}
                          {value?.category === OTHER_MAJOR_CONCENTRATION_CATEGORY_ID && (
                            <div className="col-12">
                              <Input
                                className="mb-2"
                                name={`${fieldName}.majors[${index}].otherCategory`}
                                placeholder="Write-In"
                                value={value?.otherCategory}
                                error={getError(`${fieldName}.majors[${index}].otherCategory`)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          )}
                          {value?.major !== MAJOR_UNDECIDED_ID && (
                            <>
                              <div className="col-12">
                                <Input
                                  className="mb-2"
                                  name={`${fieldName}.majors[${index}].gpa`}
                                  label="Major GPA"
                                  placeholder="Ex.: 3.98 or 3.0"
                                  value={value?.gpa}
                                  error={getError(`${fieldName}.majors[${index}].gpa`)}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  disabled={
                                    !(
                                      getIn(values, `${fieldName}.cumulativeGPA`) &&
                                      getIn(values, `${fieldName}.includeCumulativeGPAOnResume`)
                                    )
                                  }
                                />
                              </div>
                              <div className="col-12">
                                <Checkbox
                                  className="mb-3"
                                  name={`${fieldName}.majors[${index}].includeOnResume`}
                                  optionName="Include on resume and profile."
                                  checked={value?.includeOnResume}
                                  onChange={handleChange}
                                  disabled={
                                    !(
                                      getIn(values, `${fieldName}.cumulativeGPA`) &&
                                      getIn(values, `${fieldName}.includeCumulativeGPAOnResume`)
                                    )
                                  }
                                />
                              </div>
                            </>
                          )}
                        </React.Fragment>
                      )
                    )}
                    <div className="col-12">
                      <AddButton className="mb-3" onClick={() => arrayHelpers.push({ ...majorInitialValues })}>
                        Add Major
                      </AddButton>
                    </div>
                  </>
                )}
              </FieldArray>
              <FieldArray name={`${fieldName}.minors`}>
                {arrayHelpers => (
                  <>
                    {(values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.minors || []).map(
                      (value, index) => (
                        <React.Fragment key={`${fieldName}.minors[${index}]`}>
                          <div className="col-12 d-flex align-items-center justify-content-between">
                            <Label>Minor</Label>
                            <RemoveButton onClick={() => arrayHelpers.remove(index)} />
                          </div>
                          <div className="col-12">
                            <Select
                              className="mb-2"
                              name={`${fieldName}.minors[${index}].minor`}
                              placeholder="Name of Minor"
                              value={getOption(minorsOptions, value?.minor, 'id')}
                              error={getError(`${fieldName}.minors[${index}].minor`)}
                              options={minorsOptions}
                              onChange={(value, metaData) => onMinorChange(value, metaData, index)}
                              isLoading={isMinorsOptionsLoading}
                            />
                          </div>
                          {value?.minor === OTHER_MAJOR_MINOR_ID && (
                            <div className="col-12">
                              <Input
                                className="mb-2"
                                name={`${fieldName}.minors[${index}].otherMinor`}
                                placeholder="Write-In"
                                value={value?.otherMinor}
                                error={getError(`${fieldName}.minors[${index}].otherMinor`)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )
                    )}
                    <div className="col-12">
                      <AddButton onClick={() => arrayHelpers.push({ ...minorInitialValues })}>Add Minor</AddButton>
                    </div>
                  </>
                )}
              </FieldArray>
            </div>
          )}
          {isMasterDegree && (
            <div className="col-12">
              <FieldArray name={`${fieldName}.concentrations`}>
                {arrayHelpers => (
                  <>
                    {(values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]?.concentrations || []).map(
                      (value, index, array) => (
                        <React.Fragment key={`${fieldName}.concentrations[${index}]`}>
                          <div className="col-12 d-flex align-items-center justify-content-between">
                            <Label htmlFor={`${fieldName}.concentrations[${index}].concentration`}>Concentration</Label>
                            {array.length > 1 && <RemoveButton onClick={() => arrayHelpers.remove(index)} />}
                          </div>
                          <div className="col-12">
                            <Input
                              className="mb-3"
                              name={`${fieldName}.concentrations[${index}].concentration`}
                              placeholder="Name of Concentration (Write In)"
                              value={value?.concentration}
                              error={getError(`${fieldName}.concentrations[${index}].concentration`)}
                              onChange={event => onConcentrationChange(event, index)}
                              onBlur={handleBlur}
                            />
                          </div>
                          <Select
                            className="mb-3"
                            name={`${fieldName}.concentrations[${index}].category`}
                            label="Concentration Category"
                            placeholder="Select or Type Your Category"
                            value={getOption(majorCategoriesOptions, value?.category, 'id')}
                            error={getError(`${fieldName}.concentrations[${index}].category`)}
                            options={majorCategoriesOptions}
                            onChange={(value, metaData) => onConcentrationCategoryChange(value, metaData, index)}
                            onMenuClose={() =>
                              handleSetSelectFieldTouched(`${fieldName}.concentrations[${index}].category`)
                            }
                            isLoading={isMajorCategoriesOptionsLoading}
                            isDisabled={!value?.concentration}
                            required
                          />
                          {value?.category === OTHER_MAJOR_CONCENTRATION_CATEGORY_ID && (
                            <div className="col-12">
                              <Input
                                className="mb-2"
                                name={`${fieldName}.concentrations[${index}].otherCategory`}
                                placeholder="Write-In"
                                value={value?.otherCategory}
                                error={getError(`${fieldName}.concentrations[${index}].otherCategory`)}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </div>
                          )}
                          <div className="col-12">
                            <Input
                              className="mb-3"
                              name={`${fieldName}.concentrations[${index}].gpa`}
                              label="Concentration GPA"
                              placeholder="Concentration (Write In)"
                              value={value?.gpa}
                              disabled={!value?.concentration}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {values?.[NAME_PREFIX]?.[activeDegreeFormIndex]?.[degreeIndex]
                            ?.includeCumulativeGPAOnResume && (
                            <div className="col-12">
                              <Checkbox
                                className="mb-3"
                                name={`${fieldName}.concentrations[${index}].includeOnResume`}
                                optionName="Include on resume and profile."
                                checked={value?.includeOnResume}
                                disabled={!value?.concentration}
                                onChange={handleChange}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )
                    )}
                    <AddButton onClick={() => arrayHelpers.push({ ...concentrationInitialValues })}>
                      Add Concentration
                    </AddButton>
                  </>
                )}
              </FieldArray>
            </div>
          )}
        </FormRow>
      </Paper>
    </div>
  );
};

export default DegreeForm;
