import { useState } from 'react';

const useModal = (initialMode = null, initialParams = {}) => {
  const [showModal, setShowModal] = useState(initialMode);
  const [modalParams, setModalParams] = useState(initialParams);

  return { showModal, setShowModal, modalParams, setModalParams };
};

export default useModal;
