export const getOpportunity = state => state.opportunity.opportunity;

export const getOpportunities = state => state.opportunity.data?.opportunities;

export const getOpportunitiesMeta = state => state.opportunity.data?.meta;

export const getIsLoadingSearch = state => state.opportunity.isLoadingSearch;

export const getIsLoading = state => state.opportunity.isLoading;

export const getIsLoadingApply = state => state.opportunity.isLoadingApply;

export const getIsLoadingAmount = state => state.opportunity.isLoadingAmount;

export const getError = state => state.opportunity.error;

export const getAmountOpportunities = state => state.opportunity.amount;

export const getIsSettingTag = state => state.opportunity.isSettingTag;

export const getErrorApply = state => state.opportunity.errorApply;

export const getOpportunityFilters = state => state.opportunity?.filters;
