import * as yup from 'yup';

import { STRING_WITH_EMPTY_SPACE } from 'constant/regex';

const REQUIRED_MESSAGE = 'Required';

export interface StudyAbroadExperience {
  international: boolean;
  name: string;
  program: string;
  country?: string;
  state?: string;
  stateCity?: string;
  timeOfStudy: {
    year: string;
    periodOfStudy: string;
  };
  includeOnResume: boolean;
  city?: string;
  cityName?: string;
  periodOfStudy?: string;
  year?: string;
}

export const studyAbroadExperienceInitialValues: StudyAbroadExperience = {
  international: true,
  name: '',
  program: '',
  country: '',
  state: '',
  stateCity: '',
  timeOfStudy: {
    year: '',
    periodOfStudy: '',
  },
  includeOnResume: false,
  city: '',
  cityName: '',
  periodOfStudy: '',
  year: '',
};

export const validationSchema = yup.object().shape({
  studiesAbroad: yup.array().of(
    yup.object().shape({
      international: yup.boolean().nullable().required(REQUIRED_MESSAGE),
      name: yup.string().nullable().required(REQUIRED_MESSAGE),
      program: yup.string().nullable().optional(),
      // if international is true, then universityCountry is required
      country: yup
        .string()
        .nullable()
        .when('international', {
          is: true,
          then: yup.string().nullable().required(REQUIRED_MESSAGE),
        }),
      // if international is true, then universityCity as a string is required
      countryCity: yup
        .string()
        .nullable()
        .when('international', {
          is: true,
          then: yup
            .string()
            .nullable()
            .matches(STRING_WITH_EMPTY_SPACE, 'This field can only contain alphabetic characters')
            .required(REQUIRED_MESSAGE),
        }),
      state: yup
        .string()
        .nullable()
        .when('international', {
          is: false,
          then: yup.string().nullable().required(REQUIRED_MESSAGE),
        }),
      stateCity: yup
        .string()
        .nullable()
        .when('international', {
          is: false,
          then: yup.string().nullable().required(REQUIRED_MESSAGE),
        }),
      year: yup.string().nullable().required(REQUIRED_MESSAGE),
      periodOfStudy: yup.string().nullable().required(REQUIRED_MESSAGE),
      includeOnResume: yup.boolean().nullable().optional(),
    })
  ),
});
