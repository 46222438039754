import * as yup from 'yup';

import { CourseworkFormValues } from 'store/v2/level/types';
export const courseWorkFormInitialValues: CourseworkFormValues = {
  course: '',
  majorCategory: '',
  majorCategoryName: '',
  added: false,
  includeOnResume: false,
  coursework: [],
};

export const OTHER_FIELD_ID = 32 as const;

export const validationSchema = yup.object().shape({
  course: yup.string().min(2, 'Must be at least 2 characters'),
  majorCategory: yup.string(),
  majorCategoryName: yup.string().min(2, 'Must be at least 2 characters'),
  otherCategory: yup.string().when('majorCategory', {
    is: majorCategory => majorCategory === OTHER_FIELD_ID.toString(),
    then: yup.string().min(2, 'Must be at least 2 characters').required('Required'),
    otherwise: yup.string().nullable(),
  }),
});
