import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { createReducer } from 'reduxsauce';

import { companyTypes } from 'store/company/actions';
import { initialState } from 'store/company/initial-state';

const searchRequest = (state, { filters }) => ({
  ...state,
  filters: {
    ...state?.filters,
    ...filters,
  },
  isLoadingSearch: true,
  error: null,
});

const searchSuccess = (state, { companies, meta }) => ({
  ...state,
  data: {
    ...state.data,
    meta,
    companies: meta.current_page > 1 ? [...state.data?.companies, ...companies] : companies,
  },
  isLoadingSearch: initialState.isLoadingSearch,
  error: null,
});

const searchError = (state, { error }) => ({
  ...state,
  error,
  isLoadingSearch: initialState.isLoadingSearch,
});

const togglesRequest = state => ({
  ...state,
  isLoadingToggles: true,
  error: initialState.error,
});

const togglesSuccess = (state, { data, company }) => ({
  ...state,
  data,
  company,
  isLoadingToggles: initialState.isLoadingToggles,
  error: initialState.error,
});

const togglesError = (state, { error }) => ({
  ...state,
  error,
  isLoadingToggles: initialState.isLoadingToggles,
});

const companyRequest = state => ({
  ...state,
  isLoading: true,
  error: initialState.error,
  company: initialState.company,
});

const companySuccess = (state, { company }) => ({
  ...state,
  company,
  isLoading: initialState.isLoading,
  error: initialState.error,
});

const companyError = (state, { error }) => ({
  ...state,
  error,
  isLoading: initialState.isLoading,
  company: initialState.company,
});

const companyOpportunitiesRequest = state => ({
  ...state,
  isLoadingOpportunities: true,
  error: initialState.error,
});

const companyOpportunitiesSuccess = (state, { opportunities, meta }) => {
  const newOpportunities =
    !isEmpty(state.data?.opportunities) && meta.current_page > 1
      ? state.data?.opportunities.concat(opportunities)
      : opportunities;
  const opportunitiesFiltered = uniqBy(newOpportunities, opportunity => opportunity.id);
  const data = { meta, opportunities: opportunitiesFiltered };
  return {
    ...state,
    data,
    isLoadingOpportunities: initialState.isLoadingOpportunities,
    error: initialState.error,
  };
};

const companyOpportunitiesError = (state, { error }) => ({
  ...state,
  error,
  isLoadingOpportunities: initialState.isLoadingOpportunities,
  opportunities: initialState.opportunities,
});

const reducer = createReducer(initialState, {
  [companyTypes.COMPANY_SEARCH_REQUEST]: searchRequest,
  [companyTypes.COMPANY_SEARCH_SUCCESS]: searchSuccess,
  [companyTypes.COMPANY_SEARCH_ERROR]: searchError,

  [companyTypes.COMPANY_FIND_REQUEST]: companyRequest,
  [companyTypes.COMPANY_FIND_SUCCESS]: companySuccess,
  [companyTypes.COMPANY_FIND_ERROR]: companyError,

  [companyTypes.COMPANY_TOGGLES_REQUEST]: togglesRequest,
  [companyTypes.COMPANY_TOGGLES_SUCCESS]: togglesSuccess,
  [companyTypes.COMPANY_TOGGLES_ERROR]: togglesError,

  [companyTypes.COMPANY_OPPORTUNITIES_REQUEST]: companyOpportunitiesRequest,
  [companyTypes.COMPANY_OPPORTUNITIES_SUCCESS]: companyOpportunitiesSuccess,
  [companyTypes.COMPANY_OPPORTUNITIES_ERROR]: companyOpportunitiesError,
});

export default reducer;
