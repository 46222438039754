import { Error404 } from '@hivediversity/common-lib/components/errors';
import { routes } from '@hivediversity/common-lib/constant';
import React, { lazy, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect, useHistory, useRouteMatch } from 'react-router-dom';

import { listLevels, progressStatus, error404Links, levelRoutes, levelBRoutes } from 'constant';
import { DIVERSITY_TO_HOME_WORK_ELIGIBILITY } from 'constant/routes';
import { getProgress } from 'store/progress/selectors';
import { getStudent } from 'store/student/selectors';

const Home = lazy(() => import('pages/home'));
const Levels = lazy(() => import('pages/levels'));
const Dashboard = lazy(() => import('pages/dashboard'));
const Opportunity = lazy(() => import('pages/opportunity'));
const Level = lazy(() => import('pages/level'));
const LevelCongrats = lazy(() => import('pages/level/congrats'));
const LevelAllSet = lazy(() => import('pages/v2/level/components/all-set/AllSet'));
const LevelV2 = lazy(() => import('pages/v2/level'));

const WithIntercomRoutes = () => {
  const { push } = useHistory();
  const [redirectToWorkEligibility, setRedirectToWorkEligibility] = useState(false);

  const match = useRouteMatch({
    path: Object.values({ ...levelRoutes, ...levelBRoutes }),
    exact: true,
  });

  const student = useSelector(getStudent);
  const userProgress = useSelector(getProgress);

  const levelPC = (userProgress?.levels || []).find(l => l.level === listLevels.PC);

  const handleGoBackButtonClick = useCallback(() => {
    push(routes.ROOT);
  }, []);

  const Page404 = useCallback(() => {
    const links = error404Links.map(link => ({
      ...link,
      disabled: link.path === routes.PROFILE && levelPC?.status !== progressStatus.COMPLETE,
    }));

    return <Error404 links={links} goBackButton={{ label: 'Back to Home Page', onClick: handleGoBackButtonClick }} />;
  }, [levelPC]);

  useEffect(() => {
    if (student) {
      if (student.workEligibilityNeedsReset && !redirectToWorkEligibility) {
        setRedirectToWorkEligibility(true);
        push(DIVERSITY_TO_HOME_WORK_ELIGIBILITY);
      }
    }
  }, [student]);

  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Route exact path="/dashboard" component={Dashboard} />
      {/* Duplicate */}
      <Route exact path="/opportunity/:opportunityId" component={Opportunity} />
      <Route exact path="/levels" component={Levels} />
      <Route exact path="/levels/:id/all-set" component={LevelAllSet} />
      {match && <LevelV2 />}
      <Route exact path="/level/:id" component={Level} />
      <Route exact path="/levels/congrats" component={LevelCongrats} />
      <Route exact path="/404" component={Page404} />
      <Redirect exact from="/home" to="/" />
      <Redirect exact from="/login" to="/" />
      <Redirect from="*" to="/404" />
    </Switch>
  );
};

export default WithIntercomRoutes;
