import { EmptyPage } from '@hivediversity/common-lib/base';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useHistory } from 'react-router-dom';

import { DIVERSITY_TO_HOME_WORK_ELIGIBILITY } from 'constant/routes';
import progressCreator from 'store/progress/actions';
import { getIsLoading, getProgress } from 'store/progress/selectors';
import { getStudent } from 'store/student/selectors';

import WithIntercomRoutes from './with-intercom-routes';

const DiversityFlow = lazy(() => import('pages/diversity-flow'));
const Level = lazy(() => import('pages/level'));
const MyAccount = lazy(() => import('pages/my-account'));
const MyResume = lazy(() => import('pages/my-resume'));
const Company = lazy(() => import('pages/company'));
const Profile = lazy(() => import('pages/profile'));
const ProfileSent = lazy(() => import('pages/profile-sent'));
const ResumeSent = lazy(() => import('pages/resume-sent'));
const UploadPhoto = lazy(() => import('pages/upload-photo'));
const WhyOurCompany = lazy(() => import('pages/why-our-company'));

const WithSessionRoutes = () => {
  const dispatch = useDispatch();
  const userProgress = useSelector(getProgress);
  const isLoadingProgress = useSelector(getIsLoading);
  const student = useSelector(getStudent);
  const [redirectToWorkEligibility, setRedirectToWorkEligibility] = useState(false);
  const { push } = useHistory();

  const environment = process.env.REACT_APP_ENV;

  useEffect(() => {
    if (!isLoadingProgress && !userProgress) {
      dispatch(progressCreator.progressFetchRequest());
    }
  }, []);

  useEffect(() => {
    if (student?.workEligibilityNeedsReset && !redirectToWorkEligibility) {
      setRedirectToWorkEligibility(true);
      push(DIVERSITY_TO_HOME_WORK_ELIGIBILITY);
    }
  }, [student]);

  return (
    <Suspense fallback={<EmptyPage $bsStyle={{ background: 'transparent' }} />}>
      <Switch>
        {environment === 'develop' && <Route exact path="/form-builder/:id" component={Level} />}
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/my-account" component={MyAccount} />
        <Route exact path="/uploadPhoto" component={UploadPhoto} />
        <Route exact path="/my-resume" component={MyResume} />
        <Route exact path="/diversity" component={DiversityFlow} />
        <Route exact path="/company/:companyId" component={Company} />
        <Route exact path="/resume-sent/:opportunityId" component={ResumeSent} />
        <Route exact path="/profile-sent/:opportunityId" component={ProfileSent} />
        <Route exact path="/why-our-company/:opportunityId" component={WhyOurCompany} />
        <WithIntercomRoutes />
      </Switch>
    </Suspense>
  );
};

export default WithSessionRoutes;
