import { isEmpty, isBoolean } from 'lodash';

import { types } from '../constant';

const getDefaultValue = (data, values = {}, withoutNames) => {
  if (!data || isEmpty(data)) {
    return {};
  }
  return data.reduce((acc, element, index) => {
    if (!element.control && isEmpty(data[index].form)) {
      return acc;
    }

    if (data[index].form) {
      return { ...acc, ...getDefaultValue(data[index].form) };
    }

    let value = {
      [element.control]:
        data[element.control] ||
        values[element.control] ||
        (isBoolean(element.default) ? element.default : element.default || ''),
    };

    if (element.type === types.SELECT && !withoutNames) {
      value = { ...value, [`${element.control}Name`]: '' };
    }

    return { ...acc, ...value };
  }, {});
};

export default getDefaultValue;
