import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  section: PropTypes.string,
  hideOnEdit: PropTypes.bool,
  hidePreviousButton: PropTypes.bool,
  background: PropTypes.string,
  helperTitle: PropTypes.string,
  helpers: PropTypes.arrayOf(PropTypes.shape({})),
  helperDescription: PropTypes.string,
  backgroundContent: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  description: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  repeatedFromValue: PropTypes.string,
  repeatedFromArray: PropTypes.string,
  closePath: PropTypes.string,
  showHelper: PropTypes.bool,
  showHelperTime: PropTypes.string,
  customValidations: PropTypes.arrayOf(PropTypes.shape({})),
  form: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.shape({}), PropTypes.arrayOf(PropTypes.shape({}))])),
});
