import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import logo from 'assets/png/hello-hive-logo.png';
import { BackButton } from 'components/v2/buttons';
import { Stepper } from 'components/v2/stepper';
import { themesObj } from 'constant';
import registerCreators from 'store/v2/register/actions';

import { Wrapper, Container, Content, Image, Header, StyledStepper } from './RegisterTemplate.styled';

interface RegisterTemplateProps extends React.ComponentProps<typeof Stepper> {
  className?: string;
}

const RegisterTemplate: React.FC<RegisterTemplateProps> = ({ children, steps, activeStep, onStepChange }) => {
  const dispatch = useDispatch();

  const { push } = useHistory();

  const handleBackToLoginPage = useCallback(() => {
    push('/login');

    dispatch(registerCreators.clearFormValues());
  }, [push, dispatch]);

  return (
    <ThemeProvider theme={themesObj.black}>
      <Wrapper>
        <Container className="container px-3">
          <Header className="row">
            <div className="col-4 col-lg-2">
              <Image src={logo} alt="Hive 5" />
            </div>
            <div className="col-12 col-lg-7 order-2 order-lg-1">
              <StyledStepper steps={steps} activeStep={activeStep} onStepChange={onStepChange} />
            </div>
            <div className="col-8 col-lg-3 order-1 order-lg-2 d-flex justify-content-end">
              <BackButton onClick={handleBackToLoginPage}>BACK TO LOG IN PAGE</BackButton>
            </div>
          </Header>
          <div className="row">
            <div className="col-12 col-lg-7 offset-lg-2">
              <Content>{children}</Content>
            </div>
          </div>
        </Container>
      </Wrapper>
    </ThemeProvider>
  );
};

export default RegisterTemplate;
