import React from 'react';
import { PhoneInputProps as ReactPhoneInputProps } from 'react-phone-input-2';

import { ErrorMessage } from 'components/v2/forms/components/error-message';
import { i18n } from 'utils';

import { Wrapper, StyledLabel, StyledInput } from './PhoneInput.styled';

interface PhoneInputProps extends Omit<ReactPhoneInputProps, 'inputClass'> {
  className?: string;
  inputProps: React.InputHTMLAttributes<HTMLInputElement>;
  label?: string;
  error?: string;
  tooltip?: string;
  isHtmlTooltip?: boolean;
}

const defaultTooltip = `
    <p>
      Don&apos;t worry! We won&apos;t spam you with texts, but we can alert you down the road when new jobs and
      positions are available to you as well as other important alerts.
    </p>

    <p style="margin: 0">
      By providing your mobile phone number to HIVE DIVERSITY, you consent to receive messages and marketing from us,
      including but not limited to text messages made with an autodialer. Consent not required for purchase or sign-up,
      and you can opt-out at any time. Message & data rates may apply. Message frequency varies.
    </p>

    <a style="color: white" href="https://hellohive.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
      Privacy Policy
    </a>
  `;

const PhoneInput: React.FC<PhoneInputProps> = ({
  className = '',
  label = 'Cell Phone',
  country = 'us',
  regions = 'north-america',
  disableSearchIcon = true,
  disableCountryCode = true,
  placeholder = i18n.t('register.screen.PLACEHOLDER_PHONE'),
  error = '',
  tooltip = defaultTooltip,
  isHtmlTooltip = true,
  ...props
}) => (
  <Wrapper className={className}>
    {label && (
      <StyledLabel
        htmlFor={props.inputProps?.name}
        required={props.inputProps?.required}
        tooltip={tooltip}
        isHtml={isHtmlTooltip}
      >
        {label}
      </StyledLabel>
    )}
    <StyledInput
      country={country}
      regions={regions}
      disableSearchIcon={disableSearchIcon}
      disableCountryCode={disableCountryCode}
      placeholder={placeholder}
      inputClass="input"
      {...props}
    />
    {error && <ErrorMessage error={error} />}
  </Wrapper>
);

export default PhoneInput;
