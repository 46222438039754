/* eslint-disable  no-param-reassign */
import { clone, includes, isEmpty, isNaN, parseInt, concat, some, template } from 'lodash';

const screenHandler = (sections, element, selectedValue, store) => {
  const hasNotSelected = selectedValue !== 0 && !selectedValue;

  if (hasNotSelected || !element.enableScreens || isEmpty(element.enableScreens)) {
    return sections;
  }

  const value = !isNaN(parseInt(selectedValue)) ? parseInt(selectedValue) : selectedValue;

  const sectionsClone = clone(sections);

  const screenIncluded = element.enableScreens.filter(screen => {
    if (screen.validation && !isEmpty(store)) {
      const isValid = template(screen.validation)({ store }) === 'true';
      if (!isValid) {
        return -1;
      }
    }

    return includes(screen.values, value);
  });

  let screensExcluded = [];

  if (element.relatedDisableValidation && !isEmpty(store)) {
    const isToDisable = template(element.validation)({ store }) === 'true';
    if (isToDisable) {
      screensExcluded = element.enableScreens.filter(screen => !includes(screen.values, value));
    }
  } else {
    screensExcluded = element.enableScreens.filter(screen => !includes(screen.values, value));
  }

  const disabledScreen = element.disabledScreens
    ? element.disabledScreens.filter(screen => includes(screen.values, value))
    : [];

  const concatenatedExcludedScreen = concat(screensExcluded, disabledScreen);

  return sectionsClone.map(screen => {
    if (some(screenIncluded, included => included.id === screen.id)) {
      screen.disabled = false;
      return { ...screen, disabled: false };
    }
    if (some(concatenatedExcludedScreen, excluded => excluded.id === screen.id)) {
      return { ...screen, disabled: true };
    }
    return screen;
  });
};

export default screenHandler;
