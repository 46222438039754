import checkIcon from '@hivediversity/common-lib/assets/svg/check.svg';
import styled from 'styled-components';

export const CheckField = styled.input`
  &:not(:checked),
  &:checked {
    position: absolute;
    left: -9999px;
  }

  &:checked ~ span {
    background-color: #964cfd;
    background-image: url(${checkIcon});
    border: none;
    background-repeat: no-repeat;
  }

  &:checked ~ p {
    opacity: 1;
  }
`;

export const Label = styled.label`
  display: flex;
  cursor: pointer;
  padding: 7px 0;
  align-items: center;
  color: white;
  @media screen and (max-height: 800px) {
    padding: 1.5vh 1vh;
  }
  p {
    @media screen and (max-height: 800px) {
      font-size: 2vh;
    }
  }

  span {
    content: '';
    height: 23px;
    width: 23px;
    display: block;
    flex-shrink: 0;
    margin-right: 20px;
    border: 1px solid #fd4cf4;
    background-size: 50%;
    background: transparent no-repeat center center;
    @media screen and (max-height: 800px) {
      height: 4vh;
      width: 4vh;
      margin-right: 2vh;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  ${({ centered }) => (centered ? `display: flex; align-items: center;` : '')};
  ${({ $bsStyle }) => $bsStyle || ''}
`;

export const WrapperCheck = styled.div`
  display: flex;
  flex-direction: row;
`;

export const CheckboxLabel = styled.p`
  margin-top: 15px;
  ${({ labelStyle }) => labelStyle || ''}
`;
