import styled from 'styled-components';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999999999999999;
  width: 100%;
  height: 100%;
  overflow: ${({ withoutOverflow }) => (withoutOverflow ? 'hidden' : 'auto')};
  outline: 0;
  background-color: ${({ fullPage }) => (fullPage ? 'black' : 'transparent')};
  display: block;
  .profile-wrapper::-webkit-scrollbar {
    display: none;
  }
  ${({ containerStyles }) => containerStyles || ''}
`;

export const Title = styled.label`
  font-size: 16px;
  font-weight: 700;
`;

export const Content = styled.div`
  z-index: 99999;
  background: ${({ background }) => background || 'transparent'};
  position: relative;
  padding: ${({ fullPage }) => (fullPage ? 0 : '1rem')};
  height: ${({ $height }) => $height || '100%'};
  margin: ${({ fullPage }) => (fullPage ? 'initial' : 'auto')};
  max-width: ${({ fullPage }) => (fullPage ? 'initial' : '500px')};
  border-radius: ${({ fullPage }) => (fullPage ? 'initial' : '3px')};
  ${({ $bsStyle }) => $bsStyle || ''}
`;
