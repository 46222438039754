import React from 'react';

import { ErrorMessage } from 'components/v2/forms/components/error-message';

import { Wrapper, StyledLabel, StyledInput } from './Input.styled';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  tooltip?: string;
  error?: string;
  inputClassName?: string;
}

const Input: React.FC<InputProps> = ({
  className = '',
  name = '',
  type = 'text',
  label = '',
  tooltip = '',
  error = '',
  required = false,
  disabled = false,
  inputClassName,
  ...props
}) => (
  <Wrapper className={className}>
    {label && (
      <StyledLabel htmlFor={name} tooltip={tooltip} required={required} disabled={disabled}>
        {label}
      </StyledLabel>
    )}
    <StyledInput
      id={name}
      name={name}
      className={inputClassName}
      type={type}
      disabled={disabled}
      required={required}
      {...props}
    />
    {error && <ErrorMessage error={error} />}
  </Wrapper>
);

export default Input;
