export default {
  LEADERSHIP:
    "Bzzz you're missing some important info! Fly into Level 1C and make sure your leadership activities are affiliated with a category.",
  GPA: "Bzzz you're missing some important info! Fly into Level 1A and make sure you've shared information on your GPA.",
  COURSEWORK:
    "Bzzz you're missing some important info! Fly into Relevant Coursework and make sure you've selected an Academic Area for each course.",
  DEFAULT_ALERT_MESSAGE: "Bzzz you're missing some important info!",
  MESSAGE_VALIDATE_PGA:
    'In order to include your Major GPAs on your resume and profile, you first must include your cumulative GPA on your resume and profile. To update your cumulative GPA, click the left arrow.',
  APPLICATION_CHECKLIST:
    'Mattis euismod nec cursus mauris phasellus arcu. In aliquam sed vitae ipsum ultricies cras venenatis velit duis.Mattis euismod nec cursus mauris phasellrasMattis euismod nec cursus mauris phasellus arcu. In aliquam sed vitae',
  LINKEDIN: 'Bzz, make sure you review your LinkedIn URL!',
  LEADERSHIP_AND_ACTIVITIES: 'Bzz, make sure you review your Leadership & Activities!',
  RESEARCH_ACADEMIC: 'Bzz, make sure you review your Research & Academic Projects!',
  DEGREE_TYPE:
    'Dual Degrees are two full degrees pursued at the same time and carry significantly more required credits for graduation. Single Degrees can carry multiple majors but still have a regular graduation credit requirement.',
  SECONDARY_COLLEGE:
    "For intents and purposes, this is the secondary institution that your degree might be associated with if you're primary institution is arranged this way. (Example: HIVE University might have a Bee School College of Business.)",
};
