import { noBoxShadow } from '@hivediversity/common-lib/styled/css-helpers';
import styled from 'styled-components';

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Title = styled.p`
  text-align: center;
  display: block;
  line-height: 120%;
  color: #ffd633;
  padding-bottom: 1rem;
  position: relative;
  margin-bottom: 15px;
  margin-top: 0;
  &:before {
    content: '';
    display: block;
    height: 1px;
    bottom: 0;
    position: absolute;
    left: 0;
    right: 0;
    background: #222;
  }
`;

export const Wrapper = styled.div`
  background: black;
  padding: 30px;
  color: white;
  border-radius: 25px;
  position: relative;
`;

export const Logo = styled.span`
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  cursor: pointer;
  position: absolute;
  width: auto;
  img {
    width: 54px;
    height: 47px;
    object-fit: contain;
  }
`;

export const Message = styled.label``;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 1rem;
`;

export const Button = styled.button.attrs(({ type }) => ({ type: type || 'button' }))`
  background: linear-gradient(163.82deg, #8b35c9 -7.85%, rgba(255, 255, 255, 0) 142.18%), #53b7c2;
  color: #fff;
  min-width: 150px;
  border: none;
  border-radius: 20px;
  text-transform: uppercase;
  padding: 2px 30px;
  letter-spacing: 0.05em;
  font-family: 'Brandon Grotesque', Arial, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 13px;
  line-height: 221.4%;
  height: auto;
  min-height: 32px;
  align-self: center;
  margin: 0 auto;
  display: block;
  ${noBoxShadow};
`;
