import PropTypes from 'prop-types';

export default PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  section: PropTypes.string,
  subsection: PropTypes.string,
  enableCloseButton: PropTypes.bool,
  enableFooter: PropTypes.bool,
  background: PropTypes.string,
  bodyBackground: PropTypes.string,
  order: PropTypes.number,
  question: PropTypes.string,
  closePath: PropTypes.string,
  form: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      type: PropTypes.string,
      label: PropTypes.string,
      enableScreens: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
});
