import { routes } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import { takeLatest, call, put } from 'redux-saga/effects';

import { setAuthorization } from 'services/http';
import { updateProgress } from 'services/progress';
import { requestConfirmEmail, requestUpdateUser, requestUser } from 'services/user';
import authCreator from 'store/authorization/actions';
import progressCreator from 'store/progress/actions';
import userCreator, { userTypes } from 'store/user/actions';
import { getResponseError, i18n } from 'utils';

export function* fetch() {
  try {
    const response = yield call(requestUser);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(userCreator.userFetchSuccess(response.data));
    } else {
      yield put(userCreator.userFetchError(error));
    }
  } catch (error) {
    yield put(userCreator.userFetchError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* update({ user, progress }) {
  try {
    const response = yield call(requestUpdateUser, user);
    const responseProgress = yield call(updateProgress, progress);
    const error = yield getResponseError(response);
    const errorProgress = yield getResponseError(responseProgress);

    if (!error && !errorProgress) {
      yield put(progressCreator.progressUpdateSuccess(responseProgress.data.progress));
      yield put(userCreator.userUpdateSuccess(response.data));
      yield put(push(routes.DIVERSITY_TO_HOME));
    } else {
      yield put(userCreator.userUpdateError(error || errorProgress));
    }
  } catch (error) {
    yield put(userCreator.userUpdateError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* confirmEmail({ token }) {
  try {
    const response = yield call(requestConfirmEmail, token);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(userCreator.userConfirmEmailSuccess());
      yield put(authCreator.authLoginSuccess(response.data.user, response.data.token));
      yield setAuthorization(response.data.token);
      yield put(push(routes.ON_BOARDING));
    } else {
      yield put(userCreator.userConfirmEmailError(error));
    }
  } catch (error) {
    yield put(userCreator.userConfirmEmailError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* userSaga() {
  yield takeLatest(userTypes.USER_FETCH_REQUEST, fetch);
  yield takeLatest(userTypes.USER_UPDATE_REQUEST, update);
  yield takeLatest(userTypes.USER_CONFIRM_EMAIL_REQUEST, confirmEmail);
}
