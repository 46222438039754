import { FieldArray, Formik } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BackButton, ForwardButton } from 'components/v2/buttons';
import { Form, FormActionButtonsRow, FormRow, Radio } from 'components/v2/forms/components';
import { HeadingLevel } from 'components/v2/heading';
import { levelRoutes, LEVEL_STEPS } from 'constant';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { isResearchAndProgramsLoadingSelector, isResearchAndProgramsCompletedSelector } from 'store/v2/level/selectors';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  locationStatesIsLoadingSelector,
  locationStatesSelector,
  locationStatesErrorSelector,
  locationCountriesIsLoadingSelector,
  locationCountriesSelector,
  yearOptionsIsLoadingSelector,
  yearOptionsSelector,
  yearOptionsLoadingErrorSelector,
  periodOfStudiesOptionsIsLoadingSelector,
  periodOfStudiesOptionsSelector,
  periodOfStudiesOptionsLoadingErrorSelector,
} from 'store/v2/shared/selectors';

import { baseInitialValues, validationSchema } from './form-config';
import { RadioWrapper, StyledHeading, StyledAddButton, StyledParagraph } from './ResearchAndPrograms.styled';
import ResearchAndProgramsForm from './ResearchAndProgramsForm';
const ResearchAndPrograms = () => {
  const dispatch = useDispatch();

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.researchAndPrograms);
  const [showForm, setShowForm] = React.useState(() => initialValues.enhancedAcademicExperiences.length > 0);

  // ** state options
  const isStatesOptionsLoading = useSelector(locationStatesIsLoadingSelector);
  const statesOptions = useSelector(locationStatesSelector);
  const statesOptionsLoadFailure = useSelector(locationStatesErrorSelector);
  // ** countries options
  const isCountriesOptionsLoading = useSelector(locationCountriesIsLoadingSelector);
  const countriesOptions = useSelector(locationCountriesSelector);
  const countriesOptionsLoadFailure = useSelector(locationStatesErrorSelector);

  // ** year options
  const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
  const yearOptions = useSelector(yearOptionsSelector);
  const yearOptionsLoadFailure = useSelector(yearOptionsLoadingErrorSelector);

  // ** period of studies options
  const isPeriodOfStudiesOptionsLoading = useSelector(periodOfStudiesOptionsIsLoadingSelector);
  const periodOfStudiesOptions = useSelector(periodOfStudiesOptionsSelector);
  const periodOfStudiesOptionsLoadFailure = useSelector(periodOfStudiesOptionsLoadingErrorSelector);

  // ** research and programs
  const isResearchAndProgramsLoading = useSelector(isResearchAndProgramsLoadingSelector);

  const isResearchAndProgramsCompleted = useSelector(isResearchAndProgramsCompletedSelector);

  // this are common info so loading them all here, so won't need to load per item basis
  React.useEffect(() => {
    if (!isStatesOptionsLoading && statesOptions.length === 0 && !statesOptionsLoadFailure) {
      dispatch(sharedActionsCreator.statesOptionsRequest());
    }

    if (!isCountriesOptionsLoading && countriesOptions.length === 0 && !countriesOptionsLoadFailure) {
      dispatch(sharedActionsCreator.countriesOptionsRequest());
    }

    if (!isPeriodOfStudiesOptionsLoading && periodOfStudiesOptions.length === 0 && !periodOfStudiesOptionsLoadFailure) {
      dispatch(sharedActionsCreator.periodOfStudiesOptionsRequest());
    }

    if (!isYearOptionsLoading && yearOptions.length === 0 && !yearOptionsLoadFailure) {
      dispatch(sharedActionsCreator.yearOptionsRequest());
    }
  }, [
    statesOptions.length,
    isStatesOptionsLoading,
    countriesOptions.length,
    isCountriesOptionsLoading,
    periodOfStudiesOptions.length,
    isPeriodOfStudiesOptionsLoading,
    yearOptions.length,
    isYearOptionsLoading,
  ]);

  return (
    <div className="container px-0">
      <FormRow>
        <div className="col-12 d-flex">
          <StyledHeading level={HeadingLevel.h2}>Enhanced Experiences: Academic & Research Projects</StyledHeading>
        </div>
        <div className="col-12">
          <StyledParagraph>
            This is where you will include research, class projects, asynchronous certifications and scholarship
            programs that have experiential components to them to help employers understand where you have relevant
            experience that isn&apos;t only in previous internships or jobs.
          </StyledParagraph>
        </div>
      </FormRow>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={values => {
          const nextPage =
            values.enhancedAcademicExperiences.length > 0 ? levelRoutes.additionalDetails : levelRoutes.coursework;
          dispatch(
            levelActionsCreator.saveLevelFormValuesRequest(LEVEL_STEPS.researchAndPrograms, values, true, nextPage)
          );
        }}
      >
        {({ values, setFieldValue, isValid, handleSubmit }) => {
          const handleGoBack = React.useCallback(() => {
            dispatch(
              levelActionsCreator.saveLevelFormValuesRequest(
                LEVEL_STEPS.researchAndPrograms,
                values,
                isResearchAndProgramsCompleted,
                levelRoutes.studyAbroad
              )
            );
          }, [values, isResearchAndProgramsCompleted]);

          const handleRadioChange = e => {
            const isChecked = e.target.value === 'yes';
            const enhancedAcademicExperiences = isChecked ? [baseInitialValues] : [];

            setFieldValue('enhancedAcademicExperiences', enhancedAcademicExperiences);

            dispatch(
              levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.researchAndPrograms, {
                enhancedAcademicExperiences,
              })
            );

            setShowForm(isChecked);
          };

          return (
            <>
              <div className="col-12">
                <RadioWrapper>
                  <Radio name="showForm" optionName="Yes" value="yes" checked={showForm} onChange={handleRadioChange} />
                  <Radio name="showForm" optionName="No" value="no" checked={!showForm} onChange={handleRadioChange} />
                </RadioWrapper>
              </div>
              <Form className="container px-0" onSubmit={handleSubmit}>
                <div>
                  <FieldArray
                    name="enhancedAcademicExperiences"
                    render={({ push, remove }) => (
                      <>
                        {(values.enhancedAcademicExperiences || []).length > 0 ? (
                          <>
                            {values.enhancedAcademicExperiences.map((_val, index) => (
                              <ResearchAndProgramsForm key={index} index={index} remove={remove} />
                            ))}
                            <StyledAddButton onClick={() => push(baseInitialValues)}>Add Another</StyledAddButton>
                          </>
                        ) : null}
                      </>
                    )}
                  />
                  <FormActionButtonsRow>
                    <BackButton
                      className="col-6 col-md-3"
                      disabled={isResearchAndProgramsLoading}
                      onClick={handleGoBack}
                    />
                    <ForwardButton
                      className="col-6 col-md-3"
                      type="submit"
                      isLoading={isResearchAndProgramsLoading}
                      disabled={!isValid || isResearchAndProgramsLoading}
                    />
                  </FormActionButtonsRow>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResearchAndPrograms;
