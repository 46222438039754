import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  levelFetchRequest: ['levelId'],
  levelFetchSuccess: ['data'],
  levelFetchError: ['error'],

  levelSaveRequest: ['levelId', 'store', 'progress'],
  levelSaveSuccess: [null],
  levelSaveError: ['error'],

  levelUpdateRequest: ['levelId', 'store', 'progress', 'hasRedirect', 'closeWindow'],
  levelUpdateSuccess: [null],
  levelUpdateError: ['error'],

  levelCleanState: [null],
});

export const levelsTypes = Types;
export default Creators;
