import React from 'react';

import { StyledSpan } from './ErrorMesage.styled';

interface ErrorMessageProps {
  error: string;
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, className = '' }) => (
  <StyledSpan className={className}>{error}</StyledSpan>
);

export default ErrorMessage;
