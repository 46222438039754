import { createReducer } from 'reduxsauce';

import { studentTypes } from 'store/student/actions';
import { initialState } from 'store/student/initial-state';

const fetchRequest = state => ({
  ...state,
  isLoading: true,
  error: initialState.error,
});

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();
const mapDate = experience => {
  // HPD-1329 auto update the end date to be Present so on the resume the date will read
  if (!experience.endYear && experience.startYear == currentYear && experience.startMonth == currentMonth) {
    experience.endMonth = '13';
  }
  return experience;
};

const fetchSuccess = (state, { data }) => {
  const professionalExperiences = data.professionalExperiences.map(experience => mapDate(experience));
  const otherExperiences = data.otherExperiences.map(experience => mapDate(experience));
  data = { ...data, professionalExperiences: professionalExperiences, otherExperiences: otherExperiences };

  return {
    ...state,
    data,
    isLoading: initialState.isLoading,
    error: initialState.error,
  };
};

const fetchError = (state, { error }) => ({
  ...state,
  error,
  isLoading: initialState.isLoading,
});

const saveImageRequest = state => ({
  ...state,
  isSaving: true,
  error: initialState.error,
});

const saveImageSuccess = (state, { data }) => ({
  ...state,
  data,
  isSaving: initialState.isSaving,
  error: initialState.error,
});

const saveImageError = (state, { error }) => ({
  ...state,
  error,
  isSaving: initialState.isSaving,
});

const clearImageRequest = state => ({
  ...state,
  isSaving: true,
  error: initialState.error,
});

const clearImageSuccess = state => ({
  ...state,
  isSaving: initialState.isSaving,
  error: initialState.error,
});

const clearImageError = (state, { error }) => ({
  ...state,
  error,
  isSaving: initialState.isSaving,
});

const deleteAccountRequest = state => ({
  ...state,
  isDeleting: true,
  error: initialState.error,
});

const deleteAccountSuccess = (state, { data }) => ({
  ...state,
  data,
  isDeleting: initialState.isDeleting,
  error: initialState.error,
});

const deleteAccountError = (state, { error }) => ({
  ...state,
  error,
  isDeleting: false,
});

const changePasswordRequest = state => ({
  ...state,
  isLoadingPassword: true,
  error: null,
  isSuccess: false,
});

const changePasswordSuccess = state => ({
  ...state,
  isLoadingPassword: false,
  error: null,
  isSuccess: true,
});

const changePasswordError = (state, { error }) => ({
  ...state,
  error,
  isLoadingPassword: false,
  isSuccess: false,
});

const setStudent = (state, { data }) => ({
  ...state,
  data,
});

const availableLinesRequest = state => ({
  ...state,
  isLoadingAvailableLines: true,
  errorLines: initialState.errorLines,
  availableLines: initialState.availableLines,
});

const availableLinesSuccess = (state, { availableLines }) => ({
  ...state,
  availableLines,
  isLoadingAvailableLines: initialState.isLoadingAvailableLines,
  errorLines: initialState.errorLines,
});

const availableLinesError = (state, { error }) => ({
  ...state,
  isLoadingAvailableLines: initialState.isLoadingAvailableLines,
  errorLines: error,
  availableLines: initialState.availableLines,
});

const resumeCountRequest = state => ({
  ...state,
  isLoadingResume: true,
  resumeCount: initialState.resumeCount,
  errorResume: initialState.errorResume,
});

const resumeCountSuccess = (state, { resumeCount }) => ({
  ...state,
  resumeCount,
  isLoadingResume: initialState.isLoadingResume,
  errorResume: initialState.errorResume,
});

const setBlockResume = (state, { blockResume }) => ({
  ...state,
  blockResume,
  isLoadingResume: initialState.isLoadingResume,
  errorResume: initialState.errorResume,
});

const resumeCountError = (state, { error }) => ({
  ...state,
  resumeCount: initialState.resumeCount,
  isLoadingResume: initialState.isLoadingResume,
  errorResume: error,
});

const findApplicantRequest = state => ({
  ...state,
  isLoadingApplicant: true,
  error: null,
  applicant: initialState.applicant,
  areAlertsDeleted: false,
});

const findApplicantSuccess = (state, { applicant }) => ({
  ...state,
  applicant,
  isLoadingApplicant: initialState.isLoadingApplicant,
  error: null,
});

const findApplicantError = (state, { error }) => ({
  ...state,
  error,
  isLoadingApplicant: initialState.isLoadingApplicant,
});

const findResumeTempRequest = state => ({
  ...state,
  isLoadingResumeTemp: true,
  error: null,
  resumePdf: initialState.resumePdf,
});

const findResumeTempSuccess = (state, { resumePdf }) => ({
  ...state,
  resumePdf,
  isLoadingResumeTemp: initialState.isLoadingResumeTemp,
  error: null,
});

const findResumeTempError = (state, { error }) => ({
  ...state,
  error,
  isLoadingResumeTemp: initialState.isLoadingResumeTemp,
  resumePdf: initialState.resumePdf,
});

const checkAssignmentValuesRequest = state => ({
  ...state,
  isVerifyingValues: true,
  assignmentErrors: initialState.assignmentErrors,
  verifiedValues: initialState.verifiedValues,
});

const checkAssignmentValuesSuccess = (state, { verifiedValues }) => ({
  ...state,
  verifiedValues,
  isVerifyingValues: initialState.isVerifyingValues,
  assignmentErrors: initialState.assignmentErrors,
});

const checkAssignmentValuesError = (state, { assignmentErrors }) => ({
  ...state,
  assignmentErrors,
  isVerifyingValues: initialState.isVerifyingValues,
  verifiedValues: initialState.verifiedValues,
});

const studentsAlertsFetchRequest = state => ({
  ...state,
  isLoadingAlerts: true,
});

const studentsAlertsFetchSuccess = (state, { alerts }) => ({
  ...state,
  alerts,
  isLoadingAlerts: false,
});

const studentsAlertsFetchError = (state, { error }) => ({
  ...state,
  isLoadingAlerts: false,
  error,
});

const resumeStatusRequest = state => ({
  ...state,
  isLoadingResumeStatus: true,
  resumeError: initialState.resumeError,
});

const resumeStatusSuccess = (state, { percentageOccupied }) => ({
  ...state,
  percentageOccupied,
  isLoadingResumeStatus: initialState.isLoadingResumeStatus,
  resumeError: initialState.resumeError,
});

const resumeStatusError = (state, { resumeError }) => ({
  ...state,
  resumeError,
  isLoadingResumeStatus: initialState.isLoadingResumeStatus,
});

const cleanErrors = state => ({
  ...state,
  assignmentErrors: initialState.assignmentErrors,
  errorResume: initialState.errorResume,
  error: initialState.error,
  errorLines: initialState.errorLines,
  verifiedValues: initialState.verifiedValues,
});

const cleanState = state => ({
  ...state,
  ...initialState,
});

const cleanHighlightRequest = state => ({
  ...state,
});

const cleanHighlightSuccess = (state, { student }) => ({
  ...state,
  student,
});

const cleanHighlightError = (state, { error }) => ({
  ...state,
  error,
});

const removeStudentAlertRequest = state => ({
  ...state,
  deletingAlert: true,
  error: null,
});

const removeStudentAlertSuccess = (state, { alerts }) => ({
  ...state,
  deletingAlert: false,
  error: null,
  alerts,
});

const removeStudentAlertError = (state, { error }) => ({
  ...state,
  error,
});

const removeStudentAlertsRequest = state => ({
  ...state,
  deletingAlerts: true,
  error: null,
  areAlertsDeleted: false,
});

const removeStudentAlertsSuccess = state => ({
  ...state,
  deletingAlerts: false,
  error: null,
  areAlertsDeleted: true,
});

const removeStudentAlertsError = (state, { error }) => ({
  ...state,
  error,
  deletingAlerts: false,
  areAlertsDeleted: false,
});

const reducer = createReducer(initialState, {
  [studentTypes.STUDENT_FETCH_REQUEST]: fetchRequest,
  [studentTypes.STUDENT_FETCH_SUCCESS]: fetchSuccess,
  [studentTypes.STUDENT_FETCH_ERROR]: fetchError,

  [studentTypes.STUDENT_SAVE_IMAGE_REQUEST]: saveImageRequest,
  [studentTypes.STUDENT_SAVE_IMAGE_SUCCESS]: saveImageSuccess,
  [studentTypes.STUDENT_SAVE_IMAGE_ERROR]: saveImageError,

  [studentTypes.STUDENT_CLEAR_IMAGE_REQUEST]: clearImageRequest,
  [studentTypes.STUDENT_CLEAR_IMAGE_SUCCESS]: clearImageSuccess,
  [studentTypes.STUDENT_CLEAR_IMAGE_ERROR]: clearImageError,

  [studentTypes.STUDENT_DELETE_ACCOUNT_REQUEST]: deleteAccountRequest,
  [studentTypes.STUDENT_DELETE_ACCOUNT_SUCCESS]: deleteAccountSuccess,
  [studentTypes.STUDENT_DELETE_ACCOUNT_ERROR]: deleteAccountError,

  [studentTypes.STUDENT_CHANGE_PASSWORD_REQUEST]: changePasswordRequest,
  [studentTypes.STUDENT_CHANGE_PASSWORD_SUCCESS]: changePasswordSuccess,
  [studentTypes.STUDENT_CHANGE_PASSWORD_ERROR]: changePasswordError,

  [studentTypes.STUDENT_AVAILABLE_LINES_REQUEST]: availableLinesRequest,
  [studentTypes.STUDENT_AVAILABLE_LINES_SUCCESS]: availableLinesSuccess,
  [studentTypes.STUDENT_AVAILABLE_LINES_ERROR]: availableLinesError,

  [studentTypes.STUDENT_FETCH_RESUME_COUNT]: resumeCountRequest,
  [studentTypes.STUDENT_SET_RESUME_COUNT]: resumeCountSuccess,
  [studentTypes.STUDENT_SET_BLOCK_RESUME]: setBlockResume,
  [studentTypes.STUDENT_SET_RESUME_ERROR]: resumeCountError,

  [studentTypes.STUDENT_FIND_APPLICANT_REQUEST]: findApplicantRequest,
  [studentTypes.STUDENT_FIND_APPLICANT_SUCCESS]: findApplicantSuccess,
  [studentTypes.STUDENT_FIND_APPLICANT_ERROR]: findApplicantError,

  [studentTypes.STUDENT_FIND_TEMP_RESUME_REQUEST]: findResumeTempRequest,
  [studentTypes.STUDENT_FIND_TEMP_RESUME_SUCCESS]: findResumeTempSuccess,
  [studentTypes.STUDENT_FIND_TEMP_RESUME_ERROR]: findResumeTempError,

  [studentTypes.STUDENT_CHECK_ASSIGNMENT_VALUES_REQUEST]: checkAssignmentValuesRequest,
  [studentTypes.STUDENT_CHECK_ASSIGNMENT_VALUES_SUCCESS]: checkAssignmentValuesSuccess,
  [studentTypes.STUDENT_CHECK_ASSIGNMENT_VALUES_ERROR]: checkAssignmentValuesError,

  [studentTypes.STUDENTS_ALERTS_FETCH_REQUEST]: studentsAlertsFetchRequest,
  [studentTypes.STUDENTS_ALERTS_FETCH_SUCCESS]: studentsAlertsFetchSuccess,
  [studentTypes.STUDENTS_ALERTS_FETCH_ERROR]: studentsAlertsFetchError,

  [studentTypes.STUDENT_RESUME_STATUS_REQUEST]: resumeStatusRequest,
  [studentTypes.STUDENT_RESUME_STATUS_SUCCESS]: resumeStatusSuccess,
  [studentTypes.STUDENT_RESUME_STATUS_ERROR]: resumeStatusError,

  [studentTypes.STUDENT_CLEAN_HIGHLIGHT_REQUEST]: cleanHighlightRequest,
  [studentTypes.STUDENT_CLEAN_HIGHLIGHT_SUCCESS]: cleanHighlightSuccess,
  [studentTypes.STUDENT_CLEAN_HIGHLIGHT_ERROR]: cleanHighlightError,

  [studentTypes.STUDENT_SET]: setStudent,
  [studentTypes.STUDENT_CLEAN_ERRORS]: cleanErrors,
  [studentTypes.STUDENT_CLEAN_STATE]: cleanState,

  [studentTypes.REMOVE_STUDENT_ALERT_REQUEST]: removeStudentAlertRequest,
  [studentTypes.REMOVE_STUDENT_ALERT_SUCCESS]: removeStudentAlertSuccess,
  [studentTypes.REMOVE_STUDENT_ALERT_ERROR]: removeStudentAlertError,

  [studentTypes.REMOVE_STUDENT_ALERTS_REQUEST]: removeStudentAlertsRequest,
  [studentTypes.REMOVE_STUDENT_ALERTS_SUCCESS]: removeStudentAlertsSuccess,
  [studentTypes.REMOVE_STUDENT_ALERTS_ERROR]: removeStudentAlertsError,
});

export default reducer;
