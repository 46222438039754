import { CustomButton } from '@hivediversity/common-lib/buttons';
import { Link } from 'react-router-dom';
import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  :root{
    /* Color Primary */
    --color-primary: #278A96;
    --color-primary-dark: #21666C;

    /* Color Secondary */
    --color-secondary: #FFBA1F;
    --color-secondary-light: #FEE16C;


    /* Color White */
    --color-white: hsl(0, 0%, 100%);

    /* Color Black*/
    --color-black: #212121;

    /* Color LightBlue Dropdown*/
    --color-dropdown: #3d9ed0;

    /* Tags: types of jobs */
    --tag-int-ft: #54daea;
    --tag-int-pt: #5ca7bf;
    --tag-full-time: #47c1b0;
    --tag-part-time: #3d9ed0;
    --tag-event: #5af1bc;

  }

  html {
    height: -webkit-fill-available;
  }

  body {
    margin: 0;
    overscroll-behavior: none;
    font-family: 'Poppins', Arial, -apple-system, BlinkMacSystemFont, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0.02px;
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }

  *, :after, :before { box-sizing: border-box; }

  html, body, #root {
    scroll-behavior: smooth;
    min-height: -webkit-fill-available;
    height: 100%;
    overflow: hidden;
    font-feature-settings:"liga" 0;
  }

  body {
    scroll-behavior: smooth;
    min-height: -webkit-fill-available;
    height: 100%;
    overflow: auto;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    width: 100vw;
    max-width: 100vw;
  }

  .layout {
    display: flex;
    height: 100%;
    background-color: #000;
    flex-direction: column;
    overflow: auto;
    max-width: 100%;
    margin: 0 auto;
    background-size: 60px;
    background-repeat: no-repeat;
    background-position: right 20px top 20px;
    overflow-x: hidden;
  }

  label {
    margin-bottom: 0;
  }

  input, textarea, select {
    letter-spacing: 0.02px;
  }

  textarea {
    -webkit-transform: translateZ(0px)!important;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  .invisible {
    visibility:hidden!important;
    text-shadow:none!important;
    box-shadow:none!important
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 .5rem;
    font-weight: 500;
    line-height: 1.2;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  h1 {
    font-size:2.5rem
  }

  h2 {
    font-size:2rem
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size:1.5rem
  }

  h5 {
    font-size:1.25rem
  }

  h6 {
    font-size:1rem
  }

  h6 {
    margin: 0;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.2;
  }

  img, svg {
    vertical-align: middle;
  }

  img {
    border-style: none;
  }

  button {
    cursor: pointer;
  }

  button, input {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
  }

  button:active, button:focus {
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .small-triangle {
    width: 0;
    height: 0;
    border-style: solid;
    display: inline-block;
    border-width: 2.5px 0 2.5px 5px;
    border-color: transparent transparent transparent #ffbe33;
  }

  .small-triangle-alt {
    width: 0;
    display: inline-block;
    height: 0;
    border-style: solid;
    border-width: 4.97px 0 4.97px 5px;
    border-color: transparent transparent transparent #ffbe33;
  }

  .multi-step {
    display: block;
    flex-direction: column;
    flex: 1;
    position: relative;
    overflow: auto;
    overflow-x: hidden;
  }

  .userway-s4-2-ff, .userway-s4-3-ff, .userway-s4-4-ff .lhcLZY {
    margin-bottom: 25px!important;
  }

  .special-label {
    display: none!important;
  }
  #sidebar-container-profile #blankSection {
    z-index: -1;
  }

  #sidebar-container #toggle-icon+div {
    transform: translatex(-230px);
    @media (max-width: 430px) {
      transform: translatex(-430px);
    }
  }

  #sidebar-container #toggle-icon+div[transform="0"] {
    transform: inherit             ;
  }

  #toggle-icon {
    top: 35px;
    left: 16px;
    @media (max-width: 430px) {
      top: 42px;
    }
  }
`;

export const RoundButton = styled.button`
  border: none;
  width: 68px;
  height: 68px;
  border-radius: 100%;
  color: #ffffff;
  font-size: 7px;
  text-align: center;
  display: flex;
  align-items: center;
  background: linear-gradient(
      160deg,
      rgba(139, 53, 201, 0.9) 0%,
      rgba(175, 115, 218, 0.7) 40%,
      rgba(255, 255, 255, 0.2) 100%
    ),
    #53b7c2;
  line-height: normal;
  justify-content: center;
  font-family: 'Verdana', Arial, serif;
  font-weight: 600;
  position: absolute;
  top: 24px;
  z-index: 999999999;
  margin-bottom: -60px;
  margin-left: -10px;
  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const StyledCustomButton = styled(CustomButton)`
  background-color: #fab211;
  border-radius: 8px;
  height: auto;
  min-height: 60px;
  font-size: 18px;
  font-weight: bold;
  line-height: 125%;
  width: 100%;
  text-transform: initial;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  margin: 0;
  padding: 0;
  max-width: initial;
  &:disabled {
    background-color: #fab211;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const StyledLink = styled(Link)`
  background-color: #fab211;
  border-radius: 8px;
  height: auto;
  min-height: 60px;
  font-size: 16px;
  font-weight: 900;
  line-height: 125%;
  padding: 10px;
  margin: 10px;
  width: 100%;
  text-transform: initial;
  font-family: 'Poppins', sans-serif;
  color: #fff;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ $bsStyle }) => $bsStyle || ''};
`;
