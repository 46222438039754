import { create, CancelToken } from 'apisauce';
import * as Cache from 'cache';

import { clearToken, getToken, setToken } from './storage';

const cache = new Cache(5 * 60 * 1000); // Cache TTL - 5 minutes in milliseconds.

let cancel;

const http = create(
  getToken()
    ? {
        baseURL: process.env.REACT_APP_URL_BASE,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        timeout: 60000,
        cancelToken: new CancelToken(c => {
          cancel = c;
        }),
      }
    : {
        baseURL: process.env.REACT_APP_URL_BASE,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        timeout: 60000,
        cancelToken: new CancelToken(c => {
          cancel = c;
        }),
      }
);

http.addResponseTransform(response => {
  if (!response.ok) {
    console.error('Error in response:', response);
  }
});

export const httpFile = create(
  getToken()
    ? {
        baseURL: process.env.REACT_APP_URL_BASE,
        headers: {
          Authorization: `Bearer ${getToken()}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        responseType: 'blob',
        timeout: 60000,
      }
    : {
        baseURL: process.env.REACT_APP_URL_BASE,
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        responseType: 'blob',
        timeout: 60000,
      }
);

export const uploader = create({
  baseURL: process.env.REACT_APP_URL_BASE,
  headers: {
    Authorization: `Bearer ${getToken()}`,
    'Content-Type': 'multipart/form-data',
  },
  timeout: 60000,
});

export const setAuthorization = token => {
  setToken(token);
  uploader.setHeader('Authorization', `Bearer ${token}`);
  return http.setHeader('Authorization', `Bearer ${token}`);
};

const navMonitor = async response => {
  const isAuthRequest = response.config.url === '/auth/login';
  if (response.status === 401 && !isAuthRequest) {
    clearToken();
    window.location.href = '/';
  }
};

// eslint-disable-next-line consistent-return
const navMonitorRequest = config => {
  const isExampleLevel = window.location.pathname === '/form-builder/example';
  if (isExampleLevel) {
    cancel();
  }
  return config;
};

const errorInterceptor = response => {
  if (response.status === 404) {
    window.location.href = '/404';
  }

  return response;
};

/**
 * Send a GET request to the given URL or fetch the result from cache.
 * @param {String} url a URL where the request is send.
 * @returns {Promise<any>} A promise with the response body when the request is completed.
 */
export const getOrCached = async (url, params = {}, axiosConfig = {}) => {
  const key = url + '||' + JSON.stringify(params) + '||' + axiosConfig?.baseURL;
  const cached = await cache.get(key);
  if (cached) {
    return cached;
  }
  const result = await http.get(url, params, axiosConfig);
  // Only cache if request succeeded.
  if (result.status === 200) {
    await cache.put(key, result);
  }
  return result;
};

http.axiosInstance.interceptors.request.use(navMonitorRequest);
http.axiosInstance.interceptors.response.use(errorInterceptor);

httpFile.axiosInstance.interceptors.response.use(errorInterceptor);

http.addAsyncResponseTransform(navMonitor);

export default http;
