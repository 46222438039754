import styled from 'styled-components';

import { AddButton } from 'components/v2/buttons';
import { Heading } from 'components/v2/heading';

export const StyledHeading = styled(Heading)`
  text-align: left;
`;
export const StyledParagraph = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.1px;

  color: #ffffff;
  margin-bottom: 16px;
`;

export const StyledAddButton = styled(AddButton)`
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;
