import styled from 'styled-components';

export const StyledPaper = styled.div`
  background: #1c1a2b;
  border-radius: 4px;
  padding: 16px;

  @media screen and (min-width: 768px) {
    padding: 24px;
  }
`;
