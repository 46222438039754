const getDiversityName = diversity => {
  if (diversity.otherStatus) {
    return `${diversity.categoryName} (${diversity.otherStatus})`;
  }
  if (diversity.statusName) {
    return `${diversity.categoryName} (${diversity.statusName})`;
  }
  return diversity.categoryName;
};

export default getDiversityName;
