import { CloseButton } from '@hivediversity/common-lib/buttons';
import React, { cloneElement } from 'react';
import ReactDOM from 'react-dom';

import { Container, Content, Overlay, Title } from './styled';

interface CustomModalProps {
  id?: string;
  children: JSX.Element;
  title?: string;
  isActive?: boolean | string;
  handleClose?: () => void;
  background?: string;
  height?: boolean;
  fullPage?: boolean;
  withoutOverflow?: boolean;
  $bsStyle?: Record<string, unknown>;
  containerStyles?: Record<string, unknown>;
  closeButtonStyles?: Record<string, unknown>;
}

const CustomModal: React.FC<CustomModalProps> = ({
  id,
  children,
  isActive = false,
  title = null,
  handleClose = null,
  fullPage = false,
  background = null,
  height = false,
  withoutOverflow = false,
  $bsStyle = {},
  containerStyles = {},
  closeButtonStyles = {},
  ...props
}) =>
  isActive
    ? ReactDOM.createPortal(
        <>
          <Overlay />
          <Container
            id={id}
            fullPage={fullPage}
            aria-modal
            aria-hidden
            tabIndex={-1}
            role="dialog"
            withoutOverflow={withoutOverflow}
            containerStyles={containerStyles}
          >
            <Content fullPage={fullPage} background={background} $height={height} $bsStyle={$bsStyle}>
              {handleClose && (
                <CloseButton onClick={handleClose} $bsStyle={{ position: 'fixed', ...closeButtonStyles }} />
              )}
              {title && <Title>{title}</Title>}
              {cloneElement(children, { ...props })}
            </Content>
          </Container>
        </>,
        document.body
      )
    : null;

export default CustomModal;
