import { CustomButton } from '@hivediversity/common-lib/buttons';
import { colors } from '@hivediversity/common-lib/constant';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef } from 'react';

import { Container, SideBarRight, Wrapper, Menu, CloseButton } from './styled';

const SidebarButton = ({ placeholder, children, close, setClose }) => {
  const containerRef = useRef();
  const initialWidth = 400;
  const [position, setPosition] = useState(initialWidth);

  useEffect(() => {
    if (close && setClose) {
      setPosition(initialWidth);
      setClose(false);
    }
  }, [close]);

  const toggleMenu = () => {
    if (position > 0) {
      setPosition(0);
    } else {
      setPosition(initialWidth);
    }
  };

  const handleClickOutside = event => {
    if (!containerRef || (containerRef && containerRef.current && !containerRef.current.contains(event.target))) {
      setPosition(initialWidth);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Container>
      <Wrapper>
        <CustomButton color={colors.VIOLET} onClick={toggleMenu} $bsStyle="min-width: 100px; width: 170px;">
          {placeholder}
        </CustomButton>
      </Wrapper>
      <SideBarRight ref={containerRef} transform={position} width={`${initialWidth}px`}>
        <CloseButton id="toggle-icon" onClick={toggleMenu} close={position !== initialWidth} isOpen={position === 0}>
          <span className={`top ${position === 0 ? 'open' : ''}`} />
          <span className={`mid ${position === 0 ? 'open' : ''}`} />
          <span className={`bot ${position === 0 ? 'open' : ''}`} />
        </CloseButton>
        <Menu>{children}</Menu>
      </SideBarRight>
    </Container>
  );
};

SidebarButton.propTypes = {
  placeholder: PropTypes.string,
  setClose: PropTypes.func,
  close: PropTypes.bool,
};

SidebarButton.defaultProps = {
  placeholder: 'Select',
  setClose: undefined,
  close: false,
};

export default SidebarButton;
