import { listLevels } from 'constant';

const getPreviousLevel = levelId => {
  switch (levelId) {
    case listLevels.PC:
      return { id: listLevels.SIX };
    case listLevels.FIVE:
      return { id: listLevels.FOUR };
    case listLevels.SIX:
      return { id: listLevels.FOUR };
    case listLevels.FOUR:
      return { id: listLevels.THREE };
    case listLevels.THREE:
      return { id: listLevels.TWO };
    case listLevels.TWO:
      return { id: listLevels.ONE_D };
    case listLevels.ONE_D:
      return { id: listLevels.ONE_C };
    case listLevels.ONE_C:
      return { id: listLevels.ONE_B };
    case listLevels.ONE_B:
      return { id: listLevels.ONE_A };
    case listLevels.ONE_A:
      return { id: listLevels.ZERO };
    default:
      return null;
  }
};

export default getPreviousLevel;
