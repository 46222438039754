export const getTextWidth = (
  text,
  fontSize,
  fontStyle = 'gentleman_600regular, -apple-system, BlinkMacSystemFont, sans-serif'
) => {
  if (!text) {
    return 0;
  }

  const element = document.createElement('canvas');
  const context = element.getContext('2d');
  context.font = `${fontSize || 14}px ${fontStyle}`;

  return context.measureText(text).width;
};

const adjustTextToWidth = (text, width, maxSize, minSize, adjust) => {
  const textWidth = getTextWidth(text, maxSize - adjust);

  if (!text || !textWidth || textWidth === 0 || width <= 0) {
    return maxSize;
  }
  if (maxSize - adjust <= minSize) {
    return maxSize - adjust;
  }
  if (textWidth <= width) {
    return adjust ? maxSize - adjust : maxSize;
  }

  return adjustTextToWidth(text, width, maxSize, minSize, adjust ? adjust + 1 : 1);
};

export default adjustTextToWidth;
