import getBreadcrumbSelectedItem from './get-breadcrumb-selected-item';

const getBreadcrumbWidthItem = (wrapper, itemsRefs, groupedSections, section) => {
  const selectedItem = getBreadcrumbSelectedItem(groupedSections, section);
  const itemActive = itemsRefs.current[selectedItem];
  const widthWrapper = wrapper.current.scrollLeft;
  const widthLeftActiveItem = itemActive.offsetLeft;
  const paddingWrapper = wrapper.current.offsetLeft;
  return (widthWrapper - widthLeftActiveItem + paddingWrapper) * -1;
};

export default getBreadcrumbWidthItem;
