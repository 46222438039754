import _, { template, mapValues, isArray, toInteger, find } from 'lodash';

const getValuesFromData = (item, fetchedData, element) => {
  const generatedFields = mapValues(element.generateFields, fieldTemplate => {
    try {
      if (isArray(fieldTemplate)) {
        const [control, field] = fieldTemplate;
        return find(fetchedData[control], { id: toInteger(item[control]) })[field];
      }

      return template(fieldTemplate)({ item, fetchedData, _ });
    } catch (e) {
      return undefined;
    }
  });

  return { ...item, ...generatedFields };
};

export default getValuesFromData;
