import { getIn, useFormikContext } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { IconButton, IconPosition } from 'components/v2/buttons';
import { FormRow, Radio, Input, Select, Label, Checkbox } from 'components/v2/forms/components';
import { InputWithCounter } from 'components/v2/forms/components/input-with-counter';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS } from 'constant';
import levelActionsCreator from 'store/v2/level/actions';
import { ResearchAndProgramsFormValues } from 'store/v2/level/types';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  locationStatesIsLoadingSelector,
  locationStatesSelector,
  locationCountriesIsLoadingSelector,
  locationCountriesSelector,
  periodOfStudiesOptionsIsLoadingSelector,
  periodOfStudiesOptionsSelector,
  yearOptionsIsLoadingSelector,
  yearOptionsSelector,
  cacheStateCitiesIsLoadingSelector,
  cacheStateCitiesSelector,
  cacheStateCitiesFailureSelector,
} from 'store/v2/shared/selectors';
import { getOption } from 'utils';

import { FieldArrayHeader, RadioWrapper } from './ResearchAndProgramsForm.styled';

type Props = {
  index: number;
  remove: (index: number) => void;
};

const ResearchAndProgramsForm = ({ index, remove }: Props) => {
  const { handleBlur, handleChange, errors, touched, values, setFieldValue } =
    useFormikContext<ResearchAndProgramsFormValues>();

  const indexValue = values.enhancedAcademicExperiences[index];
  const baseName = `enhancedAcademicExperiences.${index}`;

  const dispatch = useDispatch();

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.researchAndPrograms, values));
  }, 600);

  // ** state options
  const isStatesOptionsLoading = useSelector(locationStatesIsLoadingSelector);
  const statesOptions = useSelector(locationStatesSelector);

  // ** cities options
  const isCacheStateCitiesOptionsLoading = useSelector(cacheStateCitiesIsLoadingSelector(indexValue?.state));
  const cacheStateCitiesOptions = useSelector(cacheStateCitiesSelector(indexValue?.state));
  const cacheStateCitiesOptionsLoadFailure = useSelector(cacheStateCitiesFailureSelector(indexValue?.state));

  // ** countries options
  const isCountriesOptionsLoading = useSelector(locationCountriesIsLoadingSelector);
  const countriesOptions = useSelector(locationCountriesSelector);

  // ** year options
  const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
  const yearOptions = useSelector(yearOptionsSelector);

  // ** period of studies options
  const isPeriodOfStudiesOptionsLoading = useSelector(periodOfStudiesOptionsIsLoadingSelector);
  const periodOfStudiesOptions = useSelector(periodOfStudiesOptionsSelector);

  const handleSelectChange = React.useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value?.id, true);
    },
    [setFieldValue]
  );

  const handleStateChange = React.useCallback(
    (value, metaData) => {
      handleSelectChange(value, metaData);
      setFieldValue(`${baseName}.stateName`, value?.name, true);
      setFieldValue(`${baseName}.city`, '');
    },
    [setFieldValue, baseName, handleSelectChange]
  );
  const handleCityChange = React.useCallback(
    (value, metaData) => {
      handleSelectChange(value, metaData);
      setFieldValue(`${baseName}.cityName`, value?.name, true);
    },
    [setFieldValue, baseName, handleSelectChange]
  );
  const handlePeriodOfStudyChange = React.useCallback(
    (value, metaData) => {
      handleSelectChange(value, metaData);
      setFieldValue(`${baseName}.periodOfStudyName`, value?.name, true);
    },
    [setFieldValue, baseName, handleSelectChange]
  );

  const handleUsOrInternationalChange = React.useCallback(
    event => {
      const isInternational = event.target.value === 'International';

      setFieldValue(`${baseName}.international`, isInternational);

      if (isInternational) {
        setFieldValue(`${baseName}.state`, '', true);
        setFieldValue(`${baseName}.stateName`, '', true);
        setFieldValue(`${baseName}.city`, '', true);
        setFieldValue(`${baseName}.cityName`, '', true);
      } else {
        setFieldValue(`${baseName}.country`, '', true);
        setFieldValue(`${baseName}.countryName`, '', true);
        setFieldValue(`${baseName}.countryCity`, '', true);
      }
    },
    [setFieldValue, baseName, handleSelectChange]
  );

  const handleCountryChange = React.useCallback(
    (value, metaData) => {
      handleSelectChange(value, metaData);
      setFieldValue(`${baseName}.countryName`, value?.name);
    },
    [setFieldValue, baseName, handleSelectChange]
  );

  const handleRemoteChange = React.useCallback(
    event => {
      if (event.target.checked) {
        setFieldValue(`${baseName}.state`, '', true);
        setFieldValue(`${baseName}.stateName`, '', true);
        setFieldValue(`${baseName}.city`, '', true);
        setFieldValue(`${baseName}.cityName`, '', true);

        setFieldValue(`${baseName}.country`, '', true);
        setFieldValue(`${baseName}.countryName`, '', true);
        setFieldValue(`${baseName}.countryCity`, '', true);
      }
      handleChange(event);
    },
    [handleChange, setFieldValue, baseName]
  );

  const handleThroughoutCollegeChange = React.useCallback(
    event => {
      if (event.target.checked) {
        setFieldValue(`${baseName}.year`, '', true);
        setFieldValue(`${baseName}.periodOfStudy`, '', true);
      }
      handleChange(event);
    },
    [handleChange, setFieldValue, baseName]
  );

  React.useEffect(() => {
    if (
      indexValue?.state &&
      !isCacheStateCitiesOptionsLoading &&
      cacheStateCitiesOptions.length === 0 &&
      !cacheStateCitiesOptionsLoadFailure
    ) {
      dispatch(sharedActionsCreator.cacheStateCitiesOptionsRequest(indexValue?.state));
    }
  }, [
    indexValue?.state,
    isCacheStateCitiesOptionsLoading,
    cacheStateCitiesOptions.length,
    cacheStateCitiesOptionsLoadFailure,
  ]);

  const getError = React.useCallback(
    (key: string) => {
      return getIn(touched, key) && getIn(errors, key) ? getIn(errors, key) : '';
    },
    [touched, errors, getIn]
  );

  React.useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  return (
    <>
      <FieldArrayHeader>
        <span className="text">Experience #{index + 1}</span>
        {values.enhancedAcademicExperiences.length > 1 ? (
          <IconButton
            className="research-remove"
            position={IconPosition.right}
            icon="cross"
            onClick={() => remove(index)}
          >
            Remove
          </IconButton>
        ) : null}
      </FieldArrayHeader>
      <Paper>
        <div className="col-12 ">
          <InputWithCounter
            name={`${baseName}.program`}
            label="Name of Program"
            placeholder="Name of Program (Write In)"
            counterLimit={42}
            value={indexValue.program}
            onChange={handleChange}
            onBlur={handleBlur}
            error={getError(`${baseName}.program`)}
            required
          />
        </div>
        <div className="col-12">
          <RadioWrapper>
            <Radio
              name={`${baseName}.international`}
              optionName="US"
              value="US"
              onChange={handleUsOrInternationalChange}
              onBlur={handleBlur}
              checked={!indexValue.international}
            />
            <Radio
              name={`${baseName}.international`}
              optionName="International"
              value="International"
              onChange={handleUsOrInternationalChange}
              onBlur={handleBlur}
              checked={indexValue.international}
            />
          </RadioWrapper>
        </div>
        <div className="col-12 mt-2">
          <Checkbox
            optionName="Remote"
            name={`${baseName}.remote`}
            onBlur={handleBlur}
            onChange={handleRemoteChange}
            checked={indexValue.remote}
          />
        </div>

        {!indexValue.remote ? (
          indexValue.international ? (
            <FormRow>
              <Label required>Location Information</Label>
              <div className="col-6">
                <Select
                  name={`${baseName}.country`}
                  placeholder="Country"
                  value={getOption(countriesOptions, indexValue.country, 'id')}
                  error={getError(`${baseName}.country`)}
                  options={countriesOptions}
                  onChange={handleCountryChange}
                  isLoading={isCountriesOptionsLoading}
                  required
                />
              </div>
              <div className="col-6">
                <Input
                  name={`${baseName}.countryCity`}
                  placeholder="City"
                  value={indexValue.countryCity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError(`${baseName}.countryCity`)}
                />
              </div>
            </FormRow>
          ) : (
            <FormRow>
              <Label required>Location Information</Label>
              <div className="col-6">
                <Select
                  name={`${baseName}.state`}
                  placeholder="State"
                  value={getOption(statesOptions, indexValue.state, 'id')}
                  error={getError(`${baseName}.state`)}
                  options={statesOptions}
                  onChange={handleStateChange}
                  isLoading={isStatesOptionsLoading}
                  required
                />
              </div>
              <div className="col-6">
                <Select
                  name={`${baseName}.city`}
                  placeholder="City"
                  value={getOption(cacheStateCitiesOptions, indexValue.city, 'id')}
                  error={getError(`${baseName}.city`)}
                  options={cacheStateCitiesOptions}
                  onChange={handleCityChange}
                  isLoading={isCacheStateCitiesOptionsLoading}
                  required
                />
              </div>
            </FormRow>
          )
        ) : null}

        <FormRow>
          <Checkbox
            name={`${baseName}.isThroughoutCollege`}
            optionName="Throughout College"
            onBlur={handleBlur}
            onChange={handleThroughoutCollegeChange}
            checked={indexValue.isThroughoutCollege}
          />
        </FormRow>
        {!indexValue.isThroughoutCollege ? (
          <FormRow>
            <Label required>Time of Study</Label>
            <div className="col-6">
              <Select
                name={`${baseName}.year`}
                placeholder="Academic Year"
                value={getOption(yearOptions, indexValue.year, 'id')}
                error={getError(`${baseName}.year`)}
                options={yearOptions}
                onChange={handleSelectChange}
                isLoading={isYearOptionsLoading}
                required
              />
            </div>
            <div className="col-6">
              <Select
                name={`${baseName}.periodOfStudy`}
                placeholder="Period of Study"
                value={getOption(periodOfStudiesOptions, indexValue.periodOfStudy, 'id')}
                error={getError(`${baseName}.periodOfStudy`)}
                options={periodOfStudiesOptions}
                onChange={handlePeriodOfStudyChange}
                isLoading={isPeriodOfStudiesOptionsLoading}
                required
              />
            </div>
          </FormRow>
        ) : null}

        <div className="col-12">
          <Checkbox
            name={`${baseName}.includeOnResume`}
            optionName="Include on resume and profile."
            onChange={handleChange}
            checked={indexValue.includeOnResume}
          />
        </div>
      </Paper>
    </>
  );
};

export default ResearchAndProgramsForm;
