import styled from 'styled-components';

import { Label } from 'components/v2/forms/components';

export const Wrapper = styled.div`
  margin-bottom: 24px;
  text-align: left;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 8px;
  color: ${props => (props.isDisabled ? '#4A485B' : '#fff')};
`;
