import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: 1306px) {
    display: grid;
    flex-wrap: nowrap;
    grid-auto-columns: 1fr;
    grid-column-gap: 14px;
    grid-row-gap: 14px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: auto auto;
  }
  @media screen and (max-width: 991px) {
    margin-bottom: 20px;
    display: flex;
    flex-flow: wrap;
  }
  @media screen and (max-width: 767px) {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
  }
  @media screen and (max-width: 420px) {
    display: flex;
    flex-flow: wrap;
  }
`;

export const Button = styled.button`
  flex: 1 1 auto;
  background-color: transparent;
  color: white;
  border: 2px solid #fff;
  padding: 14px 29px;
  border-radius: 8px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 19px;
  line-height: 24px;
  letter-spacing: -0.015em;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  display: flex;

  &:not(:last-child) {
    margin-right: 14px;
  }

  ${({ isActive }) =>
    isActive &&
    css`
      border-color: #febf32;
      color: #febf32;
    `};

  @media screen and (max-width: 1306px) {
    margin: 0 !important;
    padding: 12px 14px;
    font-size: 16px;
    line-height: 20px;
  }
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 18px;
    padding: 8px 9px 8px 9px;
  }
`;
