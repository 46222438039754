import { IconButton } from '@hivediversity/common-lib/components/icons';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { origins } from 'constant';
import { getAlerts } from 'store/student/selectors';
import { getAlertsByLevel } from 'utils';

import { Warning } from './styled';

const WarningIcon = ({ showTooltip, onClick, level, origin }) => {
  const alerts = useSelector(getAlerts);
  const levelAlerts = getAlertsByLevel(alerts, level, origin);
  return (
    <>
      {(levelAlerts.length > 0 || showTooltip) && (
        <IconButton
          icon={Warning}
          hasAction
          tooltipConfig={{ backgroundColor: 'black' }}
          bsStyleContainer={{ width: '30px', cursor: 'pointer' }}
          onClick={onClick}
        />
      )}
    </>
  );
};

WarningIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
  showTooltip: PropTypes.bool,
  origin: PropTypes.oneOf([origins.HOME, origins.LEVELS]).isRequired,
  level: PropTypes.string,
};

WarningIcon.defaultProps = {
  showTooltip: false,
  level: undefined,
};

export default WarningIcon;
