import filter from 'lodash/filter';
import groupBy from 'lodash/groupBy';

import { sections as sectionTypes } from 'constant';

const getIsSection = type => type === sectionTypes.QUESTION || type === sectionTypes.ASSIGNMENTS;

const getGroupSections = sections => {
  const sectionsFiltered = filter(sections, s => s.tag && getIsSection(s.type));
  return groupBy(sectionsFiltered, sf => sf.tag);
};

export default getGroupSections;
