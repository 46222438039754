import styled from 'styled-components';

export const StyledSpan = styled.span`
  display: inline-block;
  color: #ef3f5f;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  margin-top: 4px;
  text-align: center;
`;
