export default {
  BUTTON: 'button',
  CHECKBOX: 'checkbox',
  CUSTOM_COMPONENT: 'customComponent',
  CUSTOM_RADIO: 'customRadio',
  DATE: 'date',
  INPUT: 'input',
  INPUT_WITH_COUNTER: 'inputWithCounter',
  OPTION: 'option',
  POINTS: 'points',
  RADIO: 'radio',
  ROW: 'row',
  SELECT: 'select',
  CUSTOM_SELECT: 'customSelect',
  CUSTOM_MULTI_SELECT: 'customMultiSelect',
  SWITCH: 'switch',
  TEXT: 'text',
  TEXT_AREA: 'textArea',
  YES_NO_BUTTONS: 'yesNoButtons',
  BUTTON_PLUS: 'buttonPlus',
  LIST_BUTTON_PLUS: 'ListButtonPlus',
  PHONE_INPUT: 'phoneInput',
  LINES: 'lines',
  CHECK: 'check',
  MULTI_SELECT: 'multiSelect',
  CREATABLE_MULTI_SELECT: 'creatableMultiSelect',
  REPEATABLE: 'repeatable',
  SKIP_BUTTON: 'skipButton',
  VIDEO: 'video',
  ITEMS_ROW: 'itemsRow',
};
