import { takeLatest, call, put, select, fork } from 'redux-saga/effects';

import { breakpoints, LEVEL_STEPS, progressStatus } from 'constant';
import { allLevelSteps } from 'pages/v2/level/levelSteps';
import { saveLevelRequest } from 'services/level_v2';
import { updateProgress } from 'services/progress';
import { history } from 'store';
import progressCreator from 'store/progress/actions';
import { getProgress } from 'store/progress/selectors';
import studentActionCreators from 'store/student/actions';
import { getStudent } from 'store/student/selectors';
import { appendToProgress, i18n } from 'utils';

import levelActionCreators, { levelTypes } from './actions';

export function* saveLevelFormValuesRequestSaga(action) {
  try {
    const levelStep = allLevelSteps.find(levelStep => levelStep.name === action.stepName);
    const levelSteps = allLevelSteps.filter(({ levelName }) => levelStep?.levelName === levelName);
    const levelStepIndex = levelSteps.findIndex(levelStep => levelStep.name === action.stepName);

    if (levelStepIndex >= 0) {
      const student = yield select(getStudent);
      const userProgress = yield select(getProgress);

      const oldLevelProgress = (userProgress?.levels || []).find(level => level.name === levelStep?.levelName);

      const newProgress = appendToProgress({
        levelId: levelStep?.levelName,
        typeId: student.typeId,
        previousProgress: userProgress,
        breakpoint: breakpoints.LEVELS,
        // Do not clear a previous completed steps
        screen: oldLevelProgress?.screen > levelStepIndex ? oldLevelProgress?.screen : levelStepIndex,
        status:
          oldLevelProgress?.status === progressStatus.COMPLETE || action?.lastLevelStep
            ? progressStatus.COMPLETE
            : progressStatus.INCOMPLETE,
      });

      yield fork(updateProgress, newProgress);
      yield put(progressCreator.progressUpdateSuccess(newProgress));
    }

    const response = yield call(saveLevelRequest, action.stepName, action.values);

    yield put(levelActionCreators.saveLevelFormValuesSuccess(action.stepName, action.values, action.isCompleted));
    yield put(studentActionCreators.studentFetchSuccess(response.data || {}));
    if (action.redirectTo) {
      yield call(history.push, action.redirectTo);
    }
  } catch (error) {
    yield put(levelActionCreators.saveLevelFormValuesFailure(action.stepName, i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* saveCourseworkFormValuesRequestSaga(action) {
  yield put(
    levelActionCreators.saveLevelFormValuesRequest(
      LEVEL_STEPS.coursework,
      { coursework: action.values.coursework },
      action.isCompleted,
      action.redirectTo
    )
  );
}

export function* levelSaga() {
  yield takeLatest(levelTypes.SAVE_LEVEL_FORM_VALUES_REQUEST, saveLevelFormValuesRequestSaga);
  yield takeLatest(levelTypes.SAVE_COURSEWORK_FORM_VALUES_REQUEST, saveCourseworkFormValuesRequestSaga);
}
