import { listLevels, progressStatus } from 'constant';

import isEmpty from './is-empty';

const getLevelStatus = (userProgress, level, typeId) => {
  // When is a new user (prev is onBoarding)
  if (isEmpty(userProgress) && level.id === listLevels.ONE_A) {
    return progressStatus.UNLOCKED;
  }

  if (isEmpty(userProgress)) {
    return '';
  }

  const level1A = userProgress.find(l => l.level === listLevels.ONE_A);
  const level1B = userProgress.find(l => l.level === listLevels.ONE_B);
  const level1C = userProgress.find(l => l.level === listLevels.ONE_C);
  const level1D = userProgress.find(l => l.level === listLevels.ONE_D && l.typeId === typeId);
  const level2 = userProgress.find(l => l.level === listLevels.TWO && l.typeId === typeId);
  const level3 = userProgress.find(l => l.level === listLevels.THREE && l.typeId === typeId);
  const level4 = userProgress.find(l => l.level === listLevels.FOUR && l.typeId === typeId);
  const level6 = userProgress.find(l => l.level === listLevels.SIX && l.typeId === typeId);
  const levelPC = userProgress.find(l => l.level === listLevels.PC);

  const isComplete = userProgress.find(l => l.level === level.id && l.typeId === typeId);

  if (isComplete && isComplete.status === progressStatus.COMPLETE) {
    return progressStatus.COMPLETE;
  }

  if (!level1A && level.id === listLevels.ONE) {
    return progressStatus.UNLOCKED;
  }

  if (
    level.id === listLevels.ONE &&
    level1A &&
    level1A.status === progressStatus.COMPLETE &&
    level1B &&
    level1B.status === progressStatus.COMPLETE &&
    level1C &&
    level1C.status === progressStatus.COMPLETE &&
    level1D &&
    level1D.status === progressStatus.COMPLETE
  ) {
    return progressStatus.COMPLETE;
  }

  if (level1D && level1D.status === progressStatus.COMPLETE && level.id === listLevels.TWO) {
    return progressStatus.UNLOCKED;
  }

  if (level2 && level2.status === progressStatus.COMPLETE && level.id === listLevels.THREE) {
    return progressStatus.UNLOCKED;
  }

  if (level3 && level3.status === progressStatus.COMPLETE && level.id === listLevels.FOUR) {
    return progressStatus.UNLOCKED;
  }

  if (level4 && level4.status === progressStatus.COMPLETE && level.id === listLevels.SIX) {
    return progressStatus.UNLOCKED;
  }

  if (
    !isEmpty(level6) &&
    level.id === listLevels.PC &&
    level6.status === progressStatus.COMPLETE &&
    (!levelPC || (levelPC && levelPC.status !== progressStatus.COMPLETE))
  ) {
    return progressStatus.UNLOCKED;
  }

  if (levelPC && level.id === listLevels.PC && levelPC.status === progressStatus.COMPLETE) {
    return progressStatus.COMPLETE;
  }

  return '';
};

export default getLevelStatus;
