import styled from 'styled-components';

import { Heading } from 'components/v2/heading';

const StyledSectionHeader = styled(Heading)`
  font-size: 16px;
  font-weight: normal;
`;

export default StyledSectionHeader;
