const isActiveExperience = exp => {
  return exp.activeLines && exp.activeLines > 0;
};

const checkBuzzWordsLength = (experiences, minBuzz = 4, maxBuzz = 7) => {
  const totalActiveWords = experiences.reduce((acc, exp) => {
    for (let index = 0; index < exp?.lines?.length; index++) {
      const line = exp?.lines[index];
      acc += line.isHighlighted && isActiveExperience(exp) ? 1 : 0;
    }

    return acc;
  }, 0);

  return totalActiveWords >= minBuzz && totalActiveWords <= maxBuzz;
};

export default checkBuzzWordsLength;
