import styled from 'styled-components';

import { AddButton } from 'components/v2/buttons';
import { Heading } from 'components/v2/heading';

export const FieldArrayHeader = styled.div`
  .text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height, or 175% */
    color: #ffbe33;
  }
  margin-top: 24px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .remove {
    padding-inline: 0.5rem;
  }
`;

export const StyledAddButton = styled(AddButton)`
  margin-top: 16px;
`;

export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 26px;
`;

export const StyledHeading = styled(Heading)`
  text-align: left;
`;
