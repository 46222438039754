import * as Yup from 'yup';

import { CertificationsAndLicensesFormValues } from 'store/v2/level/types';

export const certificationsFormInitialValues: CertificationsAndLicensesFormValues = {
  certificationOrLicense: '',
  certificationsAndLicences: [],
};

export const MAX_COUNT = 75;

export const validationSchema = Yup.object().shape({
  certificationOrLicense: Yup.string().nullable(),
});
