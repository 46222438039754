import { findIndex } from 'lodash';

const getStartStep = (sections, screen) => {
  if (!screen) {
    return 0;
  }
  let amountDisabled = 0;
  const tagIndex = findIndex(sections, l => l.tag === screen);
  if (tagIndex < 0) {
    return 0;
  }

  for (const [index, s] of sections.entries()) {
    if (index === tagIndex) {
      break;
    }
    if (s.disabled) {
      amountDisabled += 1;
    }
  }
  return tagIndex - amountDisabled;
};

export default getStartStep;
