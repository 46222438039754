import { EmptyPage } from '@hivediversity/common-lib/base';
import { Error404 } from '@hivediversity/common-lib/components/errors';
import { routes } from '@hivediversity/common-lib/constant';
import React, { lazy, Suspense, useCallback } from 'react';
import { Route, Switch, Redirect, useHistory, useRouteMatch } from 'react-router-dom';

import { registerRoutesV2 } from 'constant';
import { VerifyAccount } from 'pages/v2/register/components/verify-account';

const Impersonate = lazy(() => import('pages/impersonate'));
const Login = lazy(() => import('pages/v2/login'));
const Register = lazy(() => import('pages/v2/register'));
const Opportunity = lazy(() => import('pages/opportunity'));
const RecoveryPassword = lazy(() => import('pages/recovery-password'));

const RegisterValidated = lazy(() => import('pages/register-validated'));
const ResetPassword = lazy(() => import('pages/reset-password'));
const Splash = lazy(() => import('pages/splash'));
const VerifyEmail = lazy(() => import('pages/verify-email'));
const Level = lazy(() => import('pages/level'));
const Company = lazy(() => import('pages/company'));

const WithoutSessionRoutes = () => {
  const { push, location } = useHistory();
  const environment = process.env.REACT_APP_ENV;
  const match = useRouteMatch({
    path: ['/register', ...Object.values(registerRoutesV2)],
    exact: true,
  });

  const Page404 = useCallback(
    () => <Error404 goBackButton={{ label: 'Back to Login Page', onClick: () => push(routes.LOGIN) }} />,
    []
  );

  return (
    <Suspense fallback={<EmptyPage />}>
      <Switch>
        {environment === 'develop' && <Route exact path="/form-builder/:id" component={Level} />}
        <Route exact path="/" component={Splash} />
        <Route exact path="/impersonate" component={Impersonate} />
        <Route exact path="/login" render={() => <Login redirectTo={`${location.pathname}${location.search}`} />} />

        {match && <Register />}
        <Route exact path="/register/verify-email" component={VerifyAccount} />

        <Route exact path="/register/:token" component={RegisterValidated} />
        <Route exact path="/recovery" component={RecoveryPassword} />
        <Route exact path="/recovery/:token" component={ResetPassword} />
        <Route exact path="/verify-email/:code" component={VerifyEmail} />
        <Route exact path="/opportunity/:opportunityId" component={Opportunity} />
        <Route exact path="/company/:companyId" component={Company} />
        <Route exact path="/404" component={Page404} />
        <Redirect exact from="*" to="/login" />
      </Switch>
    </Suspense>
  );
};

export default WithoutSessionRoutes;
