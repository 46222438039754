import { nonSelectable } from '@hivediversity/common-lib/styled/css-helpers';
import styled, { css } from 'styled-components';

export const ButtonStyled = styled.button`
  border: none;
  padding: 10px 30px;
  background: none;
  color: #fff;
  font-size: 22px;
  border-radius: 8px;
  cursor: pointer;
  opacity: 1;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 50px;
  }

  ${({ $active }) =>
    $active &&
    css`
      background-color: #43404f;
      color: #febf32;
    `}

  &:hover {
    border-bottom-color: yellow;
  }
  &:active,
  :focus {
    outline: 0;
    opacity: 1;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &:disabled {
    cursor: default;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
  @media screen and (max-width: 1024px) {
    min-width: 146px;
    font-size: 15px;
  }
  @media screen and (max-width: 767px) {
    min-width: 120px;
    font-size: 15px;
  }
  @media screen and (max-width: 420px) {
    min-width: 100px;
  }
`;

export const WrapperButton = styled.div`
  max-width: 500px;
  display: flex;
  margin-bottom: 37px;
  width: 100%;
  ${nonSelectable}
`;
