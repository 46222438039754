import React from 'react';

const LEADERSHIP_LONG_TEXT =
  "You have long titles in Leadership & Activities and they don't fit in a one-line, please make them short and try again:";

export default {
  DEFAULT_TITLE: 'Helper B',
  EXIT_MESSAGE:
    'When updating sections of your resume and profile, we encourage clicking through all steps within a section to ensure all necessary info fields are completed and saved. If you exit now, some of your information may not save. Are you sure you want to fly away?',
  EXIT_MESSAGE_ONE_C:
    'When updating sections of your resume and profile, we encourage clicking through all steps within a section to ensure all necessary info fields are completed and saved. If you exit now, some of your information may not save. Are you sure you want to fly away?',
  DIALOG_TRIVIA:
    'Make sure you’ve reviewed all of the resources in this level. Trivia isn’t just about the video, it also includes things you should have learned throughout all of the provided content.',
  PRIORITIZE_LINES: `Hey you! To keep your resume at a perfect page or under, you need to be within the amount of lines identified with the lines counter above. Prioritize your most recent and most important experiences when deciding where to remove bullets. Try to reduce some individual line allocations without going below the minimums within an experience, before toggling/turning off an entire experience.`,
  CHECK_AND_TRY_AGAIN: `You’re so close! But you can’t move on until we’ve helped you build a PERFECT one-page resume to show just how amazing you are. Check the following if you are seeing this Helper B message:
        <br /><br />• Your lines counter may be over the available amount and you need to reduce your amount of active lines or prioritize further.
        <br /><br />• You may have a line activated with a green check without content in it. 
        <br /><br />• You may not have the minimum amount of lines for an experience active with a green check. ( 3 for Work, 2 for Leadership and Activities)
        <br /><br />Check things out and try again. You can do it. :)`,
  CLOSE_TO_FINISH: `You’re so close! But you can’t move on until we’ve helped you build a PERFECT one-page resume to show just how amazing you are. Check the following if you are seeing this Helper B message:
        <br /><br />• Your lines counter may be over the available amount and you need to reduce your amount of active lines or prioritize further.
        <br /><br />• You may have a line activated with a green check without content in it. 
        <br /><br />• You may not have the minimum amount of lines for an experience active with a green check. ( 3 for Work, 1 for future Work, 1 for Leadership and Activities)
        <br /><br />Check things out and try again. You can do it. :)`,
  HAVE_TOO_MANY_LINES: 'Bzzzz. You have too many lines',
  CHECK_IS_COMPLETE: 'Check if you completed all required information.',
  ERROR_POSITION_LONG: 'Keep this short and sweet - under 70 characters on position.',
  ERROR_COMPANY_LONG: 'Keep this short and sweet - under 70 characters on company name.',
  ERROR_GROUP_LONG: 'Keep this short and sweet - under 70 characters on group name.',
  ERROR_BUZZ_WORDS:
    'Whoops! You need to select between 4-7 buzzwords before moving forward. See <a href="https://info.hellohive.com/knowledge-base/buzzwords-help/" target="_blank">here</a> for more info or click below to keep going.',
  NOT_SO_FAST: 'Bzzzz. Not so fast!',
  NO_ONE_PAGE_TITLE: `Bzzz. Your resume needs attention.`,
  NOT_ONE_PAGE: `<p>Hey! Changes, additions or updates to the Education or Skills & Interests Sections of your resume sometimes mean that you need to re-prioritize your content, space and lines in your Experience Section to stay within a perfect page.</p><p>And that time is now. :)</p><p>Head to the Experience section of the HIVE Resume Build and let’s get your resume perfect and activated.</p>`,
  LINKEDIN_URL: `Check your linkedin URL. Please check that there are no blank spaces`,
  CHANGE_EMAIL:
    "Changing your student email address will adjust your HIVE DIVERSITY login credentials. Upon completing this change you will be logged out and will receive a confirmation email link to your new .edu address. Don't worry, your information will not be lost. See when you get back!",
  CHANGE_PASSWORD:
    "Changing your password will adjust your Hive login credentials. This change you will be logged out. Don't worry, your information will not be lost. See when you get back!",
  CHANGE_CLASS_STANDING:
    'Changing your class standing locks the HIVE 5 levels in order for you to complete the complete HIVE 5 process for your new class standing. Your current profile and resume information will not be affected',
  HELPER_INFO: `<p> If you have any questions or need help, email us at <a href="mailto:support@hellohive.com">support@hellohive.com</a></p>`,
  CLASS_STANDING_ALERT_TITLE: 'Class Standing',
  CLASS_STANDING_ALERT:
    'Class standing refers to your current year, or if you’re joining us during the summer, your upcoming year’s standing.',
  EMOJI_ALERT_TITLE: 'Be Creative with Emojis',
  EMOJI_ALERT: 'Employers hire people, not just candidates. Show them some personality!',
  SCHOOL_EMAIL_ALERT_TITLE: 'School Email',
  SCHOOL_EMAIL_ALERT:
    'Using your .edu email address ensures employers know you’re a student or recent graduate focused on internship or entry-level positions.',
  TEXT_ALERT_PHONE_TITLE: 'Phone Number',
  TEXT_ALERT_PHONE:
    '<p>Don’t worry! We won’t spam you with texts, but we can alert you down the road when new jobs and positions are available to you as well as other important alerts.</p>' +
    '<p>By providing your mobile phone number to HIVE DIVERSITY, you consent to receive messages and marketing from us, including but not limited to text messages made with an autodialer. Consent not required for purchase or sign-up, and you can opt-out at any time. Message & data rates may apply. Message frequency varies.<br>' +
    '<a href="https://hellohive.com/privacy-policy/" target="_blank">Privacy Policy</a></p>',
  DEFAULT_HELPER_DASHBOARD:
    'Fly into the HIVE5 Process to complete your profile and resume. Once completed, you’ll have access to sweet positions in the #FlyHire Portal.',
  DIVERSITY_HELPER:
    'HIVE offers all students and recent grads the company to share your self-identified diverse backgrounds with potential employers.',
  INSUFFICIENT_CHARACTERS: 'You do not have enough space left.',
  CHECK_VALUES: 'You entered a new item but you need to hit check button to add it to the list, or clear it.',
  STATUS_UPDATED_DASHBOARD:
    'Status Updates: Use the status section at the bottom of each submitted application to keep track of where you are with each application and to inform the HIVE team. When you do we’ll be able to send you more information along with tips to help you be successful as you progress. Thanks!',
  ERROR_AVAILABLE_LINE_MESSAGE:
    'We’re experiencing an issue with your server. Please try revisiting this page in a few minutes. You can contact support@hellohive.com for additional support.',
  ERROR_ALERT_END_DATE:
    'If you’re currently in this role, make sure to change the end date to Present instead of a future date.',
  END_DATE_MUST_BE_AFTER_START_DATE: 'End date must be after start date.',
  ADDITIONAL_DESCRIPTIVE_WORD:
    'Please add more detail to your position name so that employers can learn more about your experience. Focus on the team or department rather than the season of your employment (Spring, Fall, Winter, Summer).',
  MESSAGE_VALIDATE_MAJOR_CATEGORY: 'You must select an Academic Area to proceed.',
  getMessageLongText: errors => (
    <div>
      <label>{LEADERSHIP_LONG_TEXT}</label>
      <ul>
        {errors.map(err => (
          <li key={err}>
            <p style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{err}</p>
          </li>
        ))}
      </ul>
    </div>
  ),
  getErrorMessage: errors => (
    <ul>
      {errors.inner.map((e, index) => (
        <li key={`${e.message}-${index}`}>{`For the field ${e?.params?.path}: ${e?.message}`}</li>
      ))}
    </ul>
  ),
};
