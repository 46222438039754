import { Formik, FieldArray, Form } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BackButton, ForwardButton } from 'components/v2/buttons';
import { FormRow, FormActionButtonsRow, Radio } from 'components/v2/forms/components';
import { HeadingLevel } from 'components/v2/heading';
import { levelRoutes, LEVEL_STEPS } from 'constant';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { isCollegiateAthleticsCompletedSelector, isCollegiateAthleticsLoadingSelector } from 'store/v2/level/selectors';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  periodRangesOptionsErrorSelector,
  periodRangesOptionsIsLoadingSelector,
  periodRangesOptionsSelector,
  sportsOptionsErrorSelector,
  sportsOptionsIsLoadingSelector,
  sportsOptionsSelector,
  universityOptionsIsLoadingSelector,
  universityOptionsLoadingErrorSelector,
  universityOptionsSelector,
  yearOptionsIsLoadingSelector,
  yearOptionsLoadingErrorSelector,
  yearOptionsSelector,
} from 'store/v2/shared/selectors';

import { RadioWrapper, StyledAddButton, StyledHeading } from './CollegiateAthletics.styled';
import CollegiateAthleticsForm from './CollegiateAthleticsForm';
import { collegiateAthleticsDefaultValue, validationSchema } from './form-config';

const CollegiateAthletics: React.FC = () => {
  const dispatch = useDispatch();
  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.collegiateAthletics);
  const [showForm, setShowForm] = React.useState(() => initialValues.d1Athletics.length > 0);

  // ** university options
  const isUniversityOptionsLoading = useSelector(universityOptionsIsLoadingSelector);
  const isUniversityOptionsLoadingError = useSelector(universityOptionsLoadingErrorSelector);
  const universityOptions = useSelector(universityOptionsSelector);

  // ** sports options
  const isSportsOptionsLoading = useSelector(sportsOptionsIsLoadingSelector);
  const isSportsOptionsLoadingError = useSelector(sportsOptionsErrorSelector);
  const sportsOptions = useSelector(sportsOptionsSelector);

  // ** periods range options
  const isPeriodRangesOptionsLoading = useSelector(periodRangesOptionsIsLoadingSelector);
  const isPeriodRangesOptionsLoadingError = useSelector(periodRangesOptionsErrorSelector);
  const periodRangesOptions = useSelector(periodRangesOptionsSelector);

  // * years options
  const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
  const isYearOptionsLoadingError = useSelector(yearOptionsLoadingErrorSelector);
  const yearOptions = useSelector(yearOptionsSelector);

  // ** collegiate athletics
  const isCollegiateAthleticsCompleted = useSelector(isCollegiateAthleticsCompletedSelector);
  const isCollegiateAthleticsLoading = useSelector(isCollegiateAthleticsLoadingSelector);

  React.useEffect(() => {
    if (!isUniversityOptionsLoading && universityOptions.length === 0 && !isUniversityOptionsLoadingError) {
      dispatch(sharedActionsCreator.universityOptionsRequest());
    }

    if (!isYearOptionsLoading && yearOptions.length === 0 && !isYearOptionsLoadingError) {
      dispatch(sharedActionsCreator.yearOptionsRequest());
    }

    if (!isPeriodRangesOptionsLoading && periodRangesOptions.length === 0 && !isPeriodRangesOptionsLoadingError) {
      dispatch(sharedActionsCreator.periodRangesOptionsRequest());
    }
    if (!isSportsOptionsLoading && sportsOptions.length === 0 && !isSportsOptionsLoadingError) {
      dispatch(sharedActionsCreator.sportsOptionsRequest());
    }
  }, [
    universityOptions.length,
    yearOptions.length,
    periodRangesOptions.length,
    sportsOptions.length,
    isUniversityOptionsLoading,
    isYearOptionsLoading,
    isPeriodRangesOptionsLoading,
    isSportsOptionsLoading,
    isUniversityOptionsLoadingError,
    isYearOptionsLoadingError,
    isPeriodRangesOptionsLoadingError,
    isSportsOptionsLoadingError,
  ]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={validationSchema}
      onSubmit={values => {
        const nextPage =
          values.d1Athletics.length > 0
            ? levelRoutes.additionalDetailsCollegiateAthletics
            : levelRoutes.finalStepLevel1A;
        dispatch(
          levelActionsCreator.saveLevelFormValuesRequest(
            LEVEL_STEPS.collegiateAthletics,
            values,
            true,
            nextPage,
            nextPage === levelRoutes.finalStepLevel1A
          )
        );
      }}
    >
      {({ values, isValid, setFieldValue }) => {
        const handleGoBack = () => {
          dispatch(
            levelActionsCreator.saveLevelFormValuesRequest(
              LEVEL_STEPS.collegiateAthletics,
              values,
              isCollegiateAthleticsCompleted,
              levelRoutes.coursework
            )
          );
        };
        const handleRadioChange = e => {
          const isChecked = e.target.value === 'yes';
          const d1Athletics = isChecked ? [collegiateAthleticsDefaultValue] : [];

          setFieldValue('d1Athletics', d1Athletics);

          dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.collegiateAthletics, { d1Athletics }));

          setShowForm(isChecked);
        };

        return (
          <>
            <Form className="container px-0" id="collegiate-athletics-form">
              <FormRow>
                <div className="col-12 d-flex">
                  <StyledHeading level={HeadingLevel.h2}>Have you competed in Collegiate Athletics?</StyledHeading>
                </div>
              </FormRow>
              <FormRow>
                <RadioWrapper>
                  <Radio name="showForm" optionName="Yes" value="yes" checked={showForm} onChange={handleRadioChange} />
                  <Radio name="showForm" optionName="No" value="no" checked={!showForm} onChange={handleRadioChange} />
                </RadioWrapper>
              </FormRow>
              <FieldArray
                name="d1Athletics"
                render={({ push, remove }) => (
                  <>
                    {(values.d1Athletics || []).length > 0 ? (
                      <>
                        {values.d1Athletics.map((_val, index) => (
                          <CollegiateAthleticsForm key={index} index={index} remove={remove} />
                        ))}
                        <StyledAddButton onClick={() => push(collegiateAthleticsDefaultValue)}>
                          Add Another
                        </StyledAddButton>
                      </>
                    ) : null}
                  </>
                )}
              />
              <FormActionButtonsRow>
                <BackButton className="col-6 col-md-3" onClick={handleGoBack} disabled={isCollegiateAthleticsLoading} />
                <ForwardButton
                  className="col-6 col-md-3"
                  type="submit"
                  isLoading={isCollegiateAthleticsLoading}
                  disabled={!isValid || isCollegiateAthleticsLoading}
                  form="collegiate-athletics-form"
                >
                  {values.d1Athletics.length > 0 ? 'NEXT' : 'FINISH'}
                </ForwardButton>
              </FormActionButtonsRow>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default CollegiateAthletics;
