export default [
  'CA',
  'US',
  'AG',
  'AI',
  'AS',
  'BB',
  'BM',
  'BS',
  'DM',
  'DO',
  'GD',
  'GU',
  'JM',
  'MS',
  'MP',
  'KN',
  'KY',
  'LC',
  'PR',
  'SX',
  'TC',
  'TT',
  'VC',
  'VG',
  'VI',
];
