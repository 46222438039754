export default {
  width160: { width: '160px' },

  maxWidth160: { maxWidth: '160px' },
  maxWidth170: { maxWidth: '170px' },
  maxWidth280: { maxWidth: '280px' },
  maxWidth298: { maxWidth: '298px' },

  minWidth20: { minWidth: '20px' },
  minWidth120: { minWidth: '120px' },
  minWidth145: { minWidth: '145px' },

  fullWidth: { width: '100%' },

  fullHeight: { height: '100%' },

  justifyContentSpcBet: { justifyContent: 'space-between' },
  justifyContentMargBottom: { justifyContent: 'space-between', marginBottom: '20px' },
  justifyContentMargTop: { justifyContent: 'space-between', marginTop: '20px' },

  marginVh: { margin: '3vh 0 2vh' },

  marginBottom40: { marginBottom: '40px' },
  marginBottom30: { marginBottom: '30px' },

  marginTop30: { marginTop: '30px' },
  marginTop20: { marginTop: '20px' },
  marginTop10: { marginTop: '10px' },

  buttonNext: { maxWidth: '180px', margin: '10px', fontSize: '15px' },
  buttonGoDashboardStyles: { marginTop: '16px', width: '200px' },
  buttonAppliedModal: {
    width: '165px',
    alignSelf: 'center',
    minHeight: '39px',
    marginBottom: '0',
    marginRight: '5px',
  },
  top15Right15: { top: '15px', right: '15px' },
  h23SelfPd7Initial: { height: '23px', alignSelf: 'center', padding: '0 7px', display: 'initial' },
  buttonMaxWidth150AlignSelf: { maxWidth: '150px', alignSelf: 'center' },
  mWidth150Self: { maxWidth: '150px', alignSelf: 'center' },
  mWidth191SelfMB10: { maxWidth: '191px', alignSelf: 'center', marginBottom: '10px' },
  buttonMarginTop20MaxWidth180: { marginTop: '20px', maxWidth: '180px' },
  buttonTop40Right20: { top: '40px', right: '20px' },
  buttonNextPlay: { maxWidth: '180px', marginBottom: '70px', position: 'absolute' },

  inputMaxW298MarTop30: { maxWidth: '298px', marginTop: '30px' },
  inputHearAboutUs: { maxWidth: '388px', marginTop: '30px', color: '#ffffff' },
  inputAxWidth280Margin: { maxWidth: '280px', margin: '40px 10px 20px 10px' },
  inputMaxWidth170Margin0: { maxWidth: '170px', margin: 0 },
  inputMaxWidth150Margin0: { maxWidth: '150px', margin: 0 },

  displayInline: { display: 'inline-block' },
  displayBlock: { display: 'block' },

  cardSubTitleStyles: { marginLeft: '5px', display: 'inline-block' },
  cardStyles: { justifyContent: 'space-between', margin: '0 0 40px 0', alignItems: 'center' },

  loadingButtonStyles: { maxWidth: '200px', margin: 0 },
  loadingButtonW160AlignSelfMar0: { width: '160px', alignSelf: 'center', margin: 0, padding: '0' },
  loadingButtonW160AlignSelfMarPad: {
    width: '165px',
    marginBottom: '0',
    marginLeft: '5px',
    padding: 0,
  },
  loadingMaxWidth230MarginAuto: { maxWidth: '230px', margin: 'auto' },
  loadingWidth160alignSelf: { width: '160px', alignSelf: 'center' },

  rowCustomDrop: { marginTop: '10px', zIndex: 1 },
  rowMarginBot20Wrap: { marginBottom: '20px', flexWrap: 'wrap' },
  rowMargin30px0px: { margin: '30px 0' },
  rowMargin30px0pxJustifyContent: { margin: '30px 0', justifyContent: 'center' },

  texBoxStyles: { width: '100%', height: '39px' },

  colorErrorMessage: { color: '#ffffff', marginTop: '10px', textAlign: 'center', height: 'auto' },
  messageErrorSendEmail: { color: 'white', fontSize: '26px', fontWeight: 300, textAlign: 'center' },
  messageErrorMarTop22MarBot4: { marginTop: '-22px', marginBottom: '4px' },
  messageErrorMarTop32MarBot4: { marginTop: '-32px', marginBottom: '4px' },

  applyCheckMarginTop: { marginTop: '-16px' },

  cancelSearchStyle: { maxWidth: '180px', alignSelf: 'center', marginTop: '15px' },

  containerPhoneStyles: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  innerPhoneStyles: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    borderColor: 'transparent',
    borderBottomColor: '#fff',
    borderRadius: 0,
    textAlign: 'center',
    opacity: 1,
    width: '100%',
    maxWidth: '400px',
    color: '#fff',
    fontFamily: 'gentleman_600regular',
  },

  buttonStyle: {
    minWidth: '230px',
    maxWidth: '320px',
    width: '100%',
    alignSelf: 'center',
    marginBottom: '20px',
  },

  bigButtonStyle: {
    minWidth: '230px',
    maxWidth: '320px',
    width: '100%',
    alignSelf: 'center',
    marginBottom: '20px',
    lineHeight: '16px',
    height: 'auto',
    paddingTop: '12px',
    paddingBottom: '12px',
    borderRadius: '34px',
  },

  buttonPadding: {
    minWidth: '230px',
    maxWidth: '320px',
    width: '100%',
    alignSelf: 'center',
    marginBottom: '20px',
    paddingLeft: '0px',
    paddingRight: '0px',
  },

  opportunityDropdown: { border: 0, boxShadow: 'initial', paddingBottom: '90px' },

  menuDropdown: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: '6px',
    fontSize: '14px',
    fontFamily: 'gentleman_600regular, -apple-system, BlinkMacSystemFont, sans-serif',
  },

  borderRadius5: { borderRadius: '5px' },

  marginLeft4: { marginLeft: '4px' },

  loadingAssigment: { marginLeft: '4px', display: 'flex', alignItems: 'center' },

  cursorPointer: { cursor: 'pointer' },

  colorWhite: { color: 'white' },

  resumeColumn: { minHeight: '100%', display: 'flex', flexDirection: 'column' },

  dFlexCenter: { justifyContent: 'center', display: 'flex' },

  resumeVideo: { position: 'fixed', zIndex: 0, background: '#000' },

  pinchZoom: { width: '100%', height: '100%', overflow: 'hidden' },

  loader: { display: 'flex', justifyContent: 'center' },

  resumeRoundButton: { position: 'relative', margin: '0', top: '0' },

  resumeJobDescription: { top: '20%', marginLeft: '16px', width: '95%' },
};
