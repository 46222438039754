import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  heardFromUsOptionsRequest: [''],
  heardFromUsOptionsSuccess: ['options'],
  heardFromUsOptionsFailure: ['error'],

  heardFromUsSubOptionsRequest: ['id'],
  heardFromUsSubOptionsSuccess: ['options'],
  heardFromUsSubOptionsFailure: ['error'],

  universityOptionsRequest: [''],
  universityOptionsSuccess: ['options'],
  universityOptionsFailure: ['error'],

  yearOptionsRequest: [''],
  yearOptionsSuccess: ['options'],
  yearOptionsFailure: ['error'],

  studyPeriodOptionsRequest: [''],
  studyPeriodOptionsSuccess: ['options'],
  studyPeriodOptionsFailure: ['error'],

  diversityOptionsRequest: [''],
  diversityOptionsSuccess: ['options'],
  diversityOptionsFailure: ['error'],

  diversitySubOptionsRequest: ['id'],
  diversitySubOptionsSuccess: ['options'],
  diversitySubOptionsFailure: ['error'],

  statesOptionsRequest: [''],
  statesOptionsSuccess: ['options'],
  statesOptionsFailure: ['error'],

  stateCitiesOptionsRequest: ['id'],
  stateCitiesOptionsSuccess: ['options'],
  stateCitiesOptionsFailure: ['error'],

  schoolStateCitiesOptionsRequest: ['id'],
  schoolStateCitiesOptionsSuccess: ['options'],
  schoolStateCitiesOptionsFailure: ['error'],

  countriesOptionsRequest: [''],
  countriesOptionsSuccess: ['options'],
  countriesOptionsFailure: ['error'],

  bachelorDegreeOptionsRequest: ['degreeType'],
  bachelorDegreeOptionsSuccess: ['options'],
  bachelorDegreeOptionsFailure: ['error'],

  masterDegreeOptionsRequest: ['degreeType'],
  masterDegreeOptionsSuccess: ['options'],
  masterDegreeOptionsFailure: ['error'],

  majorsOptionsRequest: [''],
  majorsOptionsSuccess: ['options'],
  majorsOptionsFailure: ['error'],

  minorsOptionsRequest: [''],
  minorsOptionsSuccess: ['options'],
  minorsOptionsFailure: ['error'],

  majorCategoriesOptionsRequest: [''],
  majorCategoriesOptionsSuccess: ['options'],
  majorCategoriesOptionsError: ['error'],

  degreeTypeOptionsRequest: [''],
  degreeTypeOptionsSuccess: ['options'],
  degreeTypeOptionsError: ['error'],

  registerCheckEmailRequest: ['email'],
  registerCheckEmailSuccess: ['exists', 'message'],
  registerCheckEmailError: ['error'],

  sportsOptionsRequest: [''],
  sportsOptionsSuccess: ['options'],
  sportsOptionsFailure: ['error'],

  periodRangesOptionsRequest: [''],
  periodRangesOptionsSuccess: ['options'],
  periodRangesOptionsFailure: ['error'],

  periodOfStudiesOptionsRequest: [''],
  periodOfStudiesOptionsSuccess: ['options'],
  periodOfStudiesOptionsFailure: ['error'],

  cacheStateCitiesOptionsRequest: ['id'],
  cacheStateCitiesOptionsSuccess: ['options', 'id'],
  cacheStateCitiesOptionsFailure: ['error', 'id'],

  proficiencyLevelsOptionsRequest: [''],
  proficiencyLevelsOptionsSuccess: ['options'],
  proficiencyLevelsOptionsFailure: ['error'],

  proficiencySkillCategoriesOptionsRequest: [''],
  proficiencySkillCategoriesOptionsSuccess: ['options'],
  proficiencySkillCategoriesOptionsFailure: ['error'],

  languageProficiencyOptionsRequest: [''],
  languageProficiencyOptionsSuccess: ['options'],
  languageProficiencyOptionsFailure: ['error'],

  languageOptionsRequest: [''],
  languageOptionsSuccess: ['options'],
  languageOptionsFailure: ['error'],
});

export const sharedTypes = Types;

export default Creators;
