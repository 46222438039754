import { FieldArray, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';

import { AddButton, BackButton, ButtonVariant, ForwardButton, IconPosition } from 'components/v2/buttons';
import { Form, FormActionButtonsRow, FormRow, Select } from 'components/v2/forms/components';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS, levelRoutes, tooltipsMessages } from 'constant';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import { bachelorDegreeInitialValues, masterDegreeInitialValues } from 'store/v2/level/initial-state';
import { isAcademicsCompletedSelector, isAcademicsLoadingSelector } from 'store/v2/level/selectors';
import { AcademicsFormValues } from 'store/v2/level/types';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  degreeTypeOptionsErrorSelector,
  degreeTypeOptionsIsLoadingSelector,
  degreeTypeOptionsSelector,
  locationStatesErrorSelector,
  locationStatesIsLoadingSelector,
  locationStatesSelector,
  majorCategoriesOptionsErrorSelector,
  majorCategoriesOptionsIsLoadingSelector,
  majorCategoriesOptionsSelector,
  majorsOptionsIsLoadingSelector,
  majorsOptionsSelector,
  universityOptionsIsLoadingSelector,
  universityOptionsLoadingErrorSelector,
  universityOptionsSelector,
  yearOptionsIsLoadingSelector,
  yearOptionsLoadingErrorSelector,
  yearOptionsSelector,
  majorsOptionsErrorSelector,
  minorsOptionsIsLoadingSelector,
  minorsOptionsSelector,
  minorsOptionsErrorSelector,
} from 'store/v2/shared/selectors';
import { DegreeTypeOption } from 'store/v2/shared/types';
import { getOption } from 'utils';

import { LoaderWrapper, StyledIconButton, StyledPaper } from './Academics.styled';
import { DegreeForm } from './components/degree-form';
import { Honors } from './components/honors';
import { Scholarships } from './components/scholarships';
import { validationSchema } from './form-config';

const UNDERGRADUATE_TAB_INDEX = 0;
const GRADUATE_TAB_INDEX = 1;

const SINGLE_DEGREE = 1;
const DUAL_DEGREE = 2;

const DEGREES_NAME_PREFIX = 'degrees';
const SCHOLARSHIPS_NAME_PREFIX = 'degreeScholarships';
const HONORS_NAME_PREFIX = 'degreeHonors';

const Academics: React.FC = () => {
  const dispatch = useDispatch();

  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.academics);

  const [activeDegreeFormIndex, setActiveDegreeFormIndex] = useState(
    initialValues?.degrees?.findIndex(degree => degree?.length > 0) || 0
  );

  const degreesFieldName = `${DEGREES_NAME_PREFIX}[${activeDegreeFormIndex}]`;
  const scholarshipsFieldName = `${SCHOLARSHIPS_NAME_PREFIX}[${activeDegreeFormIndex}]`;
  const honorsFieldName = `${HONORS_NAME_PREFIX}[${activeDegreeFormIndex}]`;

  const isGraduateTab = activeDegreeFormIndex === GRADUATE_TAB_INDEX;

  const isAcademicsLoading = useSelector(isAcademicsLoadingSelector);
  const isAcademicsCompleted = useSelector(isAcademicsCompletedSelector);

  const isDegreeTypeOptionsLoading = useSelector(degreeTypeOptionsIsLoadingSelector);
  const allDegreeTypeOptions = useSelector(degreeTypeOptionsSelector);
  const degreeTypeOptionsError = useSelector(degreeTypeOptionsErrorSelector);

  const degreeTypeOptions: DegreeTypeOption[] = allDegreeTypeOptions.filter(
    ({ isMaster }) => isGraduateTab === isMaster
  );

  const isMajorsOptionsLoading = useSelector(majorsOptionsIsLoadingSelector);
  const majorsOptions = useSelector(majorsOptionsSelector);
  const majorsOptionsError = useSelector(majorsOptionsErrorSelector);

  const isMinorsOptionsLoading = useSelector(minorsOptionsIsLoadingSelector);
  const minorsOptions = useSelector(minorsOptionsSelector);
  const minorsOptionsError = useSelector(minorsOptionsErrorSelector);

  const isMajorCategoriesOptionsLoading = useSelector(majorCategoriesOptionsIsLoadingSelector);
  const majorCategoriesOptions = useSelector(majorCategoriesOptionsSelector);
  const majorCategoriesOptionsError = useSelector(majorCategoriesOptionsErrorSelector);

  const isUniversityOptionsLoading = useSelector(universityOptionsIsLoadingSelector);
  const universityOptions = useSelector(universityOptionsSelector);
  const universityOptionsError = useSelector(universityOptionsLoadingErrorSelector);

  const isStatesOptionsLoading = useSelector(locationStatesIsLoadingSelector);
  const statesOptions = useSelector(locationStatesSelector);
  const statesOptionsError = useSelector(locationStatesErrorSelector);

  const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
  const yearOptions = useSelector(yearOptionsSelector);
  const yearOptionsError = useSelector(yearOptionsLoadingErrorSelector);

  const areOptionsLoading =
    isDegreeTypeOptionsLoading ||
    isMajorsOptionsLoading ||
    isMinorsOptionsLoading ||
    isMajorCategoriesOptionsLoading ||
    isUniversityOptionsLoading ||
    isStatesOptionsLoading ||
    isYearOptionsLoading;

  const areOptionsEmpty = [
    degreeTypeOptions,
    majorsOptions,
    minorsOptions,
    majorCategoriesOptions,
    universityOptions,
    statesOptions,
    yearOptions,
  ].some(options => options.length === 0);

  const handleUndergraduateClick = useCallback(() => {
    setActiveDegreeFormIndex(UNDERGRADUATE_TAB_INDEX);
  }, [setActiveDegreeFormIndex]);

  const handleGraduateClick = useCallback(() => {
    setActiveDegreeFormIndex(GRADUATE_TAB_INDEX);
  }, [setActiveDegreeFormIndex]);

  useEffect(() => {
    if (!isDegreeTypeOptionsLoading && degreeTypeOptions.length === 0 && !degreeTypeOptionsError) {
      dispatch(sharedActionsCreator.degreeTypeOptionsRequest());
    }

    if (!isMajorsOptionsLoading && majorsOptions.length === 0 && !majorsOptionsError) {
      dispatch(sharedActionsCreator.majorsOptionsRequest());
    }

    if (!isMinorsOptionsLoading && minorsOptions.length === 0 && !minorsOptionsError) {
      dispatch(sharedActionsCreator.minorsOptionsRequest());
    }

    if (!isMajorCategoriesOptionsLoading && majorCategoriesOptions.length === 0 && !majorCategoriesOptionsError) {
      dispatch(sharedActionsCreator.majorCategoriesOptionsRequest());
    }

    if (!isUniversityOptionsLoading && universityOptions.length === 0 && !universityOptionsError) {
      dispatch(sharedActionsCreator.universityOptionsRequest());
    }

    if (!isStatesOptionsLoading && statesOptions.length === 0 && !statesOptionsError) {
      dispatch(sharedActionsCreator.statesOptionsRequest());
    }

    if (!isYearOptionsLoading && yearOptions.length === 0 && !yearOptionsError) {
      dispatch(sharedActionsCreator.yearOptionsRequest());
    }
  }, [
    degreeTypeOptions.length,
    majorsOptions.length,
    minorsOptions.length,
    majorCategoriesOptions.length,
    universityOptions.length,
    statesOptions.length,
    yearOptions.length,
    isDegreeTypeOptionsLoading,
    isMajorsOptionsLoading,
    isMinorsOptionsLoading,
    isMajorCategoriesOptionsLoading,
    isUniversityOptionsLoading,
    isStatesOptionsLoading,
    isYearOptionsLoading,
    degreeTypeOptionsError,
    majorsOptionsError,
    minorsOptionsError,
    majorCategoriesOptionsError,
    universityOptionsError,
    statesOptionsError,
    yearOptionsError,
  ]);

  return (
    <Formik<AcademicsFormValues>
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={values => {
        dispatch(
          levelActionsCreator.saveLevelFormValuesRequest(LEVEL_STEPS.academics, values, true, levelRoutes.studyAbroad)
        );
      }}
    >
      {({ values, setFieldValue, handleSubmit, isValid }) => (
        <Form className="container px-0" onSubmit={handleSubmit}>
          <FieldArray name="degrees">
            {arrayHelpers =>
              areOptionsLoading || areOptionsEmpty ? (
                <LoaderWrapper>
                  <Loader type="TailSpin" color="#febf32" />
                </LoaderWrapper>
              ) : (
                <>
                  <FormRow>
                    <div className="col-12 mb-4 d-flex">
                      {values?.degrees?.[UNDERGRADUATE_TAB_INDEX]?.length === 0 ? (
                        <AddButton
                          onClick={() => {
                            arrayHelpers.replace(UNDERGRADUATE_TAB_INDEX, [{ ...bachelorDegreeInitialValues }]);

                            handleUndergraduateClick();
                          }}
                        >
                          Add Undergraduate
                        </AddButton>
                      ) : (
                        <StyledIconButton
                          icon="cross"
                          position={IconPosition.right}
                          variant={isGraduateTab ? ButtonVariant.basic : ButtonVariant.primary}
                          onClick={handleUndergraduateClick}
                          onIconClick={async event => {
                            event.stopPropagation();

                            if (values?.degrees?.[GRADUATE_TAB_INDEX]) {
                              handleGraduateClick();
                            }

                            await setFieldValue(scholarshipsFieldName, []);
                            await setFieldValue(honorsFieldName, []);

                            arrayHelpers.replace(UNDERGRADUATE_TAB_INDEX, []);

                            dispatch(
                              levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.academics, {
                                ...values,
                                [DEGREES_NAME_PREFIX]: [[], [...values[DEGREES_NAME_PREFIX][1]]],
                                [SCHOLARSHIPS_NAME_PREFIX]: [[], [...values[SCHOLARSHIPS_NAME_PREFIX][1]]],
                                [HONORS_NAME_PREFIX]: [[], [...values[HONORS_NAME_PREFIX][1]]],
                              })
                            );
                          }}
                        >
                          Undergraduate
                        </StyledIconButton>
                      )}
                      {values?.degrees?.[GRADUATE_TAB_INDEX]?.length === 0 ? (
                        <AddButton
                          onClick={() => {
                            arrayHelpers.replace(GRADUATE_TAB_INDEX, [{ ...masterDegreeInitialValues }]);

                            handleGraduateClick();
                          }}
                        >
                          Add Graduate
                        </AddButton>
                      ) : (
                        <StyledIconButton
                          icon="cross"
                          position={IconPosition.right}
                          variant={isGraduateTab ? ButtonVariant.primary : ButtonVariant.basic}
                          onClick={handleGraduateClick}
                          onIconClick={async event => {
                            event.stopPropagation();

                            if (values?.degrees?.[UNDERGRADUATE_TAB_INDEX]) {
                              handleUndergraduateClick();
                            }

                            await setFieldValue(scholarshipsFieldName, []);
                            await setFieldValue(honorsFieldName, []);

                            arrayHelpers.replace(GRADUATE_TAB_INDEX, []);

                            dispatch(
                              levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.academics, {
                                ...values,
                                [DEGREES_NAME_PREFIX]: [[...values[DEGREES_NAME_PREFIX][0]], []],
                                [SCHOLARSHIPS_NAME_PREFIX]: [[...values[SCHOLARSHIPS_NAME_PREFIX][0]], []],
                                [HONORS_NAME_PREFIX]: [[...values[HONORS_NAME_PREFIX][0]], []],
                              })
                            );
                          }}
                        >
                          Graduate
                        </StyledIconButton>
                      )}
                    </div>
                  </FormRow>
                  {values?.degrees?.some(degree => degree?.length > 0) && (
                    <>
                      <Paper className="mb-4">
                        <FormRow>
                          <div className="col-12">
                            <Select
                              name="degreeType"
                              className="mb-0"
                              label="Degree Type"
                              placeholder="Select Degree Type"
                              tooltip={tooltipsMessages.DEGREE_TYPE}
                              value={getOption(
                                degreeTypeOptions,
                                values.degrees?.[activeDegreeFormIndex]?.length,
                                'count'
                              )}
                              options={degreeTypeOptions}
                              onChange={value => {
                                // The case when a student switch from dual degree to single degree
                                if (
                                  (value as DegreeTypeOption)?.count === SINGLE_DEGREE &&
                                  values.degrees?.[activeDegreeFormIndex]?.length === DUAL_DEGREE
                                ) {
                                  setFieldValue(degreesFieldName, [
                                    { ...values.degrees?.[activeDegreeFormIndex]?.[0] },
                                  ]);
                                } else if (
                                  // The case when a student switch from single degree to dual degree
                                  (value as DegreeTypeOption)?.count === DUAL_DEGREE &&
                                  values.degrees?.[activeDegreeFormIndex]?.length === SINGLE_DEGREE
                                ) {
                                  setFieldValue(degreesFieldName, [
                                    { ...values.degrees?.[activeDegreeFormIndex]?.[0] },
                                    {
                                      ...(isGraduateTab ? masterDegreeInitialValues : bachelorDegreeInitialValues),
                                      university: values.degrees?.[activeDegreeFormIndex]?.[0]?.university || null,
                                      state: values.degrees?.[activeDegreeFormIndex]?.[0]?.state || '',
                                      city: values.degrees?.[activeDegreeFormIndex]?.[0]?.city || null,
                                      graduationMonth:
                                        values.degrees?.[activeDegreeFormIndex]?.[0]?.graduationMonth || null,
                                      graduationYear:
                                        values.degrees?.[activeDegreeFormIndex]?.[0]?.graduationYear || null,
                                      cumulativeGPA: values.degrees?.[activeDegreeFormIndex]?.[0]?.cumulativeGPA || '',
                                      includeCumulativeGPAOnResume:
                                        values.degrees?.[activeDegreeFormIndex]?.[0]?.includeCumulativeGPAOnResume ||
                                        false,
                                      notPublishCumulativeGPAs:
                                        values.degrees?.[activeDegreeFormIndex]?.[0]?.notPublishCumulativeGPAs || false,
                                      notHaveCumulativeGPA:
                                        values.degrees?.[activeDegreeFormIndex]?.[0]?.notHaveCumulativeGPA || false,
                                    },
                                  ]);
                                }
                              }}
                              isLoading={isDegreeTypeOptionsLoading}
                              required
                            />
                          </div>
                        </FormRow>
                      </Paper>
                      {values.degrees?.[activeDegreeFormIndex]?.map((degreeForm, index) => (
                        <DegreeForm
                          key={`${degreeForm}_${index}`}
                          activeDegreeFormIndex={activeDegreeFormIndex}
                          degreeIndex={index}
                          degreeType={getOption(
                            degreeTypeOptions,
                            values.degrees?.[activeDegreeFormIndex]?.length,
                            'count'
                          )}
                        />
                      ))}
                      <StyledPaper>
                        <FormRow>
                          <Scholarships activeDegreeFormIndex={activeDegreeFormIndex} />
                          <Honors activeDegreeFormIndex={activeDegreeFormIndex} />
                        </FormRow>
                      </StyledPaper>
                    </>
                  )}
                </>
              )
            }
          </FieldArray>
          <FormActionButtonsRow className="mt-0">
            <BackButton
              className="col-6 col-md-3"
              onClick={() => {
                dispatch(
                  levelActionsCreator.saveLevelFormValuesRequest(
                    LEVEL_STEPS.academics,
                    values,
                    isAcademicsCompleted,
                    levelRoutes.highSchool
                  )
                );
              }}
              disabled={areOptionsLoading || isAcademicsLoading}
            />
            <ForwardButton
              className="col-6 col-md-3"
              type="submit"
              disabled={
                // TODO need to find a way how to validate amount of degrees via yup
                values?.degrees?.every(degree => degree?.length === 0) ||
                !isValid ||
                areOptionsLoading ||
                isAcademicsLoading
              }
              isLoading={isAcademicsLoading}
            />
          </FormActionButtonsRow>
        </Form>
      )}
    </Formik>
  );
};

export default Academics;
