import max from 'lodash/max';

const getAssignmentHeaderLines = (collection, headerSize = 0) => {
  const activeLines = collection.filter(experience => experience.activeLines);
  const headerLines = activeLines.length * headerSize;
  const marginLines = max([0, activeLines.length - 1]) * 0.1406; // 9px
  const titleLines = activeLines.length > 0 ? 2 : 0; // 1px
  return headerLines + marginLines + titleLines;
};

export default getAssignmentHeaderLines;
