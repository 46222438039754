/* eslint-disable no-undef */
import { get, set, del } from 'idb-keyval';

const WHY_OUR_COMPANY_KEY = 'whyOurCompany';
const HAS_VISITED_KEY = 'hasVisited';
const TOKEN_KEY = 'token';
const LAST_PROGRESS_KEY = 'lastProgress';
const LEVEL_1_FIRST_TIME_KEY = 'level1FirstTime';

export const setToken = token => localStorage.setItem(TOKEN_KEY, token);

export const getToken = () => localStorage.getItem(TOKEN_KEY) || null;

export const clearToken = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(LAST_PROGRESS_KEY);
  return null;
};

export const setVisited = () => localStorage.setItem(HAS_VISITED_KEY, '1');

export const getVisited = () => localStorage.getItem(HAS_VISITED_KEY);

export const removeVisited = () => localStorage.removeItem(HAS_VISITED_KEY);

export const getWhyOurCompany = () => {
  localStorage.getItem(WHY_OUR_COMPANY_KEY);
  return get(WHY_OUR_COMPANY_KEY);
};

export const removeWhyOurCompany = () => {
  localStorage.removeItem(WHY_OUR_COMPANY_KEY);
  return del(WHY_OUR_COMPANY_KEY);
};

export const setLocalWhyOurCompany = whyOurCompany => {
  localStorage.setItem(WHY_OUR_COMPANY_KEY, whyOurCompany);
  return set(WHY_OUR_COMPANY_KEY, whyOurCompany);
};

export const getLevel1FirstTime = () => localStorage.getItem(LEVEL_1_FIRST_TIME_KEY);

export const setLevel1FirstTime = value => localStorage.setItem(LEVEL_1_FIRST_TIME_KEY, value);
