const validateText = text => {
  const text2 = text?.split(' ').join('');
  switch (text2) {
    case 'intern':
      return true;
    case 'fallintern':
      return true;
    case 'springintern':
      return true;
    case 'winterintern':
      return true;
    case 'summerintern':
      return true;
    default:
      return false;
  }
};

export default validateText;
