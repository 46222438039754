import { getIn, useFormikContext } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';

import { IconButton, IconPosition } from 'components/v2/buttons';
import { Checkbox, FormRow, Input, Label, Radio, Select } from 'components/v2/forms/components';
import { Paper } from 'components/v2/paper';
import { LEVEL_STEPS } from 'constant';
import levelActionsCreator from 'store/v2/level/actions';
import { StudyAbroadFormValues } from 'store/v2/level/types';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  locationStatesIsLoadingSelector,
  locationStatesSelector,
  locationCountriesIsLoadingSelector,
  locationCountriesSelector,
  cacheStateCitiesIsLoadingSelector,
  cacheStateCitiesSelector,
  cacheStateCitiesFailureSelector,
} from 'store/v2/shared/selectors';
import { getOption } from 'utils';

import TimeOfStudyInput from './TimeOfStudyInput';
import { StyledFormSectionTitle } from '../StudyAbroad.styled';

interface StudyAbroadExperienceFormProps {
  formId: string;
  index: number;
  canBeRemoved: boolean;
  data: any;
  handleRemove: (index: number) => void;
}

interface FormHeaderProps {
  title: string;
  showRemove: boolean;
  handleRemove: () => void;
}

const FormHeader = ({ title, showRemove, handleRemove }: FormHeaderProps) => {
  return (
    <>
      <br />
      <div className="row">
        <StyledFormSectionTitle className="col-12 d-flex align-items-center justify-content-between">
          {title}
          {showRemove && (
            <IconButton className="p-0" position={IconPosition.right} icon="cross" onClick={handleRemove}>
              Remove
            </IconButton>
          )}
        </StyledFormSectionTitle>
      </div>
    </>
  );
};

const StudyAbroadExperienceForm = (props: StudyAbroadExperienceFormProps) => {
  const { formId, index, handleRemove, canBeRemoved } = props;

  const { handleBlur, handleChange, errors, touched, values, setFieldValue } =
    useFormikContext<StudyAbroadFormValues>();
  const indexValue = values.studiesAbroad[index];

  const dispatch = useDispatch();

  const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
    dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.studyAbroad, values));
  }, 600);

  const isStatesOptionsLoading = useSelector(locationStatesIsLoadingSelector);
  const statesOptions = useSelector(locationStatesSelector);

  const isCountriesOptionsLoading = useSelector(locationCountriesIsLoadingSelector);
  const countriesOptions = useSelector(locationCountriesSelector);

  const isCacheStateCitiesOptionsLoading = useSelector(cacheStateCitiesIsLoadingSelector(indexValue.state));
  const cacheStateCitiesOptions = useSelector(cacheStateCitiesSelector(indexValue.state));
  const cacheStateCitiesOptionsLoadFailure = useSelector(cacheStateCitiesFailureSelector(indexValue.state));

  useEffect(() => {
    if (
      indexValue?.state &&
      !isCacheStateCitiesOptionsLoading &&
      cacheStateCitiesOptions.length === 0 &&
      !cacheStateCitiesOptionsLoadFailure
    ) {
      dispatch(sharedActionsCreator.cacheStateCitiesOptionsRequest(indexValue?.state));
    }
  }, [
    indexValue?.state,
    isCacheStateCitiesOptionsLoading,
    cacheStateCitiesOptions.length,
    cacheStateCitiesOptionsLoadFailure,
  ]);

  useEffect(() => {
    debouncedSaveLevelFormValues(values);
  }, [values]);

  const handleSelectChange = useCallback(
    (value, metaData) => {
      setFieldValue(metaData?.name, value?.id, true);
    },
    [setFieldValue]
  );

  const handleStateChange = useCallback(
    (value, metaData) => {
      handleSelectChange(value, metaData);
      setFieldValue(`${formId}.stateName`, value?.name, true);
      setFieldValue(`${formId}.stateCity`, '');
    },
    [setFieldValue, formId, handleSelectChange]
  );

  const handleStateCityChange = useCallback(
    (value, metaData) => {
      handleSelectChange(value, metaData);
      setFieldValue(`${formId}.cityName`, value?.name, true);
    },
    [setFieldValue, formId, handleSelectChange]
  );

  const handleCountryChange = useCallback(
    (value, metaData) => {
      handleSelectChange(value, metaData);
      setFieldValue(`${formId}.countryName`, value?.name);
    },
    [setFieldValue, formId, handleSelectChange]
  );

  const handleLocationInfoChange = useCallback(
    e => {
      const international = e.target.value === 'International';

      if (international) {
        setFieldValue(`${formId}.state`, '', true);
        setFieldValue(`${formId}.stateName`, '', true);
        setFieldValue(`${formId}.stateCity`, '', true);
        setFieldValue(`${formId}.cityName`, '', true);
      } else {
        setFieldValue(`${formId}.country`, '', true);
        setFieldValue(`${formId}.countryName`, '', true);
        setFieldValue(`${formId}.cityName`, '', true);
      }
      setFieldValue(`${formId}.international`, international, true);
    },
    [setFieldValue, formId, handleSelectChange]
  );

  const handleYearChange = yearData => {
    setFieldValue(`${formId}.timeOfStudy.year`, yearData); // for parsing TimeInput as a single object
    setFieldValue(`${formId}.year`, yearData.id);
  };

  const handlePeriodOfStudyChange = periodData => {
    setFieldValue(`${formId}.timeOfStudy.periodOfStudy`, periodData);
    setFieldValue(`${formId}.periodOfStudy`, periodData.id);
  };

  const getError = useCallback(
    (key: string) => {
      return getIn(touched, key) && getIn(errors, key) ? getIn(errors, key) : '';
    },
    [touched, errors, getIn]
  );

  return (
    <>
      <FormHeader
        title={`Study Abroad Experience #${index + 1}`}
        showRemove={canBeRemoved}
        handleRemove={() => handleRemove(index)}
      />
      <Paper>
        <FormRow>
          <div className="col-12">
            <Label htmlFor="isUS" required>
              Location Info
            </Label>
          </div>
          <div className="col-3">
            <Radio
              name={`${formId}.international`}
              className="mb-3"
              optionName="US"
              value="US"
              onChange={handleLocationInfoChange}
              checked={!indexValue?.international}
            />
          </div>
          <div className="col-6">
            <Radio
              name={`${formId}.international`}
              className="mb-3"
              optionName="International"
              value="International"
              onChange={handleLocationInfoChange}
              checked={indexValue?.international}
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="col-12">
            <Input
              name={`${formId}.name`}
              label="University/School"
              placeholder="Name of University/School (Write In)"
              value={indexValue.name}
              error={getError(`${formId}.name`)}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
              required
            />
          </div>
        </FormRow>
        <FormRow>
          <div className="col-12">
            <Input
              name={`${formId}.program`}
              label="Program"
              placeholder="Name of Program (Write In)"
              value={indexValue?.program}
              error={getError(`${formId}.program`)}
              onChange={handleChange}
              onBlur={handleBlur}
              autoComplete="off"
            />
          </div>
        </FormRow>
        <FormRow>
          <Label required>Location Information</Label>
          {!indexValue?.international && (
            <>
              <div className="col-6">
                <Select
                  className="mb-0"
                  name={`${formId}.state`}
                  placeholder="State"
                  value={getOption(statesOptions, indexValue.state, 'id')}
                  error={getError(`${formId}.state`)}
                  options={statesOptions}
                  onChange={handleStateChange}
                  isLoading={isStatesOptionsLoading}
                  required
                />
              </div>
              <div className="col-6">
                <Select
                  className="mb-0"
                  name={`${formId}.stateCity`}
                  placeholder="City"
                  value={getOption(cacheStateCitiesOptions, indexValue.stateCity, 'id')}
                  error={getError(`${formId}.stateCity`)}
                  options={cacheStateCitiesOptions}
                  onChange={handleStateCityChange}
                  isLoading={isCacheStateCitiesOptionsLoading}
                  required
                />
              </div>
            </>
          )}
          {indexValue?.international && (
            <>
              <div className="col-6 ">
                <Select
                  className="mb-0"
                  name={`${formId}.country`}
                  placeholder="Country"
                  value={getOption(countriesOptions, indexValue.country, 'id')}
                  error={getError(`${formId}.country`)}
                  options={countriesOptions}
                  onChange={handleCountryChange}
                  isLoading={isCountriesOptionsLoading}
                  required
                />
              </div>
              <div className="col-6">
                <Input
                  className="mb-0"
                  name={`${formId}.countryCity`}
                  placeholder="City (Write In)"
                  value={indexValue.countryCity}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={getError(`${formId}.countryCity`)}
                />
              </div>
            </>
          )}
        </FormRow>
        <br />
        <FormRow>
          <TimeOfStudyInput
            formId={formId}
            data={{ year: indexValue.year, periodOfStudy: indexValue.periodOfStudy }}
            handleYearChange={handleYearChange}
            handlePeriodChange={handlePeriodOfStudyChange}
            handleBlur={handleBlur}
            timeError={getError(`${formId}.timeOfStudy`)}
          />
        </FormRow>
        <br />
        <div className="col-12 ">
          <Checkbox
            optionName="Include on resume and profile."
            name={`${formId}.includeOnResume`}
            onChange={handleChange}
            checked={indexValue.includeOnResume}
          />
        </div>
      </Paper>
    </>
  );
};

export default StudyAbroadExperienceForm;
