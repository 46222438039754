import alertsTags from './alerts-tags';
import alertsTagsMessages from './alerts-tags-messages';
import applySections from './apply-sections';
import assignment from './assignament';
import breakpoints from './breakpoints';
import carouselBreakpoints from './carousel-breakpoints';
import controls from './controls';
import customStyles from './custom-styles';
import dashboardCardStyles from './dashboard-card-styles';
import dashboardStyles from './dashboard-styles';
import {
  defaultLines,
  defaultLine,
  defaultLeadershipLines,
  defaultExperienceLinesEnabled,
  defaultExperienceLines,
  defaultLeadershipLinesEnabled,
} from './default-lines';
import defaultRegister from './default-register';
import defaultValuesApply from './default-values-apply';
import defaultValuesLevels from './default-values-levels';
import diversityData from './diversity-data';
import dropdownStyleForCards from './dropdown-style-for-cards';
import entities from './entities';
import exampleAssignments from './example-assignments';
import filterErrorMessages from './filter-error-messages';
import flatStyles from './flat-styles';
import createMarkup from './get-tag';
import inputBorderTypes from './input-border-types';
import levels from './levels';
import listLevels, { LEVEL_NAMES, type LEVEL_NAME, LEVEL_STEPS } from './list-levels';
import listQuizzes from './list-quizzes';
import messageBee from './message-bee';
import operatingSystem from './operating-system';
import opportunityLocationTypes from './opportunity-locations-type';
import opportunityOrders from './opportunity-orders';
import opportunityStyles from './opportunity-styles';
import { durationOptions } from './options';
import origins from './origins';
import placeholders from './placeholders';
import progressStatus from './progress-status';
import quickCompanyFilters from './quick-company-filters';
import quickOpportunityFilters from './quick-opportunity-filters';
import quickOpportunityFiltersMobile from './quick-opportunity-filters-mobile';
import regionCode from './region-code';
import responseErrors from './responses-errors';
import sections from './sections';
import sliderSettingsForOpportunities from './slider-settings-for-opportunities';
import sliderSettingsForResume from './slider-settings-for-resume';
import tabs from './tabs';
import themesObj from './theme';
import themes from './themes';
import tooltipsMessages from './tooltips-messages';
import types from './types';
import variantButtons from './variant-buttons';

export { error404Links } from './errorPagesLinks';

export { routeNames } from './route-name';
export { registerRoutes, registerRoutesV2, REGISTER_STEPS } from './register-routes';
export {
  type LevelConfig,
  type LevelGoBackConfig,
  type LevelRoute,
  levelRoutes,
  levelBRoutes,
  levelConfigs,
} from './level-configs';

const exampleLevel = 'example';
const delAccount = 'Are you sure you want to delete your account?\n(Your data cannot be recovered)';
const emptyProgress = { levels: [], breakpoint: breakpoints.REGISTER };
const OVER_ZOOM_TOLERANCE = 0.05;
const DOUBLE_TAP_THRESHOLD = 250;
const ANIMATION_SPEED = 0.1;
const MAIN_VIDEO = 'https://d3a3zxuzhhpfk9.cloudfront.net/videos/level_5/level5_congrats_2021.mp4';
const LINK_HIVE_HACKS = 'https://info.hellohive.com/knowledge-base/be-yourself-creating-a-strong-hive-resume';

const fullWidth = {
  style: { width: '100%' },
  wrapperStyle: { maxWidth: '100%' },
};

export {
  quickOpportunityFilters,
  quickCompanyFilters,
  quickOpportunityFiltersMobile,
  alertsTags,
  applySections,
  alertsTagsMessages,
  durationOptions,
  OVER_ZOOM_TOLERANCE,
  LINK_HIVE_HACKS,
  DOUBLE_TAP_THRESHOLD,
  ANIMATION_SPEED,
  defaultValuesApply,
  diversityData,
  types,
  sections,
  exampleAssignments,
  variantButtons,
  inputBorderTypes,
  defaultLines,
  defaultExperienceLinesEnabled,
  defaultExperienceLines,
  defaultLeadershipLinesEnabled,
  defaultLeadershipLines,
  defaultLine,
  carouselBreakpoints,
  breakpoints,
  themes,
  levels,
  defaultRegister,
  themesObj,
  progressStatus,
  delAccount,
  emptyProgress,
  exampleLevel,
  operatingSystem,
  listLevels,
  LEVEL_NAMES,
  LEVEL_NAME,
  LEVEL_STEPS,
  messageBee,
  MAIN_VIDEO,
  entities,
  regionCode,
  listQuizzes,
  defaultValuesLevels,
  responseErrors,
  fullWidth,
  assignment,
  dropdownStyleForCards,
  sliderSettingsForOpportunities,
  sliderSettingsForResume,
  customStyles,
  flatStyles,
  dashboardStyles,
  dashboardCardStyles,
  opportunityStyles,
  placeholders,
  tooltipsMessages,
  createMarkup,
  controls,
  opportunityOrders,
  opportunityLocationTypes,
  origins,
  filterErrorMessages,
  tabs,
};
