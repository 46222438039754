import React from 'react';

import { ButtonVariant, IconButton } from 'components/v2/buttons';

import { StyledIconButton } from './BackButton.styled';

type BackButtonProps = Omit<React.ComponentProps<typeof IconButton>, 'icon' | 'variant' | 'position'>;

const BackButton: React.FC<BackButtonProps> = ({ children = 'BACK', ...props }) => (
  <StyledIconButton icon="leftArrow" variant={ButtonVariant.secondary} {...props}>
    {children}
  </StyledIconButton>
);

export default BackButton;
