import { size } from 'lodash';

const getStyleText = state => {
  const textSize = state && state.data && state.data.name && size(state.data.name);

  if (textSize && textSize > 35 && window.screen.width < 400) {
    return { fontFamily: 'Poppins', fontSize: '9px', fontWeight: '200' };
  }

  if (textSize && textSize > 35 && window.screen.width < 800) {
    return { fontFamily: 'Poppins', fontSize: '12px', fontWeight: '400' };
  }

  return { fontFamily: 'Poppins', fontSize: '13px', fontWeight: '400' };
};

const getContainerHeight = state => {
  if (state.hasValue && state.isMulti) {
    return 'auto';
  }
  return '40px';
};

const customStyles = () => {
  return {
    option: (provided, state) => {
      return {
        ...provided,
        color: state.isSelected ? 'white' : 'grey',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        display: 'flex',
        height: '30px',
        flexDirection: 'row',
        fontSize: '13px',
        width: '100%',
        fontFamily: 'Poppins',
      };
    },
    clearIndicator: provider => ({ ...provider, padding: '5px 10px 5px 10px', color: '#ffbe33' }),
    dropdownIndicator: provider => {
      return {
        ...provider,
        padding: '8px 4px',
        right: '1px',
        top: '9px',
        position: 'absolute',
        borderWidth: '5px 0 5px 6px',
      };
    },
    container: (provided, state) => {
      return {
        border: '1px solid #fff',
        borderRadius: '4px',
        padding: '4px 6px',
        minHeight: '40px',
        maxHeight: 'auto',
        alignItems: 'flex-end',
        display: 'flex',
        position: 'relative',
        fontFamily: 'Poppins',
        fontWeight: '300',
        '&:hover': {
          border: '1px solid #fdc93b',
          color: 'grey',
        },
        height: getContainerHeight(state),
      };
    },
    menu: provider => {
      return {
        ...provider,
        display: 'flex',
        background: 'white',
        width: '100%',
        justifyContent: 'center',
        flexDirection: 'column',
        fontSize: '14px',
        borderRadius: '4px',
      };
    },
    placeholder: provider => {
      return {
        ...provider,
        color: 'white',
        whiteSpace: 'normal',
        fontFamily: 'Poppins',
        fontWeight: '400',
        fontSize: '13px',
        position: 'absolute',
      };
    },
    control: () => ({
      width: '100%',
      display: 'flex',
      whiteSpace: 'nowrap',
      minHeight: 'fit-content',
      height: 'fit-content',
    }),
    valueContainer: provided => ({
      ...provided,
      height: 'auto',
      justifyContent: 'center',
      textAlign: 'center',
      marginTop: '3px',
      fontFamily: 'Poppins',
      fontWeight: '400',
    }),

    input: () => ({
      color: 'white',
      textAlign: 'left',
      display: 'inline-block',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';
      const fontStyle = getStyleText(state);
      const paddingLeft = state.hasValue ? '20px' : '0';

      return {
        ...provided,
        ...fontStyle,
        opacity,
        transition,
        color: 'white',
        justifyContent: 'center',
        width: '100%',
        display: 'inline-block',
        position: 'absolute',
        paddingLeft,
      };
    },
  };
};

export default customStyles;
