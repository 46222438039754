import isObjectEqual from './is-object-equal';

const isArraysEqual = (firstArray, secondArray) => {
  if (!firstArray || !secondArray || (firstArray.length === 0 && secondArray.length !== 0)) {
    return false;
  }
  return (
    firstArray.length === secondArray.length && firstArray.every((obj, index) => isObjectEqual(obj, secondArray[index]))
  );
};

export default isArraysEqual;
