import { useRef, useEffect } from 'react';

const useMergedLinesModal = ({ setShowDialog, setDialogTitle, setDialogContent }) => {
  const mergeLinesModalShown = useRef(false);
  const showMergeLinesHelper = () => {
    if (!mergeLinesModalShown.current) {
      mergeLinesModalShown.current = true;
      setShowDialog(true);
      setDialogTitle('Two Line Bullet');
      setDialogContent(
        'Remember, if you’re going on to a second line make sure you use at least half of your new line’s characters. Otherwise it’s best to save this line for somewhere else that you need more room for content.'
      );
    }
  };
  useEffect(() => {
    window.addEventListener('show-merge-lines-helper', showMergeLinesHelper);
    return () => {
      window.removeEventListener('show-merge-lines-helper', showMergeLinesHelper);
    };
  }, []);

  return mergeLinesModalShown;
};

export default useMergedLinesModal;
