import { push } from 'connected-react-router';
import { takeLatest, call, put, all, fork } from 'redux-saga/effects';

import { getLevel, levelSave } from 'services/level';
import { updateProgress } from 'services/progress';
import levelsCreator, { levelsTypes } from 'store/level/actions';
import progressCreator from 'store/progress/actions';
import studentCreator from 'store/student/actions';
import { getResponseError, i18n, parseNullToEmptyString } from 'utils';

export function* fetch({ levelId }) {
  try {
    const response = yield call(getLevel, levelId);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(levelsCreator.levelFetchSuccess(response.data));
    } else {
      yield put(levelsCreator.levelFetchError(error));
    }
  } catch (error) {
    yield put(levelsCreator.levelFetchError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* save({ levelId, store, progress }) {
  try {
    const parsedStore = parseNullToEmptyString(store);
    const response = yield call(levelSave, levelId, parsedStore);
    const error = yield getResponseError(response);

    let resProgress;
    let errorProgress;

    if (progress) {
      resProgress = yield call(updateProgress, progress);
      errorProgress = yield getResponseError(resProgress);
    }

    if (!progress && !error) {
      yield all([yield put(levelsCreator.levelSaveSuccess()), yield put(studentCreator.studentSet(response.data))]);
    } else if (!error && !errorProgress) {
      yield all([
        yield put(levelsCreator.levelSaveSuccess()),
        yield put(studentCreator.studentSet(response.data)),
        yield put(progressCreator.progressUpdateSuccess(resProgress.data.progress)),
      ]);
    } else {
      yield put(levelsCreator.levelSaveError(error));
    }
  } catch (error) {
    yield put(levelsCreator.levelSaveError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* update({ levelId, store, progress, hasRedirect, closeWindow }) {
  try {
    yield fork(updateProgress, progress);
    yield put(progressCreator.progressUpdateSuccess(progress));

    if (hasRedirect) {
      yield call(levelSave, levelId, store);
      yield put(levelsCreator.levelUpdateSuccess());
      yield put(push(hasRedirect));
    } else {
      yield call(levelSave, levelId, store);
    }
    if (closeWindow && !hasRedirect) {
      yield put(levelsCreator.levelUpdateSuccess());
      yield call(window.close);
    }
  } catch (error) {
    yield put(levelsCreator.levelUpdateError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* levelSaga() {
  yield takeLatest(levelsTypes.LEVEL_FETCH_REQUEST, fetch);
  yield takeLatest(levelsTypes.LEVEL_SAVE_REQUEST, save);
  yield takeLatest(levelsTypes.LEVEL_UPDATE_REQUEST, update);
}
