import styled from 'styled-components';

import { Label } from 'components/v2/forms/components';

export const Wrapper = styled.div`
  text-align: left;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledLabel = styled(Label)`
  margin-bottom: 0;
`;

export const OptionName = styled.span`
  margin-left: 12px;
  margin-bottom: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #fff;

  &[aria-disabled='true'] {
    color: #4a485b;
  }
`;

export const StyledRadio = styled.input`
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #cecae2;
  border-radius: 50%;

  &:checked {
    background-color: #4a485b;
    border-color: #4a485b;
    box-shadow: inset 0 0 0 3px #131021;

    &:not(:disabled) {
      background-color: #ffbe33;
      border-color: #ffbe33;
    }
  }

  &:disabled {
    border-color: #4a485b;
    opacity: 0.3;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
`;
