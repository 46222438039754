import React from 'react';

import { StyledPaper } from './Paper.styled';

interface PaperProps {
  className?: string;
}

const Paper: React.FC<PaperProps> = ({ children, className = '' }) => (
  <StyledPaper className={className}>{children}</StyledPaper>
);

export default Paper;
