import React from 'react';

interface FormRowProps {
  className?: string;
}

const FormRow: React.FC<FormRowProps> = ({ children, className = '' }) => (
  <div className={`row gx-3 ${className}`}>{children}</div>
);

export default FormRow;
