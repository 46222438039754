import { has, isBoolean, isNumber, isEmpty } from 'lodash';
import PropTypes from 'prop-types';

const cleanValues = ({ data, section, values }) => {
  if (isEmpty(section.form) || isEmpty(values)) {
    return values;
  }

  const controls = section.form.filter(e => e.control);
  const parent = data ? !isEmpty(data.array) && values[data.array.parent][data.array.index] : {};

  const defaultValues = controls.reduce((acc, element) => {
    if (!has(values, element.control) && isEmpty(parent)) {
      return acc;
    }
    if (!isEmpty(parent)) {
      return { ...parent, ...acc, [element.control]: '' };
    }
    if (has(element, 'form')) {
      return { ...acc, [element.control]: [] };
    }
    if (isBoolean(values[element.control])) {
      return { ...acc, [element.control]: false };
    }
    if (isNumber(values[element.control])) {
      return { ...acc, [element.control]: 0 };
    }
    return { ...acc, [element.control]: '' };
  }, {});

  return isEmpty(parent) ? { ...values, ...defaultValues } : defaultValues;
};

cleanValues.propTypes = {
  values: PropTypes.shape({}).isRequired,
  section: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({}),
};

export default cleanValues;
