import styled from 'styled-components';

import { RemoveButton } from 'components/v2/buttons';

const StyledRemoveButton = styled(RemoveButton)`
  margin-bottom: 8px;
  padding: 0;
  font-weight: 400;
`;

export default StyledRemoveButton;
