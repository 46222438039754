const getBreadcrumbSelectedItem = (groupedSections, section) => {
  let returnIndex = 0;
  Object.keys(groupedSections).forEach((element, i) => {
    if (element === section.tag) {
      returnIndex = i;
    }
  });
  return returnIndex;
};

export default getBreadcrumbSelectedItem;
