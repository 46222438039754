import React from 'react';

import { StyledCheckbox, StyledLabel, Wrapper, Container, OptionName } from './Checkbox.styled';

interface CheckboxProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  label?: string;
  optionName?: string | React.ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  className = '',
  name = '',
  label = '',
  optionName = '',
  required = false,
  disabled = false,
  id,
  ...props
}) => (
  <Wrapper className={className}>
    <StyledLabel required={required} disabled={disabled}>
      {label}
      <Container>
        <StyledCheckbox type="checkbox" name={name} required={required} disabled={disabled} {...props} />
        {optionName && <OptionName aria-disabled={disabled}>{optionName}</OptionName>}
      </Container>
    </StyledLabel>
  </Wrapper>
);

export default Checkbox;
