import { nonSelectable } from '@hivediversity/common-lib/styled/css-helpers';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
  min-height: 200px;
  height: auto;
  margin-bottom: 16px;
  @media (max-width: 430px) {
    margin: 0 auto 16px auto;
    width: 100%;
    max-width: 100%;
  }
  ${nonSelectable}
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: ${({ isQualified }) => (isQualified ? '3px solid #FFBA1F' : 'transparent')};
  flex: 1;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 430px) {
    height: 520px;
  }
  border-radius: 8px;
  margin: 0;
  background-color: ${({ theme }) => theme.lightBackground};
  overflow: hidden;
  align-items: flex-start;
  padding: 12px;
  border: 3px solid transparent;
  transition: all 0.25s;
  min-height: 200px;
  height: auto;
  position: relative;
  @media (max-width: 430px) {
    height: auto;
    min-height: 200px;
  }
  ${({ state2 }) =>
    state2 &&
    css`
      box-shadow: 0 4px 20px rgba(255, 255, 255, 0.35);
      border-color: var(--color-white);
      h3,
      .parentCompany {
        color: var(--color-white);
      }
      .duration,
      .description {
        color: var(--color-white);
      }
    `}
  ${({ state3 }) =>
    state3 &&
    css`
      border-color: var(--color-secondary);
    `}
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Name = styled.h3`
  font-size: 18px;
  margin-top: 16px;
  font-family: 'Gentleman', Arial, sans-serif;
  font-weight: 800;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const WrapperDescription = styled.div`
  display: -webkit-box;
  max-width: 750px;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  height: 131px;
  overflow: hidden;
  flex-direction: column;
  flex: 1;
  color: #838485;
  width: 100%;
  @media screen and (max-width: 430px) {
    height: 134px;
    -webkit-line-clamp: 8;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Description = styled.p`
  word-break: break-word;
  position: relative;
  display: block;
  line-height: 1.4em;
  font-size: 14px;
  text-justify: distribute-center-last;
  margin: 0;
  color: white;
  padding: 0 16px;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const SubTitle = styled.p`
  display: inline;
  text-align: center;
  padding-right: 5px;
  margin: 0;
  font-size: 14px;
  ${({ $bsStyle }) => $bsStyle || ''}
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const BodyHeader = styled.div`
  display: -webkit-box;
  max-width: 750px;
  -webkit-line-clamp: ${({ $isIOS }) => {
    return $isIOS ? '3;' : '4;';
  }};
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: 115px;
  flex-direction: column;
  align-items: center;
  color: white;
  padding: 0 16px;
  @media screen and (max-width: 430px) {
    -webkit-line-clamp: ${({ $isIOS }) => {
      return $isIOS ? '2;' : '4;';
    }};
    text-align: center;
    margin-bottom: 10px;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const WrapperContentCard = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Photo = styled.img`
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 430px) {
    height: 120px;
  }
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  background-image: none;
  background-color: var(--primary-color-darker);
  height: 132px;
  width: 132px;
  position: relative;
  @media (max-width: 430px) {
    width: 75px;
    height: 75px;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  cursor: pointer;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const TagsContentCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 10px;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Tag = styled.div`
  color: #fff;
  background-color: #43404f;
  padding: 5px 9px 5px 9px;
  border: 1px solid transparent;
  border-radius: 4px;
  display: inline-flex;
  margin: 0 5px 5px 0;
  font-family: 'Poppins', Arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: -0.015em;

  &.highlighted {
    border-color: #febf32;
    color: #febf32;
  }

  img {
    width: 10px;
    height: 10px;
    margin-right: 4px;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Favoritebutton = styled.button.attrs({ type: 'button' })`
  display: flex;
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 20px;
  border: none;
  background: transparent;
  img {
    image-rendering: pixelated;
  }
  @media (max-width: 430px) {
    right: 12px;
    top: 12px;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const ContentTagsCard = styled.div`
  display: flex;
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const ContentPhotoCard = styled.div`
  display: block;
  flex-direction: row;
  @media (max-width: 430px) {
    flex-direction: column;
  }
  ${({ $bsStyle }) => $bsStyle || ''};
`;

export const Icon = styled.img.attrs({ width: '25px', height: '25px' })`
  margin: 0 0 4px 10px;
`;

export const FollowButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 3px solid #febf32;
  border-radius: 8px;
  margin: 0.5rem 0;
  padding: 0.5rem;
  color: #febf32;
  font-weight: bolder;
  background: transparent;

  &.followed {
    background-color: #febf32;
    color: #100f19;
  }

  @media (max-width: 430px) {
    font-size: x-small;
  }
`;
