import { createReducer } from 'reduxsauce';

import { LEVEL_STEPS } from 'constant';

import { levelTypes } from './actions';
import { initialState } from './initial-state';
import { LevelState } from './types';

const setLevelFormValues = (state, { level }) => ({
  ...state,
  ...Object.keys(level).reduce(
    (acc, stepName) => ({
      ...acc,
      [LEVEL_STEPS[stepName]]: {
        ...state[LEVEL_STEPS[stepName]],
        values: level[stepName],
      },
    }),
    {}
  ),
});

const setLevelStepFormValues = (state, { stepName, values }) => ({
  ...state,
  [LEVEL_STEPS[stepName]]: {
    ...state[LEVEL_STEPS[stepName]],
    values,
  },
});

const saveLevelFormValuesRequest = (state, { stepName }) => ({
  ...state,
  [LEVEL_STEPS[stepName]]: {
    ...state[LEVEL_STEPS[stepName]],
    isLoading: true,
  },
});

const saveLevelFormValuesSuccess = (state, { stepName, values, isCompleted }) => ({
  ...state,
  [LEVEL_STEPS[stepName]]: {
    ...state[LEVEL_STEPS[stepName]],
    values: {
      ...state[LEVEL_STEPS[stepName]].values,
      ...values,
    },
    isLoading: false,
    isCompleted,
  },
});

const saveLevelFormValuesFailure = (state, { stepName, error }) => ({
  ...state,
  [LEVEL_STEPS[stepName]]: {
    ...state[LEVEL_STEPS[stepName]],
    error,
    isLoading: false,
    isCompleted: false,
  },
});

const reducer = createReducer<LevelState>(initialState, {
  [levelTypes.SET_LEVEL_FORM_VALUES]: setLevelFormValues,
  [levelTypes.SET_LEVEL_STEP_FORM_VALUES]: setLevelStepFormValues,

  [levelTypes.SAVE_LEVEL_FORM_VALUES_REQUEST]: saveLevelFormValuesRequest,
  [levelTypes.SAVE_LEVEL_FORM_VALUES_SUCCESS]: saveLevelFormValuesSuccess,
  [levelTypes.SAVE_LEVEL_FORM_VALUES_FAILURE]: saveLevelFormValuesFailure,
});

export default reducer;
