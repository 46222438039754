import styled from 'styled-components';

export const Steps = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const Step = styled.li`
  width: 16px;
  height: 16px;
  background-color: rgba(255, 190, 51, 0.3);
  border-radius: 50%;

  &.active,
  &.completed {
    background-color: #ffbe33;
  }

  &.active {
    border: 1px solid #ffbe33;
    box-shadow: inset 0 0 0 2px #131021;
    width: 24px;
    height: 24px;
  }

  &.completed {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-right: 16px;
  }

  @media screen and (min-width: 992px) {
    width: 10px;
    height: 10px;

    &.active {
      width: 16px;
      height: 16px;
    }
  }
`;
