import React from 'react';

import { IconButton, IconPosition } from 'components/v2/buttons';

import { StyledIconButton } from './AddButton.styled';

type AddButtonProps = Omit<React.ComponentProps<typeof IconButton>, 'icon' | 'position'>;

const AddButton: React.FC<AddButtonProps> = ({ children, ...rest }) => (
  <StyledIconButton icon="plus" position={IconPosition.right} {...rest}>
    {children}
  </StyledIconButton>
);

export default AddButton;
