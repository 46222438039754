import PropTypes from 'prop-types';

export default PropTypes.shape({
  // Filters for companies
  verticals: PropTypes.number,
  industries: PropTypes.arrayOf(PropTypes.number),
  jobFunctions: PropTypes.arrayOf(PropTypes.number),
  career: PropTypes.arrayOf(PropTypes.number),
  hqZone: PropTypes.number,
  hqState: PropTypes.arrayOf(PropTypes.string),
  otherZone: PropTypes.number,
  otherState: PropTypes.arrayOf(PropTypes.string),

  // Filters for opportunities
  qualifiedFor: PropTypes.string,
  compensationType: PropTypes.number,
  oppVerticals: PropTypes.number,
  oppIndustries: PropTypes.arrayOf(PropTypes.number),
  oppJobFunctions: PropTypes.arrayOf(PropTypes.number),
  oppCareer: PropTypes.arrayOf(PropTypes.number),
  oppZone: PropTypes.number,
  oppState: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.number,
  type: PropTypes.string,
});
