import flatten from 'lodash/flatten';
import reduce from 'lodash/reduce';
import size from 'lodash/size';

const getLineNumbers = (lines, number) => {
  const numbers = reduce(
    lines,
    (acc, line, position) => {
      const currentAmount = size(flatten(acc)) + 1;
      acc[position] = line.high === 0 ? [currentAmount] : [currentAmount, currentAmount + 1];
      return acc;
    },
    []
  );
  return numbers[number];
};

export default getLineNumbers;
