const getOpportunityIsValid = opportunity => {
  if (!opportunity) {
    return null;
  }
  const now = new Date();
  const newDateTime = new Date(now.getTime() - 60 * 4 * 60000).valueOf();
  const startDateTime = opportunity.start && new Date(opportunity.start).valueOf();
  const endDateTime = opportunity.end && new Date(opportunity.end).valueOf();

  const isStarted = startDateTime ? newDateTime >= startDateTime : true;
  const isFinished = endDateTime ? newDateTime > endDateTime : false;

  return isStarted && !isFinished;
};

export default getOpportunityIsValid;
