import { push } from 'connected-react-router';
import { find, first } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useQuery } from 'hooks';
import { getDashboardSearchData } from 'store/dashboard/selector';

import Button from './button';
import { WrapperButton } from './styled';

const Tabs = ({ id, active, onClick, children }) => {
  const dispatch = useDispatch();
  const selectedTab = find(children, child => child?.props?.id === active);
  const firstTab = first(children) || children;
  const location = useLocation();
  const query = useQuery();
  const searchReduxData = useSelector(getDashboardSearchData);
  const handleRedirect = useCallback(
    childId => {
      // set overwrites the query string
      query.set('tab', childId);
      query.set('search', searchReduxData);
      return dispatch(push(location.search ? `${location.pathname}?${query}` : `${location.pathname}?${query}`));
    },
    [searchReduxData]
  );

  return (
    <Fragment key={id}>
      <WrapperButton>
        {Array.isArray(children) ? (
          children.map(child => (
            <Button
              key={child?.props?.id}
              onClick={() => {
                handleRedirect(child?.props?.id);
                onClick(child?.props?.id);
              }}
              child={child}
              active={child?.props?.id === active}
            >
              {child.props.title}
            </Button>
          ))
        ) : (
          <Button
            child={children}
            onClick={() => {
              handleRedirect(children?.props?.id);
              onClick(children?.props?.id);
            }}
          >
            {children.props.title}
          </Button>
        )}
      </WrapperButton>
      <Fragment key={id}>{selectedTab || firstTab}</Fragment>
    </Fragment>
  );
};

Tabs.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  active: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClick: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
};

Tabs.defaultProps = {
  id: 'tab',
  active: null,
  children: [],
};

export default Tabs;
