import React from 'react';

import { Tooltip } from 'components/v2/Tooltip';

import { Required, StyledLabel } from './Label.styled';

type LabelProps = Pick<React.LabelHTMLAttributes<HTMLLabelElement>, 'htmlFor' | 'className'> & {
  required?: boolean;
  disabled?: boolean;
  tooltip?: string;
  isHtml?: boolean;
};

const Label: React.FC<LabelProps> = ({
  children,
  htmlFor,
  className = '',
  required = false,
  disabled = false,
  tooltip,
  isHtml,
}) => (
  <StyledLabel className={className} htmlFor={htmlFor} aria-required={required} aria-disabled={disabled}>
    {children}
    {required && <Required aria-disabled={disabled}>*</Required>}
    {tooltip && (
      <Tooltip id={htmlFor} className="tooltip-wrapper" tooltipClassName="tooltip" text={tooltip} html={isHtml} />
    )}
  </StyledLabel>
);

export default Label;
