import styled from 'styled-components';

import { AddButton, IconButton } from 'components/v2/buttons';

export const Wrapper = styled.div`
  display: flex;
  overflow-x: auto;
  padding: 10px;
`;

export const StyledIconButton = styled(IconButton)`
  border-radius: 8px;
  flex-shrink: 0;
  font-size: 16px;
  line-height: 22px;
  padding: 8px 12px 8px 16px;

  &:not(:last-child) {
    margin-right: 16px;
  }
`;

export const StyledAddButton = styled(AddButton)`
  flex-shrink: 0;
`;
