import styled from 'styled-components';

import { AddButton } from 'components/v2/buttons';

export const StyledAddButton = styled(AddButton)`
  margin-top: 16px;
  margin-bottom: 32px;
`;

export const StyledFormSectionTitle = styled.div`
  font-family: 'Roboto';
  line-height: 28px;
  color: #ffbe33;
  margin: 24px 0 8px;
`;
