import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CustomModal } from 'components';
import { origins } from 'constant';
import { getAlerts } from 'store/student/selectors';
import { getAlertsByLevel } from 'utils';

import Alerts from './alerts';
import { ArrowDown, Container, ModalAnimation } from './styled';

const WarningsModal = ({ show, level, onClose, origin }) => {
  const [isScrollable, setIsScrollable] = useState(false);
  const alerts = useSelector(getAlerts);
  const levelAlerts = getAlertsByLevel(alerts, level, origin);

  useEffect(() => {
    const container = document.getElementById('container');
    setIsScrollable(container && container.scrollHeight > container.clientHeight);
  });

  return (
    <CustomModal
      background="#278A96"
      isActive={show}
      handleClose={onClose}
      $bsStyle={{ borderRadius: '11px', maxWidth: 'initial' }}
      containerStyles={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: 'initial',
        width: '100%',
        maxWidth: '600px',
        minWidth: '200px',
      }}
      closeButtonStyles={{
        background: 'transparent',
        position: 'absolute',
        fontSize: '35px',
        fontWeight: 400,
        right: '25px',
        top: '10px',
      }}
    >
      <>
        <Container id="container">
          <Alerts alerts={levelAlerts} origin={origin} />
        </Container>
        {isScrollable && (
          <ModalAnimation>
            <ArrowDown />
          </ModalAnimation>
        )}
      </>
    </CustomModal>
  );
};

WarningsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  level: PropTypes.string,
  origin: PropTypes.oneOf([origins.HOME, origins.LEVELS]).isRequired,
};

WarningsModal.defaultProps = {
  level: undefined,
};

export default WarningsModal;
