import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  setLevelFormValues: ['level'],

  setLevelStepFormValues: ['stepName', 'values'],

  saveCourseworkFormValuesRequest: ['values', 'isCompleted', 'redirectTo'],

  saveLevelFormValuesRequest: ['stepName', 'values', 'isCompleted', 'redirectTo', 'lastLevelStep'],
  saveLevelFormValuesSuccess: ['stepName', 'values', 'isCompleted'],
  saveLevelFormValuesFailure: ['stepName', 'error'],
});

export const levelTypes = Types;

export default Creators;
