export const initialState = {
  notifications: null,
  notification_read_isLoading: false,
  notifications_isSuccess: false,
  companies: { data: [], meta: {} },
  types: [],
  companyId: {},
  error: null,
  oppState: {
    data: {
      states: [],
      cities: [],
    },
  },
  oppVerticals: [],
};
