import * as yup from 'yup';

import { HighSchoolFormValues, Honor } from 'store/v2/level/types';

const REQUIRED_MESSAGE = 'Required';

export const highSchoolInitialValues: HighSchoolFormValues = {
  highSchool: {
    name: '',
    international: false,
    city: null,
    cityName: '',
    countryCity: '',
    country: null,
    countryName: '',
    state: null,
    stateName: '',
    monthGraduation: null,
    yearGraduation: null,
    satScore: null,
    actScore: null,
    includeHonorsOnResume: false,
    includeActSatScoreOnResume: false,
    includeOnResume: false,
  },
  highSchoolHonors: [],
};

export const degreeHonorsSchema = yup.object<Honor>().shape({
  name: yup.string().nullable().required(REQUIRED_MESSAGE),
  year: yup.string().nullable().required(REQUIRED_MESSAGE),
});

export const validationSchema = yup.object().shape({
  highSchool: yup.object().shape({
    includeOnResume: yup.boolean().nullable(),
    actScore: yup.number().nullable().optional(),
    city: yup
      .number()
      .nullable()
      .optional()
      .when(['state'], {
        is: state => !!state,
        then: yup.number().required(REQUIRED_MESSAGE),
      }),
    cityName: yup.string().nullable().optional(),
    countryCity: yup
      .string()
      .nullable()
      .optional()
      .when(['name', 'international'], {
        is: (name, international) => !!name && international,
        then: yup.string().nullable().required(REQUIRED_MESSAGE),
      }),
    country: yup
      .string()
      .nullable()
      .optional()
      .when(['name', 'international'], {
        is: (name, international) => !!name && international,
        then: yup.string().nullable().required(REQUIRED_MESSAGE),
      }),
    countryName: yup.string().nullable().optional(),
    includeActSatScoreOnResume: yup.boolean().optional(),
    includeHonorsOnResume: yup.boolean().optional(),
    international: yup.boolean().optional(),
    monthGraduation: yup
      .number()
      .nullable()
      .optional()
      .when(['name'], {
        is: name => !!name,
        then: yup.number().nullable().required(REQUIRED_MESSAGE),
      }),
    name: yup.string().when(['includeOnResume'], {
      is: includeOnResume => includeOnResume,
      then: yup.string().required(REQUIRED_MESSAGE),
    }),
    satScore: yup.number().nullable().optional(),
    state: yup
      .string()
      .nullable()
      .optional()
      .when(['name', 'international'], {
        is: (name, international) => !!name && !international,
        then: yup.string().nullable().required(REQUIRED_MESSAGE),
      }),
    stateName: yup.string().nullable().optional(),
    yearGraduation: yup
      .number()
      .nullable()
      .optional()
      .when(['name'], {
        is: name => !!name,
        then: yup.number().nullable().required(REQUIRED_MESSAGE),
      }),
  }),
  highSchoolHonors: yup.array(degreeHonorsSchema),
});
