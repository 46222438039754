import { operatingSystem } from 'constant';

const getMobileOperatingSystem = () => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/windows phone/i.test(userAgent)) {
    return operatingSystem.WINDOWS_PHONE;
  }

  if (/android/i.test(userAgent)) {
    return operatingSystem.ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return operatingSystem.IOS;
  }

  return operatingSystem.UNKNOWN;
};

export default getMobileOperatingSystem;
