import { defaultLines } from './default-lines';

export default {
  OTHER_EXPERIENCES: 'otherExperiences',
  PROFESSIONAL_EXPERIENCES: 'professionalExperiences',
  OMIT_FORMATTED_POSITION_INTERNATIONAL: [
    'city',
    'state',
    'switch',
    'countryName',
    'activeLines',
    'cityName',
    'stateName',
    'endYearName',
    'endMonthName',
    'industryName',
    'positionFunctionName',
    'startMonthName',
    'startYearName',
  ],
  OMIT_FORMATTED_POSITION: [
    'country',
    'countryCity',
    'countryName',
    'international',
    'switch',
    'activeLines',
    'stateName',
    'cityName',
    'endYearName',
    'endMonthName',
    'industryName',
    'positionFunctionName',
    'startMonthName',
    'startYearName',
  ],
  FIRST_EXPERIENCE_CONTROL: 'professionalExperiences.0',
  EMPTY_PROFESSIONAL_EXPERIENCE: {
    city: '',
    company: '',
    endMonth: '',
    endYear: '',
    industry: '',
    position: '',
    positionFunction: '',
    startMonth: '',
    startYear: '',
    state: '',
    international: false,
    isHighlighted: false,
    country: '',
    countryCity: '',
    lines: defaultLines,
    cityName: undefined,
    stateName: undefined,
    countryName: undefined,
    activeLines: true,
  },
  EMPTY_OTHER_EXPERIENCE: {
    group: '',
    city: '',
    company: '',
    endMonth: '',
    endYear: '',
    industry: '',
    position: '',
    positionFunction: '',
    startMonth: '',
    startYear: '',
    state: '',
    international: false,
    isHighlighted: false,
    organizationalCategories: [],
    country: '',
    countryCity: '',
    lines: defaultLines,
    cityName: undefined,
    countryName: undefined,
    endMonthName: undefined,
    endYearName: undefined,
    startMonthName: undefined,
    startYearName: undefined,
    stateName: undefined,
    activeLines: true,
  },
};
