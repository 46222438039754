import { Sidebar, SidebarItem } from '@hivediversity/common-lib/components';
import { routes } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import isEmpty from 'lodash/isEmpty';
import noop from 'lodash/noop';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { listLevels, progressStatus } from 'constant';
import { clearToken } from 'services/storage';
import progressCreator from 'store/progress/actions';
import { getIsLoading, getProgress } from 'store/progress/selectors';

const SidebarLogged = ({ ...props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const handleRedirect = useCallback(e => dispatch(push(e.target.id)), []);
  const getPathName = useCallback(path => location.pathname.startsWith(path), []);
  const userProgress = useSelector(getProgress);
  const isLoadingProgress = useSelector(getIsLoading);

  const handleLogout = () => {
    clearToken();
    window.location.href = routes.ROOT;
  };

  useEffect(() => {
    if (!isLoadingProgress && !userProgress) {
      dispatch(progressCreator.progressFetchRequest());
    }
  }, []);

  const levelPC =
    userProgress && !isEmpty(userProgress.levels) ? userProgress.levels.find(l => l.level === listLevels.PC) : null;

  return (
    <Sidebar {...props}>
      <SidebarItem
        id={routes.ROOT}
        onClick={handleRedirect}
        active={location.pathname === routes.ROOT}
        style={{ fontSize: '20px' }}
      >
        Home
      </SidebarItem>

      <SidebarItem
        id={routes.DASHBOARD}
        onClick={handleRedirect}
        active={getPathName(routes.DASHBOARD)}
        style={{ fontSize: '20px' }}
      >
        #FlyHire Portal
      </SidebarItem>

      <SidebarItem
        id={routes.PROFILE}
        onClick={levelPC && levelPC.status === progressStatus.COMPLETE ? handleRedirect : noop}
        active={getPathName(routes.PROFILE)}
        disabled={levelPC ? levelPC.status !== progressStatus.COMPLETE : true}
        style={{ fontSize: '20px' }}
      >
        Resume &amp; Profile
      </SidebarItem>

      <SidebarItem id="contact" onClick={noop} href="mailto:support@hellohive.com" style={{ fontSize: '20px' }}>
        <a href="mailto:support@hellohive.com" style={{ color: 'white' }}>
          Contact
        </a>
      </SidebarItem>

      <SidebarItem
        id={routes.ACCOUNT}
        onClick={handleRedirect}
        active={getPathName(routes.ACCOUNT)}
        style={{ fontSize: '20px' }}
      >
        My Account
      </SidebarItem>

      <SidebarItem id="privacy" onClick={noop} href="mailto:support@hellohive.com" style={{ fontSize: '20px' }}>
        <a href="https://hellohive.com/userprivacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
      </SidebarItem>

      <SidebarItem id="conditions" onClick={noop} href="mailto:support@hellohive.com" style={{ fontSize: '20px' }}>
        <a href="https://hellohive.com/userterms" target="_blank" rel="noopener noreferrer">
          Terms &amp; Conditions
        </a>
      </SidebarItem>

      <SidebarItem onClick={handleLogout} style={{ fontSize: '20px' }}>
        Sign Out
      </SidebarItem>
    </Sidebar>
  );
};

export default SidebarLogged;
