import http from './http';

export const requestLogin = (email, password) => {
  return http.post('/auth/login', { email, password });
};

export const requestRegister = user => {
  return http.post('/auth/register', user);
};

export const requestResetPassword = (email, password, passwordConfirmation, token) => {
  return http.post('/auth/resetPassword', { email, password, passwordConfirmation, token });
};

export const requestForgotPassword = email => {
  return http.post('/auth/forgotPassword', { email });
};

export const requestCheckExistEmail = email => {
  return http.post('/auth/emailExists', { email });
};

export const requestValidateToken = token => {
  return http.post('/auth/validToken', { token });
};

export const requestRetrySendEmail = email => {
  return http.post('/auth/retrySendRegisterEmail', { email });
};

export const requestImpersonate = code => {
  return http.post('/auth/impersonate', { code });
};

export const requestConfirmEmail = token => {
  return http.post('/auth/emailConfirmation', { token });
};
