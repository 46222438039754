export default {
  innerSearchButton: {
    background: 'transparent',
    position: 'absolute',
    padding: '0',
    width: '24px',
    height: '24px',
    minHeight: '24px',
    minWidth: '24px',
    top: '12px',
    left: '12px',
  },
  innerSearchButtonX: {
    background: 'transparent',
    position: 'absolute',
    padding: '0',
    width: '24px',
    height: '24px',
    minHeight: '24px',
    minWidth: '24px',
    top: '12px',
    right: '12px',
  },
  texBoxStyles: {
    height: '50px',
    borderRadius: '8px',
    backgroundColor: '#2d2a39',
    border: 'none',
    paddingLeft: '2.8rem',
    paddingRight: '2.8rem',
    marginBottom: '26px',
    fontSize: '1rem',
    fontFamily: 'Poppins',
    '&:hover, &:focus': {
      border: 'none',
    },
    ':-webkit-autofill': {
      transition: 'background-color 10000s ease 0s',
      background: 'none !important',
      '-webkit-text-fill-color': 'rgb(255, 255, 255) !important',
    },
    '&::placeholder': {
      color: 'var(--color-white)!important',
      fontSize: '1rem',
      '@media (max-width: 430px)': {
        fontSize: '12px',
      },
      '@media (max-width: 386px)': {
        fontSize: '11px',
      },
    },
    '@media (max-width: 500px)': {
      display: 'block',
    },
  },
};
