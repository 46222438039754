import { Row } from '@hivediversity/common-lib/base';
import { BeeIcon, IconButton, InfoIcon, CheckIcon } from '@hivediversity/common-lib/components/icons';
import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

const beeTooltip = `<div>Bullets with a ${renderToStaticMarkup(
  <IconButton
    hasAction={false}
    icon={BeeIcon}
    iconProps={{ width: 14, height: 14 }}
    bsStyle={{ display: 'inline-flex' }}
    background="#FFD633"
    padding="2"
  />
)} may<br/>be highlighted as<br/>prioritized content on<br/>the resume delivered to<br/>the hiring employer.</div>`;

const checkTooltip = `<div>Activate your<br/> lines with a ${renderToStaticMarkup(
  <IconButton
    hasAction={false}
    icon={CheckIcon}
    iconProps={{ width: 14, height: 14 }}
    bsStyle={{ display: 'inline-flex' }}
    background="#4ad153"
    padding="2"
  />
)} </div>`;

const getProfessionalTitle = hasHighLight =>
  hasHighLight ? (
    <Row $bsStyle={{ alignItems: 'center' }}>
      Work&nbsp;&nbsp;
      <IconButton
        icon={BeeIcon}
        background="#FFD633"
        hasAction={false}
        iconProps={{ width: 14, height: 14 }}
        bsStyle={{ width: '24px', height: '24px' }}
      />
      &nbsp;
      <IconButton
        icon={InfoIcon}
        hasAction={false}
        borderWidth={1}
        padding={2}
        bsStyle={{ borderColor: '#83A8AF', marginBottom: '5px', textTransform: 'none' }}
        iconProps={{ width: 11, height: 11 }}
        tooltip={beeTooltip}
        tooltipConfig={{ backgroundColor: 'black' }}
      />
      <IconButton
        icon={CheckIcon}
        background="#4ad153"
        hasAction={false}
        iconProps={{ width: 14, height: 14 }}
        bsStyle={{ width: '28px', height: '24px', marginLeft: '4px' }}
      />
      &nbsp;
      <IconButton
        icon={InfoIcon}
        hasAction={false}
        borderWidth={1}
        padding={2}
        bsStyle={{ borderColor: '#83A8AF', marginBottom: '5px', textTransform: 'none' }}
        iconProps={{ width: 11, height: 11 }}
        tooltip={checkTooltip}
        tooltipConfig={{ backgroundColor: 'black' }}
      />
    </Row>
  ) : (
    'Work'
  );

export default getProfessionalTitle;
