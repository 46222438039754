import { createReducer } from 'reduxsauce';

import { appTypes } from 'store/app/actions';
import { initialState } from 'store/app/initial-state';

const setLoadingEndpoint = (state, { key, loading }) => ({
  ...state,
  [`${key}_loading`]: loading,
});

const setEndpointFetchError = (state, { key, error }) => ({
  ...state,
  [`${key}_error`]: error,
});

const setData = (state, { key, data }) => ({
  ...state,
  [key]: data,
});

const notificationsRequest = state => ({
  ...state,
  notifications_isLoading: true,
  notifications_isSuccess: false,
  error: null,
});

const notificationsSuccess = (state, { notifications }) => ({
  ...state,
  notifications,
  notifications_isLoading: false,
  notifications_isSuccess: true,
  error: null,
});

const notificationsError = (state, { error }) => ({
  ...state,
  error,
  notifications: null,
  notifications_isLoading: false,
  notifications_isSuccess: false,
});

const notificationReadRequest = state => ({
  ...state,
  notification_read_isLoading: true,
  error: null,
});

const notificationReadSuccess = (state, { notifications }) => ({
  ...state,
  notifications,
  notification_read_isLoading: false,
  error: null,
});

const notificationReadError = (state, { error }) => ({
  ...state,
  error,
  notification_read_isLoading: false,
});

const reducer = createReducer(initialState, {
  [appTypes.SET_LOADING_ENDPOINT]: setLoadingEndpoint,
  [appTypes.SET_ENDPOINT_FETCH_ERROR]: setEndpointFetchError,
  [appTypes.SET_DATA]: setData,

  [appTypes.NOTIFICATIONS_REQUEST]: notificationsRequest,
  [appTypes.NOTIFICATIONS_SUCCESS]: notificationsSuccess,
  [appTypes.NOTIFICATIONS_ERROR]: notificationsError,

  [appTypes.NOTIFICATION_READ_REQUEST]: notificationReadRequest,
  [appTypes.NOTIFICATION_READ_SUCCESS]: notificationReadSuccess,
  [appTypes.NOTIFICATION_READ_ERROR]: notificationReadError,
});

export default reducer;
