import { month as monthOptions } from '@hivediversity/common-lib/constant';
import { Form, Formik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';

import { BackButton, ForwardButton } from 'components/v2/buttons';
import { Checkbox, FormActionButtonsRow, FormRow, Input, Label, Radio, Select } from 'components/v2/forms/components';
import { HeadingLevel } from 'components/v2/heading';
import { Paper } from 'components/v2/paper';
import { levelRoutes, LEVEL_STEPS } from 'constant';
import { useMappedLevelFormValues } from 'pages/v2/level/hooks/useMappedLevelFormValues';
import levelActionsCreator from 'store/v2/level/actions';
import sharedActionsCreator from 'store/v2/shared/actions';
import {
  cacheStateCitiesFailureSelector,
  cacheStateCitiesIsLoadingSelector,
  cacheStateCitiesSelector,
  locationCountriesIsLoadingSelector,
  locationCountriesSelector,
  locationStatesErrorSelector,
  locationStatesIsLoadingSelector,
  locationStatesSelector,
  yearOptionsIsLoadingSelector,
  yearOptionsLoadingErrorSelector,
  yearOptionsSelector,
} from 'store/v2/shared/selectors';
import { getOption } from 'utils';

import { validationSchema } from './form-config';
import { HighSchoolHonors } from './high-school-honors';
import { RadioWrapper, StyledHeading, StyledParagraph } from '../ResearchAndPrograms/ResearchAndPrograms.styled';

const HighSchool = () => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const initialValues = useMappedLevelFormValues(LEVEL_STEPS.highSchool);

  return (
    <div className="container px-0">
      <FormRow>
        <div className="col-12 d-flex">
          <StyledHeading level={HeadingLevel.h2}>High School Info</StyledHeading>
        </div>
        <div className="col-12">
          <StyledParagraph>
            Would you like to include your high school <br /> information on your resume?
          </StyledParagraph>
        </div>
      </FormRow>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={true}
        onSubmit={values => {
          dispatch(
            levelActionsCreator.saveLevelFormValuesRequest(LEVEL_STEPS.highSchool, values, true, levelRoutes.academics)
          );
        }}
      >
        {({ values, setFieldValue, isValid, handleSubmit, handleChange, handleBlur }) => {
          const handleGoBack = useCallback(() => {
            if (values.highSchool.name) {
              dispatch(
                levelActionsCreator.saveLevelFormValuesRequest(
                  LEVEL_STEPS.highSchool,
                  values,
                  true,
                  levelRoutes.contactInformation
                )
              );
            } else {
              push(levelRoutes.contactInformation);
            }
          }, [values, push]);

          const isStatesOptionsLoading = useSelector(locationStatesIsLoadingSelector);
          const statesOptions = useSelector(locationStatesSelector);
          const statesOptionsLoadFailure = useSelector(locationStatesErrorSelector);

          const isCountriesOptionsLoading = useSelector(locationCountriesIsLoadingSelector);
          const countriesOptions = useSelector(locationCountriesSelector);
          const countriesOptionsLoadFailure = useSelector(locationStatesErrorSelector);
          const isCacheStateCitiesOptionsLoading = useSelector(
            cacheStateCitiesIsLoadingSelector(values.highSchool.state || '')
          );
          const cacheStateCitiesOptions = useSelector(cacheStateCitiesSelector(values.highSchool.state || ''));
          const cacheStateCitiesOptionsLoadFailure = useSelector(
            cacheStateCitiesFailureSelector(values.highSchool.state || '')
          );
          const isYearOptionsLoading = useSelector(yearOptionsIsLoadingSelector);
          const yearOptions = useSelector(yearOptionsSelector);
          const yearOptionsError = useSelector(yearOptionsLoadingErrorSelector);

          const handleLocationInfoChange = useCallback(
            e => {
              const international = e.target.value === 'International';

              if (international) {
                setFieldValue(`highSchool.state`, '', true);
                setFieldValue(`highSchool.city`, '', true);
                setFieldValue(`highSchool.cityId`, '', true);
                setFieldValue(`highSchool.satScore`, null, true);
                setFieldValue(`highSchool.actScore`, null, true);
              } else {
                setFieldValue(`highSchool.country`, '', true);
                setFieldValue(`highSchool.countryName`, '', true);
              }
              setFieldValue(`highSchool.international`, international, true);
            },
            [setFieldValue]
          );

          useEffect(() => {
            if (!isStatesOptionsLoading && statesOptions.length === 0 && !statesOptionsLoadFailure) {
              dispatch(sharedActionsCreator.statesOptionsRequest());
            }

            if (!isCountriesOptionsLoading && countriesOptions.length === 0 && !countriesOptionsLoadFailure) {
              dispatch(sharedActionsCreator.countriesOptionsRequest());
            }

            if (!isYearOptionsLoading && yearOptions.length === 0 && !yearOptionsError) {
              dispatch(sharedActionsCreator.yearOptionsRequest());
            }
          }, [
            statesOptions.length,
            isStatesOptionsLoading,
            countriesOptions.length,
            isCountriesOptionsLoading,
            yearOptions.length,
          ]);

          useEffect(() => {
            if (
              values?.highSchool?.state &&
              !isCacheStateCitiesOptionsLoading &&
              cacheStateCitiesOptions.length === 0 &&
              !cacheStateCitiesOptionsLoadFailure
            ) {
              dispatch(sharedActionsCreator.cacheStateCitiesOptionsRequest(values?.highSchool.state));
            }
          }, [
            values?.highSchool?.state,
            isCacheStateCitiesOptionsLoading,
            cacheStateCitiesOptions.length,
            cacheStateCitiesOptionsLoadFailure,
          ]);

          const handleSelectChange = useCallback(
            (value, metaData) => {
              setFieldValue(metaData?.name, value?.id, true);
            },
            [setFieldValue]
          );

          const handleCountryChange = useCallback(
            (value, metaData) => {
              handleSelectChange(value, metaData);
              setFieldValue(`highSchool.countryName`, value?.name);
            },
            [setFieldValue, values, handleSelectChange]
          );

          const handleStateCityChange = useCallback(
            (value, metaData) => {
              handleSelectChange(value, metaData);
              setFieldValue(`highSchool.city`, value?.id);
            },
            [setFieldValue, values, handleSelectChange]
          );

          const handleStateChange = useCallback(
            (value, metaData) => {
              handleSelectChange(value, metaData);
              setFieldValue(`highSchool.stateName`, value?.name);
              setFieldValue(`highSchool.city`, '');
            },
            [setFieldValue, values, handleSelectChange]
          );

          const handleMonthsChange = useCallback(
            (value, metaData) => {
              handleSelectChange(value, metaData);
              setFieldValue(`highSchool.monthGraduation`, value?.id);
            },
            [setFieldValue, values, handleSelectChange]
          );

          const handleYearChange = useCallback(
            (value, metaData) => {
              handleSelectChange(value, metaData);
              setFieldValue(`highSchool.yearGraduation`, value?.id);
            },
            [setFieldValue, values, handleSelectChange]
          );

          const updateShowForm = useCallback(show => {
            setFieldValue(`highSchool.includeOnResume`, show);
          }, []);

          const debouncedSaveLevelFormValues = useDebouncedCallback(values => {
            dispatch(levelActionsCreator.setLevelStepFormValues(LEVEL_STEPS.highSchool, values));
          }, 600);

          useEffect(() => {
            debouncedSaveLevelFormValues(values);
          }, [values]);

          return (
            <>
              <div className="col-12">
                <RadioWrapper>
                  <Radio
                    name="highSchool.includeOnResume"
                    optionName="Yes"
                    value="yes"
                    checked={values['highSchool']?.includeOnResume}
                    onChange={() => updateShowForm(true)}
                  />
                  <Radio
                    name="highSchool.includeOnResume"
                    optionName="No"
                    value="no"
                    checked={!values['highSchool']?.includeOnResume}
                    onChange={() => updateShowForm(false)}
                  />
                </RadioWrapper>
              </div>
              <Form className="container px-0" onSubmit={handleSubmit}>
                <div>
                  {values['highSchool']?.includeOnResume && (
                    <>
                      <Paper className="mt-4">
                        <Input
                          name="highSchool.name"
                          label="Name of High School"
                          placeholder="Name of High School (Write In)"
                          value={values.highSchool.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          autoComplete="off"
                          required
                        />
                        <FormRow>
                          <div className="col-12">
                            <Label>Location</Label>
                          </div>
                          <div className="col-3">
                            <Radio
                              name="international"
                              className="mb-3"
                              optionName="US"
                              value="US"
                              onChange={handleLocationInfoChange}
                              checked={!values.highSchool.international}
                            />
                          </div>
                          <div className="col-6">
                            <Radio
                              name="international"
                              className="mb-3"
                              optionName="International"
                              value="International"
                              onChange={handleLocationInfoChange}
                              checked={values.highSchool.international}
                            />
                          </div>
                        </FormRow>
                        <FormRow>
                          <Label required={!!values.highSchool.name}>Location Information</Label>
                          {!values?.highSchool.international && (
                            <>
                              <div className="col-6 mt-2">
                                <Select
                                  className="mb-0"
                                  name="highSchool.state"
                                  placeholder="State"
                                  value={getOption(statesOptions, values.highSchool.state, 'id')}
                                  options={statesOptions}
                                  onChange={handleStateChange}
                                  isLoading={isStatesOptionsLoading}
                                />
                              </div>
                              <div className="col-6 mt-2">
                                <Select
                                  className="mb-0"
                                  name="highSchool.city"
                                  placeholder="City"
                                  value={getOption(cacheStateCitiesOptions, values.highSchool.city, 'id')}
                                  options={cacheStateCitiesOptions}
                                  onChange={handleStateCityChange}
                                  isLoading={isCacheStateCitiesOptionsLoading}
                                />
                              </div>
                            </>
                          )}
                          {values?.highSchool.international && (
                            <>
                              <div className="col-6 mt-2">
                                <Select
                                  className="mb-0"
                                  name="highSchool.country"
                                  placeholder="Country"
                                  value={getOption(countriesOptions, values.highSchool.country, 'id')}
                                  options={countriesOptions}
                                  onChange={handleCountryChange}
                                  isLoading={isCountriesOptionsLoading}
                                />
                              </div>
                              <div className="col-6 mt-2">
                                <Input
                                  className="mb-0"
                                  name="highSchool.countryCity"
                                  placeholder="City (Write In)"
                                  value={values.highSchool.countryCity || ''}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </>
                          )}
                        </FormRow>
                        <FormRow>
                          <div className="col-12 mt-4">
                            <Label required={!!values.highSchool.name}>Graduation Date</Label>
                          </div>
                          <div className="col-6">
                            <Select
                              className="mb-3"
                              name="highSchool.monthGraduation"
                              placeholder="Month"
                              value={getOption(monthOptions, values.highSchool.monthGraduation, 'id')}
                              options={monthOptions}
                              onChange={handleMonthsChange}
                            />
                          </div>
                          <div className="col-6">
                            <Select
                              className="mb-3"
                              name="highSchool.yearGraduation"
                              placeholder="Year"
                              value={getOption(yearOptions, values.highSchool.yearGraduation, 'id')}
                              options={yearOptions}
                              onChange={handleYearChange}
                              isLoading={isYearOptionsLoading}
                            />
                          </div>
                        </FormRow>
                      </Paper>
                      {!values?.highSchool.international && (
                        <Paper className="mb-5 mt-3">
                          <FormRow>
                            <Input
                              name="highSchool.actScore"
                              label="ACT"
                              placeholder="ACT Score (Write In)"
                              value={values.highSchool.actScore ?? ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete="off"
                            />
                          </FormRow>
                          <FormRow>
                            <Input
                              name="highSchool.satScore"
                              label="SAT"
                              placeholder="SAT Score (Write In)"
                              value={values.highSchool.satScore ?? ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              autoComplete="off"
                            />
                          </FormRow>
                          <FormRow className="mb-0">
                            <Checkbox
                              className="mb-0"
                              name="highSchool.includeActSatScoreOnResume"
                              optionName="Include on resume and profile."
                              checked={values?.highSchool.includeActSatScoreOnResume}
                              onChange={handleChange}
                            />
                          </FormRow>
                        </Paper>
                      )}
                      <Paper className="mb-5 mt-3">
                        <FormRow>
                          <HighSchoolHonors />
                        </FormRow>
                      </Paper>
                    </>
                  )}
                  <FormActionButtonsRow>
                    <BackButton
                      className="col-6 col-md-3"
                      disabled={!isValid || isCountriesOptionsLoading || isStatesOptionsLoading}
                      onClick={handleGoBack}
                    />
                    <ForwardButton
                      className="col-6 col-md-3"
                      type="submit"
                      isLoading={isCountriesOptionsLoading || isStatesOptionsLoading}
                      disabled={!isValid || isCountriesOptionsLoading || isStatesOptionsLoading}
                    />
                  </FormActionButtonsRow>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default HighSchool;
