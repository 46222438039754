import http from './http';

export const requestConfirmEmail = token => {
  return http.post('/user/confirmEmail', { token });
};

export const requestUpdateUser = user => {
  return http.patch('/user/update', user);
};

export const requestUser = () => {
  return http.get('/user');
};
