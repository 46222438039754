import { routes } from '@hivediversity/common-lib/constant';
import { push } from 'connected-react-router';
import { takeLatest, call, put } from 'redux-saga/effects';

import { emptyProgress } from 'constant';
import {
  requestLogin,
  requestRegister,
  requestForgotPassword,
  requestResetPassword,
  requestCheckExistEmail,
  requestValidateToken,
  requestRetrySendEmail,
  requestImpersonate,
  requestConfirmEmail,
} from 'services/authorization';
import { setAuthorization } from 'services/http';
import { fetchProgress } from 'services/progress';
import { getToken } from 'services/storage';
import authCreator, { authTypes } from 'store/authorization/actions';
import progressCreator from 'store/progress/actions';
import { i18n, isEmpty, getResponseError, trackCompleteRegistration } from 'utils';

export function* login({ email, password, redirectTo }) {
  try {
    const response = yield call(requestLogin, email, password);
    const error = yield getResponseError(response);

    if (!error) {
      yield setAuthorization(response.data.token);
      yield put(authCreator.authLoginSuccess(response.data.user, response.data.token));
      if (redirectTo) {
        yield put(push(redirectTo));
      } else {
        yield put(push(routes.ROOT));
      }
    } else {
      yield put(authCreator.authLoginError(error));
    }
  } catch (error) {
    yield put(authCreator.authLoginError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* register({ user }) {
  try {
    const response = yield call(requestRegister, user);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(authCreator.authRegisterSuccess(response.data.message));
      trackCompleteRegistration();
    } else {
      yield put(authCreator.authRegisterError(error));
    }
  } catch (error) {
    yield put(authCreator.authRegisterError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* forgotPassword({ email }) {
  try {
    const response = yield call(requestForgotPassword, email);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(authCreator.authForgotPasswordSuccess());
    } else {
      yield put(authCreator.authForgotPasswordError(error));
    }
  } catch (error) {
    yield put(authCreator.authForgotPasswordError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* resetPassword({ email, password, passwordConfirmation, token }) {
  try {
    const response = yield call(requestResetPassword, email, password, passwordConfirmation, token);
    const error = yield getResponseError(response);

    if (!error) {
      if (response.data.success) {
        yield put(authCreator.authResetPasswordSuccess());
      } else {
        yield put(authCreator.authResetPasswordError(i18n.t('errors.TOKEN_ERROR')));
      }
    } else {
      yield put(authCreator.authResetPasswordError(error));
    }
  } catch (error) {
    yield put(authCreator.authResetPasswordError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* checkToken({ token }) {
  try {
    const response = yield call(requestValidateToken, token);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(authCreator.authCheckTokenSuccess(response.data.message));
    } else {
      yield put(authCreator.authCheckTokenError(error));
    }
  } catch (error) {
    yield put(authCreator.authCheckTokenError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* checkEmail({ email }) {
  try {
    const response = yield call(requestCheckExistEmail, email);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(authCreator.authCheckEmailSuccess(response.data.exists, response.data.message));
    } else {
      yield put(authCreator.authCheckEmailError(error));
    }
  } catch (error) {
    yield put(authCreator.authCheckEmailError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* retrySendEmail({ email }) {
  try {
    const response = yield call(requestRetrySendEmail, email);
    const error = yield getResponseError(response);

    if (!error) {
      yield put(authCreator.authRetrySendEmailSuccess(response.data.message));
    } else {
      yield put(authCreator.authRetrySendEmailError(error));
    }
  } catch (error) {
    yield put(authCreator.authRetrySendEmailError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* impersonate({ code }) {
  try {
    const response = yield call(requestImpersonate, code);
    const error = yield getResponseError(response);

    if (!error) {
      yield setAuthorization(response.data.token);
      const responseProgress = yield call(fetchProgress);
      const { progress } = responseProgress.data;
      yield put(progressCreator.progressUpdateSuccess(!isEmpty(progress) ? progress : emptyProgress));
      yield put(authCreator.authImpersonateSuccess(response.data.user, response.data.token));
      yield put(push(routes.ROOT));
    } else {
      yield put(authCreator.authImpersonateError(error));
    }
  } catch (error) {
    yield put(authCreator.authImpersonateError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* confirmEmail({ code }) {
  try {
    const response = yield call(requestConfirmEmail, code);
    const error = yield getResponseError(response);

    if (!error) {
      yield setAuthorization(response.data.token);
      const responseProgress = yield call(fetchProgress);
      const { progress } = responseProgress.data;
      yield put(progressCreator.progressUpdateSuccess(!isEmpty(progress) ? progress : emptyProgress));
      yield put(authCreator.authConfirmEmailSuccess(response.data.user, response.data.token));
      yield put(push(routes.ROOT));
    } else {
      yield put(authCreator.authConfirmEmailError(error));
    }
  } catch (error) {
    yield put(authCreator.authConfirmEmailError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* tokenRequest() {
  try {
    const token = yield call(getToken);
    yield put(authCreator.authGetTokenSuccess(token));
  } catch (error) {
    yield put(authCreator.authGetTokenError(i18n.t('errors.GENERAL_ERROR')));
  }
}

export function* authorizationSaga() {
  yield takeLatest(authTypes.AUTH_LOGIN_REQUEST, login);
  yield takeLatest(authTypes.AUTH_IMPERSONATE_REQUEST, impersonate);
  yield takeLatest(authTypes.AUTH_REGISTER_REQUEST, register);
  yield takeLatest(authTypes.AUTH_FORGOT_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(authTypes.AUTH_RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(authTypes.AUTH_CHECK_TOKEN_REQUEST, checkToken);
  yield takeLatest(authTypes.AUTH_CHECK_EMAIL_REQUEST, checkEmail);
  yield takeLatest(authTypes.AUTH_RETRY_SEND_EMAIL_REQUEST, retrySendEmail);
  yield takeLatest(authTypes.AUTH_CONFIRM_EMAIL_REQUEST, confirmEmail);
  yield takeLatest(authTypes.AUTH_GET_TOKEN_REQUEST, tokenRequest);
}
