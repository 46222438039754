import { createReducer } from 'reduxsauce';

import { registerTypes } from 'store/register/actions';
import { initialState } from 'store/register/initial-state';

const registerStoreSaveFormData = (state, { formData }) => ({
  ...state,
  ...formData,
});

const reducer = createReducer(initialState, {
  [registerTypes.REGISTER_STORE_SAVE_FORM_DATA]: registerStoreSaveFormData,
});

export default reducer;
