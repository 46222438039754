import { assignment } from 'constant';

import getAssignmentHeaderLines from './get-assignment-header-lines';
import getEnabledLines from './get-enabled-lines';

const getTotalLines = (setTotalLines, setTotalExtra, totalExtra, values) => {
  const otherExperiencesLines = getEnabledLines(values[assignment.OTHER_EXPERIENCES]);
  const professionalExperiencesLines = getEnabledLines(values[assignment.PROFESSIONAL_EXPERIENCES]);
  const otherExperiencesExtraLines = getAssignmentHeaderLines(values[assignment.OTHER_EXPERIENCES], 1);
  const professionalExperiencesExtraLines = getAssignmentHeaderLines(values[assignment.PROFESSIONAL_EXPERIENCES], 2);
  const newTotalExtra = otherExperiencesExtraLines + professionalExperiencesExtraLines;
  setTotalLines(professionalExperiencesLines + otherExperiencesLines);
  if (newTotalExtra !== totalExtra) {
    setTotalExtra(newTotalExtra);
  }
};

export default getTotalLines;
