import PropTypes from 'prop-types';
import React from 'react';

import { ButtonStyled } from './styled';

const Button = ({ child, active, onClick, $bsStyle }) => (
  <ButtonStyled type="button" disabled={child.props.disabled} onClick={onClick} $active={active} $bsStyle={$bsStyle}>
    {child.props.title}
  </ButtonStyled>
);

Button.propTypes = {
  child: PropTypes.shape({
    props: PropTypes.shape({
      id: PropTypes.string,
      disabled: PropTypes.bool,
      title: PropTypes.string,
    }),
  }).isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  $bsStyle: PropTypes.shape({}),
};

Button.defaultProps = {
  $bsStyle: undefined,
  active: false,
};

export default Button;
