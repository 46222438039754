import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import app from 'store/app/reducers';
import authorization from 'store/authorization/reducers';
import company from 'store/company/reducers';
import search from 'store/dashboard/reducers';
import level from 'store/level/reducers';
import opportunity from 'store/opportunity/reducers';
import progress from 'store/progress/reducers';
import register from 'store/register/reducers';
import staticData from 'store/static-data/reducers';
import student from 'store/student/reducers';
import user from 'store/user/reducers';
import level_v2 from 'store/v2/level/reducers';
import register_v2 from 'store/v2/register/reducers';
import shared from 'store/v2/shared/reducers';

export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    authorization,
    level,
    student,
    progress,
    app,
    opportunity,
    company,
    staticData,
    search,
    register,
    v2: combineReducers({
      register: register_v2,
      shared,
      level: level_v2,
    }),
  });
